import { UserOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Avatar, Tag } from 'antd';
import { FC } from 'react';

import { EUserStatus } from '../../api/features/auth/types';
import { UserColorByStatus, UserTextByStatus } from '../../utils/user/status';

const ProfileInfoWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ProfileTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.MAIN.TEXT.C100};
`;

const ProfileName = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

type TProps = {
  name: string;
  status: EUserStatus;
};

export const UserCard: FC<TProps> = ({ name, status }) => {
  return (
    <ProfileInfoWrapper aria-label="Информация о текущем пользователе">
      <div aria-hidden="true">
        <Avatar size={64} icon={<UserOutlined />} />
      </div>
      <ProfileTextWrapper>
        <ProfileName aria-label="Email текущего пользователя">
          {name}
        </ProfileName>
        <div>
          <Tag
            color={UserColorByStatus[status]}
            aria-label="Статус текущего пользователя"
          >
            {UserTextByStatus[status]}
          </Tag>
        </div>
      </ProfileTextWrapper>
    </ProfileInfoWrapper>
  );
};
