import { TWidgetSettings } from '../../../api/features/widget/types';
import {
  EWidgetPresetThemeKey,
  WidgetPresetThemeColors,
} from '../../../styles/widget-preset-style';

const DARK_THEME: TWidgetSettings['theme']['COLORS'] = {
  COMMON: {
    ACCENT: WidgetPresetThemeColors[EWidgetPresetThemeKey.DEFAULT],
    ON_ACCENT: '#f3f3f3',
    BASE_RADIUS: 8,
    TEXT_COMMON: '#e5e5e5',
  },
  ACCESS_BUTTON: {
    MAIN_COLOR: WidgetPresetThemeColors[EWidgetPresetThemeKey.DEFAULT],
    SECONDARY_COLOR: WidgetPresetThemeColors[EWidgetPresetThemeKey.DEFAULT],
    INSIDE_ELEMENTS_COLOR: '#e5e5e5',
  },
  MENU: {
    SHADOW: '0 0 20px rgba(0, 0, 0, 0.2)',
    BACKGROUND: '#2f3742',
    SCROLLBAR_BG: '#878E9A',
    SCROLLBAR_BG_HOVERED: '#A6ACB6',
    HEADER_BG: '#2f3742',
    HEADER_TEXT_COLOR: '#e5e5e5',
    TEXT_ON_MENU: '#e5e5e5',
    RESET_BUTTON: {
      BACKGROUND: WidgetPresetThemeColors[EWidgetPresetThemeKey.DEFAULT],
      BACKGROUND_HOVERED:
        WidgetPresetThemeColors[EWidgetPresetThemeKey.DEFAULT],
      TEXT: '#e5e5e5',
    },
    MENU_ITEM: {
      BORDER_RADIUS: 8,
      BACKGROUND: '#2f3742',
      BORDER: '#434141',
      TITLE: '#ffffff',
      TEXT: '#e5e5e5',
      SWITCH_BG: '#151B28',
    },
    SUB_GROUP: {
      BACKGROUND: '#3a4350',
      BORDER_RADIUS: 16,
      TITLE_COLOR: '#ffffff',
    },
  },
};

export default DARK_THEME;
