import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { getIconBaseColor } from '../../utils/ui/icons';
import { TBaseIconProps } from './index';

const HomeIcon: FC<TBaseIconProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_551_1671)">
        <path
          d="M9 11.2437C7.75737 11.2437 6.75 12.251 6.75 13.4937V17.9937H11.25V13.4937C11.25 12.251 10.2426 11.2437 9 11.2437Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
        <path
          d="M12.75 13.4942V17.9942H15.75C16.9926 17.9942 18 16.9868 18 15.7442V8.90342C18.0002 8.51379 17.8488 8.13937 17.5777 7.85942L11.2043 0.969152C10.0797 -0.247606 8.18167 -0.322313 6.96491 0.802265C6.90711 0.855702 6.85143 0.911355 6.79802 0.969152L0.435762 7.85717C0.156551 8.13828 -0.000105416 8.51846 5.32206e-08 8.91467V15.7442C5.32206e-08 16.9868 1.00737 17.9942 2.25 17.9942H5.24999V13.4942C5.26402 11.4491 6.9152 9.77899 8.9088 9.7309C10.9691 9.68119 12.7343 11.3797 12.75 13.4942Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
        <path
          d="M9 11.2437C7.75737 11.2437 6.75 12.251 6.75 13.4937V17.9937H11.25V13.4937C11.25 12.251 10.2426 11.2437 9 11.2437Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
      </g>
      <defs>
        <clipPath id="clip0_551_1671">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(HomeIcon);
