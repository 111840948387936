import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from '../../base-query';
import { usersApi } from '../users';
import {
  TAuthPayload,
  TAuthResponse,
  TRegistrationPayload,
  TUserDataResponse,
} from './types';

export const authApi = createApi({
  reducerPath: 'auth/api',
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<TAuthResponse, TAuthPayload>({
      query: (data) => ({
        url: '/auth/signin',
        method: 'POST',
        body: data,
      }),
    }),
    registration: builder.mutation<TAuthResponse, TRegistrationPayload>({
      query: (data) => ({
        url: '/auth/signup',
        method: 'POST',
        body: data,
      }),
      async onCacheEntryAdded(arg, api) {
        if (arg.skipAccountSetup) {
          api.dispatch(
            usersApi.endpoints.getAll.initiate(undefined, {
              forceRefetch: true,
            })
          );
        }
      },
    }),
    getMyUserData: builder.query<TUserDataResponse, void>({
      query: () => ({
        url: 'auth/me',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetMyUserDataQuery,
  useLoginMutation,
  useRegistrationMutation,
} = authApi;
