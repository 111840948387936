import { message, notification } from 'antd';
import { FC, PropsWithChildren, createContext, useContext } from 'react';

import { NotificationInstance } from 'antd/lib/notification/interface';

export type TMessageAPI = ReturnType<typeof message.useMessage>[0];

type TContext = {
  messageApi: TMessageAPI;
  notificationApi: NotificationInstance;
};

const context = createContext<TContext>({
  messageApi: message,
  notificationApi: notification,
});

export const useAppMessage = () => useContext(context);

export const NotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();

  return (
    <context.Provider
      value={{
        messageApi,
        notificationApi,
      }}
    >
      {notificationContextHolder}
      {messageContextHolder}
      {children}
    </context.Provider>
  );
};
