import dayjs, { Dayjs } from 'dayjs';

/**
 * Метод для приведения серверного времени к клиентскому часовому поясу.
 * По умолчанию у сервера UTC +2
 * @returns {Dayjs}
 *
 * @example
 * const clientUTCDate = withServerUtcOffset.call(dayjs(YOUR_DATE)).format('DD.MM.YYYY HH:mm')
 */
export function withServerUtcOffset(this: Dayjs): Dayjs {
  return this.add(dayjs().utcOffset() - 120, 'minutes');
}
