import { FC, Fragment, PropsWithChildren, useEffect, useRef } from 'react';

import { PRESET_DEPENDENCIES, WIDGET_PRESETS_LIST } from '../presets';
import { useAppDispatch } from '../state';
import {
  useEffectsSelector,
  usePresetSelector,
} from '../state/reducers/widget/selectors';
import { setPresetAction } from '../state/reducers/widget/slices/presetSlice';

export const PresetService: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const presetData = usePresetSelector();
  const effects = useEffectsSelector();

  const presetRef = useRef(presetData);

  useEffect(() => {
    presetRef.current = presetData;
  }, [presetData]);

  useEffect(() => {
    WIDGET_PRESETS_LIST.map((el) => {
      const deps = PRESET_DEPENDENCIES[el.key];

      let isAllDepsTrue = true;

      deps.forEach((dp) => {
        if (
          typeof dp.value === 'number' &&
          typeof effects[dp.key] === 'number' &&
          (effects[dp.key].value as number) > 10
        ) {
          if ((effects[dp.key].value as number) < dp.value) {
            isAllDepsTrue = false;
          }
        } else if (effects[dp.key].value !== dp.value) {
          isAllDepsTrue = false;
        }
      });

      if (presetRef.current[el.key] !== isAllDepsTrue) {
        dispatch(
          setPresetAction({
            key: el.key,
            value: isAllDepsTrue,
          })
        );
      }
    });
  }, [effects]);

  return <Fragment>{children}</Fragment>;
};
