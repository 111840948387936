import { FC, PropsWithChildren, useEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import i18next from '../locale';
import { useLocaleSelector } from '../state/app/selectors';

export const LocaleProvider: FC<PropsWithChildren> = ({ children }) => {
  const { i18n } = useTranslation();

  const { current } = useLocaleSelector();

  useEffect(() => {
    void i18n.changeLanguage(current);
  }, [current, i18n]);

  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;
};
