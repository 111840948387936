import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';

import { EPaths } from '../../../routes/route-paths';
import PlusIcon from '../../icons/plus-icon';
import { WidgetPaneWrapper } from './index';

const IconWrapper = styled('div')`
  width: 2.625rem;
  height: 2.625rem;

  svg {
    width: 100%;
    height: 100%;

    path {
      transition: fill 0.3s ease-out;
    }
  }
`;

const WrapperButton = WidgetPaneWrapper.withComponent(NavLink);

const Wrapper = styled(WrapperButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.MAIN.WHITE.C200};
  transition: border-color 0.3s ease-out;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.MAIN.ACCENT.C300};
  }

  &:hover {
    border-color: ${({ theme }) => theme.MAIN.ACCENT.C100};
  }
`;

const Label = styled('span')`
  font-size: 0.75rem;
  margin-top: 0.75rem;
  color: ${({ theme }) => theme.MAIN.TEXT.C200};
`;

type TProps = {
  theme: Theme;
  text: string;
};

const WidgetAddNewButton: FC<TProps> = memo(({ theme, text }) => {
  return (
    <Wrapper
      primarycolor={theme.MAIN.WHITE.C200}
      textcolor={theme.MAIN.TEXT.C200}
      to={EPaths.CREATE_WIDGET}
      aria-label="Создать виджет"
    >
      <IconWrapper>
        <PlusIcon />
      </IconWrapper>
      <Label>{text}</Label>
    </Wrapper>
  );
});

export default withTheme(WidgetAddNewButton);
