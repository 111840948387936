export function getElementByXPath(xpath: string): HTMLElement | null {
  try {
    const result = document.evaluate(
      xpath,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    );
    return result.singleNodeValue as HTMLElement | null;
  } catch (e) {
    return null;
  }
}

export function getXPath(element: HTMLElement): string | null {
  try {
    if (element.id) {
      return `//*[@id="${element.id}"]`;
    }
    if (element === document.body) {
      return '/html/body';
    }

    let index = 0;
    const siblings = element.parentNode?.childNodes;

    if (siblings) {
      for (let i = 0; i < siblings.length; i++) {
        const sibling = siblings[i] as HTMLElement;
        if (sibling === element) {
          return (
            getXPath(element.parentNode as HTMLElement) +
            '/' +
            element.tagName.toLowerCase() +
            `[${index + 1}]`
          );
        }
        if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
          index++;
        }
      }
    }

    return null;
  } catch (e) {
    return null;
  }
}
