import styled from '@emotion/styled';
import { Alert, Button, ConfigProvider, Form, FormInstance, Input } from 'antd';
import { TFunction } from 'i18next';
import { CSSProperties, FC, memo, useCallback, useMemo } from 'react';

import {
  TWidgetBaseForm,
  TWidgetCreateForm,
} from '../../api/features/widget/types';
import { FormLabel } from '../../components/forms/base-form-components';
import { useCurrentTheme } from '../../providers/theme-provider';
import { useAppSelector } from '../../state';
import { useCanCreateWidgetSelector } from '../../state/widgets/selectors';
import { EWidgetSettingsFormNames } from '../../types/forms/widget-settings';
import {
  ALLOWED_URL_REGEX_LIST,
  URL_REGEX,
  WIDGET_FOLDER_REGEX,
} from '../../utils/regex';
import { WidgetAdditionalDomainsForm } from './widget-settings/widget-additional-domains-form';

const buttonStyles: CSSProperties = {
  width: '100%',
};

const Wrapper = styled('div')`
  max-width: 320px;
  width: 320px;

  .ant-form-item-label {
    padding-left: 0.75rem;
  }
`;

const AlertStyled = styled(Alert)`
  margin-bottom: 24px;
  padding: 0.75rem;
`;

type TProps = {
  onFormFinish: (form: TWidgetBaseForm) => void;
  checkWidgetFolder: (folder: string) => Promise<boolean>;
  form: FormInstance<TWidgetCreateForm>;
  t: TFunction;
};

const getFormRules = (
  t: TProps['t'],
  form: FormInstance,
  checkWidgetFolder: TProps['checkWidgetFolder']
) => ({
  domain: [
    {
      required: true,
      message: t('form.rules.required'),
    },
    {
      pattern: URL_REGEX,
      message: t('form.rules.urlInvalid'),
    },
    {
      validator: (_: any, value: string) =>
        ALLOWED_URL_REGEX_LIST.test(value)
          ? Promise.reject(new Error(t('form.rules.urlUnSupport')))
          : Promise.resolve(),
    },
    {
      validator: (_: any, value: string) =>
        value.endsWith('/')
          ? Promise.reject(new Error('Вводите адрес без "/" в конце'))
          : Promise.resolve(),
    },
  ],
  widgetFolder: [
    {
      required: true,
      message: t('form.rules.required'),
    },
    {
      pattern: WIDGET_FOLDER_REGEX,
      message: t('form.rules.nameOnlyInLatin'),
    },
    {
      validator: (_: any, value?: string) => {
        if (value) {
          return new Promise((res, rej) => {
            checkWidgetFolder(value)
              .then((response) => {
                if (response) {
                  rej(t('form.rules.nameAlreadyUsed'));
                } else {
                  res({});
                }
              })
              .catch(() => {
                rej(t('form.rules.errorOnNameValidate'));
              });
          });
        } else {
          return Promise.resolve();
        }
      },
    },
  ],
});

export const CreateWidgetForm: FC<TProps> = memo(
  ({ onFormFinish, checkWidgetFolder, form, t }) => {
    const theme = useCurrentTheme();
    const isAddWidgetAvailable = useAppSelector(useCanCreateWidgetSelector);

    const handleFormFinish = useCallback(
      (values: TWidgetCreateForm) => {
        if (
          Array.isArray(values.subdomain?.filter(Boolean)) &&
          (values.subdomain as string[]).filter(Boolean).length > 0
        ) {
          onFormFinish({
            domain: values.domain,
            widgetFolder: values.widgetFolder,
            subdomain: values.subdomain?.filter(Boolean),
          });
        } else {
          onFormFinish({
            domain: values.domain,
            widgetFolder: values.widgetFolder,
            subdomain: undefined,
          });
        }
      },
      [onFormFinish]
    );

    const formRules = useMemo(
      () => getFormRules(t, form, checkWidgetFolder),
      [t, checkWidgetFolder]
    );

    return (
      <Wrapper>
        <ConfigProvider
          theme={{
            token: {
              colorBorder: theme.MAIN.WHITE.C200,
            },
          }}
        >
          <FormLabel>{t('title')}</FormLabel>
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={handleFormFinish}
          >
            <Form.Item
              name={EWidgetSettingsFormNames.DOMAIN}
              label={t('form.labels.domain')}
              validateTrigger="onBlur"
              tooltip={t('form.tooltips.domain')}
              rules={formRules.domain}
            >
              <Input
                size="large"
                type="url"
                placeholder="https://assistapp.tech"
              />
            </Form.Item>
            <WidgetAdditionalDomainsForm name="subdomain" form={form} />
            <Form.Item
              name="widgetFolder"
              label={t('form.labels.widgetFolder')}
              validateDebounce={600}
              tooltip={t('form.tooltips.widgetFolder')}
              rules={formRules.widgetFolder}
            >
              <Input size="large" placeholder="assistapp" />
            </Form.Item>
          </Form>
          {isAddWidgetAvailable && (
            <AlertStyled
              type="warning"
              showIcon
              message="Внимание"
              description="Установка виджета на сайт с доменом отличным от введенных URL-доменов повлечет ошибки в работе сайта"
            />
          )}
          {!isAddWidgetAvailable && (
            <AlertStyled
              type="error"
              showIcon
              message="Создание виджета недоступно"
              description="У вас создано максимальное колличество виджетов"
            />
          )}
          <Button
            type="primary"
            size="large"
            style={buttonStyles}
            onClick={form.submit}
            disabled={!isAddWidgetAvailable}
          >
            {t('createButton')}
          </Button>
        </ConfigProvider>
      </Wrapper>
    );
  }
);
