import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { getIconBaseColor } from '../../utils/ui/icons';
import { TBaseIconProps } from './index';

const PlusIcon: FC<TBaseIconProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_551_1681)">
        <path
          d="M17.25 8.25001H9.74999V0.749988C9.74999 0.335777 9.41421 0 9 0C8.58579 0 8.25001 0.335777 8.25001 0.749988V8.24998H0.749988C0.335777 8.25001 0 8.58579 0 9C0 9.41421 0.335777 9.74999 0.749988 9.74999H8.24998V17.25C8.24998 17.6642 8.58575 18 8.99996 18C9.41417 18 9.74995 17.6642 9.74995 17.25V9.74999H17.2499C17.6642 9.74999 17.9999 9.41421 17.9999 9C18 8.58579 17.6642 8.25001 17.25 8.25001Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
      </g>
      <defs>
        <clipPath id="clip0_551_1681">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(PlusIcon);
