import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Localization } from '../../../locale';
import { addClearStateOnLogoutMatcher } from '../../utils/state-utils';

type TInitialState = {
  current: Localization;
};

const initialState: TInitialState = {
  current: Localization.RU,
};

const localeSlice = createSlice({
  name: 'widget/locale',
  initialState,
  reducers: {
    setLocale(state, action: PayloadAction<Localization>) {
      state.current = action.payload;
    },
  },
  extraReducers: (builder) => {
    addClearStateOnLogoutMatcher(builder, initialState);
  },
});

export const { setLocale } = localeSlice.actions;
export const localeReducer = localeSlice.reducer;
