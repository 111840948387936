import dayjs from 'dayjs';
import { FC, memo, useCallback, useEffect, useMemo } from 'react';

import WidgetMenu from '../components/widget-menu';
import { useSettingsList } from '../hooks/useSettingsList';
import { useStatisticWriter } from '../hooks/useStatisticWriter';
import { usePresetList } from '../presets';
import { useFocusContext } from '../services/focus-service';
import { useAppDispatch } from '../state';
import { useWidgetSelector } from '../state/reducers/widget/selectors';
import {
  resetEffects,
  setEffectValue,
} from '../state/reducers/widget/slices/effectsSlice';
import { setWidgetOpenAction } from '../state/reducers/widget/slices/mainStateSlice';
import { resetWidgetSettingsAction } from '../state/reducers/widget/slices/settingsSlice';
import { TStatEvent } from '../types/statistic';
import { TOnWidgetChange } from '../types/widget';

export const WidgetMenuPowered: FC = memo(() => {
  const dispatch = useAppDispatch();
  const { opened } = useWidgetSelector();
  const [handleWriteStatistic] = useStatisticWriter();
  const { focusAtLastElement } = useFocusContext();

  const handleCloseWidget = useCallback(() => {
    if (opened) {
      dispatch(setWidgetOpenAction(false));
      void handleWriteStatistic({
        event: TStatEvent.CLOSE_WIDGET,
        text: 'Виджет закрыт',
        time: dayjs().toISOString(),
      });

      focusAtLastElement();
    }
  }, [opened]);

  const [settingsList] = useSettingsList(
    (process.env.WIDGET_CONFIG as any).effects
  );

  const presetList = usePresetList((process.env.WIDGET_CONFIG as any).presets);

  const onChangeList: TOnWidgetChange[] = useMemo(() => {
    return settingsList.map((el) => ({
      key: el.key,
      onChange: (value) => {
        dispatch(
          setEffectValue({
            value: value,
            key: el.key,
          })
        );
      },
    }));
  }, [settingsList]);

  const handleReset = useCallback(() => {
    dispatch(resetEffects());
    dispatch(resetWidgetSettingsAction());
    void handleWriteStatistic({
      event: TStatEvent.RESET_SETTINGS,
      text: 'Сброс настроек',
      time: dayjs().toISOString(),
    });
  }, []);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Escape' && opened) {
        dispatch(setWidgetOpenAction(false));
        void handleWriteStatistic({
          event: TStatEvent.CLOSE_WIDGET_KBD,
          text: 'Виджет закрыт (клавиатура)',
          time: dayjs().toISOString(),
        });

        focusAtLastElement();
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [opened]);

  return (
    <WidgetMenu
      visible={opened}
      handleClose={handleCloseWidget}
      settingsList={settingsList}
      onChangeList={onChangeList}
      handleReset={handleReset}
      presetList={presetList}
    />
  );
});
