import styled from '@emotion/styled';
import { Button, Input } from 'antd';
import { CSSProperties, FC, HTMLAttributes, memo, useCallback } from 'react';

import { TMessageAPI } from '../../../providers/notification-provider';
import CopyIcon from '../../icons/copy-icon';

const { TextArea } = Input;

const Wrapper = styled.div`
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
`;

const TextAreaStyled = styled(TextArea)<{
  textareabackgroundcolor: CSSProperties['backgroundColor'];
}>`
  color: inherit;
  background-color: ${({ textareabackgroundcolor }) => textareabackgroundcolor};
  overflow: hidden;
  border: none;
  white-space: pre;

  &:hover,
  &:focus {
    color: inherit;
    background-color: transparent;
  }
`;

type TProps = {
  value?: string;
  messageApi?: TMessageAPI;
  textAreaBackgroundColor?: CSSProperties['backgroundColor'];
} & HTMLAttributes<HTMLDivElement>;

const SourceCopyInput: FC<TProps> = memo(
  ({ value, messageApi, textAreaBackgroundColor, ...props }) => {
    const handleCopy = useCallback(async () => {
      try {
        await new Promise<void>((res, rej) => {
          try {
            if (value) {
              navigator.clipboard.writeText(value).then(() => res());
            } else {
              res();
            }
          } catch (e) {
            rej(e);
          }
        });
        if (messageApi) {
          messageApi.success('Текст успешно скопирован');
        }
      } catch (e) {
        if (messageApi) {
          messageApi.error('Не удалось скопировать текст');
        }
        console.error(e);
      }
    }, [value]);

    return (
      <Wrapper {...props}>
        <TextAreaStyled
          value={value}
          textareabackgroundcolor={textAreaBackgroundColor}
          readOnly
          autoSize={{
            maxRows: 2,
            minRows: 2,
          }}
        />
        <Button type="primary" icon={<CopyIcon />} onClick={handleCopy} />
      </Wrapper>
    );
  }
);

export default SourceCopyInput;
