import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const ContrastIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_150)">
        <path
          d="M2.64547 6.26658C2.79514 6.26706 2.94265 6.23089 3.07512 6.16122C3.20758 6.09155 3.32098 5.9905 3.40539 5.86691C4.08685 4.85817 4.96214 3.99504 5.98031 3.32774C6.08094 3.26154 6.16755 3.17615 6.23519 3.07647C6.30283 2.97679 6.35017 2.86477 6.37452 2.74679C6.39886 2.62881 6.39973 2.5072 6.37707 2.38888C6.35442 2.27057 6.30868 2.15788 6.24247 2.05724C6.17626 1.95661 6.09088 1.87 5.9912 1.80236C5.89152 1.73472 5.7795 1.68738 5.66152 1.66303C5.54354 1.63869 5.42193 1.63782 5.30361 1.66047C5.1853 1.68313 5.07261 1.72887 4.97197 1.79508C3.75276 2.59426 2.70456 3.62781 1.88831 4.83566C1.79497 4.97338 1.74088 5.1339 1.73183 5.30002C1.72277 5.46614 1.7591 5.63159 1.83691 5.77864C1.91472 5.92569 2.03108 6.04879 2.17352 6.13475C2.31596 6.2207 2.47911 6.26627 2.64547 6.26658Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M1.83329 10.9998C1.83313 10.3938 1.89238 9.78927 2.01021 9.19486C2.03572 9.07609 2.03737 8.95343 2.01506 8.83402C1.99275 8.7146 1.94693 8.60081 1.88026 8.49927C1.81359 8.39772 1.7274 8.31043 1.62669 8.24249C1.52599 8.17454 1.41279 8.12729 1.29367 8.10348C1.17455 8.07966 1.05188 8.07977 0.932796 8.10378C0.813713 8.12779 0.700591 8.17523 0.600004 8.24335C0.499417 8.31146 0.413373 8.39889 0.346871 8.50055C0.280369 8.60221 0.234738 8.71607 0.212628 8.83552C-0.0715271 10.2662 -0.0715271 11.7388 0.212628 13.1695C0.254892 13.3766 0.367395 13.5627 0.531096 13.6963C0.694797 13.8299 0.899642 13.9029 1.11096 13.9029C1.1725 13.9031 1.23391 13.8973 1.29429 13.8854C1.53267 13.8377 1.7423 13.6972 1.87708 13.4948C2.01185 13.2925 2.06073 13.0449 2.01296 12.8065C1.89402 12.2116 1.83384 11.6065 1.83329 10.9998Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M5.98408 18.6745C4.9657 18.0087 4.09007 17.1468 3.40824 16.139C3.34065 16.0394 3.25409 15.954 3.1535 15.8877C3.05291 15.8215 2.94026 15.7758 2.82199 15.7531C2.58312 15.7073 2.33584 15.7582 2.13454 15.8947C1.93324 16.0312 1.79441 16.2421 1.7486 16.481C1.70278 16.7199 1.75374 16.9671 1.89025 17.1684C2.70817 18.3763 3.75828 19.4093 4.97941 20.2072C5.18266 20.3409 5.43069 20.3884 5.66896 20.3392C5.90722 20.2901 6.1162 20.1483 6.24991 19.945C6.38363 19.7418 6.43112 19.4937 6.38196 19.2555C6.33279 19.0172 6.19099 18.8082 5.98775 18.6745H5.98408Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M11 0C10.3325 0.00113715 9.66644 0.062497 9.00994 0.183333C8.78462 0.224131 8.58272 0.347785 8.44399 0.529961C8.30525 0.712137 8.23973 0.939641 8.2603 1.1677C8.28088 1.39576 8.38607 1.60787 8.55517 1.76227C8.72427 1.91667 8.94504 2.00219 9.17403 2.002C9.22964 2.00159 9.28512 1.99668 9.33994 1.98733C9.8875 1.88585 10.4431 1.8343 11 1.83333V20.1667C10.4433 20.1669 9.88761 20.1166 9.33994 20.0163C9.10072 19.9726 8.8539 20.0256 8.6538 20.1639C8.4537 20.3021 8.3167 20.5141 8.27294 20.7533C8.22918 20.9926 8.28224 21.2394 8.42046 21.4395C8.55867 21.6396 8.77072 21.7766 9.00994 21.8203C10.5964 22.1105 12.2272 22.0486 13.7871 21.639C15.3469 21.2294 16.7978 20.482 18.0369 19.4498C19.2761 18.4176 20.2734 17.1258 20.9582 15.6657C21.643 14.2055 21.9987 12.6128 22 11C21.9969 8.08359 20.8369 5.28753 18.7747 3.22531C16.7125 1.16309 13.9164 0.00315432 11 0V0Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
      </g>
      <defs>
        <clipPath id="clip0_8_150">
          <rect width="22" height="22" fill={theme.COLORS.COMMON.ON_ACCENT} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(ContrastIcon);
