import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useRef,
} from 'react';

import { WIDGET_MENU_ID_NAME } from '../constants';

type TContext = {
  setFocusElement: (element: HTMLElement) => void;
  focusAtLastElement: () => void;
};

const context = createContext<TContext>({
  setFocusElement: () => {},
  focusAtLastElement: () => {},
});

export const useFocusContext = () => useContext(context);

export const FocusService: FC<PropsWithChildren> = ({ children }) => {
  const focusElementRef = useRef<HTMLElement | null>(null);

  const setFocusElement: TContext['setFocusElement'] = useCallback(
    (element) => {
      focusElementRef.current = element;

      setTimeout(() => {
        const widgetMenu = document.getElementById(WIDGET_MENU_ID_NAME);

        if (widgetMenu) {
          widgetMenu.focus();
        }
      }, 0);
    },
    []
  );

  const focusAtLastElement: TContext['focusAtLastElement'] = useCallback(() => {
    if (focusElementRef.current) {
      focusElementRef.current?.focus?.();
    }
  }, []);

  return (
    <context.Provider
      value={{
        setFocusElement,
        focusAtLastElement,
      }}
    >
      {children}
    </context.Provider>
  );
};
