import { Form, Modal } from 'antd';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonProps } from 'antd/es/button/button';

import { useRegistrationMutation } from '../../api/features/auth';
import { LocaleParts } from '../../locale';
import { useAppMessage } from '../../providers/notification-provider';
import { useAppDispatch } from '../../state';
import { logErrorAction } from '../../state/actions';
import { TBaseModalProps } from '../../types/ui/modal';
import RegistrationForm, {
  TRegisterFormType,
} from '../forms/registration-form';

export const UserCreateModal: FC<TBaseModalProps> = ({ open, setOpen }) => {
  const { t } = useTranslation([LocaleParts.AUTH_FORMS]);
  const [form] = Form.useForm<TRegisterFormType>();
  const dispatch = useAppDispatch();

  const { messageApi } = useAppMessage();
  const [registration, { isLoading }] = useRegistrationMutation();

  const handleFormFinish = useCallback(
    async (values: TRegisterFormType) => {
      try {
        await registration({
          email: values.email,
          password: values.password,
          skipAccountSetup: true,
        }).unwrap();
        messageApi.success(
          `Пользователь успешно создан. Email - ${values.email}. Пароль: - ${values.password}`
        );
        setOpen(false);
      } catch (e: any) {
        if ('status' in e && e.status === 400) {
          messageApi.error(t('async.userIsAlreadyRegister'));
        } else {
          dispatch(logErrorAction(e));
          messageApi.error(t('async.registrationError'));
        }
      }
    },
    [t]
  );

  const okButtonProps: ButtonProps = useMemo(
    () => ({
      loading: isLoading,
      onClick: () => form.submit(),
    }),
    [isLoading]
  );

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Modal
      open={open}
      okButtonProps={okButtonProps}
      onCancel={onClose}
      title="Регистрация нового пользователя"
      okText="Зарегистрировать"
      cancelText="Отмена"
    >
      <Form form={form} layout="vertical" onFinish={handleFormFinish}>
        <RegistrationForm t={t} />
      </Form>
    </Modal>
  );
};
