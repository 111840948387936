export type TAccessTheme = {
  WHITE: {
    C100: string;
    C200: string;
    C300: string;
    C400: string;
    C500: string;
    C900: string;
  };
  TEXT: {
    C100: string;
    C200: string;
    C300: string;
  };
  TEXT_WHITE: {
    C100: string;
    C200: string;
    C300: string;
  };
  BLACK: {
    C100: string;
    C200: string;
    C300: string;
    C001: string;
  };
  ACCENT: {
    C100: string;
    C200: string;
    C300: string;
    DISABLED: string;
    SUCCESS: string;
    WARNING: string;
    DANGER: string;
  };
  DANGER: {
    C100: string;
    C200: string;
  };
  SUCCESS: {
    C100: string;
    C200: string;
  };
  WARNING: {
    C100: string;
    C200: string;
  };
};

export const THEME_LIGHT: TAccessTheme = {
  WHITE: {
    C100: '#FFFFFF',
    C200: '#F1F3F6',
    C300: '#EAEAF2',
    C400: '#e0e0e5',
    C500: '#c5c5c9',
    C900: '#e0e0e5',
  },
  TEXT: {
    C100: '#242831',
    C200: '#4A5262',
    C300: '#717171',
  },
  TEXT_WHITE: {
    C100: '#FFFFFF',
    C200: '#B0B0B0',
    C300: '#696969',
  },
  BLACK: {
    C100: '#242831',
    C200: '#4A5262',
    C300: '#717171',
    C001: 'rgba(36,40,49,0.7)',
  },
  ACCENT: {
    C100: '#5B3AF3',
    C200: '#795DF7',
    C300: '#bdadff',
    DISABLED: '',
    SUCCESS: '',
    WARNING: '',
    DANGER: '',
  },
  DANGER: {
    C100: '#EC4E4E',
    C200: '#D44747',
  },
  SUCCESS: {
    C100: '#40D2AD',
    C200: '#42BB9C',
  },
  WARNING: {
    C100: '#EBB834',
    C200: '#D2A83B',
  },
};

export enum ECSSVars {
  THEME_ICON_COLOR = '--theme-icon-color',

  THEME_RADIO_BORDER_COLOR = '--theme-radio-border-color',
  THEME_RADIO_BOX_SHADOW = '--theme-radio-box-shadow',
}
