export enum EWidgetPresetThemeKey {
  DEFAULT = 'DEFAULT',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  DARK = 'DARK',
}

export const WidgetPresetThemeColors: Record<EWidgetPresetThemeKey, string> = {
  [EWidgetPresetThemeKey.DEFAULT]: '#5B3AF3',
  [EWidgetPresetThemeKey.YELLOW]: '#F29D23',
  [EWidgetPresetThemeKey.GREEN]: '#33DD84',
  [EWidgetPresetThemeKey.DARK]: '#4A5262',
};

export const WidgetPresetThemeList: {
  key: EWidgetPresetThemeKey;
  color: string;
}[] = [
  {
    key: EWidgetPresetThemeKey.DEFAULT,
    color: WidgetPresetThemeColors[EWidgetPresetThemeKey.DEFAULT],
  },
  {
    key: EWidgetPresetThemeKey.YELLOW,
    color: WidgetPresetThemeColors[EWidgetPresetThemeKey.YELLOW],
  },
  {
    key: EWidgetPresetThemeKey.GREEN,
    color: WidgetPresetThemeColors[EWidgetPresetThemeKey.GREEN],
  },
  {
    key: EWidgetPresetThemeKey.DARK,
    color: WidgetPresetThemeColors[EWidgetPresetThemeKey.DARK],
  },
];
