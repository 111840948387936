import { ETextEffectKeys } from '../effects/names';
import { ECiteThemeEffect, TSettingValue } from '../types/widget';

export const toTextBoolean: (state?: boolean) => 'true' | 'false' = (state) => {
  return state ? 'true' : 'false';
};

export const textToBoolean: (state?: string) => boolean = (state) => {
  return state === 'true';
};

export const widgetSettingAvailableValues: Record<
  (typeof ETextEffectKeys)[keyof typeof ETextEffectKeys],
  Array<TSettingValue>
> = {
  [ETextEffectKeys.TEXT_INCREASE]: [false, 20, 40],
  [ETextEffectKeys.CONTENT_SCALE]: [false, 10, 20, 30],
  [ETextEffectKeys.READABLE_FONT]: [false, true],
  [ETextEffectKeys.DYSLEXIC_FONT]: [false, true],
  [ETextEffectKeys.SIMPLE_LANGUAGE]: [false, true],
  [ETextEffectKeys.CURSOR]: [false, 'white', 'black'],
  [ETextEffectKeys.CITE_THEME]: [
    false,
    ECiteThemeEffect.WHITE,
    ECiteThemeEffect.BLACK,
    ECiteThemeEffect.BLUE,
    ECiteThemeEffect.BEIGE,
    ECiteThemeEffect.BROWN,
  ],
  [ETextEffectKeys.INVERT_COLORS]: [false, true],
  [ETextEffectKeys.BRIGHTNESS]: [false, -1, 1],
  [ETextEffectKeys.CONTRAST]: [false, -1, 1],
  [ETextEffectKeys.GRAYSCALE]: [false, true],
  [ETextEffectKeys.READ_LINE]: [false, true],
  [ETextEffectKeys.READ_AREA]: [false, true],
  [ETextEffectKeys.HIDE_IMAGES]: [false, true],
  [ETextEffectKeys.STOP_ANIMATIONS]: [false, true],
  [ETextEffectKeys.LINKS_MARK]: [false, true],
  [ETextEffectKeys.TEXT_TO_SPEECH]: [false, true],
};

export const widgetEffectTextKeys: Record<
  (typeof ETextEffectKeys)[keyof typeof ETextEffectKeys],
  Array<string>
> = {
  [ETextEffectKeys.TEXT_INCREASE]: ['base', 'increased'],
  [ETextEffectKeys.CONTENT_SCALE]: ['base', 'increased'],
  [ETextEffectKeys.READABLE_FONT]: ['base'],
  [ETextEffectKeys.DYSLEXIC_FONT]: ['base'],
  [ETextEffectKeys.SIMPLE_LANGUAGE]: ['base'],
  [ETextEffectKeys.CURSOR]: ['base', 'light', 'dark'],
  [ETextEffectKeys.CITE_THEME]: [
    'base',
    ECiteThemeEffect.WHITE,
    ECiteThemeEffect.BLACK,
    ECiteThemeEffect.BLUE,
    ECiteThemeEffect.BEIGE,
    ECiteThemeEffect.BROWN,
  ],
  [ETextEffectKeys.INVERT_COLORS]: ['base'],
  [ETextEffectKeys.BRIGHTNESS]: ['base', 'dark', 'light'],
  [ETextEffectKeys.CONTRAST]: ['base', 'dark', 'light'],
  [ETextEffectKeys.GRAYSCALE]: ['base'],
  [ETextEffectKeys.READ_LINE]: ['base'],
  [ETextEffectKeys.READ_AREA]: ['base'],
  [ETextEffectKeys.HIDE_IMAGES]: ['base'],
  [ETextEffectKeys.STOP_ANIMATIONS]: ['base'],
  [ETextEffectKeys.LINKS_MARK]: ['base'],
  [ETextEffectKeys.TEXT_TO_SPEECH]: ['base'],
};
