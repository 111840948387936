import { Reducer, combineReducers } from '@reduxjs/toolkit';

import * as apiList from '../api/features/api';
import { applicationReducers } from './app';
import { authReducer } from './auth';
import { widgetsReducer } from './widgets';

type TBaseApi = (typeof apiList)[keyof typeof apiList];

type TApi = Record<TBaseApi['reducerPath'], TBaseApi['reducer']>;

const appReducer = combineReducers({
  ...(Object.keys(apiList) as (keyof typeof apiList)[]).reduce(
    (acc, curr) => ({
      ...acc,
      [apiList[curr].reducerPath]: apiList[curr].reducer,
    }),
    {} as TApi
  ),
  app: applicationReducers,
  auth: authReducer,
  widgets: widgetsReducer,
});

export type RootCombine = ReturnType<typeof appReducer>;

export const rootReducer: Reducer = (state: RootCombine, action) => {
  return appReducer(state, action);
};
