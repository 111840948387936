import { FC, Fragment, PropsWithChildren } from 'react';

import { CursorComponent } from '../../components/effects-components/cursor';
import { ETextEffectKeys } from '../../effects/names';
import { useEffectSelector } from '../../state/reducers/widget/selectors';

export const CursorEffectExp: FC<PropsWithChildren> = ({ children }) => {
  const effect = useEffectSelector(ETextEffectKeys.CURSOR);

  return (
    <Fragment>
      {effect.value && <CursorComponent isBlack={effect.value === 'black'} />}
      {children}
    </Fragment>
  );
};
