import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CheckerIcon from '../../components/icons/checker-icon';
import DocsIcon from '../../components/icons/docs-icon';
import HomeIcon from '../../components/icons/home-icon';
import PaymentIcon from '../../components/icons/payment-icon';
import PlusIcon from '../../components/icons/plus-icon';
import SettingsIcon from '../../components/icons/settings-icon';
import StatisticIcon from '../../components/icons/statistic-icon';
import UsersIcon from '../../components/icons/users-icon';
import { Menu, TMenuItem } from '../../components/layouts/base/menu';
import { LocaleParts } from '../../locale';
import { EPaths } from '../../routes/route-paths';
import { useAppSelector } from '../../state';
import { useUserIsAdminSelector } from '../../state/auth/selectors';
import { useCanCreateWidgetSelector } from '../../state/widgets/selectors';

type TAppMenu = Omit<TMenuItem, 'active'>;

const getAppMenu: (props: {
  widgetCanBeAdded: boolean;
  isAdmin: boolean;
}) => TAppMenu[] = ({ widgetCanBeAdded, isAdmin }) =>
  (
    [
      {
        key: 'home',
        labelTranslationPath: 'appMenu.home',
        to: EPaths.HOME,
        Icon: HomeIcon,
      },
      {
        key: 'create',
        labelTranslationPath: 'appMenu.create',
        to: EPaths.CREATE_WIDGET,
        Icon: PlusIcon,
        disabled: !widgetCanBeAdded,
      },
      {
        key: 'settings',
        labelTranslationPath: 'appMenu.settings',
        to: EPaths.SETTINGS,
        Icon: SettingsIcon,
      },
      {
        key: 'checker',
        labelTranslationPath: 'appMenu.checker',
        to: EPaths.ACCESS_CHECKER,
        Icon: CheckerIcon,
        disabled: !isAdmin,
      },
      {
        key: 'payment',
        labelTranslationPath: 'appMenu.payment',
        to: EPaths.PAYMENT,
        Icon: PaymentIcon,
        disabled: true,
      },
      {
        key: 'stats',
        labelTranslationPath: 'appMenu.stats',
        to: EPaths.STATISTIC,
        Icon: StatisticIcon,
        disabled: !isAdmin,
      },
      isAdmin && {
        key: 'users',
        labelTranslationPath: 'appMenu.users',
        to: EPaths.USERS,
        Icon: UsersIcon,
        disabled: !isAdmin,
      },
      {
        key: 'docs',
        labelTranslationPath: 'appMenu.docs',
        to: EPaths.DOCUMENTATION,
        Icon: DocsIcon,
      },
    ] as (TAppMenu | false)[]
  ).filter(Boolean) as TAppMenu[];

export const AppBaseMenu: FC = () => {
  const { t } = useTranslation([LocaleParts.LAYOUT]);
  const location = useLocation();
  const isAddWidgetAvailable = useAppSelector(useCanCreateWidgetSelector);
  const isAdmin = useAppSelector(useUserIsAdminSelector);

  const appMenuList: TMenuItem[] = useMemo(() => {
    return getAppMenu({
      widgetCanBeAdded: isAddWidgetAvailable,
      isAdmin,
    }).map((el) => ({
      ...el,
      active:
        el.to === EPaths.HOME
          ? el.to === location.pathname
          : location.pathname.startsWith(el.to),
    }));
  }, [location, isAddWidgetAvailable, isAdmin]);

  return <Menu t={t} menu={appMenuList} />;
};
