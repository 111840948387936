import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { ComponentType, FC, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { EPaths } from '../../../routes/route-paths';
import { ECSSVars } from '../../../styles';
import {
  TTextBoolean,
  textToBoolean,
  toTextBoolean,
} from '../../../utils/ui/common';

const Wrapper = styled('ul')<{
  isScrollableBlock: TTextBoolean;
}>`
  display: flex;
  flex-direction: column;
  padding: ${({ isScrollableBlock }) =>
    textToBoolean(isScrollableBlock) ? '4px 8px 4px 4px' : '4px'};
  margin: 0;
  list-style: none;
  overflow-y: auto;
  max-height: calc(100vh - 180px - 3rem);

  &::-webkit-scrollbar {
    background: transparent;
    width: 4px;
    max-width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.MAIN.WHITE.C500};
    border-radius: 2px;
    width: 4px;
    height: 4px;
  }
`;

const MeuLabel = styled('span')`
  font-size: 1rem;
  line-height: 1rem;
  margin-top: 0.06rem;
  font-weight: bold;

  @media (max-height: 752px) {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
`;

const IconWrapper = styled('div')`
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.125rem;
  height: 1.125rem;

  svg {
    width: 100%;
    height: 100%;

    path {
      transition: fill 0.3s ease-out;
    }
  }
`;

const MenuLink = styled(NavLink)`
  display: flex;
  padding: 18px;
  border-radius: 6px;

  @media (max-height: 752px) {
    padding: 12px;
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.MAIN.ACCENT.C300};
  }
`;

const DisabledLink = styled.div`
  display: flex;
  padding: 18px;
  border-radius: 6px;
  cursor: not-allowed;

  @media (max-height: 752px) {
    padding: 12px;
  }
`;

const MenuItem = styled('li')<{
  isActive: TTextBoolean;
}>`
  width: 100%;

  ${DisabledLink} {
    background-color: ${({ theme }) => theme.MAIN.WHITE.C300};
  }

  ${MenuLink} {
    background-color: ${({ theme, isActive }) =>
      textToBoolean(isActive) ? theme.MAIN.ACCENT.C100 : theme.MAIN.WHITE.C100};
    transition: background-color 0.3s ease-out;

    &:focus {
      outline: 2px solid ${({ theme }) => theme.MAIN.ACCENT.C300};
    }

    &:hover {
      background-color: ${({ theme, isActive }) =>
        textToBoolean(isActive)
          ? theme.MAIN.ACCENT.C100
          : theme.MAIN.WHITE.C300};
    }
  }

  ${MeuLabel} {
    color: ${({ theme, isActive }) =>
      textToBoolean(isActive) ? theme.MAIN.WHITE.C100 : theme.MAIN.TEXT.C200};
    user-select: none;
    transition: color 0.3s ease-out;
  }

  ${IconWrapper} {
    ${ECSSVars.THEME_ICON_COLOR}: ${({ isActive, theme }) =>
      textToBoolean(isActive) ? theme.MAIN.WHITE.C100 : theme.MAIN.TEXT.C200};
  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export type TMenuItem = {
  key: string;
  labelTranslationPath: string;
  to: EPaths;
  Icon: ComponentType;
  active: boolean;
  disabled?: boolean;
};

type TProps = {
  t: TFunction<string[]>;
  menu: TMenuItem[];
};

export const Menu: FC<TProps> = ({ t, menu }) => {
  const wrapperRef = useRef<HTMLUListElement | null>(null);
  const [isScrollableBlock, setIsScrollableBlock] = useState(false);

  useEffect(() => {
    const listener = () => {
      if (wrapperRef.current) {
        if (
          wrapperRef.current?.scrollHeight > wrapperRef.current?.clientHeight
        ) {
          setIsScrollableBlock(true);
        } else {
          setIsScrollableBlock(false);
        }
      }
    };

    listener();

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return (
    <Wrapper
      aria-label={t('appMenu.title')}
      ref={wrapperRef}
      isScrollableBlock={toTextBoolean(isScrollableBlock)}
    >
      {menu.map((menuItem) => (
        <MenuItem
          key={menuItem.key}
          aria-label={t(menuItem.labelTranslationPath)}
          aria-selected={menuItem.active}
          isActive={toTextBoolean(menuItem.active)}
          aria-disabled={menuItem.disabled}
        >
          {menuItem.disabled ? (
            <DisabledLink>
              <IconWrapper>
                <menuItem.Icon />
              </IconWrapper>
              <MeuLabel>{t(menuItem.labelTranslationPath)}</MeuLabel>
            </DisabledLink>
          ) : (
            <MenuLink to={menuItem.to}>
              <IconWrapper>
                <menuItem.Icon />
              </IconWrapper>
              <MeuLabel>{t(menuItem.labelTranslationPath)}</MeuLabel>
            </MenuLink>
          )}
        </MenuItem>
      ))}
    </Wrapper>
  );
};
