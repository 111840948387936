import { ICommonStyles } from '@kylfyk/accessability-react/dist/styles';

import { TPaths } from '../../utils/type-utils';

export type TFormNames<T extends object> = {
  key: TPaths<T>;
  label: string;
  tooltip?: string;
  placeholder?: string;
  type: 'color' | 'number' | 'string';
}[];

export const ThemeFormItems: TFormNames<ICommonStyles<string>> = [
  {
    key: 'COMMON.ACCENT',
    label: 'Основной',
    type: 'color',
  },
  {
    key: 'COMMON.ON_ACCENT',
    label: 'Контрастный цвет к основному',
    type: 'color',
  },
  {
    key: 'COMMON.BASE_RADIUS',
    label: 'Базовый радиус скругления углов',
    type: 'number',
  },
  {
    key: 'COMMON.TEXT_COMMON',
    label: 'Текст',
    type: 'color',
  },
  {
    key: 'ACCESS_BUTTON.MAIN_COLOR',
    label: 'Цвет кнопки',
    type: 'color',
  },
  {
    key: 'ACCESS_BUTTON.SECONDARY_COLOR',
    label: 'Вторичный цвет кнопки',
    type: 'color',
  },
  {
    key: 'ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR',
    label: 'Цвет иконки внутри кнопки',
    type: 'color',
  },
  {
    key: 'MENU.SHADOW',
    label: 'Тень меню',
    type: 'string',
    placeholder: '0 0 20px rgba(0, 0, 0, 0.2)',
    tooltip:
      'Заполняйте по правилам CSS. Например: "0 0 20px rgba(0, 0, 0, 0.2)"',
  },
  {
    key: 'MENU.BACKGROUND',
    label: 'Фон меню виджета',
    type: 'color',
  },
  {
    key: 'MENU.SCROLLBAR_BG',
    label: 'Фон скроллбара',
    type: 'color',
  },
  {
    key: 'MENU.SCROLLBAR_BG_HOVERED',
    label: 'Фон скроллбара при наведении',
    type: 'color',
  },
  {
    key: 'MENU.HEADER_BG',
    label: 'Фон шапки виджета',
    type: 'color',
  },
  {
    key: 'MENU.HEADER_TEXT_COLOR',
    label: 'Текст шапки виджета',
    type: 'color',
  },
  {
    key: 'MENU.TEXT_ON_MENU',
    label: 'Текст в меню виджета',
    type: 'color',
  },
  {
    key: 'MENU.RESET_BUTTON.BACKGROUND',
    label: 'Фон кнопки сброса настроек',
    type: 'color',
  },
  {
    key: 'MENU.RESET_BUTTON.BACKGROUND_HOVERED',
    label: 'Фон сброса настроек при наведении',
    type: 'color',
  },
  {
    key: 'MENU.RESET_BUTTON.TEXT',
    label: 'Текст кнопки сброса настроек',
    type: 'color',
  },
  {
    key: 'MENU.SUB_GROUP.BACKGROUND',
    label: 'Фон секций виджета',
    type: 'color',
  },
  {
    key: 'MENU.SUB_GROUP.BORDER_RADIUS',
    label: 'Радиус скругления углов секций',
    type: 'number',
  },
  {
    key: 'MENU.SUB_GROUP.TITLE_COLOR',
    label: 'Цвет заголовков',
    type: 'color',
  },
  {
    key: 'MENU.MENU_ITEM.BORDER_RADIUS',
    label: 'Радиус скругления углов элементов меню виджета',
    type: 'number',
  },
  {
    key: 'MENU.MENU_ITEM.BACKGROUND',
    label: 'Фон элементов меню виджета',
    type: 'color',
  },
  {
    key: 'MENU.MENU_ITEM.BORDER',
    label: 'Рамка элементов меню виджета',
    type: 'color',
  },
  {
    key: 'MENU.MENU_ITEM.TITLE',
    label: 'Заголовок элементов меню виджета',
    type: 'color',
  },
  {
    key: 'MENU.MENU_ITEM.TEXT',
    label: 'Текст элементов меню виджета',
    type: 'color',
  },
  {
    key: 'MENU.MENU_ITEM.SWITCH_BG',
    label: 'Фон переключателей',
    type: 'color',
  },
];

export enum EWidgetSettingsFormNames {
  DATE_TO = 'dateTo',
  DOMAIN = 'domain',
  SUB_DOMAIN = 'subdomain',
  READY_THEME = 'widgetReadyTheme',
  COLORS = 'colors',
  FONT = 'font',
  META_LINK_HIDDEN = 'metaLinkHidden',
  MENU_Z_INDEX = 'menuIndex',
  USES_REM = 'citeUseRem',
  REM_TARGET_SELECTOR = 'remTargetSelector',
  BUTTON_TYPE = 'buttonType',
  HIDE_BUTTON = 'hideButton',
  ACCESS_BUTTON_Z_INDEX = 'accessZIndex',
  ACCESS_BUTTON_SIZE = 'accessSize',
  ACCESS_BUTTON_BORDER_RADIUS = 'borderRadius',
  ADAPTIVE = 'adaptive',
  ACCESS_BUTTON_ENABLE_BREAKPOINTS = 'accessBreakPointsEnabled',
  ACCESS_BUTTON_ADAPTIVE_BREAKPOINTS = 'accessBreakAdaptiveBreakpoints',
  EFFECTS_SETTINGS = 'effectsConfig',
  PRESETS_SETTINGS = 'presetsConfig',
}
