import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const ReadAreaIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46667 17.8667C8.85565 17.8667 9.2287 17.7122 9.50376 17.4371C9.77881 17.1621 9.93333 16.789 9.93333 16.4V14.9334H12.8667V16.4C12.8667 16.789 13.0212 17.1621 13.2962 17.4371C13.5713 17.7122 13.9444 17.8667 14.3333 17.8667C14.7223 17.8667 15.0954 17.7122 15.3704 17.4371C15.6455 17.1621 15.8 16.789 15.8 16.4V10.5334C15.8 9.36641 15.3364 8.24725 14.5113 7.42209C13.6861 6.59693 12.567 6.13336 11.4 6.13336C10.233 6.13336 9.11389 6.59693 8.28873 7.42209C7.46357 8.24725 7 9.36641 7 10.5334V16.4C7 16.789 7.15452 17.1621 7.42958 17.4371C7.70463 17.7122 8.07768 17.8667 8.46667 17.8667ZM11.3947 9.11712C11.7837 9.11712 12.1567 9.27165 12.4318 9.5467C12.7068 9.82175 12.8614 10.1948 12.8614 10.5838V12.0505H9.92802V10.5838C9.92802 10.1948 10.0825 9.82175 10.3576 9.5467C10.6326 9.27165 11.0057 9.11712 11.3947 9.11712Z"
        fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
      />
      <path
        d="M6 2L17 2"
        stroke={theme.COLORS.MENU.MENU_ITEM.TEXT}
        strokeWidth="2.3"
        strokeLinecap="round"
        className="assistapp_stroke_req"
      />
      <path
        d="M6 22L17 22"
        stroke={theme.COLORS.MENU.MENU_ITEM.TEXT}
        strokeWidth="2.3"
        strokeLinecap="round"
        className="assistapp_stroke_req"
      />
    </svg>
  );
};

export default withTheme(ReadAreaIcon);
