import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const ReadLineIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46667 16.8667C8.85565 16.8667 9.2287 16.7122 9.50376 16.4371C9.77881 16.1621 9.93333 15.789 9.93333 15.4V13.9334H12.8667V15.4C12.8667 15.789 13.0212 16.1621 13.2962 16.4371C13.5713 16.7122 13.9444 16.8667 14.3333 16.8667C14.7223 16.8667 15.0954 16.7122 15.3704 16.4371C15.6455 16.1621 15.8 15.789 15.8 15.4V9.53336C15.8 8.36641 15.3364 7.24725 14.5113 6.42209C13.6861 5.59693 12.567 5.13336 11.4 5.13336C10.233 5.13336 9.11389 5.59693 8.28873 6.42209C7.46357 7.24725 7 8.36641 7 9.53336V15.4C7 15.789 7.15452 16.1621 7.42958 16.4371C7.70463 16.7122 8.07768 16.8667 8.46667 16.8667ZM11.3947 8.11712C11.7837 8.11712 12.1567 8.27165 12.4318 8.5467C12.7068 8.82175 12.8614 9.1948 12.8614 9.58379V11.0505H9.92802V9.58379C9.92802 9.1948 10.0825 8.82175 10.3576 8.5467C10.6326 8.27165 11.0057 8.11712 11.3947 8.11712Z"
        fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
      />
      <path
        d="M6 21L17 21"
        stroke={theme.COLORS.MENU.MENU_ITEM.TEXT}
        strokeWidth="2.3"
        strokeLinecap="round"
        className="assistapp_stroke_req"
      />
    </svg>
  );
};

export default withTheme(ReadLineIcon);
