import { MobileFilled, WindowsFilled } from '@ant-design/icons';
import { Checkbox, Form } from 'antd';

import { FormItemProps } from 'antd/es/form/FormItem';
import { ColumnsType } from 'antd/lib/table';

import {
  EffectCellIconWrapper,
  EffectNameCell,
} from '../../../components/table/effect-table-components';
import { WIDGET_BASE_THEME } from '../../../utils/widget/theme';
import { TWidgetEffectTableData } from '../types/widget-effect-types';

export function getFormItemName(
  initialName: FormItemProps['name'],
  ...args: FormItemProps['name'][]
): FormItemProps['name'] {
  const newLastName = Array.isArray(args) ? [...args] : [args];

  return Array.isArray(initialName)
    ? ([...initialName, ...newLastName] as FormItemProps['name'])
    : ([initialName, ...newLastName] as FormItemProps['name']);
}

export const getWidgetEffectColumns: (
  baseFormName: FormItemProps['name']
) => ColumnsType<TWidgetEffectTableData> = (baseFormName) => [
  {
    title: 'Эффект',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => {
      return (
        <EffectNameCell>
          <EffectCellIconWrapper>
            <record.Icon theme={WIDGET_BASE_THEME} />
          </EffectCellIconWrapper>
          <span>{value}</span>
        </EffectNameCell>
      );
    },
  },
  {
    title: <MobileFilled />,
    dataIndex: 'key',
    key: 'mobileForm',
    width: 39,
    render: (key) => {
      return (
        <Form.Item
          name={getFormItemName(baseFormName, key, 'mobile')}
          valuePropName="checked"
          noStyle
        >
          <Checkbox />
        </Form.Item>
      );
    },
  },
  {
    title: <WindowsFilled />,
    dataIndex: 'key',
    key: 'desktopForm',
    render: (key) => {
      return (
        <Form.Item
          name={getFormItemName(baseFormName, key, 'desktop')}
          valuePropName="checked"
          noStyle
        >
          <Checkbox />
        </Form.Item>
      );
    },
  },
];
