export enum EPaths {
  HOME = '/',
  LOGIN = '/login',
  REGISTRATION = '/registration',
  FORGOT_PASSWORD = '/forgot-password',
  CREATE_WIDGET = '/create',
  SETTINGS = '/settings',
  PAYMENT = '/payment',
  STATISTIC = '/statistic',
  ACCESS_CHECKER = '/accessibility-checker',
  DOCUMENTATION = '/documentation',
  USERS = '/users',
}

export const ADMIN_AVAILABLE_PATHS: EPaths[] = [EPaths.USERS];

export const UNAUTH_AVAILABLE_PATHS: EPaths[] = [
  EPaths.LOGIN,
  // EPaths.REGISTRATION,
  EPaths.FORGOT_PASSWORD,
];
