import { TWidgetSettings } from '../../api/features/widget/types';
import { EWidgetPresetThemeKey } from '../../styles/widget-preset-style';
import { READY_THEMES } from './ready-themes/ready-themes';

export const BASE_WIDGET_WIDTH = 520;

export const WIDGET_BASE_THEME: TWidgetSettings['theme'] = {
  COLORS: READY_THEMES[EWidgetPresetThemeKey.DEFAULT],
  SETTINGS: {
    COMMON: {
      FONT: '-apple-system,BlinkMacSystemFont,"SegoeUI",Roboto,Helvetica,Arial,sans-serif,"AppleColorEmoji","Segoe UI Emoji","Segoe UI Symbol"',
    },
    MAIN_BUTTON: {
      Z_INDEX: 99999,
      SIZE: 60,
      BORDER_RADIUS: 30,
      POSITION: {
        right: 40,
        bottom: 40,
      },
    },
    MENU: {
      Z_INDEX: 99999,
    },
    EFFECTS: {
      CITE_USE_REM: false,
    },
  },
};
