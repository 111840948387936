import styled from '@emotion/styled';
import { FC, MouseEventHandler, ReactNode, memo } from 'react';

import { TUserDataResponse } from '../../../api/features/auth/types';
import { UserCard } from '../../ui/user-card';

export type TUserPopoverMenuItem = {
  key: string;
  label: string;
  onClick?: MouseEventHandler<HTMLLIElement>;
  icon: ReactNode;
};

type TProps = {
  userData: TUserDataResponse;
  userMenu: TUserPopoverMenuItem[];
};

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ProfileMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ProfileMenuItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  padding: 4px;
  background-color: transparent;

  :hover {
    background-color: ${({ theme }) => theme.MAIN.WHITE.C300};
  }
`;

const MenuItemLabel = styled.span`
  color: ${({ theme }) => theme.MAIN.TEXT.C300};
`;

const IconWrapper = styled.div`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.MAIN.TEXT.C300};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfilePopoverContent: FC<TProps> = ({ userData, userMenu }) => {
  return (
    <PopoverContainer>
      <UserCard name={userData.email} status={userData.status} />
      <ProfileMenu aria-label="Меню пользователя">
        {userMenu.map((el) => (
          <ProfileMenuItem
            key={el.key}
            aria-label={el.label}
            onClick={el.onClick}
          >
            <IconWrapper aria-hidden="true">{el.icon}</IconWrapper>
            <MenuItemLabel>{el.label}</MenuItemLabel>
          </ProfileMenuItem>
        ))}
      </ProfileMenu>
    </PopoverContainer>
  );
};

export default memo(ProfilePopoverContent);
