import { ColorPicker, Form, Input, InputNumber } from 'antd';
import { FC, memo } from 'react';

import {
  WidgetGridSettingItem,
  WidgetSettingsGridForm,
} from '../../../components/ui/widget/widget-settings';
import {
  EWidgetSettingsFormNames,
  ThemeFormItems,
} from '../../../types/forms/widget-settings';

const ExpandedColorsForm: FC = memo(() => {
  return (
    <WidgetSettingsGridForm>
      {ThemeFormItems.map((el) => {
        let content: JSX.Element = (
          <ColorPicker
            style={{
              width: '100%',
            }}
            showText
            defaultFormat="hex"
          />
        );

        if (el.type === 'color') {
          content = (
            <ColorPicker
              style={{
                width: '100%',
              }}
              showText
              defaultFormat="hex"
            />
          );
        }

        if (el.type === 'number') {
          content = (
            <InputNumber
              style={{
                width: '100%',
              }}
            />
          );
        }

        if (el.type === 'string') {
          content = (
            <Input
              style={{
                width: '100%',
              }}
            />
          );
        }

        return (
          <WidgetGridSettingItem key={el.key}>
            <Form.Item
              label={el.label}
              name={[EWidgetSettingsFormNames.COLORS, el.key]}
              tooltip={el.tooltip}
              style={{
                marginBottom: 0,
                width: '100%',
              }}
              rules={[
                {
                  required: true,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              {content}
            </Form.Item>
          </WidgetGridSettingItem>
        );
      })}
    </WidgetSettingsGridForm>
  );
});

export default ExpandedColorsForm;
