export const CONTEXT_ROOT = process.env.CONTEXT_ROOT || '/';
export const API_URL = process.env.REMOTE_HOST || '/';
export const RIGHTS_RESERVED_YEAR = 2024;

export const WIDGET_URL_BLACK_LIST = [
  'localhost:XXXX',
  'localhost',
  '127.0.0.1',
  '127.0.0.1:XXXX',
  'tilda.ws',
];
