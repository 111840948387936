import { Theme, ThemeProvider } from '@emotion/react';
import React, { FC, PropsWithChildren, useMemo } from 'react';

import { useThemeSelector } from '../state/reducers/app/selectors';
import { COMMON_STYLES, SETTINGS } from '../styles';
import { LocaleService } from './locale-service';

export const lightTheme: Theme = {
  COLORS: COMMON_STYLES,
  SETTINGS,
};

export const useCurrentTheme: () => Theme = () => {
  const { currentTheme } = useThemeSelector();

  return useMemo(() => {
    return lightTheme;
  }, [currentTheme]);
};

export const ThemeService: FC<PropsWithChildren> = ({ children }) => {
  const theme = useCurrentTheme();

  return (
    <ThemeProvider theme={theme}>
      <LocaleService theme={theme}>{children}</LocaleService>
    </ThemeProvider>
  );
};
