import { EAccessButtonType } from '@kylfyk/accessability-react';
import { TWidgetPresetConfig } from '@kylfyk/accessability-react/dist/presets';
import {
  ICommonStyles,
  TBreakPoints,
  TSettingsPosition,
} from '@kylfyk/accessability-react/dist/styles';
import { TWidgetEffectsSettings } from '@kylfyk/accessability-react/dist/types/widget';
import { FormInstance } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { isEqualWith } from 'lodash';

import {
  EWidgetStatus,
  TWidget,
  TWidgetBaseForm,
  TWidgetFullSettingsForm,
  TWidgetSettings,
  TWidgetSettingsItem,
} from '../../api/features/widget/types';
import { EWidgetPresetThemeKey } from '../../styles/widget-preset-style';
import {
  EWidgetSettingsFormNames,
  TFormNames,
} from '../../types/forms/widget-settings';
import { deepMerge } from '../common-js';
import { TPaths } from '../type-utils';
import { getDefaultWidgetSettings } from './base-config';
import { READY_THEMES } from './ready-themes/ready-themes';

// Преобразование виджета в объект TWidgetSettingsItem
export function mapServerWidgetToSettingsItem(
  widget: TWidget
): TWidgetSettingsItem {
  return {
    id: widget.id,
    domain: widget.domain,
    subdomain: widget.subdomain,
    widgetFolder: widget.widgetFolder,
    setting: widget.setting,
    status: widget.status,
    updatedAt: widget.updatedAt,
    dateTo: widget.dateTo,
  };
}

// Преобразование нового виджета (до отправки на сервер) в объект TWidgetSettingsItem
export function mapClientNewWidgetToSettingsItem(
  form: TWidgetBaseForm
): TWidgetSettingsItem {
  return {
    domain: form.domain,
    subdomain: form.subdomain?.join(', '),
    widgetReadyTheme: EWidgetPresetThemeKey.DEFAULT,
    widgetFolder: form.widgetFolder,
    setting: getDefaultWidgetSettings(),
    updatedAt: dayjs().toISOString(),
    status: EWidgetStatus.DRAFT,
  };
}

type TParseObjectResponse<T extends object> = [
  TFormNames<T>[number]['key'],
  unknown,
][];

function parseObjectKeys<T extends object>(
  obj: T,
  parentKey?: string
): TParseObjectResponse<T> {
  const values: TParseObjectResponse<T> = [];

  (Object.keys(obj) as (keyof T)[]).map((el) => {
    const value = obj[el];

    if (typeof value === 'object') {
      values.push(
        ...parseObjectKeys(
          value as object,
          parentKey ? `${parentKey}.${el as TPaths<T>}` : (el as TPaths<T>)
        )
      );
    } else {
      // @ts-ignore
      values.push([parentKey ? `${parentKey}.${el}` : el, value]);
    }
  });

  return values;
}

export function mapWidgetColorsToFormValues(
  colors: ICommonStyles<number>
): Record<TFormNames<ICommonStyles<string>>[number]['key'], string> {
  return Object.fromEntries(parseObjectKeys(colors)) as Record<
    TFormNames<ICommonStyles<string>>[number]['key'],
    string
  >;
}

export function getWidgetColorsByTheme(
  theme: EWidgetPresetThemeKey
): TWidgetSettings['theme']['COLORS'] {
  return READY_THEMES[theme];
}

// Наименования ключей которые соответствуют ключам в TWidgetSettingsItem и для которых не нужна доп обработка
const simpleWidgetThemeKeys: EWidgetSettingsFormNames[] = [
  EWidgetSettingsFormNames.DOMAIN,
  EWidgetSettingsFormNames.READY_THEME,
];

// Преобразование формы виджета в TWidgetSettingsItem
export function mapWidgetThemeFormToScheme(
  data: Partial<TWidgetFullSettingsForm>,
  current: TWidgetSettingsItem,
  form: FormInstance,
  forceTheme: boolean = false,
  isAdmin: boolean = false
): TWidgetSettingsItem {
  let result = { ...current };

  (Object.keys(data) as EWidgetSettingsFormNames[]).forEach((dataKey) => {
    const value = data[dataKey as keyof typeof data];

    if (simpleWidgetThemeKeys.includes(dataKey)) {
      result = {
        ...result,
        [dataKey]: value,
      };
    }

    try {
      switch (dataKey) {
        case EWidgetSettingsFormNames.DATE_TO:
          result = {
            ...result,
            dateTo: (value as Dayjs | undefined)?.toISOString(),
          };
          break;
        case EWidgetSettingsFormNames.SUB_DOMAIN:
          result = {
            ...result,
            subdomain: (value as string[] | undefined)?.join(', '),
          };
          break;
        case EWidgetSettingsFormNames.BUTTON_TYPE:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  MAIN_BUTTON: {
                    ...result.setting.theme.SETTINGS.MAIN_BUTTON,
                    TYPE: value as EAccessButtonType | undefined,
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.READY_THEME:
          if (forceTheme && value) {
            const newColors = getWidgetColorsByTheme(
              value as EWidgetPresetThemeKey
            );

            result = {
              ...result,
              setting: {
                ...result.setting,
                theme: {
                  ...result.setting.theme,
                  COLORS: {
                    ...result.setting.theme.COLORS,
                    ...newColors,
                  },
                },
              },
            };
          }

          form.setFieldsValue({
            // @ts-ignore
            [EWidgetSettingsFormNames.COLORS]: {
              ...mapWidgetColorsToFormValues(result.setting.theme.COLORS),
            },
          });
          break;
        case EWidgetSettingsFormNames.COLORS:
          (
            Object.keys(
              value as Partial<
                TWidgetFullSettingsForm[EWidgetSettingsFormNames.COLORS]
              >
            ) as (keyof TWidgetFullSettingsForm[EWidgetSettingsFormNames.COLORS])[]
          ).forEach((key) => {
            let themeValue: any =
              // @ts-ignore
              typeof value[key] === 'object' &&
              // @ts-ignore
              value[key].hasOwnProperty('metaColor')
                ? // @ts-ignore
                  value[key].toHexString()
                : // @ts-ignore
                  value[key];

            key
              .split('.')
              .reverse()
              .forEach((subKey) => {
                themeValue = {
                  [subKey]: themeValue,
                };
              });

            themeValue = {
              setting: {
                theme: {
                  COLORS: themeValue,
                },
              },
            };

            deepMerge(result, themeValue);
          });
          break;
        case EWidgetSettingsFormNames.META_LINK_HIDDEN:
          if (!isAdmin) {
            form.resetFields([EWidgetSettingsFormNames.META_LINK_HIDDEN]);
            break;
          }
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  COMMON: {
                    ...result.setting.theme.SETTINGS.COMMON,
                    HIDE_LINK: value as boolean | undefined,
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.FONT:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  COMMON: {
                    ...result.setting.theme.SETTINGS.COMMON,
                    FONT: value as string,
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.MENU_Z_INDEX:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  MENU: {
                    ...result.setting.theme.SETTINGS.MENU,
                    Z_INDEX: value as number,
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.USES_REM:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  EFFECTS: {
                    ...result.setting.theme.SETTINGS.EFFECTS,
                    CITE_USE_REM: value as boolean | undefined,
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.REM_TARGET_SELECTOR:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  EFFECTS: {
                    ...result.setting.theme.SETTINGS.EFFECTS,
                    REM_TARGET_SELECTOR: value as string | undefined,
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.HIDE_BUTTON:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  MAIN_BUTTON: {
                    ...result.setting.theme.SETTINGS.MAIN_BUTTON,
                    BUTTON_HIDDEN: value as boolean,
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.ACCESS_BUTTON_Z_INDEX:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  MAIN_BUTTON: {
                    ...result.setting.theme.SETTINGS.MAIN_BUTTON,
                    Z_INDEX: value as number,
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.ACCESS_BUTTON_SIZE:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  MAIN_BUTTON: {
                    ...result.setting.theme.SETTINGS.MAIN_BUTTON,
                    SIZE: value as number,
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.ACCESS_BUTTON_BORDER_RADIUS:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  MAIN_BUTTON: {
                    ...result.setting.theme.SETTINGS.MAIN_BUTTON,
                    BORDER_RADIUS: value as number,
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.ADAPTIVE:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  MAIN_BUTTON: {
                    ...result.setting.theme.SETTINGS.MAIN_BUTTON,
                    POSITION: {
                      ...result.setting.theme.SETTINGS.MAIN_BUTTON.POSITION,
                      ...(value as TSettingsPosition<number>),
                    },
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.ACCESS_BUTTON_ENABLE_BREAKPOINTS:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  MAIN_BUTTON: {
                    ...result.setting.theme.SETTINGS.MAIN_BUTTON,
                    POSITION: {
                      ...result.setting.theme.SETTINGS.MAIN_BUTTON.POSITION,
                      BREAK_POINTS: (value as boolean)
                        ? result.setting.theme.SETTINGS.MAIN_BUTTON.POSITION
                            .BREAK_POINTS
                        : undefined,
                    },
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.ACCESS_BUTTON_ADAPTIVE_BREAKPOINTS:
          result = {
            ...result,
            setting: {
              ...result.setting,
              theme: {
                ...result.setting.theme,
                SETTINGS: {
                  ...result.setting.theme.SETTINGS,
                  MAIN_BUTTON: {
                    ...result.setting.theme.SETTINGS.MAIN_BUTTON,
                    POSITION: {
                      ...result.setting.theme.SETTINGS.MAIN_BUTTON.POSITION,
                      BREAK_POINTS: value as TBreakPoints<number>,
                    },
                  },
                },
              },
            },
          };
          break;
        case EWidgetSettingsFormNames.EFFECTS_SETTINGS:
          const effectValue = value as Partial<TWidgetEffectsSettings>;
          const effectKeys = Object.keys(
            effectValue
          ) as (keyof Partial<TWidgetEffectsSettings>)[];
          effectKeys.forEach((effectKey) => {
            result = {
              ...result,
              setting: {
                ...result.setting,
                effects: {
                  ...result.setting.effects,
                  [effectKey]: {
                    ...result.setting.effects[effectKey],
                    ...effectValue[effectKey],
                  },
                },
              },
            };
          });
          break;
        case EWidgetSettingsFormNames.PRESETS_SETTINGS:
          const presetsValue = value as Partial<TWidgetPresetConfig>;

          result = {
            ...result,
            setting: {
              ...result.setting,
              presets: {
                ...result.setting.presets,
                ...presetsValue,
              },
            },
          };
          break;
      }
    } catch (e) {
      console.error('Map widget form throw error', e);
    }
  });

  return result;
}

// Преобразование TWidgetSettingsItem в объект формы
export function mapWidgetSettingItemToFormValues(
  widgetSettingsItem: TWidgetSettingsItem
): TWidgetFullSettingsForm {
  let isInitialTheme: boolean = false;

  if (widgetSettingsItem.widgetReadyTheme) {
    isInitialTheme = isEqualWith(
      widgetSettingsItem.setting.theme.COLORS,
      READY_THEMES[widgetSettingsItem.widgetReadyTheme]
    );
  }

  return {
    [EWidgetSettingsFormNames.DATE_TO]: widgetSettingsItem.dateTo
      ? dayjs(widgetSettingsItem.dateTo)
      : undefined,
    [EWidgetSettingsFormNames.BUTTON_TYPE]:
      widgetSettingsItem.setting.theme.SETTINGS.MAIN_BUTTON.TYPE,
    [EWidgetSettingsFormNames.DOMAIN]: widgetSettingsItem.domain,
    [EWidgetSettingsFormNames.SUB_DOMAIN]:
      widgetSettingsItem.subdomain?.split(', '),
    [EWidgetSettingsFormNames.READY_THEME]: isInitialTheme
      ? widgetSettingsItem.widgetReadyTheme
      : undefined,
    [EWidgetSettingsFormNames.COLORS]: mapWidgetColorsToFormValues(
      widgetSettingsItem.setting.theme.COLORS
    ),
    [EWidgetSettingsFormNames.FONT]:
      widgetSettingsItem.setting.theme.SETTINGS.COMMON.FONT,
    [EWidgetSettingsFormNames.META_LINK_HIDDEN]:
      widgetSettingsItem.setting.theme.SETTINGS.COMMON.HIDE_LINK,
    [EWidgetSettingsFormNames.MENU_Z_INDEX]:
      widgetSettingsItem.setting.theme.SETTINGS.MENU.Z_INDEX,
    [EWidgetSettingsFormNames.USES_REM]:
      widgetSettingsItem.setting.theme.SETTINGS.EFFECTS?.CITE_USE_REM ?? false,
    [EWidgetSettingsFormNames.HIDE_BUTTON]:
      widgetSettingsItem.setting.theme.SETTINGS.MAIN_BUTTON.BUTTON_HIDDEN,
    [EWidgetSettingsFormNames.ACCESS_BUTTON_Z_INDEX]:
      widgetSettingsItem.setting.theme.SETTINGS.MAIN_BUTTON.Z_INDEX,
    [EWidgetSettingsFormNames.ACCESS_BUTTON_SIZE]:
      widgetSettingsItem.setting.theme.SETTINGS.MAIN_BUTTON.SIZE,
    [EWidgetSettingsFormNames.ACCESS_BUTTON_BORDER_RADIUS]:
      widgetSettingsItem.setting.theme.SETTINGS.MAIN_BUTTON.BORDER_RADIUS,
    [EWidgetSettingsFormNames.ACCESS_BUTTON_ENABLE_BREAKPOINTS]:
      !!widgetSettingsItem.setting.theme.SETTINGS.MAIN_BUTTON.POSITION
        .BREAK_POINTS,
    [EWidgetSettingsFormNames.ADAPTIVE]:
      widgetSettingsItem.setting.theme.SETTINGS.MAIN_BUTTON.POSITION,
    [EWidgetSettingsFormNames.ACCESS_BUTTON_ADAPTIVE_BREAKPOINTS]: {
      ...widgetSettingsItem.setting.theme.SETTINGS.MAIN_BUTTON.POSITION
        .BREAK_POINTS,
    },
    [EWidgetSettingsFormNames.REM_TARGET_SELECTOR]:
      widgetSettingsItem.setting.theme.SETTINGS?.EFFECTS?.REM_TARGET_SELECTOR ??
      'html',
    [EWidgetSettingsFormNames.EFFECTS_SETTINGS]:
      widgetSettingsItem.setting.effects,
    [EWidgetSettingsFormNames.PRESETS_SETTINGS]:
      widgetSettingsItem.setting.presets,
    [EWidgetSettingsFormNames.BUTTON_TYPE]:
      widgetSettingsItem.setting.theme.SETTINGS.MAIN_BUTTON.TYPE,
  };
}
