import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const DyslexicIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1342_6340)">
        <path
          d="M1.03936 24L8.23936 5.775H10.5644L17.7394 24H14.2144L13.5144 21.025H5.31436L4.38936 24H1.03936ZM12.7644 18.125L9.38936 8.025L6.03936 18.125H12.7644ZM28.9507 16.2V24H26.7007V22.05C25.6757 23.55 24.2007 24.35 21.9757 24.35C19.2007 24.35 17.4007 22.65 17.4007 20.025C17.4007 16.95 19.4757 15.375 23.5507 15.375H26.7007V15.15C26.7007 13.075 25.4507 11.825 22.8257 11.65C21.2757 11.65 19.7757 12.025 18.4007 12.775V10.95C20.0507 10.325 21.6007 10 23.0507 10C27.0257 10 28.9507 12.025 28.9507 16.2ZM25.4507 17.025H23.4257C20.1507 17.025 19.3007 17.725 19.2757 19.4C19.5257 20.8 20.7007 20.925 22.3257 20.925C24.5757 20.925 25.4507 20.45 25.4507 17.525V17.025Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
      </g>
      <defs>
        <clipPath id="clip0_1342_6340">
          <rect
            width="30"
            height="30"
            fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(DyslexicIcon);
