import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { textToBoolean, toTextBoolean } from '../../../utils/ui/common';

type TProps = {
  theme: Theme;
  active: boolean;
  path: string;
  text: string;
};

const Wrapper = styled(NavLink)<{
  active: 'true' | 'false';
}>`
  white-space: nowrap;
  transition:
    background-color 0.3s ease-out,
    color 0.1s ease-out;
  border-radius: 6px;
  display: inline-block;
  padding: 0.875rem 0.75rem;
  font-weight: bold;
  color: ${({ theme, active }) =>
    textToBoolean(active) ? theme.MAIN.WHITE.C100 : theme.MAIN.TEXT.C200};
  background-color: ${({ theme, active }) =>
    textToBoolean(active) ? theme.MAIN.ACCENT.C100 : 'transparent'};

  &:hover {
    color: ${({ theme, active }) =>
      textToBoolean(active) ? theme.MAIN.WHITE.C100 : theme.MAIN.TEXT.C300};
  }

  &:last-of-type {
    position: sticky;
    right: 0;
    background-color: ${({ theme, active }) =>
      textToBoolean(active) ? theme.MAIN.ACCENT.C100 : theme.MAIN.WHITE.C200};
  }
`;

const PageTabItem: FC<TProps> = ({ theme, active, path, text }) => {
  return (
    <Wrapper to={path} active={toTextBoolean(active)} theme={theme}>
      <span>{text}</span>
    </Wrapper>
  );
};

export default withTheme(PageTabItem);
