import { useEffect } from 'react';

import { useWidgetEffect } from '../../hooks/useWidgetEffect';
import BrightIcon from '../../icons/brightIcon';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const brightEffect: TSettingItem = {
  Icon: BrightIcon,
  key: ETextEffectKeys.BRIGHTNESS,
};

export const useBrightEffect = () => {
  const { effect } = useWidgetEffect(ETextEffectKeys.BRIGHTNESS);
  const brightRule = 'brightness(1.2)';

  useEffect(() => {
    const container = document.querySelector('html') as HTMLElement;

    let oldFilters = window.getComputedStyle(container)?.filter ?? '';

    oldFilters = oldFilters
      .split(' ')
      .filter((el) => el !== brightRule && el !== 'none')
      .join(' ');

    if (effect.value === 1) {
      container.style.filter = `${oldFilters} ${brightRule}`;
    } else {
      container.style.filter = oldFilters;
    }
  }, [effect]);
};
