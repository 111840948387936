import { FC, Fragment, PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useGetMyUserDataQuery } from '../api/features/auth';
import { EPaths, UNAUTH_AVAILABLE_PATHS } from '../routes/route-paths';
import { useAppSelector } from '../state';
import { useUserAuthSelector } from '../state/auth/selectors';

const REDIRECT_PARAM_NAME = 'redirectTo';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { authed } = useAppSelector(useUserAuthSelector);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { refetch } = useGetMyUserDataQuery(undefined, {
    skip: !authed, // Пропускаем запрос, если не авторизован
  });

  // Интервал для периодической проверки данных пользователя
  useEffect(() => {
    if (authed) {
      const interval = setInterval(() => {
        refetch();
      }, 900000); // 15 минут в миллисекундах

      return () => clearInterval(interval);
    }
  }, [authed, refetch]);

  useEffect(() => {
    if (
      !authed &&
      location.pathname &&
      !UNAUTH_AVAILABLE_PATHS.includes(location.pathname as EPaths)
    ) {
      if (location.pathname && location.pathname !== EPaths.HOME) {
        navigate(EPaths.LOGIN + `?${REDIRECT_PARAM_NAME}=${location.pathname}`);
      } else {
        navigate(EPaths.LOGIN);
      }
    } else if (
      authed &&
      UNAUTH_AVAILABLE_PATHS.includes(location.pathname as EPaths)
    ) {
      const redirectFromParam = searchParams.get(REDIRECT_PARAM_NAME);

      if (redirectFromParam) {
        navigate(redirectFromParam);
      } else {
        navigate(EPaths.HOME);
      }
    }
  }, [location, authed, searchParams]);

  return <Fragment>{children}</Fragment>;
};
