import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type TWidgetSettingsState = {
  voicingActions: boolean; // Озвучивание действий
  widgetIncreased: boolean; // Виджет увеличен
};

const initialState: TWidgetSettingsState = {
  voicingActions: false,
  widgetIncreased: false,
};

const settingsSlice = createSlice({
  name: 'widget/settings',
  initialState: initialState,
  reducers: {
    setVoicingActionsAction(state, action: PayloadAction<boolean>) {
      state.voicingActions = action.payload;
    },
    setWidgetIncreasedAction(state, action: PayloadAction<boolean>) {
      state.widgetIncreased = action.payload;
    },
    resetWidgetSettingsAction(state) {
      state.widgetIncreased = false;
      state.voicingActions = false;
    },
  },
});

export const {
  setVoicingActionsAction,
  setWidgetIncreasedAction,
  resetWidgetSettingsAction,
} = settingsSlice.actions;
export const widgetSettingsReducer = settingsSlice.reducer;
