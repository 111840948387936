import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const AccessEye: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_646_3327"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="60"
        height="60"
      >
        <path d="M60 0H0V60H60V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_646_3327)">
        <g opacity="0.08">
          <path
            d="M30 59.9998C46.297 59.9998 59.5082 46.7885 59.5082 30.4916C59.5082 14.1947 46.297 0.983398 30 0.983398C13.7031 0.983398 0.491882 14.1947 0.491882 30.4916C0.491882 46.7885 13.7031 59.9998 30 59.9998Z"
            fill="black"
          />
        </g>
        <path
          d="M30 59.0164C46.297 59.0164 59.5082 45.8051 59.5082 29.5082C59.5082 13.2113 46.297 0 30 0C13.7031 0 0.491882 13.2113 0.491882 29.5082C0.491882 45.8051 13.7031 59.0164 30 59.0164Z"
          fill="#351E9F"
        />
        <g opacity="0.16">
          <path
            d="M30 0C13.7027 0 0.491882 13.2108 0.491882 29.5082C0.491882 45.8055 13.7027 59.0164 30 59.0164C46.2975 59.0164 59.5082 45.8057 59.5082 29.5084C59.5082 13.2109 46.2975 0 30 0ZM30 57.0493C14.7896 57.0493 2.45912 44.7187 2.45912 29.5084C2.45912 14.2978 14.7896 1.96723 30 1.96723C45.2106 1.96723 57.541 14.2977 57.541 29.5082C57.5243 44.7118 45.2036 57.0324 30 57.0493Z"
            fill="url(#paint0_linear_646_3327)"
          />
        </g>
        <path
          d="M30 56.0654C15.3325 56.0654 3.44263 44.1757 3.44263 29.5081C3.44263 14.8405 15.3325 2.95068 30 2.95068C44.6676 2.95068 56.5573 14.8405 56.5573 29.5081C56.5406 44.1688 44.6606 56.0488 30 56.0654ZM30 4.91791C16.4194 4.91791 5.40986 15.9274 5.40986 29.5081C5.40986 43.0888 16.4194 54.0982 30 54.0982C43.5806 54.0982 54.5901 43.0888 54.5901 29.5081C54.5745 15.9333 43.5748 4.93374 30 4.91791Z"
          fill="url(#paint1_linear_646_3327)"
        />
        <g opacity="0.16">
          <path
            d="M30 0C13.7027 0 0.491882 13.2108 0.491882 29.5082C0.491882 45.8055 13.7027 59.0164 30 59.0164C46.2975 59.0164 59.5082 45.8057 59.5082 29.5084C59.5082 13.2109 46.2975 0 30 0ZM30 58.0327C14.2466 58.0327 1.47544 45.2618 1.47544 29.5082C1.47544 13.7546 14.2466 0.983554 30 0.983554C45.7534 0.983554 58.5246 13.7546 58.5246 29.5082C58.5246 45.2618 45.7534 58.0327 30 58.0327Z"
            fill={theme.COLORS.ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR}
          />
        </g>
        <path
          d="M33.8989 29.4998C33.8989 31.9339 31.9255 33.9073 29.4914 33.9073C27.0571 33.9073 25.0839 31.9339 25.0839 29.4998C25.0839 27.0656 27.0571 25.0923 29.4914 25.0923C31.9255 25.0923 33.8989 27.0656 33.8989 29.4998Z"
          fill={theme.COLORS.ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR}
        />
        <path
          d="M48.1835 28.8591C48.0975 28.7473 46.0808 26.0383 42.8085 23.2992C38.4096 19.6184 33.8344 17.6748 29.5688 17.6748C25.3032 17.6748 20.7237 19.6183 16.3248 23.2992C13.0525 26.0383 11.0358 28.7473 10.9498 28.8591L10.481 29.4998L10.954 30.1404C11.0399 30.2523 13.0567 32.9612 16.329 35.7003C20.7279 39.3811 25.3074 41.3247 29.573 41.3247C33.8386 41.3247 38.4181 39.3812 42.817 35.7003C46.0893 32.9612 48.106 30.2522 48.192 30.1404L48.6649 29.4998L48.1835 28.8591ZM29.5 38.5083C24.5722 38.5083 20.5646 34.5007 20.5646 29.5729C20.5646 24.6451 24.5722 20.6418 29.5 20.6418C34.4278 20.6418 38.4354 24.6494 38.4354 29.5772C38.4354 34.505 34.4278 38.5083 29.5 38.5083ZM13.1901 29.4998C14.2952 28.1625 17.1117 25.0063 20.8484 22.6585C19.3305 24.5549 18.4189 26.9585 18.4189 29.5729C18.4189 32.0497 19.2359 34.3373 20.6118 36.1863C16.9912 33.8557 14.2693 30.807 13.19 29.4998H13.1901ZM38.2462 36.3712C39.7082 34.4921 40.5854 32.1357 40.5854 29.5729C40.5854 26.8854 39.6223 24.4172 38.027 22.4951C41.8927 24.8558 44.821 28.1281 45.9519 29.4955C44.8425 30.8414 42.0088 34.0234 38.2463 36.3712H38.2462Z"
          fill={theme.COLORS.ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR}
        />
        <path
          d="M15.4142 42.3232L42.3229 15.4146L43.8432 16.9348L16.9345 43.8435L15.4142 42.3232Z"
          fill={theme.COLORS.ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_646_3327"
          x1="30.0002"
          y1="59.0192"
          x2="30.0002"
          y2="0.00306738"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.36" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_646_3327"
          x1="30"
          y1="56.0683"
          x2="30"
          y2="2.95378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.COLORS.ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR} />
          <stop
            offset="1"
            stopColor={theme.COLORS.ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR}
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default withTheme(AccessEye);
