import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const CloseIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0001 5.99998C17.8125 5.81251 17.5582 5.70719 17.2931 5.70719C17.0279 5.70719 16.7736 5.81251 16.5861 5.99998L12.0001 10.586L7.41406 5.99998C7.22653 5.81251 6.97223 5.70719 6.70706 5.70719C6.4419 5.70719 6.18759 5.81251 6.00006 5.99998C5.81259 6.18751 5.70728 6.44181 5.70728 6.70698C5.70728 6.97214 5.81259 7.22645 6.00006 7.41398L10.5861 12L6.00006 16.586C5.81259 16.7735 5.70728 17.0278 5.70728 17.293C5.70728 17.5581 5.81259 17.8125 6.00006 18C6.18759 18.1875 6.4419 18.2928 6.70706 18.2928C6.97223 18.2928 7.22653 18.1875 7.41406 18L12.0001 13.414L16.5861 18C16.7736 18.1875 17.0279 18.2928 17.2931 18.2928C17.5582 18.2928 17.8125 18.1875 18.0001 18C18.1875 17.8125 18.2928 17.5581 18.2928 17.293C18.2928 17.0278 18.1875 16.7735 18.0001 16.586L13.4141 12L18.0001 7.41398C18.1875 7.22645 18.2928 6.97214 18.2928 6.70698C18.2928 6.44181 18.1875 6.18751 18.0001 5.99998V5.99998Z"
        fill={theme.COLORS.MENU.HEADER_TEXT_COLOR}
      />
    </svg>
  );
};

export default withTheme(CloseIcon);
