import { Theme } from '@emotion/react';
import { FC } from 'react';

import { ETextEffectKeys } from '../effects/names';
import { TIconProps } from '../icons/types';

export enum ECiteThemeEffect {
  WHITE = 'white',
  BLACK = 'black',
  BLUE = 'blue',
  BEIGE = 'beige',
  BROWN = 'brown',
}

export type TSettingValue = boolean | string | number | ECiteThemeEffect;

export type TSettingValueInfo = {
  value: TSettingValue;
  loading?: boolean;
};

export type TSettingItem<InitFNType = Function> = {
  key: (typeof ETextEffectKeys)[keyof typeof ETextEffectKeys];
  Icon: FC<Pick<TIconProps, never> & { theme?: Theme | undefined }>;
  index?: number; // Индекс элемента настройки
} & (
  | { needToInitialize?: false }
  | {
      needToInitialize: true;
      initializeFunction: InitFNType;
    }
);

export type TSettingsList = TSettingItem[];

export type TWidgetSetting = TSettingItem;
export type TWidgetSettings = TWidgetSetting[];

export type TOnWidgetChange = {
  key: string;
  onChange: (value: TSettingValue) => void;
};

export type TWidgetEffectsSettings = Record<
  string | (typeof ETextEffectKeys)[keyof typeof ETextEffectKeys],
  {
    mobile: boolean;
    desktop: boolean;
  }
>;
