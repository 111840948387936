import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import PageTabItem from './page-tab-item';

export type TPageHorizontalMenuItem = {
  to: string;
  text: string;
};

type TMenuPowered = {
  active: boolean;
} & TPageHorizontalMenuItem;

type TProps = {
  theme: Theme;
  menu: TPageHorizontalMenuItem[];
  emptyText?: string;
  labelShowCondition?: () => boolean;
  label?: string;
};

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.125rem;
  background-color: ${({ theme }) => theme.MAIN.WHITE.C200};
  border-radius: 6px;
  gap: 12px;
  overflow-x: auto;

  --scrollbar-width: 6px;

  &::-webkit-scrollbar {
    background: transparent;
    width: var(--scrollbar-width);
    height: var(--scrollbar-width);
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.MAIN.WHITE.C500};
    border-radius: 6px;
    width: var(--scrollbar-width);
    height: var(--scrollbar-width);
  }
`;

const Label = styled('h4')`
  color: ${({ theme }) => theme.MAIN.TEXT.C300};
  padding: 0.875rem 0.75rem;
  font-weight: normal;
  margin: 0 0.5rem 0 0;
  background-color: ${({ theme }) => theme.MAIN.WHITE.C200};
  white-space: nowrap;
`;

const HorizontalPageMenu: FC<TProps> = memo(
  ({ theme, menu, label, emptyText, labelShowCondition }) => {
    const location = useLocation();

    const menuList: TMenuPowered[] = useMemo(() => {
      return menu.map((el) => ({
        ...el,
        active: location.pathname === el.to,
      }));
    }, [menu, location]);

    return (
      <Wrapper theme={theme}>
        {(menuList.length === 0 ||
          (label && labelShowCondition ? labelShowCondition() : true)) && (
          <Label theme={theme}>
            {menuList.length === 0 ? emptyText : label}
          </Label>
        )}
        {menuList.map((el) => (
          <PageTabItem
            key={el.to}
            theme={theme}
            path={el.to}
            active={el.active}
            text={el.text}
          />
        ))}
      </Wrapper>
    );
  }
);

export default withTheme(HorizontalPageMenu);
