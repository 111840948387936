import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { CSSProperties, FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WIDGET_MENU_ID_NAME } from '../../constants';
import { useStatisticWriter } from '../../hooks/useStatisticWriter';
import { LocaleParts } from '../../locale';
import { TPresetList } from '../../presets';
import { useWidgetSettingsSelector } from '../../state/reducers/widget/selectors';
import { TOnWidgetChange, TSettingsList } from '../../types/widget';
import { textToBoolean, toTextBoolean } from '../../utils/common';
import { TabIndex } from '../../utils/tab-index';
import WidgetGridSettings from '../widget-grid-settings';
import GridSettingItem from '../widget-grid-settings/grid-setting-item';
import WidgetPresetItem from '../widget-setting-item/widget-preset-item';
import WidgetSettings from '../widget-settings';
import Footer from './footer';
import Header from './header';

const Wrapper = styled('aside')<{
  menuWidth: number;
  visible: 'true' | 'false';
  defaultPosition?: 'true' | 'false';
  widgetIncreased?: 'true' | 'false';
}>`
  position: ${({ defaultPosition }) =>
    defaultPosition === 'true' ? 'unset' : 'fixed'};
  right: ${({ defaultPosition }) =>
    defaultPosition === 'true' ? 'unset' : '0'};
  top: ${({ defaultPosition }) => (defaultPosition === 'true' ? 'unset' : '0')};
  bottom: ${({ defaultPosition }) =>
    defaultPosition === 'true' ? 'unset' : '0'};
  background-color: ${({ theme }) => theme.COLORS.MENU.BACKGROUND};
  box-shadow: ${({ theme }) => theme.COLORS.MENU.SHADOW};
  opacity: ${({ visible }) => (textToBoolean(visible) ? 1 : 0)};
  transform: ${({ visible }) =>
    textToBoolean(visible) ? 'scaleX(1)' : 'scaleX(0)'};
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
  transform-origin: 100%;
  font-family: ${({ theme }) => theme.SETTINGS.COMMON.FONT};
  z-index: ${({ theme }) => theme.SETTINGS.MENU.Z_INDEX};
  width: ${({ menuWidth, widgetIncreased }) =>
    textToBoolean(widgetIncreased)
      ? `${Math.round(menuWidth * 1.3)}px`
      : `${menuWidth}px`};
  max-width: 100vw;
  outline: none;
  display: flex;
  flex-direction: column;

  &,
  & * {
    box-sizing: border-box;
  }
`;

const MenuListWrapper = styled('div')<{
  widgetIncreased?: 'true' | 'false';
}>`
  overflow-y: auto;
  flex: 1;
  height: 100%;
  border-radius: ${({ theme }) => theme.COLORS.MENU.SUB_GROUP.BORDER_RADIUS}px;
  padding: 12px 16px 0;

  &::-webkit-scrollbar {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.COLORS.MENU.SCROLLBAR_BG};

    &:hover {
      background-color: ${({ theme }) =>
        theme.COLORS.MENU.SCROLLBAR_BG_HOVERED};
    }
  }

  @media (min-width: 769px) {
    &::-webkit-scrollbar {
      width: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 12 : 6}px;
      max-width: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 12 : 6}px;
      height: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 12 : 6}px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 6 : 3}px;
      width: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 12 : 6}px;
      height: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 12 : 6}px;
    }
  }

  @media only screen and (max-width: 768px) {
    &::-webkit-scrollbar {
      width: 6px;
      max-width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      width: 6px;
      height: 6px;
    }
  }
`;

export const MenuListGroup = styled('div')<{
  widgetIncreased?: 'true' | 'false';
}>`
  padding: 18px 20px;
  background: ${({ theme }) => theme.COLORS.MENU.SUB_GROUP.BACKGROUND};
  border-radius: ${({ theme }) => theme.COLORS.MENU.SUB_GROUP.BORDER_RADIUS}px;

  &:not(:last-of-type) {
    margin-bottom: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 18 : 12}px;
  }

  @media only screen and (max-width: 520px) {
    padding: 12px 14px;
  }

  button {
    cursor: pointer;
  }
`;

export const MenuSubGroupTitle = styled('h4')<{
  widgetIncreased?: 'true' | 'false';
}>`
  font-weight: bold;
  font-size: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 36 : 18}px;
  color: ${({ theme }) => theme.COLORS.MENU.SUB_GROUP.TITLE_COLOR};
  margin-top: 0;
  margin-bottom: 12px;
  padding: 0;

  @media only screen and (max-width: 520px) {
    font-size: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 28 : 16}px;
  }
`;

type TProps = {
  theme: Theme;
  handleClose?: () => void;
  handleReset?: () => void;
  visible?: boolean;
  settingsList: TSettingsList;
  onChangeList: TOnWidgetChange[];
  defaultPosition?: boolean;
  style?: CSSProperties;
  presetList: TPresetList;
};

const BASE_MENU_WIDTH = 520;

const WidgetMenu: FC<TProps> = memo(
  ({
    theme,
    handleClose,
    handleReset,
    settingsList,
    onChangeList,
    defaultPosition,
    visible = true,
    style,
    presetList,
  }) => {
    const { t } = useTranslation([LocaleParts.WIDGET]);
    const { t: effectsTFunc } = useTranslation([LocaleParts.EFFECTS]);
    const menuRef = useRef<HTMLElement | null>(null);
    const [menuWidth, setMenuWidth] = useState(BASE_MENU_WIDTH);
    const { widgetIncreased } = useWidgetSettingsSelector();
    const [handleWriteStatistic] = useStatisticWriter();

    useEffect(() => {
      const listener = () => {
        setMenuWidth(Math.min(window.innerWidth, BASE_MENU_WIDTH));
      };

      listener();

      window.addEventListener('resize', listener);

      return () => {
        window.removeEventListener('resize', listener);
      };
    }, [theme]);

    useEffect(() => {
      const listener = (e: MouseEvent) => {
        if (!handleClose) {
          return;
        }

        const target = e.target as HTMLElement;

        if (
          menuRef.current &&
          target &&
          target.tagName &&
          !menuRef.current.contains(target)
        ) {
          handleClose();
        }
      };

      window.addEventListener('click', listener);

      return () => {
        window.removeEventListener('click', listener);
      };
    }, []);

    return (
      <Wrapper
        menuWidth={menuWidth}
        visible={toTextBoolean(visible)}
        theme={theme}
        defaultPosition={toTextBoolean(defaultPosition)}
        style={style}
        ref={menuRef}
        role="dialog"
        aria-modal="true"
        aria-live="assertive"
        aria-labelledby={t('header')}
        id={WIDGET_MENU_ID_NAME}
        widgetIncreased={toTextBoolean(widgetIncreased)}
      >
        <Header handleClose={handleClose} theme={theme} t={t} />
        <MenuListWrapper
          theme={theme}
          widgetIncreased={toTextBoolean(widgetIncreased)}
        >
          <WidgetSettings theme={theme} t={t} />
          {presetList.length > 0 && (
            <MenuListGroup
              theme={theme}
              widgetIncreased={toTextBoolean(widgetIncreased)}
            >
              <MenuSubGroupTitle
                theme={theme}
                widgetIncreased={toTextBoolean(widgetIncreased)}
              >
                {t('subGroups.profilesTitle')}
              </MenuSubGroupTitle>
              {presetList.map((el, index) => (
                <WidgetPresetItem
                  key={el.key}
                  index={index + TabIndex.START_MENU_INDEX}
                  theme={theme}
                  data={el}
                  widgetIncreased={toTextBoolean(widgetIncreased)}
                />
              ))}
            </MenuListGroup>
          )}
          <MenuListGroup
            theme={theme}
            widgetIncreased={toTextBoolean(widgetIncreased)}
          >
            <MenuSubGroupTitle
              theme={theme}
              widgetIncreased={toTextBoolean(widgetIncreased)}
            >
              {t('subGroups.allSettings')}
            </MenuSubGroupTitle>
            <WidgetGridSettings widgetIncreased={widgetIncreased}>
              {settingsList.map((el, i) => (
                <GridSettingItem
                  settingItem={el}
                  theme={theme}
                  key={el.key}
                  index={i + presetList.length + TabIndex.START_MENU_INDEX}
                  widgetIncreased={widgetIncreased}
                  t={effectsTFunc}
                  order={el.index}
                  handleWriteStatistic={handleWriteStatistic}
                  onChange={
                    (
                      onChangeList?.find(
                        (effect) => effect.key === el.key
                      ) as TOnWidgetChange
                    )?.onChange
                  }
                />
              ))}
            </WidgetGridSettings>
          </MenuListGroup>
        </MenuListWrapper>
        <Footer
          handleReset={handleReset}
          theme={theme}
          tabIndex={
            settingsList.length + presetList.length + TabIndex.START_MENU_INDEX
          }
          widgetIncreased={widgetIncreased}
        />
      </Wrapper>
    );
  }
);

export default withTheme(WidgetMenu);
