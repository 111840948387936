import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { FC } from 'react';

export type TCompanyMarkProps = {
  t: TFunction<string[]>;
  rightsYear: number;
};

const Wrapper = styled('div')`
  color: ${({ theme }) => theme.MAIN.BLACK.C300};
  display: flex;
  flex-direction: column;
`;

const CompanyTradeMark: FC<TCompanyMarkProps> = ({ t, rightsYear }) => {
  return (
    <Wrapper>
      <span>{t('common.companyFullName')}</span>
      <span>
        {t('aside.tradeMarkText', {
          yearValue: rightsYear,
        })}
      </span>
    </Wrapper>
  );
};

export default withTheme(CompanyTradeMark);
