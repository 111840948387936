import { TWidget } from '../widget/types';

export type TAuthResponse = {
  accessToken: string;
  refreshToken: string;
};

export type TAuthPayload = {
  publicKey: string;
  secretKey: string;
};

export type TRegistrationPayload = {
  email: string;
  password: string;
  skipAccountSetup?: boolean; // true - Если не нужно авторизоваться после регистрации
};

export enum EUserRole {
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
}

export enum EUserStatus {
  DEACTIVE = 'DEACTIVE',
  ACTIVE = 'ACTIVE',
}

export type TAuthJWTDecoded = {
  exp: number;
  iat: number;
  id: string;
  role: EUserRole;
};

export type TUserDataResponse = {
  createdAt: string;
  email: string;
  id: string;
  role: EUserRole;
  status: EUserStatus;
  updatedAt: string;
  widget: TWidget[];
  availableCountOfWidget: number;
  countOfWidget: number;
};
