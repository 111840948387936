import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const ReturnIcons: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_113_139)">
        <path
          d="M21.1571 7.75499C20.7322 7.32876 20.2271 6.99101 19.6709 6.76128C19.1147 6.53156 18.5184 6.41443 17.9166 6.41666H3.06664L7.0083 2.47499C7.17994 2.30262 7.27608 2.06913 7.27556 1.82588C7.27505 1.58263 7.17792 1.34955 7.00555 1.17791C6.83318 1.00627 6.59969 0.910131 6.35644 0.910647C6.11319 0.911162 5.88011 1.00829 5.70847 1.18066L1.17097 5.71724C0.741348 6.14699 0.5 6.72978 0.5 7.33745C0.5 7.94512 0.741348 8.52791 1.17097 8.95766L5.70847 13.4961C5.79358 13.5814 5.89466 13.6492 6.00595 13.6955C6.11724 13.7417 6.23656 13.7656 6.35709 13.7658C6.47762 13.766 6.597 13.7424 6.70842 13.6964C6.81984 13.6505 6.92112 13.583 7.00647 13.4979C7.09182 13.4128 7.15957 13.3117 7.20585 13.2004C7.25213 13.0891 7.27604 12.9698 7.27621 12.8493C7.27638 12.7288 7.25281 12.6094 7.20684 12.498C7.16087 12.3865 7.09341 12.2853 7.0083 12.1999L3.05564 8.24999H17.9166C18.646 8.24999 19.3455 8.53972 19.8612 9.05545C20.3769 9.57117 20.6666 10.2706 20.6666 11V17.4167C20.6666 18.146 20.3769 18.8455 19.8612 19.3612C19.3455 19.8769 18.646 20.1667 17.9166 20.1667H5.0833C4.84019 20.1667 4.60703 20.2632 4.43512 20.4351C4.26321 20.607 4.16664 20.8402 4.16664 21.0833C4.16664 21.3264 4.26321 21.5596 4.43512 21.7315C4.60703 21.9034 4.84019 22 5.0833 22H17.9166C19.1318 21.9985 20.2967 21.5152 21.1559 20.656C22.0152 19.7967 22.4985 18.6318 22.5 17.4167V11C22.502 10.3971 22.3843 9.79989 22.1538 9.24286C21.9233 8.68583 21.5845 8.1801 21.1571 7.75499Z"
          fill={theme.COLORS.MENU.RESET_BUTTON.TEXT}
        />
      </g>
      <defs>
        <clipPath id="clip0_113_139">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(ReturnIcons);
