import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authApi } from '../../api/features/auth';
import { TWidget } from '../../api/features/widget/types';
import { addClearStateOnLogoutMatcher } from '../utils/state-utils';

type TState = {
  list: TWidget[];
};

const initialState: TState = {
  list: [],
};

const widgetSlice = createSlice({
  name: 'widget/all',
  initialState,
  reducers: {
    updateWidgetByIdAction(state, action: PayloadAction<TWidget>) {
      state.list = state.list.map((el) => {
        if (el.id && el.id === action.payload.id) {
          return action.payload;
        }
        return el;
      });
    },
    addWidgetAction(state, action: PayloadAction<TWidget>) {
      state.list = [
        ...state.list.filter((el) => el.id !== action.payload.id),
        action.payload,
      ];
    },
    removeWidgetAction(state, action: PayloadAction<string>) {
      state.list = state.list.filter((el) => el.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getMyUserData.matchFulfilled,
      (state, action) => {
        state.list = action.payload.widget;
      }
    );
    addClearStateOnLogoutMatcher(builder, initialState);
  },
});

export const { updateWidgetByIdAction, addWidgetAction, removeWidgetAction } =
  widgetSlice.actions;
export default widgetSlice.reducer;
