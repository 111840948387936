import { Form, Input } from 'antd';
import { TFunction } from 'i18next';
import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoginMutation } from '../../api/features/auth';
import { LocaleParts } from '../../locale';
import { useAppMessage } from '../../providers/notification-provider';
import { useCurrentTheme } from '../../providers/theme-provider';
import { useAppDispatch } from '../../state';
import { logErrorAction } from '../../state/actions';
import { EMAIL_REGEX } from '../../utils/regex';
import { AppBaseMenuForm } from '../forms/app-base-menu-form';

type TLoginFormType = {
  publicKey: string;
  secretKey: string;
};

type TFormProps = {
  t: TFunction<string[]>;
};

const FormComponent: FC<TFormProps> = ({ t }) => {
  return (
    <Fragment>
      <Form.Item
        label={t('baseInputs.emailLabel')}
        name="publicKey"
        rules={[
          {
            required: true,
            message: t('base.emptyFieldText'),
          },
          {
            pattern: EMAIL_REGEX,
            message: t('baseInputs.emailValidError'),
          },
        ]}
      >
        <Input
          autoComplete="username"
          placeholder={t('baseInputs.emailPlaceholder')}
        />
      </Form.Item>
      <Form.Item
        label={t('baseInputs.passwordLabel')}
        name="secretKey"
        rules={[
          {
            required: true,
            message: t('base.emptyFieldText'),
          },
        ]}
      >
        <Input.Password autoComplete="current-password" />
      </Form.Item>
    </Fragment>
  );
};

export const LoginPage: FC = () => {
  const { t } = useTranslation([LocaleParts.AUTH_FORMS]);
  const theme = useCurrentTheme();
  const [formInstance] = Form.useForm<TLoginFormType>();
  const dispatch = useAppDispatch();
  const { messageApi } = useAppMessage();
  const [login, { isLoading }] = useLoginMutation();

  const handleFormFinish = useCallback(
    async (values: TLoginFormType) => {
      try {
        await login({
          ...values,
        }).unwrap();
      } catch (e: any) {
        if ('status' in e && e.status === 400) {
          messageApi.error(t('async.loginWrongPasswordError'));
        } else if ('status' in e && e.status === 404) {
          messageApi.error(t('async.loginUserNotFoundError'));
        } else {
          dispatch(logErrorAction(e));
          messageApi.error(t('async.loginSendError'));
        }
      }
    },
    [t]
  );

  return (
    <AppBaseMenuForm<TLoginFormType>
      label={t('loginForm.label')}
      FormItems={<FormComponent t={t} />}
      formInstance={formInstance}
      theme={theme}
      buttonText={t('loginForm.buttonText')}
      onFormFinish={handleFormFinish}
      loading={isLoading}
      AdditionalLinks={
        <Fragment>
          {/*<NavLink to={EPaths.FORGOT_PASSWORD}>*/}
          {/*  {t('linksText.forgotLink')}*/}
          {/*</NavLink>*/}
          {/*<NavLink to={EPaths.REGISTRATION}>*/}
          {/*  {t('linksText.registerLink')}*/}
          {/*</NavLink>*/}
        </Fragment>
      }
    />
  );
};
