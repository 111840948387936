import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import WidgetButton from '../components/widget-button';
import { WIDGET_NAME } from '../index';
import { LocaleParts } from '../locale';
import { useCurrentTheme } from '../services/theme-service';

type TProps = {
  opened: boolean;
  handleOpen: () => void;
};

export const WidgetButtonPowered: FC<TProps> = ({ opened, handleOpen }) => {
  const { t } = useTranslation([LocaleParts.WIDGET]);
  const theme = useCurrentTheme();

  useEffect(() => {
    const widget = document.querySelector(WIDGET_NAME);

    if (widget) {
      widget.ariaLabel = t('widgetAriaText');
    }
  }, [t]);

  if (theme.SETTINGS.MAIN_BUTTON.BUTTON_HIDDEN) {
    return null;
  }

  return <WidgetButton onClick={handleOpen} visible={!opened} />;
};
