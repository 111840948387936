import { useMemo } from 'react';

import { TEffectTextKey } from '../effects/names';
import { useEffectSelector } from '../state/reducers/widget/selectors';
import { useSettingsList } from './useSettingsList';

export const useWidgetEffect = (effectKey: TEffectTextKey) => {
  const effect = useEffectSelector(effectKey);

  const [settingsList] = useSettingsList(
    (process.env.WIDGET_CONFIG as any).effects
  );

  const isSettingOn = useMemo(() => {
    return !!settingsList.find((el) => el.key === effectKey);
  }, [settingsList]);

  const settingItem = useMemo(() => {
    return settingsList.find((el) => el.key === effectKey);
  }, [settingsList]);

  return { effect, isSettingOn, settingItem };
};
