import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

import { textToBoolean, toTextBoolean } from '../../utils/common';

type TProps = {
  theme: Theme;
  widgetIncreased?: boolean;
} & PropsWithChildren;

const Wrapper = styled('div')<{
  widgetIncreased?: 'true' | 'false';
}>`
  display: grid;
  grid-template-columns: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? '1fr 1fr' : '1fr 1fr 1fr'};
  grid-gap: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 24 : 12}px;

  @media only screen and (max-width: 520px) {
    grid-gap: 12px;
    grid-template-columns: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? '1fr' : '1fr 1fr'};
  }
`;

const WidgetGridSettings: FC<TProps> = ({
  theme,
  children,
  widgetIncreased,
}) => {
  return (
    <Wrapper theme={theme} widgetIncreased={toTextBoolean(widgetIncreased)}>
      {children}
    </Wrapper>
  );
};

export default withTheme(WidgetGridSettings);
