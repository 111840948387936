import { Typography } from 'antd';
import { FC } from 'react';

import { BasePageWrapper } from '../../components/pages/base-page-wrapper';

export const CheckerPage: FC = () => {
  return (
    <BasePageWrapper>
      <Typography.Title level={2}>Проверка доступности сайта</Typography.Title>
      <span>s</span>
    </BasePageWrapper>
  );
};
