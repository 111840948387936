import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const LinksMarkIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_50_18)">
        <path
          d="M13.845 17.2669L10.583 20.5289C9.63594 21.4507 8.36403 21.9625 7.04247 21.9537C5.72091 21.9448 4.45597 21.416 3.52133 20.4816C2.58669 19.5472 2.05751 18.2825 2.04829 16.9609C2.03906 15.6393 2.55052 14.3673 3.47202 13.4199L6.73402 10.155C6.92153 9.96731 7.02682 9.71287 7.02672 9.4476C7.02663 9.18233 6.92116 8.92796 6.73352 8.74046C6.54588 8.55295 6.29144 8.44766 6.02617 8.44775C5.7609 8.44785 5.50653 8.55332 5.31902 8.74096L2.05802 12.0059C0.740293 13.3243 0.00026379 15.1122 0.000732645 16.9762C0.0012015 18.8402 0.74213 20.6277 2.06052 21.9454C3.37891 23.2632 5.16677 24.0032 7.03079 24.0027C8.89481 24.0022 10.6823 23.2613 12 21.9429L15.262 18.6809C15.4442 18.4923 15.545 18.2397 15.5427 17.9775C15.5404 17.7153 15.4352 17.4645 15.2498 17.2791C15.0644 17.0937 14.8136 16.9885 14.5514 16.9863C14.2892 16.984 14.0366 17.0848 13.848 17.2669H13.845Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M21.9441 2.06142C21.2934 1.40624 20.5191 0.8867 19.6662 0.532917C18.8132 0.179135 17.8985 -0.00185973 16.9751 0.000421369V0.000421369C16.0522 -0.00203036 15.1379 0.1785 14.2852 0.531573C13.4324 0.884646 12.6582 1.40326 12.0071 2.05742L8.7401 5.32042C8.55246 5.50793 8.44699 5.7623 8.4469 6.02757C8.44681 6.29284 8.55209 6.54728 8.7396 6.73492C8.92711 6.92256 9.18148 7.02803 9.44675 7.02812C9.71202 7.02822 9.96646 6.92293 10.1541 6.73542L13.4191 3.47342C13.8847 3.00507 14.4385 2.63372 15.0485 2.38088C15.6586 2.12804 16.3127 1.99872 16.9731 2.00042V2.00042C17.9674 2.00075 18.9392 2.29585 19.7658 2.84842C20.5924 3.40099 21.2366 4.18622 21.6169 5.10484C21.9973 6.02347 22.0968 7.03424 21.9028 8.0094C21.7088 8.98455 21.2301 9.88031 20.5271 10.5834L17.2651 13.8454C17.0775 14.0331 16.972 14.2876 16.972 14.5529C16.972 14.8183 17.0775 15.0728 17.2651 15.2604C17.4527 15.4481 17.7072 15.5535 17.9726 15.5535C18.238 15.5535 18.4925 15.4481 18.6801 15.2604L21.9421 12.0004C23.2581 10.6815 23.9974 8.89453 23.9978 7.03134C23.9982 5.16815 23.2596 3.38086 21.9441 2.06142V2.06142Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M14.2932 8.2931L8.2932 14.2931C8.19769 14.3853 8.12151 14.4957 8.0691 14.6177C8.01669 14.7397 7.98911 14.8709 7.98795 15.0037C7.9868 15.1365 8.0121 15.2681 8.06238 15.391C8.11266 15.5139 8.18692 15.6256 8.28081 15.7195C8.3747 15.8134 8.48635 15.8876 8.60925 15.9379C8.73215 15.9882 8.86382 16.0135 8.9966 16.0123C9.12938 16.0112 9.2606 15.9836 9.38261 15.9312C9.50461 15.8788 9.61496 15.8026 9.7072 15.7071L15.7072 9.7071C15.8894 9.5185 15.9902 9.2659 15.9879 9.0037C15.9856 8.7415 15.8804 8.49069 15.695 8.30528C15.5096 8.11987 15.2588 8.01471 14.9966 8.01243C14.7344 8.01015 14.4818 8.11094 14.2932 8.2931Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
      </g>
      <defs>
        <clipPath id="clip0_50_18">
          <rect width="24" height="24" fill={theme.COLORS.COMMON.ON_ACCENT} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(LinksMarkIcon);
