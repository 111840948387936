import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, MouseEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LocaleParts } from '../../locale';
import { TPlacement } from '../../styles';
import { textToBoolean, toTextBoolean } from '../../utils/common';
import { getAccessIconByType } from '../../utils/get-access-icon-by-type';
import { TabIndex } from '../../utils/tab-index';

type TAdaptive = {
  breakpoints: Record<number, Record<TPlacement, string | number>>;
} & Record<TPlacement, number>;

const getAdaptiveData: (data: TAdaptive) => string = (data) =>
  Object.keys(data.breakpoints ?? {})
    .sort((a, b) => Number(b) - Number(a))
    .map(
      (breakPoint) => `
    @media (max-width: ${breakPoint}px) {
      ${Object.keys(data.breakpoints[Number(breakPoint)])
        .map((placement) => {
          const value = (
            data.breakpoints[Number(breakPoint)] as Record<
              string,
              string | number
            >
          )[placement];

          return (
            placement +
            ':' +
            (typeof value === 'number' ? value + 'px;' : value + ';')
          );
        })
        .join('')}
    }
    `
    )
    .join('') +
  (
    (Object.keys(data) as (keyof TAdaptive)[]).filter(
      (el) => el !== 'breakpoints'
    ) as TPlacement[]
  )
    .map(
      (placement) => `
      ${placement}: ${data[placement]}px;
    `
    )
    .join('');

const Wrapper = styled('button')<{
  visible: 'true' | 'false';
  adaptiveData: string;
}>`
  border: none;
  margin: 0;
  padding: 0;
  width: ${({ theme }) => theme.SETTINGS.MAIN_BUTTON.SIZE}px;
  height: ${({ theme }) => theme.SETTINGS.MAIN_BUTTON.SIZE}px;
  background-color: ${({ theme }) =>
    theme.COLORS.ACCESS_BUTTON.MAIN_COLOR} !important;
  border-radius: ${({ theme }) => theme.SETTINGS.MAIN_BUTTON.BORDER_RADIUS}px;
  position: fixed;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: ${({ theme }) => theme.SETTINGS.MAIN_BUTTON.Z_INDEX};
  pointer-events: none;
  opacity: ${({ visible }) => (textToBoolean(visible) ? 1 : 0)};
  transform: ${({ visible }) =>
    textToBoolean(visible) ? 'unset' : 'translateY(10px)'};
  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-out,
    background-color 0.3s ease-out;

  ${({ adaptiveData }) =>
    getAdaptiveData(JSON.parse(adaptiveData) as TAdaptive)}

  div {
    pointer-events: ${({ visible }) =>
      textToBoolean(visible) ? 'all' : 'none'};
  }

  :hover {
    pointer-events: all;
    transform: scale(1.1) !important;
  }

  &,
  & * {
    box-sizing: border-box;
  }

  &:focus-visible {
    outline: 4px solid ${({ theme }) => theme.COLORS.ACCESS_BUTTON.MAIN_COLOR};
  }
`;

const Inner = styled('div')`
  min-width: ${({ theme }) => theme.SETTINGS.MAIN_BUTTON.SIZE}px;
  width: ${({ theme }) => theme.SETTINGS.MAIN_BUTTON.SIZE}px;
  height: ${({ theme }) => theme.SETTINGS.MAIN_BUTTON.SIZE}px;
  border-radius: ${({ theme }) => theme.SETTINGS.MAIN_BUTTON.BORDER_RADIUS}px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;

  svg {
    width: 100%;
    height: 100%;
  }
`;

type TProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  visible?: boolean;
  theme: Theme;
};

const WidgetButton: FC<TProps> = ({ onClick, visible = true, theme }) => {
  const { t } = useTranslation([LocaleParts.WIDGET]);

  const adaptiveData: TAdaptive = useMemo(() => {
    let result: Partial<TAdaptive> = {
      breakpoints: {},
    };

    if (theme.SETTINGS.MAIN_BUTTON.POSITION.BREAK_POINTS) {
      Object.keys(theme.SETTINGS.MAIN_BUTTON.POSITION.BREAK_POINTS).forEach(
        (breakPoint) => {
          let breakPointResult = {
            ...(theme.SETTINGS.MAIN_BUTTON.POSITION.BREAK_POINTS ?? {})[
              Number(breakPoint)
            ],
          };

          Object.keys(breakPointResult).forEach((key) => {
            // @ts-ignore
            if (typeof breakPointResult[key] === 'number') {
              breakPointResult = {
                ...breakPointResult,
                // @ts-ignore
                [key]: breakPointResult[key] + 'px',
              };
            }
          });

          result.breakpoints = {
            ...result.breakpoints,
            [breakPoint]: {
              left: 'unset',
              right: 'unset',
              bottom: 'unset',
              top: 'unset',
              ...breakPointResult,
            },
          };
        }
      );
    }

    Object.keys(theme.SETTINGS.MAIN_BUTTON.POSITION)
      .filter((el) => el !== 'BREAK_POINTS')
      .forEach((position) => {
        result = {
          ...result,
          [position as TPlacement]: Number(
            theme.SETTINGS.MAIN_BUTTON.POSITION?.[position as TPlacement] ?? 0
          ),
        };
      });

    return result as TAdaptive;
  }, [theme]);

  const Icon = useMemo(
    () => getAccessIconByType(theme.SETTINGS.MAIN_BUTTON.TYPE),
    []
  );

  return (
    <Wrapper
      onClick={onClick}
      visible={toTextBoolean(visible)}
      theme={theme}
      title={t('buttonAriaLabel')}
      aria-haspopup="dialog"
      aria-hidden={!visible}
      tabIndex={TabIndex.MAIN_BUTTON}
      adaptiveData={JSON.stringify(adaptiveData)}
    >
      <Inner theme={theme}>
        <Icon theme={theme} />
      </Inner>
    </Wrapper>
  );
};

export default withTheme(WidgetButton);
