import { useEffect } from 'react';

import { useGetCiteSimpleLanguageQuery } from '../../api/features/effects';
import { useWidgetEffect } from '../../hooks/useWidgetEffect';
import SimpleLanguageIcon from '../../icons/simpleLanguage';
import { WIDGET_NAME } from '../../index';
import { OBSERVER_IGNORE_LIST } from '../../init-functions/prepare-font-size';
import { TSettingItem } from '../../types/widget';
import {
  clearAlternativeContent,
  getObserveAlternateContentFn,
  setAlternativeContent,
  updateNodeBySetting,
} from '../../utils/alternative-content';
import { ETextEffectKeys } from '../names';

export const simpleLanguageEffect: TSettingItem = {
  Icon: SimpleLanguageIcon,
  key: ETextEffectKeys.SIMPLE_LANGUAGE,
};

export const useSimpleLanguageEffect = () => {
  const { effect, isSettingOn } = useWidgetEffect(
    ETextEffectKeys.SIMPLE_LANGUAGE
  );

  const { data } = useGetCiteSimpleLanguageQuery(
    ETextEffectKeys.SIMPLE_LANGUAGE,
    {
      skip: !isSettingOn || !effect.value,
    }
  );

  useEffect(() => {
    const widgetContainer = document.querySelector(WIDGET_NAME);

    const alternativeObserverMutation: MutationCallback =
      getObserveAlternateContentFn({
        isSuccess: !!data,
        isSettingOn,
        isActive: effect.value as boolean,
        ignoreContainer: widgetContainer,
        settings: data?.settings.items,
      });

    const observer = new MutationObserver(alternativeObserverMutation);

    if (!!data && isSettingOn && effect.value) {
      data.settings.items.forEach((setting) => {
        setAlternativeContent(setting);
      });
    } else if (!!data && isSettingOn && !effect.value) {
      data.settings.items.forEach((setting) => {
        clearAlternativeContent(setting);
      });
    }

    if (isSettingOn) {
      const config: MutationObserverInit = {
        attributes: true,
        childList: true,
        subtree: true,
      };

      observer.observe(document.body, config);
    }

    return () => {
      observer.disconnect();
      observer.takeRecords().forEach((record) => {
        if (
          record?.target?.nodeName &&
          !OBSERVER_IGNORE_LIST.includes(record.target.nodeName) &&
          record?.addedNodes?.length > 0 &&
          !widgetContainer?.contains?.(record.target)
        ) {
          for (const node of record.addedNodes as unknown as HTMLElement[]) {
            updateNodeBySetting({
              settings: data?.settings.items,
              isActive: effect.value as boolean,
              node,
            });
          }
        }
      });
    };
  }, [isSettingOn, effect, data]);
};
