import { useEffect } from 'react';

import GrayScaleIcon from '../../icons/grayScaleIcon';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const grayScaleEffect: TSettingItem = {
  Icon: GrayScaleIcon,
  key: ETextEffectKeys.GRAYSCALE,
};

export const useGrayScaleEffect = () => {
  const effect = useEffectSelector(ETextEffectKeys.GRAYSCALE);
  const container = document.querySelector('html') as HTMLElement;
  const rule = 'grayscale(1)';

  useEffect(() => {
    let oldFilters = window.getComputedStyle(container)?.filter ?? '';
    oldFilters = oldFilters
      .split(' ')
      .filter((el) => el !== 'none' && el !== rule)
      .join(' ');

    if (effect.value) {
      container.style.filter = `${oldFilters} ${rule}`;
    } else {
      container.style.filter = oldFilters;
    }
  }, [effect]);
};
