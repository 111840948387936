import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const PointerCursor: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_37_43"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="24"
      >
        <path
          d="M20 0H0V24H20V0Z"
          fill={theme.COLORS.MENU.MENU_ITEM.BACKGROUND}
        />
      </mask>
      <g mask="url(#mask0_37_43)">
        <mask
          id="mask1_37_43"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="24"
        >
          <path
            d="M20 0H0V24H20V0Z"
            fill={theme.COLORS.MENU.MENU_ITEM.BACKGROUND}
          />
        </mask>
        <g mask="url(#mask1_37_43)">
          <mask
            id="mask2_37_43"
            style={{
              maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="-1"
            width="20"
            height="25"
          >
            <path
              d="M15.9727 9.37599L9.99267 8.17999V3.10698C10.0021 2.38377 9.75677 1.68029 9.29977 1.11974C8.84267 0.559193 8.20297 0.177314 7.49267 0.0409837C7.06241 -0.031743 6.62147 -0.00973381 6.20059 0.105476C5.77972 0.220686 5.38904 0.426323 5.05581 0.708046C4.72258 0.989768 4.45482 1.34079 4.2712 1.73664C4.08758 2.13249 3.99253 2.56362 3.99267 2.99998V9.66098L1.20367 13.3C0.385662 14.2574 -0.0427484 15.4871 0.00337165 16.7455C0.0494916 18.0039 0.566772 19.1991 1.45267 20.094L3.85267 22.519C4.79441 23.4639 6.0726 23.9965 7.40667 24H14.9927C16.3183 23.9984 17.5891 23.4711 18.5265 22.5338C19.4638 21.5964 19.9911 20.3256 19.9927 19V14.279C19.9902 13.1237 19.5888 12.0046 18.8563 11.1112C18.1238 10.2178 17.1051 9.60484 15.9727 9.37599Z"
              fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
            />
          </mask>
          <g mask="url(#mask2_37_43)">
            <path
              d="M19.9929 0H-0.00708008V24H19.9929V0Z"
              fill={theme.COLORS.MENU.MENU_ITEM.BACKGROUND}
            />
            <path
              d="M15.9727 9.37599L9.99267 8.17999V3.10698C10.0021 2.38377 9.75677 1.68029 9.29977 1.11974C8.84267 0.559193 8.20297 0.177314 7.49267 0.0409837C7.06241 -0.031743 6.62147 -0.00973381 6.20059 0.105476C5.77972 0.220686 5.38904 0.426323 5.05581 0.708046C4.72258 0.989768 4.45482 1.34079 4.2712 1.73664C4.08758 2.13249 3.99253 2.56362 3.99267 2.99998V9.66098L1.20367 13.3C0.385662 14.2574 -0.0427484 15.4871 0.00337165 16.7455C0.0494916 18.0039 0.566772 19.1991 1.45267 20.094L3.85267 22.519C4.79441 23.4639 6.0726 23.9965 7.40667 24H14.9927C16.3183 23.9984 17.5891 23.4711 18.5265 22.5338C19.4638 21.5964 19.9911 20.3256 19.9927 19V14.279C19.9902 13.1237 19.5888 12.0046 18.8563 11.1112C18.1238 10.2178 17.1051 9.60484 15.9727 9.37599ZM17.9927 19C17.9927 19.7956 17.6766 20.5587 17.114 21.1213C16.5514 21.6839 15.7883 22 14.9927 22H7.40667C6.60534 21.9977 5.83773 21.6772 5.27267 21.109L2.87267 18.681C2.33638 18.1353 2.02636 17.407 2.00484 16.6423C1.98332 15.8775 2.25191 15.1329 2.75667 14.558L3.99267 12.945V17C3.99267 17.2652 4.09802 17.5196 4.28556 17.7071C4.4731 17.8946 4.72745 18 4.99267 18C5.25788 18 5.51224 17.8946 5.69977 17.7071C5.88731 17.5196 5.99267 17.2652 5.99267 17V2.99998C5.99258 2.85371 6.02458 2.70919 6.08642 2.57663C6.14826 2.44407 6.23842 2.32668 6.35056 2.23276C6.4627 2.13883 6.59408 2.07065 6.73543 2.03303C6.87679 1.9954 7.02467 1.98924 7.16867 2.01498C7.41078 2.07424 7.62501 2.21527 7.77514 2.41424C7.92528 2.6132 8.00207 2.85791 7.99267 3.10698V8.99998C7.99262 9.23052 8.07227 9.45398 8.21797 9.63257C8.36377 9.81115 8.56677 9.93388 8.79267 9.97998L15.5767 11.337C16.2567 11.4739 16.8686 11.8414 17.3089 12.3775C17.7491 12.9136 17.9907 13.5853 17.9927 14.279V19Z"
              fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default withTheme(PointerCursor);
