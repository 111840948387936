import { FC, PropsWithChildren, useEffect } from 'react';

import { useBrightEffect } from '../effects/core/bright-effect';
import { useContrastEffect } from '../effects/core/contrast-effect';
import { useCursorEffect } from '../effects/core/cursor-effect';
import { useDyslexicEffect } from '../effects/core/dyslexic-effect';
import { useGrayScaleEffect } from '../effects/core/gray-scale-effect';
import { useHideImagesEffect } from '../effects/core/hide-images-effect';
import { useInvertColorEffect } from '../effects/core/invert-color-effect';
import { useLinksMarkEffect } from '../effects/core/links-mark-effect';
import { useReadableEffect } from '../effects/core/readable-effect';
import { useScaleEffect } from '../effects/core/scale-effect';
import { useSimpleLanguageEffect } from '../effects/core/simple-language-effect';
import { useStopAnimationsEffect } from '../effects/core/stop-animations-effect';
import { useTextEffect } from '../effects/core/text-effect';
import { useTextToSpeechEffect } from '../effects/core/text-to-speech-effect';
import { ETextEffectKeys, TEffectTextKey } from '../effects/names';
import { useSettingsList } from '../hooks/useSettingsList';
import { useAppDispatch } from '../state';
import { setEffectValue } from '../state/reducers/widget/slices/effectsSlice';
import { ExpandedEffects } from './expanded-effects';
import { PresetService } from './preset-service';

export const EffectsService: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();

  const [settingsList] = useSettingsList(
    (process.env.WIDGET_CONFIG as any).effects
  );

  useScaleEffect();
  useTextEffect();
  useReadableEffect();
  useDyslexicEffect();
  useCursorEffect();
  useInvertColorEffect();
  useBrightEffect();
  useContrastEffect();
  useGrayScaleEffect();
  useHideImagesEffect();
  useStopAnimationsEffect();
  useLinksMarkEffect();
  useTextToSpeechEffect();
  useSimpleLanguageEffect();

  useEffect(() => {
    (Object.values(ETextEffectKeys) as TEffectTextKey[]).forEach((listItem) => {
      const isSettingOn = !!settingsList.find((el) => el.key === listItem);

      if (!isSettingOn) {
        dispatch(
          setEffectValue({
            value: false,
            key: listItem,
          })
        );
      }
    });
  }, [settingsList]);

  return (
    <ExpandedEffects>
      <PresetService>{children}</PresetService>
    </ExpandedEffects>
  );
};
