import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authApi } from '../../../api/features/auth';
import { addClearStateOnLogoutMatcher } from '../../utils/state-utils';

interface IInitialState {
  fullAppLoading: boolean;
  baseContainerLoading: boolean;
}

const initialState: IInitialState = {
  fullAppLoading: false,
  baseContainerLoading: false,
};

const loadingSlice = createSlice({
  name: 'app/loading',
  initialState,
  reducers: {
    removeAllLoadings(state) {
      state.baseContainerLoading = false;
      state.fullAppLoading = false;
    },
    setFullAppLoading(state, action: PayloadAction<boolean>) {
      state.fullAppLoading = action.payload;
    },
    setBaseContainerLoading(state, action: PayloadAction<boolean>) {
      state.baseContainerLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getMyUserData.matchPending,
      (state) => {
        state.baseContainerLoading = true;
      }
    );
    builder.addMatcher(
      authApi.endpoints.getMyUserData.matchFulfilled,
      (state) => {
        state.baseContainerLoading = false;
      }
    );
    builder.addMatcher(
      authApi.endpoints.getMyUserData.matchRejected,
      (state) => {
        state.baseContainerLoading = false;
      }
    );
    addClearStateOnLogoutMatcher(builder, initialState);
  },
});

export const loadingReducer = loadingSlice.reducer;
