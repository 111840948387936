import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider as EmotionThemeProvider, Theme } from '@emotion/react';
import { App, ConfigProvider } from 'antd';
import React, { FC, PropsWithChildren, useMemo } from 'react';

import 'antd/dist/reset.css';
import ruLocale from 'antd/locale/ru_RU';

import { useThemeSelector } from '../state/app/selectors';
import { THEME_LIGHT } from '../styles';
import '../styles/index.scss';

const appCache = createCache({
  key: 'accessibility-application',
  prepend: true,
  speedy: false,
});

export const useCurrentTheme: () => Theme = () => {
  const { currentTheme } = useThemeSelector();

  return useMemo(() => {
    return {
      MAIN: THEME_LIGHT,
    };
  }, [currentTheme]);
};

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const theme = useCurrentTheme();

  return (
    <CacheProvider value={appCache}>
      <EmotionThemeProvider theme={theme}>
        <ConfigProvider
          locale={ruLocale}
          theme={{
            token: {
              colorPrimaryBg: theme.MAIN.ACCENT.C300,
              colorPrimaryBgHover: theme.MAIN.ACCENT.C200,
              colorPrimary: theme.MAIN.ACCENT.C100,
              colorPrimaryHover: theme.MAIN.ACCENT.C200,
              colorError: theme.MAIN.DANGER.C100,
              colorTextBase: theme.MAIN.TEXT.C200,
              colorBgContainer: theme.MAIN.WHITE.C200,
              borderRadius: 4,
              colorLink: theme.MAIN.ACCENT.C100,
            },
            components: {
              Button: {
                algorithm: true,
              },
            },
          }}
        >
          <App>{children}</App>
        </ConfigProvider>
      </EmotionThemeProvider>
    </CacheProvider>
  );
};
