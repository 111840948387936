import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, HTMLAttributes, PropsWithChildren, ReactNode, memo } from 'react';

type TProps = {
  value: ReactNode;
  label: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Wrapper = styled.div`
  color: ${({ theme }) => theme.MAIN.TEXT.C100};
  display: flex;
  flex-direction: column;
`;

const ValueText = styled.b`
  font-size: 1.3rem;
  line-height: 120%;
  font-weight: bold;
`;

const LabelText = styled.span`
  font-size: 0.75rem;
`;

const WidgetStats = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  margin-block: 14px;
  grid-gap: 20px;
`;

const StatsWrapperUnThemed: FC<
  PropsWithChildren & {
    theme: Theme;
  }
> = ({ children }) => <WidgetStats>{children}</WidgetStats>;

const StatItem: FC<TProps> = memo(({ value, label, ...props }) => {
  return (
    <Wrapper {...props}>
      <ValueText>{value}</ValueText>
      <LabelText>{label}</LabelText>
    </Wrapper>
  );
});

export default StatItem;
export const StatsWrapper = withTheme(StatsWrapperUnThemed);
