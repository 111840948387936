import { WIDGET_PRESETS_LIST } from '@kylfyk/accessability-react';
import { Form, Table } from 'antd';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormItemProps } from 'antd/es/form/FormItem';

import { LocaleParts } from '../../../locale';
import { getWidgetPresetColumns } from '../../tables/columns/widget-preset-columns';
import { TWidgetPresetTableData } from '../../tables/types/widget-preset-types';

type TProps = {
  formName: FormItemProps['name'];
};

const PresetsForm: FC<TProps> = memo(({ formName }) => {
  const { t } = useTranslation([LocaleParts.WIDGET_MODULE_PRESETS]);

  const tableData: TWidgetPresetTableData[] = useMemo(() => {
    return WIDGET_PRESETS_LIST.map((el) => ({
      key: el.key,
      Icon: el.Icon as any,
      formName,
    }));
  }, [formName]);

  const columns = useMemo(
    () => getWidgetPresetColumns(formName, t),
    [formName, t]
  );

  return (
    <Form.Item name={formName}>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        size={'small'}
      />
    </Form.Item>
  );
});

export default PresetsForm;
