import { TWidgetSettings } from '../../../api/features/widget/types';
import {
  EWidgetPresetThemeKey,
  WidgetPresetThemeColors,
} from '../../../styles/widget-preset-style';

const GREEN_THEME: TWidgetSettings['theme']['COLORS'] = {
  COMMON: {
    ACCENT: WidgetPresetThemeColors[EWidgetPresetThemeKey.GREEN],
    ON_ACCENT: '#FFFFFF',
    BASE_RADIUS: 8,
    TEXT_COMMON: '#333333',
  },
  ACCESS_BUTTON: {
    MAIN_COLOR: WidgetPresetThemeColors[EWidgetPresetThemeKey.GREEN],
    SECONDARY_COLOR: WidgetPresetThemeColors[EWidgetPresetThemeKey.GREEN],
    INSIDE_ELEMENTS_COLOR: '#FFFFFF',
  },
  MENU: {
    SHADOW: '0 0 20px rgba(0, 0, 0, 0.2)',
    BACKGROUND: '#F1F6F2',
    SCROLLBAR_BG: '#878E9A',
    SCROLLBAR_BG_HOVERED: '#4A5262',
    HEADER_BG: '#F1F6F2',
    HEADER_TEXT_COLOR: '#333333',
    TEXT_ON_MENU: '#333333',
    RESET_BUTTON: {
      BACKGROUND: WidgetPresetThemeColors[EWidgetPresetThemeKey.GREEN],
      BACKGROUND_HOVERED: WidgetPresetThemeColors[EWidgetPresetThemeKey.GREEN],
      TEXT: '#FFFFFF',
    },
    MENU_ITEM: {
      BORDER_RADIUS: 8,
      BACKGROUND: '#F1F6F2',
      BORDER: '#EAEAEA',
      TITLE: '#4A5262',
      TEXT: '#4A5262',
      SWITCH_BG: '#4A5262',
    },
    SUB_GROUP: {
      BACKGROUND: '#FFFFFF',
      BORDER_RADIUS: 16,
      TITLE_COLOR: '#4A5262',
    },
  },
};

export default GREEN_THEME;
