import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const AccessIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_44_18)">
        <g opacity="0.08">
          <path
            d="M16 32C24.6917 32 31.7377 24.954 31.7377 16.2623C31.7377 7.5706 24.6917 0.524597 16 0.524597C7.30833 0.524597 0.262329 7.5706 0.262329 16.2623C0.262329 24.954 7.30833 32 16 32Z"
            fill="black"
          />
        </g>
        <path
          d="M16 31.4754C24.6917 31.4754 31.7377 24.4294 31.7377 15.7377C31.7377 7.046 24.6917 0 16 0C7.30833 0 0.262329 7.046 0.262329 15.7377C0.262329 24.4294 7.30833 31.4754 16 31.4754Z"
          fill="url(#paint0_linear_44_18)"
        />
        <g opacity="0.16">
          <path
            d="M16 0C7.30808 0 0.262329 7.04575 0.262329 15.7377C0.262329 24.4296 7.30808 31.4754 16 31.4754C24.692 31.4754 31.7377 24.4297 31.7377 15.7378C31.7377 7.04581 24.692 0 16 0ZM16 30.4263C7.88777 30.4263 1.31152 23.85 1.31152 15.7378C1.31152 7.6255 7.88777 1.04919 16 1.04919C24.1123 1.04919 30.6885 7.62544 30.6885 15.7377C30.6796 23.8463 24.1086 30.4173 16 30.4263Z"
            fill="url(#paint1_linear_44_18)"
          />
        </g>
        <path
          d="M16 29.9016C8.17731 29.9016 1.83606 23.5604 1.83606 15.7377C1.83606 7.91498 8.17731 1.57373 16 1.57373C23.8227 1.57373 30.1639 7.91498 30.1639 15.7377C30.155 23.5567 23.819 29.8927 16 29.9016ZM16 2.62292C8.757 2.62292 2.88525 8.49467 2.88525 15.7377C2.88525 22.9807 8.757 28.8524 16 28.8524C23.243 28.8524 29.1147 22.9807 29.1147 15.7377C29.1064 8.49779 23.2399 2.63136 16 2.62292Z"
          fill="url(#paint2_linear_44_18)"
        />
        <path
          d="M23.105 11.7686C23.1092 12.1594 22.8343 12.4978 22.4508 12.5739C21.6167 12.7569 19.9333 13.1882 18.9083 13.4242C18.4314 13.5323 18.0931 13.9572 18.0951 14.4467C18.0993 15.5457 18.1135 17.3634 18.1596 17.8476C18.2163 18.5569 18.3102 19.2619 18.4413 19.9612C18.5683 20.6636 18.7293 21.3624 18.924 22.0517C19.1118 22.7331 19.3368 23.4035 19.5981 24.0603L19.6117 24.0944C19.7738 24.4973 19.5781 24.9558 19.1747 25.1174C18.7891 25.2721 18.3501 25.1005 18.1717 24.7255C17.8396 24.0215 17.5475 23.2991 17.2972 22.5615C17.0454 21.835 16.8282 21.0969 16.6462 20.3472C16.5864 20.1054 16.5329 19.862 16.4815 19.6175C16.4568 19.4969 16.3498 19.4098 16.226 19.4098H15.7738C15.65 19.4098 15.543 19.4969 15.5178 19.618C15.4669 19.8625 15.4128 20.1059 15.353 20.3477C15.1715 21.0974 14.9543 21.8355 14.702 22.562C14.4513 23.2991 14.1596 24.0215 13.8275 24.726C13.6408 25.1184 13.1713 25.2847 12.7794 25.0979C12.4043 24.9195 12.2328 24.48 12.3875 24.0949L12.4012 24.0608C12.6624 23.404 12.8875 22.7336 13.0753 22.0522C13.2699 21.3629 13.431 20.6641 13.5579 19.9617C13.689 19.2624 13.7835 18.5574 13.8396 17.8481C13.8858 17.3639 13.8994 15.5462 13.9041 14.4472C13.9057 13.9582 13.5678 13.5334 13.091 13.4247C12.0659 13.1882 10.3825 12.7575 9.5484 12.5744C9.1649 12.4983 8.89002 12.1599 8.89421 11.7691C8.91046 11.3347 9.27609 10.9959 9.71046 11.0127C9.75665 11.0142 9.80228 11.02 9.8474 11.03L9.89515 11.0405C11.8954 11.521 13.9423 11.777 15.9993 11.8037C18.0562 11.777 20.1036 11.5205 22.1034 11.0405L22.1511 11.03C22.5761 10.9377 22.9952 11.2073 23.087 11.6322C23.0976 11.6769 23.1035 11.723 23.105 11.7686ZM16 10.4918C17.1588 10.4918 18.0983 9.55223 18.0983 8.39342C18.0983 7.23461 17.1588 6.29504 16 6.29504C14.8411 6.29504 13.9016 7.23461 13.9016 8.39342C13.9016 9.55223 14.8411 10.4918 16 10.4918Z"
          fill="url(#paint3_linear_44_18)"
        />
        <g opacity="0.16">
          <path
            d="M16 0C7.30808 0 0.262329 7.04575 0.262329 15.7377C0.262329 24.4296 7.30808 31.4754 16 31.4754C24.692 31.4754 31.7377 24.4297 31.7377 15.7378C31.7377 7.04581 24.692 0 16 0ZM16 30.9508C7.5982 30.9508 0.786892 24.1396 0.786892 15.7377C0.786892 7.33581 7.5982 0.524562 16 0.524562C24.4018 0.524562 31.2131 7.33581 31.2131 15.7377C31.2131 24.1396 24.4018 30.9508 16 30.9508Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_44_18"
          x1="16.0001"
          y1="31.4769"
          x2="16.0001"
          y2="0.00163593"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.COLORS.ACCESS_BUTTON.MAIN_COLOR} />
          <stop offset="1" stopColor={theme.COLORS.ACCESS_BUTTON.MAIN_COLOR} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_44_18"
          x1="16.0001"
          y1="31.4769"
          x2="16.0001"
          y2="0.00163593"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.36" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_44_18"
          x1="16"
          y1="29.9031"
          x2="16"
          y2="1.57538"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.COLORS.ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR} />
          <stop
            offset="1"
            stopColor={theme.COLORS.ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR}
          />
        </linearGradient>
        <linearGradient
          id="paint3_linear_44_18"
          x1="15.9997"
          y1="25.1761"
          x2="15.9997"
          y2="6.29667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.COLORS.ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR} />
          <stop
            offset="1"
            stopColor={theme.COLORS.ACCESS_BUTTON.INSIDE_ELEMENTS_COLOR}
          />
        </linearGradient>
        <clipPath id="clip0_44_18">
          <rect width="32" height="32" fill="#FFFFFF" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(AccessIcon) as typeof AccessIcon;
