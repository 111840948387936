import { TPlacement } from '@kylfyk/accessability-react';
import { SliderSingleProps } from 'antd';

import { FormItemProps } from 'antd/es/form/FormItem';
import { FormListFieldData } from 'antd/es/form/FormList';

export enum EPlacement {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}

export const EPositionKeyToString: Record<EPlacement, TPlacement> = {
  [EPlacement.TOP]: 'top',
  [EPlacement.RIGHT]: 'right',
  [EPlacement.BOTTOM]: 'bottom',
  [EPlacement.LEFT]: 'left',
};

type TFormPlacementListItem = FormItemProps & FormListFieldData;

export interface TWidgetPositionOptionsList {
  key: EPlacement;
  label: string;
}

export const WIDGET_POSITION_FORM_ITEMS: TWidgetPositionOptionsList[] = [
  {
    key: EPlacement.TOP,
    label: 'Верх',
  },
  {
    key: EPlacement.RIGHT,
    label: 'Право',
  },
  {
    key: EPlacement.BOTTOM,
    label: 'Низ',
  },
  {
    key: EPlacement.LEFT,
    label: 'Лево',
  },
];

export const ADAPTIVE_SLIDER_BASE_MARKS: SliderSingleProps['marks'] = {
  480: '<= 480',
  768: '<= 768',
  1024: '<= 1024',
  1280: '<= 1280',
  1600: '<= 1600',
  1920: '<= 1920',
};
