import { useEffect, useRef, useState } from 'react';

import TextToSpeechIcon from '../../icons/textToSpeechIcon';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { TSettingItem } from '../../types/widget';
import { speak } from '../../utils/web';
import { ETextEffectKeys } from '../names';

export const TEXT_TO_SP_CLASS_NAME = 'ac-wd-tetosp-effect-style';

export const textToSpeechEffect: TSettingItem = {
  Icon: TextToSpeechIcon,
  key: ETextEffectKeys.TEXT_TO_SPEECH,
};

const ignoreTagList = ['BODY', 'HTML', 'SCRIPT', 'STYLE', 'SVG', 'HEAD'];

export const useTextToSpeechEffect = () => {
  const effect = useEffectSelector(ETextEffectKeys.TEXT_TO_SPEECH);
  const [selectedVoice, setSelectedVoice] = useState<SpeechSynthesisVoice>();
  const markedElement = useRef<HTMLElement | null>(null);

  const { speechSynthesis } = window;

  useEffect(() => {
    const listener = () => {
      const voices = speechSynthesis.getVoices();

      const ruList = voices.filter((el) => el.lang === 'ru-RU');
      const defaultRu = ruList.find((el) => el.default);

      if (ruList && defaultRu) {
        setSelectedVoice(defaultRu);
      } else {
        setSelectedVoice(voices.find((el) => el.default));
      }
    };

    listener();

    speechSynthesis.addEventListener('voiceschanged', listener);

    return () => {
      speechSynthesis.removeEventListener('voiceschanged', listener);
    };
  }, []);

  useEffect(() => {
    if (!effect.value && speechSynthesis) {
      speechSynthesis.cancel();
    }
  }, [effect]);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (
        ignoreTagList.includes((e.target as HTMLElement).tagName) ||
        !selectedVoice
      ) {
        return;
      }

      if (
        markedElement &&
        markedElement.current &&
        markedElement.current.classList.contains(TEXT_TO_SP_CLASS_NAME)
      ) {
        markedElement.current.classList.remove(TEXT_TO_SP_CLASS_NAME);
      }

      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }

      if (
        (e.target as HTMLElement)?.textContent ||
        (e.target as HTMLImageElement).getAttribute('alt')
      ) {
        const textToSpeak =
          (e.target as HTMLElement).textContent ||
          `Изображение: ${(e.target as HTMLImageElement).getAttribute('alt')}`;

        if (textToSpeak.trim()) {
          markedElement.current = e.target as HTMLElement;
          markedElement.current.classList.add(TEXT_TO_SP_CLASS_NAME);

          speak(textToSpeak, selectedVoice);
        }
      }
    };

    if (effect.value) {
      document.addEventListener('click', listener);
    } else {
      document.removeEventListener('click', listener);

      if (
        markedElement &&
        markedElement.current &&
        markedElement.current.classList.contains(TEXT_TO_SP_CLASS_NAME)
      ) {
        markedElement.current.classList.remove(TEXT_TO_SP_CLASS_NAME);
        markedElement.current = null;
      }
    }

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [effect, selectedVoice]);
};
