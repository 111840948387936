import { useEffect } from 'react';

import { useWidgetEffect } from '../../hooks/useWidgetEffect';
import DyslexicIcon from '../../icons/dyslexicIcon';
import { useAppDispatch } from '../../state';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { setEffectValue } from '../../state/reducers/widget/slices/effectsSlice';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const DYSLEXIC_CLASS_NAME = 'ac-wd-fd-effect-style';

export const dyslexicEffect: TSettingItem = {
  Icon: DyslexicIcon,
  key: ETextEffectKeys.DYSLEXIC_FONT,
};

export const useDyslexicEffect = () => {
  const dispatch = useAppDispatch();
  const { effect } = useWidgetEffect(ETextEffectKeys.DYSLEXIC_FONT);
  const regularValue = useEffectSelector(ETextEffectKeys.READABLE_FONT);

  useEffect(() => {
    const container = document.querySelector('html') as HTMLElement;
    const body = container.querySelector('body') as HTMLElement;

    if (effect.value) {
      if (regularValue) {
        dispatch(
          setEffectValue({
            key: ETextEffectKeys.READABLE_FONT,
            value: false,
          })
        );
      }

      container.classList.add(DYSLEXIC_CLASS_NAME);
      body.classList.add(DYSLEXIC_CLASS_NAME);
    } else {
      container.classList.remove(DYSLEXIC_CLASS_NAME);
      body.classList.remove(DYSLEXIC_CLASS_NAME);
    }
  }, [effect]);
};
