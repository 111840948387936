import { Theme } from '@emotion/react';
import ConfigProvider from 'antd/lib/config-provider';
import { FC, PropsWithChildren, useEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';

import i18next from '../locale';
import { useLocaleSelector } from '../state/reducers/app/selectors';

export const LocaleService: FC<
  PropsWithChildren<{
    theme: Theme;
  }>
> = ({ children, theme }) => {
  const { i18n } = useTranslation();

  const { current } = useLocaleSelector();

  useEffect(() => {
    void i18n.changeLanguage(current);
  }, [current, i18n]);

  return (
    <I18nextProvider i18n={i18next}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: theme.COLORS.COMMON.ACCENT,
            colorTextBase: theme.COLORS.COMMON.TEXT_COMMON,
            colorBgBase: theme.COLORS.MENU.BACKGROUND,
            borderRadius: theme.COLORS.COMMON.BASE_RADIUS,
          },
          components: {
            Dropdown: {
              zIndexPopup: theme.SETTINGS.MENU.Z_INDEX + 2,
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </I18nextProvider>
  );
};
