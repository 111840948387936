import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import effectsEn from './translations/effects/en.json';
import effectsEs from './translations/effects/es.json';
import effectsRu from './translations/effects/ru.json';
import presetsEn from './translations/presets/en.json';
import presetsEs from './translations/presets/es.json';
import presetsRu from './translations/presets/ru.json';
import widgetEn from './translations/widget/en.json';
import widgetEs from './translations/widget/es.json';
import widgetRu from './translations/widget/ru.json';

export enum Localization {
  EN = 'en',
  RU = 'ru',
  ES = 'es',
}

export const localeToText: (loc: Localization) => string = (loc) => {
  switch (loc) {
    case Localization.RU:
      return 'Русский';
    case Localization.EN:
      return 'English';
    case Localization.ES:
      return 'Español';
  }
};

export enum LocaleParts {
  EFFECTS = 'effects',
  WIDGET = 'widget',
  PRESETS = 'presets',
}

i18n.use(initReactI18next).init({
  fallbackLng: Localization.EN,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    [Localization.RU]: {
      [LocaleParts.EFFECTS]: effectsRu,
      [LocaleParts.WIDGET]: widgetRu,
      [LocaleParts.PRESETS]: presetsRu,
    },
    [Localization.EN]: {
      [LocaleParts.EFFECTS]: effectsEn,
      [LocaleParts.WIDGET]: widgetEn,
      [LocaleParts.PRESETS]: presetsEn,
    },
    [Localization.ES]: {
      [LocaleParts.EFFECTS]: effectsEs,
      [LocaleParts.WIDGET]: widgetEs,
      [LocaleParts.PRESETS]: presetsEs,
    },
  },
});

export default i18n;
