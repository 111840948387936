import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Form, FormInstance, Input, Space } from 'antd';
import React, { FC, useEffect } from 'react';

import { FormListProps } from 'antd/lib/form';

import { NBSP } from '../../../constants/formatters';
import { EWidgetSettingsFormNames } from '../../../types/forms/widget-settings';
import {
  ALLOWED_URL_REGEX_LIST,
  URL_REGEX,
  isSubdomainOf,
} from '../../../utils/regex';

type TProps = {
  name: FormListProps['name'];
  form: FormInstance;
  onTechDomainFounded?: () => void;
};

const StyledSpace = styled(Space)`
  width: 100%;

  .ant-space-item:first-of-type {
    flex: 1;
  }
`;

export const WidgetAdditionalDomainsForm: FC<TProps> = ({
  name,
  form,
  onTechDomainFounded,
}) => {
  useEffect(() => {
    if (name) {
      const values = form.getFieldValue(name);
      const formNames = (values as string[] | undefined)?.map((_, index) => [
        name,
        index,
      ]);
      if (formNames) {
        form.validateFields(formNames);
      }
    }
  });

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {/*{fields.length === 0 && (*/}
          {/*  <Empty*/}
          {/*    image={Empty.PRESENTED_IMAGE_SIMPLE}*/}
          {/*    imageStyle={{ height: 60 }}*/}
          {/*    description={<Typography.Text>Пока ничего нет</Typography.Text>}*/}
          {/*  />*/}
          {/*)}*/}
          {fields.map(({ key, name: inputName, ...restField }) => (
            <StyledSpace key={key}>
              <Form.Item
                label="Дополнительный домен"
                tooltip="Введите адрес сайта включая протокол соединения (http или https). Домен на русском языке должен быть в формате Punycode, пример: https://мой-сайт.рф -> https://xn----8sbzclmxk.xn--p1ai"
                {...restField}
                name={[inputName]}
                dependencies={[EWidgetSettingsFormNames.DOMAIN]}
                rules={[
                  {
                    pattern: URL_REGEX,
                    // message: t('form.rules.urlInvalid'), Сделать общий перевод для ошибок форм
                    message: 'URL не валиден. Пример: https://example.com',
                  },
                  {
                    validator: (_: any, subdomainValue: string) => {
                      const domainValue = form.getFieldValue(
                        EWidgetSettingsFormNames.DOMAIN
                      );
                      if (!domainValue) {
                        // Ошибка, сначала нужно заполнить домен
                        return Promise.reject(
                          new Error('Сначала введите основной домен')
                        );
                      }

                      // Проверяем, что subdomainURL.hostname заканчивается на '.' + domainURL.hostname
                      if (!subdomainValue || subdomainValue !== domainValue) {
                        return Promise.resolve();
                      } else {
                        // Ошибка, значение не является поддоменом
                        return Promise.reject(
                          new Error('Указаны одинаковые URL адреса')
                        );
                      }
                    },
                  },
                  {
                    validator: (_: any, value: string) =>
                      ALLOWED_URL_REGEX_LIST.test(value)
                        ? Promise.reject(
                            new Error('Указанный URL адрес не поддерживается')
                          )
                        : Promise.resolve(),
                  },
                  {
                    validator: (_: any, value: string) =>
                      value?.endsWith('/')
                        ? Promise.reject(
                            new Error('Вводите адрес без "/" в конце')
                          )
                        : Promise.resolve(),
                  },
                  {
                    warningOnly: true,
                    validator: (_, subdomainValue: string) => {
                      const domainValue = form.getFieldValue('domain');
                      if (!domainValue) {
                        return Promise.resolve();
                      }

                      if (!URL_REGEX.test(subdomainValue)) {
                        return Promise.resolve();
                      }

                      if (domainValue === subdomainValue) {
                        return Promise.resolve();
                      }

                      // Проверяем, что subdomainURL.hostname заканчивается на '.' + domainURL.hostname
                      if (isSubdomainOf(subdomainValue, domainValue)) {
                        return Promise.resolve();
                      } else {
                        // Ошибка, значение не является поддоменом
                        onTechDomainFounded?.();
                        return Promise.reject(
                          `Домен возможно использовать только в${NBSP}тестовых целях`
                        );
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Введите" size="large" />
              </Form.Item>
              <Button
                shape="circle"
                onClick={() => remove(inputName)}
                icon={<MinusOutlined />}
              />
            </StyledSpace>
          ))}
          {fields.length < 5 && (
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                block
                icon={<PlusOutlined />}
              >
                Добавить дополнительный домен
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );
};
