import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { EUserRole } from '../api/features/auth/types';
import { AppBaseLayout } from '../containers/layouts/app-base-layout';
import { CheckerPage } from '../containers/pages/checker-page';
import { CreateWidgetPage } from '../containers/pages/create-widget-page';
import { DocumentationPage } from '../containers/pages/documentation-page';
import { HomePage } from '../containers/pages/home-page';
import { SettingsPage } from '../containers/pages/settings-page';
import { StatisticPage } from '../containers/pages/statistic-page';
import { UsersPage } from '../containers/pages/users-page';
import { useAppSelector } from '../state';
import { useUserIsAdminSelector } from '../state/auth/selectors';
import { PrivateRoute } from './private-route';
import { EPaths } from './route-paths';

export const RootRoutes: FC = () => {
  const isAdmin = useAppSelector(useUserIsAdminSelector);

  return (
    <Routes>
      <Route path={'/'} element={<AppBaseLayout />}>
        <Route path={EPaths.CREATE_WIDGET} element={<CreateWidgetPage />} />
        <Route path={`${EPaths.SETTINGS}/*`} element={<SettingsPage />} />
        <Route path={EPaths.PAYMENT} element={<></>} />
        {isAdmin && (
          <>
            <Route path={`${EPaths.STATISTIC}/*`} element={<StatisticPage />} />
            <Route
              path={EPaths.USERS}
              element={
                <PrivateRoute requiredRoles={[EUserRole.ADMIN]}>
                  <UsersPage />
                </PrivateRoute>
              }
            />
          </>
        )}
        <Route path={EPaths.ACCESS_CHECKER} element={<CheckerPage />} />
        <Route path={EPaths.DOCUMENTATION} element={<DocumentationPage />} />
        <Route index element={<HomePage />} />
        <Route path={'/*'} element={<></>} />
      </Route>
    </Routes>
  );
};
