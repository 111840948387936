import type { Middleware } from '@reduxjs/toolkit';
import { MiddlewareAPI } from 'redux';

import * as apiList from '../../api/features/api';
import { authLogoutAction } from '../auth/auth.slice';
import { AppDispatch } from '../index';
import { RootCombine } from '../root';
import { isPayloadApiAction } from '../utils/state-utils';

const authMiddleware: Middleware =
  (api: MiddlewareAPI<AppDispatch, RootCombine>) => (next) => (action) => {
    if (isPayloadApiAction(action) && authLogoutAction.type === action.type) {
      // Сбрасываем state для каждой api из /api/features
      (Object.keys(apiList) as (keyof typeof apiList)[]).forEach((apiKey) => {
        api.dispatch(apiList[apiKey].util.resetApiState());
      });
    }

    return next(action);
  };

export default authMiddleware;
