import { useAppSelector } from '../..';
import { ETextEffectKeys } from '../../../effects/names';
import { IMainState } from './slices/mainStateSlice';

export const useWidgetSelector = () =>
  useAppSelector((state) => state.widget.main as IMainState);

export const useEffectsSelector = () =>
  useAppSelector((state) => state.widget.effects);

export const useEffectSelector = (
  effect: (typeof ETextEffectKeys)[keyof typeof ETextEffectKeys]
) => useAppSelector((state) => state.widget.effects[effect]);

export const usePresetSelector = () =>
  useAppSelector((state) => state.widget.presets);

export const useWidgetSettingsSelector = () =>
  useAppSelector((state) => state.widget.settings);
