import { ActionReducerMapBuilder, type PayloadAction } from '@reduxjs/toolkit';

import { authLogoutAction } from '../auth/auth.slice';

export function isPayloadApiAction(
  action: unknown | PayloadAction
): action is PayloadAction {
  return (action as PayloadAction).type !== undefined;
}

export const addClearStateOnLogoutMatcher: <StateType>(
  builder: ActionReducerMapBuilder<StateType>,
  initialState: StateType
) => Omit<ActionReducerMapBuilder<StateType>, 'addCase'> = (
  builder,
  initialState
) => {
  return builder.addMatcher(
    (action) =>
      isPayloadApiAction(action) && action.type === authLogoutAction.type,
    () => {
      return initialState;
    }
  );
};
