import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from '../../base-query';
import { TUser } from './types';

export const usersApi = createApi({
  reducerPath: 'users/api',
  baseQuery,
  endpoints: (builder) => ({
    getAll: builder.query<TUser[], void>({
      query: () => ({
        url: 'user/all',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAllQuery } = usersApi;
