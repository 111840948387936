import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { FC, memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useStatisticWriter } from '../../hooks/useStatisticWriter';
import { LocaleParts } from '../../locale';
import { PRESET_DEPENDENCIES, TPresetItem } from '../../presets';
import { useAppDispatch } from '../../state';
import { usePresetSelector } from '../../state/reducers/widget/selectors';
import { setEffectValue } from '../../state/reducers/widget/slices/effectsSlice';
import { TStatEvent } from '../../types/statistic';
import { textToBoolean } from '../../utils/common';
import SimpleSwitch from '../switch/simple-switch';

type TProps = {
  index: number;
  theme: Theme;
  data: TPresetItem;
  widgetIncreased?: 'true' | 'false';
};

export const SettingItemText = styled('div')`
  flex: 1;
  color: ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.TEXT} !important;
  font-size: 18px;
  font-family: ${({ theme }) => theme.SETTINGS.COMMON.FONT};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: center;
`;

const BaseSettingItem = styled('div')`
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const SettingItemLargeIconWrapper = styled(BaseSettingItem)<{
  widgetIncreased?: 'true' | 'false';
}>`
  width: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 56 : 28}px;
  min-width: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 56 : 28}px;
  height: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 56 : 28}px;
  min-height: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 56 : 28}px;

  @media only screen and (max-width: 520px) {
    width: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 46 : 28}px;
    min-width: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 46 : 28}px;
    height: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 46 : 28}px;
    min-height: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 46 : 28}px;
  }

  svg path {
    fill: ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.TITLE} !important;
  }
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TitleItem = styled('div')<{
  widgetIncreased?: 'true' | 'false';
}>`
  font-size: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 32 : 16}px;
  margin-bottom: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 12 : 6}px;
  color: ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.TITLE};
  font-weight: bold;
  word-break: break-word;
  min-width: 90px;

  @media only screen and (max-width: 520px) {
    font-size: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 24 : 16}px;
    min-width: 130px;
  }
`;

const TextItem = styled(SettingItemText)<{
  widgetIncreased?: 'true' | 'false';
}>`
  font-size: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 32 : 16}px;
  align-self: flex-start;
  word-break: break-word;

  @media only screen and (max-width: 520px) {
    font-size: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 24 : 16}px;
  }
`;

const SwitchWrapper = styled('div')`
  margin-top: 6px;
`;

const Wrapper = styled('button')<{
  widgetIncreased?: 'true' | 'false';
}>`
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
  justify-content: space-between;
  border-radius: ${({ theme }) =>
    theme.COLORS.MENU.MENU_ITEM.BORDER_RADIUS}px !important;
  background-color: ${({ theme }) =>
    theme.COLORS.MENU.MENU_ITEM.BACKGROUND} !important;
  padding: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? '16px 20px' : '12px 16px'};
  gap: 16px;
  user-select: none;
  border: none;
  outline-color: ${({ theme }) => theme.COLORS.COMMON.ACCENT} !important;

  @media only screen and (max-width: 520px) {
    gap: 12px;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.COLORS.MENU.MENU_ITEM.BACKGROUND} !important;
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;

const WidgetPresetItem: FC<TProps> = memo(
  ({ index, theme, data, widgetIncreased }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation([LocaleParts.PRESETS]);
    const presetsData = usePresetSelector();
    const wrapperRef = useRef<HTMLButtonElement>(null);
    const [handleWriteStatistic] = useStatisticWriter();

    const toggleValue = useCallback(
      (isKBD?: boolean) => {
        const deps = PRESET_DEPENDENCIES[data.key];

        deps.forEach((dp) => {
          if (presetsData[data.key]) {
            dispatch(
              setEffectValue({
                key: dp.key,
                value: false,
              })
            );
          } else {
            dispatch(
              setEffectValue({
                key: dp.key,
                value: dp.value as any,
              })
            );
          }
        });

        void handleWriteStatistic({
          event: isKBD
            ? TStatEvent.PRESET_CHANGE_KBD
            : TStatEvent.PRESET_CHANGE,
          text: isKBD
            ? `${t(`${data.key}.title`)} - Клавиатура`
            : t(`${data.key}.title`),
          time: dayjs().toISOString(),
          value: !presetsData[data.key],
        });
      },
      [presetsData, t]
    );

    const handleChange = useCallback(() => {}, []);

    const handleClick = useCallback(() => {
      toggleValue();
    }, [toggleValue]);

    return (
      <Wrapper
        tabIndex={index + 1}
        theme={theme}
        onClick={handleClick}
        ref={wrapperRef}
        aria-label={t(`${data.key}.title`) + '. ' + t(`${data.key}.text`)}
        widgetIncreased={widgetIncreased}
      >
        <SettingItemLargeIconWrapper
          theme={theme}
          widgetIncreased={widgetIncreased}
        >
          <data.Icon theme={theme} />
        </SettingItemLargeIconWrapper>
        <TextWrapper theme={theme}>
          <TitleItem widgetIncreased={widgetIncreased} theme={theme}>
            {t(`${data.key}.title`)}
          </TitleItem>
          <TextItem widgetIncreased={widgetIncreased} theme={theme}>
            {t(`${data.key}.text`)}
          </TextItem>
        </TextWrapper>
        <SwitchWrapper theme={theme}>
          <SimpleSwitch
            value={presetsData[data.key]}
            theme={theme}
            onChange={handleChange}
            widgetIncreased={textToBoolean(widgetIncreased)}
          />
        </SwitchWrapper>
      </Wrapper>
    );
  }
);

export default withTheme(WidgetPresetItem);
