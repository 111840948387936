import { ColumnsType } from 'antd/lib/table';

import { TAccessWidgetStatData } from '../types/access-widget-stats-types';

export const getAccessWidgetStatsColumns: () => ColumnsType<TAccessWidgetStatData> =
  () => [
    // {
    //   title: 'Тип события',
    //   dataIndex: 'event',
    //   key: 'event',
    // },
    {
      title: 'Событие',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'Кол-во событий',
      dataIndex: 'count',
      key: 'count',
      render: (count: number) => count.toLocaleString(),
    },
  ];
