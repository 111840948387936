import { useEffect } from 'react';

import HideImagesIcon from '../../icons/hideImagesIcon';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const hideImagesEffect: TSettingItem = {
  Icon: HideImagesIcon,
  key: ETextEffectKeys.HIDE_IMAGES,
};

export const HIDE_IMAGE_CLASS_NAME = 'ac-wd-hdi-effect-style';

export const useHideImagesEffect = () => {
  const effect = useEffectSelector(ETextEffectKeys.HIDE_IMAGES);

  useEffect(() => {
    if (effect.value) {
      document.querySelector('body')?.classList.add(HIDE_IMAGE_CLASS_NAME);
    } else {
      document.querySelector('body')?.classList.remove(HIDE_IMAGE_CLASS_NAME);
    }
  }, [effect]);
};
