import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { getIconBaseColor } from '../../utils/ui/icons';
import { TBaseIconProps } from './index';

const CheckerIcon: FC<TBaseIconProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_551_1711)">
        <path
          d="M10.4999 5.25V0.345C11.1937 0.607001 11.8239 1.01348 12.3487 1.5375L14.9617 4.152C15.4863 4.67616 15.8931 5.30617 16.1549 6H11.2499C11.051 6 10.8603 5.92098 10.7196 5.78033C10.579 5.63968 10.4999 5.44891 10.4999 5.25ZM16.4999 7.86375V14.25C16.4987 15.2442 16.1033 16.1973 15.4003 16.9003C14.6973 17.6033 13.7441 17.9988 12.7499 18H5.24994C4.25574 17.9988 3.30261 17.6033 2.5996 16.9003C1.8966 16.1973 1.50113 15.2442 1.49994 14.25V3.75C1.50113 2.7558 1.8966 1.80267 2.5996 1.09966C3.30261 0.396661 4.25574 0.00119089 5.24994 0L8.63619 0C8.75844 0 8.87919 0.00975 8.99994 0.018V5.25C8.99994 5.84674 9.23699 6.41903 9.65895 6.84099C10.0809 7.26295 10.6532 7.5 11.2499 7.5H16.4819C16.4902 7.62075 16.4999 7.7415 16.4999 7.86375ZM12.5429 10.7332C12.4058 10.5893 12.2172 10.5057 12.0185 10.5008C11.8198 10.4959 11.6273 10.5701 11.4832 10.707L8.78319 13.2803C8.71104 13.3528 8.62481 13.4099 8.5298 13.4479C8.43478 13.4859 8.333 13.504 8.23071 13.5012C8.12842 13.4984 8.02778 13.4747 7.935 13.4315C7.84221 13.3884 7.75924 13.3267 7.69119 13.2502L6.49944 12.1898C6.42587 12.1242 6.34009 12.0737 6.24702 12.0412C6.15394 12.0088 6.05539 11.995 5.95698 12.0006C5.75824 12.012 5.57216 12.1019 5.43969 12.2505C5.37409 12.3241 5.32363 12.4098 5.29119 12.5029C5.25874 12.596 5.24494 12.6945 5.25058 12.793C5.26198 12.9917 5.35185 13.1778 5.50044 13.3102L6.65844 14.3407C7.07819 14.7606 7.64688 14.9975 8.24056 14.9999C8.83425 15.0023 9.40482 14.77 9.82794 14.3535L12.5167 11.793C12.6606 11.6559 12.7442 11.4673 12.7491 11.2686C12.754 11.0698 12.6799 10.8773 12.5429 10.7332Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
      </g>
      <defs>
        <clipPath id="clip0_551_1711">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(CheckerIcon);
