import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from '../../base-query';
import { TStatsResponse } from './types';

export const statisticApi = createApi({
  reducerPath: 'statistic/api',
  baseQuery,
  tagTypes: ['WidgetStat'],
  endpoints: (builder) => ({
    getStatisticByDomain: builder.query<TStatsResponse, string>({
      query: (domain) => ({
        url: `/statistic/by-domain/${encodeURIComponent(domain)}`,
        method: 'GET',
        providesTags: ['WidgetStat'],
      }),
    }),
  }),
});

export const { useGetStatisticByDomainQuery } = statisticApi;
