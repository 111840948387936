import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, HTMLAttributes, PropsWithChildren, memo } from 'react';

import {
  EButtonSizes,
  EButtonTypes,
  mapButtonTypeToButtonTheme,
} from '../../../utils/ui/buttons';

export type TBaseButtonProps = {
  type?: EButtonTypes;
  theme?: Theme;
  size?: EButtonSizes;
} & HTMLAttributes<HTMLButtonElement>;

type TButtonWrapperProps = ReturnType<typeof mapButtonTypeToButtonTheme>;

const Button = styled('button')<TButtonWrapperProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  color: ${({ color }) => color};
  padding: ${({ padding }) => padding};
  font-size: ${({ fontSize }) => fontSize};
  border: 1px solid ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  display: block;
  transition:
    background-color 0.3s ease-out,
    color 0.3s ease-out;

  &:hover {
    background-color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    color: ${({ hoverColor }) => hoverColor};
  }
`;

const BaseButton: FC<PropsWithChildren<TBaseButtonProps>> = ({
  type = EButtonTypes.SECONDARY,
  theme,
  size = EButtonSizes.DEFAULT,
  children,
  ...props
}) => {
  const buttonProps = mapButtonTypeToButtonTheme(type, size, theme as Theme);

  return (
    <Button {...buttonProps} {...props}>
      {children}
    </Button>
  );
};

export default memo(withTheme(BaseButton));
