import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

import { ECSSVars } from '../../../styles';
import {
  EButtonSizes,
  EButtonTypes,
  GET_BUTTON_HOVER_PRIMARY_TEXT_COLORS,
  GET_BUTTON_PRIMARY_TEXT_COLORS,
} from '../../../utils/ui/buttons';
import BaseButton, { TBaseButtonProps } from './base-button';

const wrapperHeightMapper: Record<EButtonSizes, string> = {
  [EButtonSizes.DEFAULT]: '2.25rem',
  [EButtonSizes.SM]: '1rem',
  [EButtonSizes.L]: '3.25rem',
};

const IconButtonWrapper = styled(BaseButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ size = EButtonSizes.DEFAULT }) => wrapperHeightMapper[size]};
  min-width: 2.25rem;
  padding: 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  ${ECSSVars.THEME_ICON_COLOR}: ${({ type, theme }) =>
    GET_BUTTON_PRIMARY_TEXT_COLORS(theme)[type ?? EButtonTypes.SECONDARY]};

  &:hover {
    ${ECSSVars.THEME_ICON_COLOR}: ${({ type, theme }) =>
      GET_BUTTON_HOVER_PRIMARY_TEXT_COLORS(theme)[
        type ?? EButtonTypes.SECONDARY
      ]};
  }

  svg path {
    transition: fill 0.3s ease-out;
  }
`;

const IconButton: FC<PropsWithChildren<TBaseButtonProps>> = ({
  children,
  ...baseButtonProps
}) => {
  return <IconButtonWrapper {...baseButtonProps}>{children}</IconButtonWrapper>;
};

export default withTheme(IconButton);
