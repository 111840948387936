import styled from '@emotion/styled';
import { FC, Fragment, PropsWithChildren, useEffect, useRef } from 'react';

import { ETextEffectKeys } from '../../effects/names';
import { useEffectSelector } from '../../state/reducers/widget/selectors';

const AreaTop = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.7);
`;

const AreaBottom = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const ReadArea: FC<PropsWithChildren> = ({ children }) => {
  const effect = useEffectSelector(ETextEffectKeys.READ_AREA);
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!effect.value) {
      return;
    }

    const listener = (e: MouseEvent) => {
      if (topRef.current && bottomRef.current) {
        if (e.y + 50 - window.innerHeight <= 0) {
          topRef.current.style.height = `${Math.min(
            e.y - 50,
            window.innerHeight
          )}px`;

          bottomRef.current.style.top = `${Math.min(
            e.y + 50,
            window.innerHeight
          )}px`;
        } else {
          topRef.current.style.height = `${window.innerHeight - 100}px`;
          bottomRef.current.style.top = `${window.innerHeight}px`;
        }
      }
    };

    window.addEventListener('mousemove', listener);

    return () => {
      window.removeEventListener('mousemove', listener);
    };
  }, [effect]);

  return (
    <Fragment>
      {effect.value && (
        <Fragment>
          <AreaTop ref={topRef} />
          <AreaBottom ref={bottomRef} />
        </Fragment>
      )}

      {children}
    </Fragment>
  );
};
