import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authApi } from '../../api/features/auth';
import { TWidgetSettingsItem } from '../../api/features/widget/types';
import { deepMerge } from '../../utils/common-js';
import { mapServerWidgetToSettingsItem } from '../../utils/widget/mappers';
import { addClearStateOnLogoutMatcher } from '../utils/state-utils';

type TState = {
  list: TWidgetSettingsItem[];
  subDomainAttentionViewed: boolean;
};

const initialState: TState = {
  list: [],
  subDomainAttentionViewed: false,
};

const widgetSettingsSlice = createSlice({
  name: 'widget/settings',
  initialState,
  reducers: {
    createWidgetSettingAction(
      state,
      action: PayloadAction<TWidgetSettingsItem>
    ) {
      state.list = [action.payload, ...state.list];
    },
    removeWidgetSettingByIdAction(state, action: PayloadAction<string>) {
      state.list = state.list.filter((el) => {
        return el.id !== action.payload;
      });
    },
    removeWidgetSettingByDomainAction(state, action: PayloadAction<string>) {
      state.list = state.list.filter((el) => {
        return el.domain !== action.payload;
      });
    },
    updateWidgetSettingAction(
      state,
      action: PayloadAction<{
        data: Partial<TWidgetSettingsItem> & {
          domain: TWidgetSettingsItem['domain'];
        };
        callBack?: (newData: TWidgetSettingsItem) => void;
      }>
    ) {
      let updatedSettings: TWidgetSettingsItem | undefined;
      state.list = state.list.map((el) => {
        if (el.domain === action.payload.data.domain) {
          updatedSettings = deepMerge(
            el,
            action.payload.data
          ) as TWidgetSettingsItem;
          return updatedSettings;
        }
        return el;
      });

      if (action.payload.callBack) {
        if (updatedSettings) {
          action.payload.callBack(JSON.parse(JSON.stringify(updatedSettings)));
        }
      }
    },
    setSubDomainAttentionViewedAction(state, action: PayloadAction<boolean>) {
      state.subDomainAttentionViewed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.getMyUserData.matchFulfilled,
      (state, action) => {
        const actionWidgetsList = action.payload.widget.map((widget) =>
          mapServerWidgetToSettingsItem(widget)
        );

        const existWidgets: string[] = [];

        state.list = state.list.map((el) => {
          const myNewWidget = actionWidgetsList.find(
            (actionWidget) => actionWidget.id === el.id
          );

          if (myNewWidget) {
            if (myNewWidget.id) {
              existWidgets.push(myNewWidget.id);
            }
            return myNewWidget;
          } else return el;
        });

        state.list.push(
          ...actionWidgetsList.filter(
            (el) => !existWidgets.includes(el.id as string)
          )
        );
      }
    );
    addClearStateOnLogoutMatcher(builder, initialState);
  },
});

export const {
  createWidgetSettingAction,
  updateWidgetSettingAction,
  removeWidgetSettingByIdAction,
  removeWidgetSettingByDomainAction,
  setSubDomainAttentionViewedAction,
} = widgetSettingsSlice.actions;
export default widgetSettingsSlice.reducer;
