import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const SimpleLanguageIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_403_3155)">
        <path
          d="M22.2 2.16303C21.6378 1.69296 20.9791 1.35242 20.2705 1.1656C19.5619 0.978773 18.8209 0.950242 18.1 1.08203L14.278 1.77603C13.3956 1.93808 12.5933 2.39206 12 3.06503C11.4052 2.3909 10.6006 1.93681 9.716 1.77603L5.9 1.08203C5.17916 0.950127 4.43813 0.978283 3.72938 1.16451C3.02062 1.35073 2.36147 1.69048 1.79857 2.15969C1.23567 2.6289 0.782786 3.2161 0.471982 3.87974C0.161178 4.54338 4.88125e-05 5.26722 0 6.00003L0 16.793C5.74559e-05 17.9638 0.410977 19.0975 1.16113 19.9964C1.91129 20.8953 2.95311 21.5025 4.105 21.712L10.391 22.855C11.455 23.0484 12.545 23.0484 13.609 22.855L19.9 21.712C21.051 21.5014 22.0916 20.8938 22.8408 19.995C23.5899 19.0962 24.0002 17.9631 24 16.793V6.00003C24.0005 5.26748 23.8394 4.54383 23.5283 3.88063C23.2171 3.21742 22.7636 2.63097 22.2 2.16303ZM11 20.928C10.916 20.916 10.832 20.902 10.748 20.887L4.463 19.745C3.77176 19.6193 3.14659 19.2549 2.69649 18.7154C2.24638 18.176 1.99989 17.4956 2 16.793V6.00003C2 5.20438 2.31607 4.44132 2.87868 3.87871C3.44129 3.3161 4.20435 3.00003 5 3.00003C5.18109 3.00047 5.36179 3.01686 5.54 3.04903L9.36 3.74903C9.81961 3.83314 10.2353 4.0755 10.5348 4.43408C10.8344 4.79265 10.999 5.24479 11 5.71203V20.928ZM22 16.793C22.0001 17.4956 21.7536 18.176 21.3035 18.7154C20.8534 19.2549 20.2282 19.6193 19.537 19.745L13.252 20.887C13.168 20.902 13.084 20.916 13 20.928V5.71203C12.9999 5.24365 13.1643 4.79009 13.4643 4.43044C13.7644 4.0708 14.1812 3.82787 14.642 3.74403L18.463 3.04403C18.8957 2.9653 19.3405 2.98269 19.7657 3.09494C20.191 3.2072 20.5863 3.41159 20.9238 3.69364C21.2613 3.97569 21.5326 4.32849 21.7186 4.72706C21.9046 5.12563 22.0007 5.56021 22 6.00003V16.793Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
      </g>
      <defs>
        <clipPath id="clip0_403_3155">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(SimpleLanguageIcon);
