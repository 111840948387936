import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URL } from '../constants/app';
import { useUserAuthSelector } from '../state/auth/selectors';
import { RootCombine } from '../state/root';

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const userAuthState = useUserAuthSelector(getState() as RootCombine);

    if (userAuthState.authed && userAuthState.data?.accessToken) {
      headers.set('authorization', `Bearer ${userAuthState.data.accessToken}`);
    }
    return headers;
  },
});

export default baseQuery;
