import { Theme } from '@emotion/react';
import { FC } from 'react';

import { ETextEffectKeys } from '../effects/names';
import AdhdIcon from '../icons/adhd';
import BlindProfile from '../icons/blindProfile';
import CognitiveIcon from '../icons/cognitive';
import Epilepsy from '../icons/epilepsy';
import { TIconProps } from '../icons/types';
import { TSettingValue } from '../types/widget';

export enum TPresetKey {
  Epilepsy = 'epilepsy',
  Blind = 'blindProfile',
  ADHD = 'adhd',
  Cognitive = 'cognitive',
}

export type TPresetItem = {
  Icon: FC<Pick<TIconProps, never> & { theme?: Theme | undefined }>;
  key: TPresetKey;
};

export type TPresetList = TPresetItem[];

export type TWidgetPresetConfig = Record<TPresetKey, boolean>;

export const WIDGET_PRESETS_LIST: TPresetList = [
  {
    Icon: Epilepsy,
    key: TPresetKey.Epilepsy,
  },
  {
    Icon: BlindProfile,
    key: TPresetKey.Blind,
  },
  {
    Icon: AdhdIcon,
    key: TPresetKey.ADHD,
  },
  {
    Icon: CognitiveIcon,
    key: TPresetKey.Cognitive,
  },
];

export const PRESET_DEPENDENCIES: Record<
  TPresetKey,
  {
    key: (typeof ETextEffectKeys)[keyof typeof ETextEffectKeys];
    value: TSettingValue;
  }[]
> = {
  [TPresetKey.Epilepsy]: [
    {
      key: ETextEffectKeys.BRIGHTNESS,
      value: -1,
    },
    {
      key: ETextEffectKeys.STOP_ANIMATIONS,
      value: true,
    },
  ],
  [TPresetKey.Blind]: [
    {
      key: ETextEffectKeys.CONTENT_SCALE,
      value: 20,
    },
    {
      key: ETextEffectKeys.READABLE_FONT,
      value: true,
    },
    {
      key: ETextEffectKeys.CONTRAST,
      value: 1,
    },
  ],
  [TPresetKey.ADHD]: [
    {
      key: ETextEffectKeys.CONTRAST,
      value: 1,
    },
    {
      key: ETextEffectKeys.STOP_ANIMATIONS,
      value: true,
    },
    {
      key: ETextEffectKeys.READ_AREA,
      value: true,
    },
  ],
  [TPresetKey.Cognitive]: [
    {
      key: ETextEffectKeys.LINKS_MARK,
      value: true,
    },
    {
      key: ETextEffectKeys.STOP_ANIMATIONS,
      value: true,
    },
    {
      key: ETextEffectKeys.HIDE_IMAGES,
      value: true,
    },
  ],
};

export const usePresetList = (data: TWidgetPresetConfig) => {
  return WIDGET_PRESETS_LIST.filter((el) => data[el.key]);
};
