import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import {
  TTextBoolean,
  textToBoolean,
  toTextBoolean,
} from '../../../utils/ui/common';
import Aside, { TAsideProps } from './aside';

const ContentWrapper = styled.div`
  padding-left: 320px;
  display: flex;
`;

const ContentInner = styled.div`
  padding: 30px 34px 0 0;
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.MAIN.WHITE.C100};
`;

const LoadingWrapper = styled.div<{
  opened: TTextBoolean;
}>`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.MAIN.BLACK.C001};
  color: ${({ theme }) => theme.MAIN.WHITE.C100};
  transition: opacity 0.3s ease-out;
  pointer-events: ${({ opened }) => (textToBoolean(opened) ? 'all' : 'none')};
  opacity: ${({ opened }) => (textToBoolean(opened) ? 1 : 0)};
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
`;

type TProps = {
  t: TFunction<string[]>;
  userData?: TAsideProps['userData'];
  userMenu: TAsideProps['userMenu'];
  loading?: boolean;
  languageOptions: TAsideProps['languageOptions'];
  currentLanguage: TAsideProps['currentLanguage'];
  setLanguage: TAsideProps['setLanguage'];
  rightsYear: TAsideProps['rightsYear'];
  AsideContentComponent: TAsideProps['AsideContentComponent'];
};

export const BaseLayout: FC<TProps> = ({
  t,
  userData,
  userMenu,
  loading,
  currentLanguage,
  setLanguage,
  languageOptions,
  rightsYear,
  AsideContentComponent,
}) => {
  return (
    <ContentWrapper>
      <Aside
        t={t}
        userData={userData}
        userMenu={userMenu}
        currentLanguage={currentLanguage}
        setLanguage={setLanguage}
        languageOptions={languageOptions}
        rightsYear={rightsYear}
        AsideContentComponent={AsideContentComponent}
      />
      <ContentInner>
        <Outlet />
        <LoadingWrapper opened={toTextBoolean(loading)}>
          {loading && <LoadingOutlined />}
        </LoadingWrapper>
      </ContentInner>
    </ContentWrapper>
  );
};
