import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { getIconBaseColor } from '../../utils/ui/icons';
import { TBaseIconProps } from './index';

const PaymentIcon: FC<TBaseIconProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_551_1695)">
        <path
          d="M14.25 2.25H3.74999C2.7558 2.25119 1.80266 2.64666 1.09966 3.34966C0.396654 4.05267 0.00118326 5.0058 -7.62939e-06 6H18C17.9988 5.0058 17.6033 4.05267 16.9003 3.34966C16.1973 2.64666 15.2442 2.25119 14.25 2.25Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
        <path
          d="M-7.62939e-06 12C0.00118326 12.9942 0.396654 13.9473 1.09966 14.6503C1.80266 15.3533 2.7558 15.7488 3.74999 15.75H14.25C15.2442 15.7488 16.1973 15.3533 16.9003 14.6503C17.6033 13.9473 17.9988 12.9942 18 12V7.5H-7.62939e-06V12ZM5.24999 11.625C5.24999 11.8475 5.18401 12.065 5.0604 12.25C4.93678 12.435 4.76108 12.5792 4.55551 12.6644C4.34994 12.7495 4.12374 12.7718 3.90552 12.7284C3.68729 12.685 3.48683 12.5778 3.3295 12.4205C3.17216 12.2632 3.06502 12.0627 3.02161 11.8445C2.9782 11.6262 3.00048 11.4 3.08563 11.1945C3.17078 10.9889 3.31497 10.8132 3.49998 10.6896C3.68498 10.566 3.90249 10.5 4.12499 10.5C4.42336 10.5 4.70951 10.6185 4.92049 10.8295C5.13147 11.0405 5.24999 11.3266 5.24999 11.625Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
      </g>
      <defs>
        <clipPath id="clip0_551_1695">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(PaymentIcon);
