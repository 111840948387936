import { useEffect } from 'react';

import StopAnimationsIcon from '../../icons/stopAnimationsIcon';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const STOP_ANIMATIONS_CLASS_NAME = 'ac-wd-stan-effect-style';

export const stopAnimationsEffect: TSettingItem = {
  Icon: StopAnimationsIcon,
  key: ETextEffectKeys.STOP_ANIMATIONS,
};

export const useStopAnimationsEffect = () => {
  const effect = useEffectSelector(ETextEffectKeys.STOP_ANIMATIONS);

  useEffect(() => {
    if (effect.value) {
      document.querySelector('body')?.classList.add(STOP_ANIMATIONS_CLASS_NAME);
    } else {
      document
        .querySelector('body')
        ?.classList.remove(STOP_ANIMATIONS_CLASS_NAME);
    }
  }, [effect]);
};
