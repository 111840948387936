import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { EAccessButtonType } from '@kylfyk/accessability-react';
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Slider,
  Tooltip,
} from 'antd';
import { FC, Fragment, memo, useEffect, useMemo, useState } from 'react';

import {
  TWidgetFullSettingsForm,
  TWidgetSettingsItem,
} from '../../../api/features/widget/types';
import AccordionTrigger from '../../../components/ui/accordion-trigger';
import ThemePickerButton from '../../../components/ui/buttons/theme-picker-button';
import CommandText from '../../../components/ui/command-text';
import SectionParagraph from '../../../components/ui/section-paragraph';
import SectionTitle from '../../../components/ui/section-title';
import SubTitle from '../../../components/ui/sub-title';
import { NBSP } from '../../../constants/formatters';
import { useWidgetFormContext } from '../../../providers/widget-settings-form-provider';
import { WidgetPresetThemeList } from '../../../styles/widget-preset-style';
import { EWidgetSettingsFormNames } from '../../../types/forms/widget-settings';
import { ALLOWED_URL_REGEX_LIST, URL_REGEX } from '../../../utils/regex';
import { mapWidgetSettingItemToFormValues } from '../../../utils/widget/mappers';
import { ADAPTIVE_SLIDER_BASE_MARKS } from '../../../utils/widget/options';
import PositionListForm from '../position-list-form';
import EffectsForm from './effects-form';
import ExpandedColorsForm from './expanded-colors-form';
import PresetsForm from './presets-form';
import { WidgetAdditionalDomainsForm } from './widget-additional-domains-form';

type TProps = {
  theme: Theme;
  widgetSettingsItem: TWidgetSettingsItem;
};

const ButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.MAIN.WHITE.C100};
  padding-block: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

const WidgetSettings: FC<TProps> = memo(({ theme, widgetSettingsItem }) => {
  const [resetLoading, setResetLoading] = useState(false);
  const {
    form,
    onFormValuesChange,
    onFormFinish,
    handleResetForm,
    finisLoading,
    handleDeleteWidget,
    currentSettingItem,
    handleDeactivateWidget,
    subDomainAttentionViewed,
    handleCloseDomainAttention,
    isUserAdmin,
  } = useWidgetFormContext();

  const [isAlertNeedToBeShown, setIsAlertNeedToBeShown] = useState(false);

  const [colorFormExpanded, setColorFormExpanded] = useState(false);
  const [selectedAdaptiveStep, setSelectedAdaptiveStep] =
    useState<number>(1920);

  const [mainPlacementFormFocused, setMainPlacementFormFocused] =
    useState(false);

  const formInitialValues: TWidgetFullSettingsForm = useMemo(() => {
    return mapWidgetSettingItemToFormValues(widgetSettingsItem);
  }, [widgetSettingsItem]);

  useEffect(() => {
    if (mainPlacementFormFocused) {
      setSelectedAdaptiveStep(1920);
    }
  }, [mainPlacementFormFocused]);

  const handleCancel = async () => {
    setResetLoading(true);
    await handleResetForm();
    setResetLoading(false);
  };

  return (
    <Form<TWidgetFullSettingsForm>
      form={form}
      layout="vertical"
      initialValues={formInitialValues}
      requiredMark={false}
      onValuesChange={onFormValuesChange}
      onFinish={onFormFinish}
      style={{
        paddingRight: 20,
      }}
    >
      <SectionTitle title="Основное" theme={theme} />
      <Form.Item
        label="Скрыть мета-ссылку на сайт компании"
        name={EWidgetSettingsFormNames.META_LINK_HIDDEN}
        tooltip="Изменять данный параметр может только администратор"
        valuePropName="checked"
      >
        <Checkbox>Скрыть</Checkbox>
      </Form.Item>
      {isUserAdmin && (
        <Form.Item
          label="Срок действия виджета"
          name={EWidgetSettingsFormNames.DATE_TO}
          tooltip="Время в местном формате"
        >
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            style={{
              width: 240,
            }}
            showTime
            showNow={false}
          />
        </Form.Item>
      )}
      <Form.Item
        label="Домен"
        name={EWidgetSettingsFormNames.DOMAIN}
        tooltip="Введите адрес сайта включая протокол соединения (http или https). Домен на русском языке должен быть в формате Punycode, пример: https://мой-сайт.рф -> https://xn----8sbzclmxk.xn--p1ai"
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
          {
            pattern: URL_REGEX,
            // message: t('form.rules.urlInvalid'), Сделать общий перевод для ошибок форм
            message: 'URL не валиден. Пример: https://example.com',
          },
          {
            validator: (_: any, value: string) =>
              ALLOWED_URL_REGEX_LIST.test(value)
                ? Promise.reject(
                    new Error('Указанный URL адрес не поддерживается')
                  )
                : Promise.resolve(),
          },
          {
            validator: (_: any, value: string) =>
              value.endsWith('/')
                ? Promise.reject(new Error('Вводите адрес без "/" в конце'))
                : Promise.resolve(),
          },
        ]}
      >
        <Input placeholder="Домен" size="large" />
      </Form.Item>
      <WidgetAdditionalDomainsForm
        name={EWidgetSettingsFormNames.SUB_DOMAIN}
        form={form}
        onTechDomainFounded={() => {
          setIsAlertNeedToBeShown(true);
        }}
      />
      {!subDomainAttentionViewed && isAlertNeedToBeShown && (
        <Alert
          message="Внимание"
          description="Использование виджета на дополнительном домене разрешено для доменов второго уровня. Для любых других доменов использование разрешено только в целях тестирования"
          type="warning"
          showIcon
          closable
          onClose={handleCloseDomainAttention}
        />
      )}

      <SectionTitle title="Цветовая схема" theme={theme} />
      <SectionParagraph>
        Вы можете выбрать готовую цветовую схему или настроить виджет
        самостоятельно
      </SectionParagraph>
      <Form.Item name={EWidgetSettingsFormNames.READY_THEME} noStyle>
        <Radio.Group className="hidden-radio">
          {WidgetPresetThemeList.map((el) => (
            <Radio value={el.key} key={el.key}>
              <ThemePickerButton color={el.color} theme={theme} />
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <AccordionTrigger
        open={colorFormExpanded}
        setOpen={setColorFormExpanded}
        theme={theme}
        label="Расширенные настройки"
      />
      {colorFormExpanded && <ExpandedColorsForm />}
      <SectionTitle title="Общие настройки" theme={theme} />
      <Form.Item
        label="Шрифт виджета"
        tooltip="Поддерживается в формате CSS. Шрифт должен быть подключен к вашему сайту."
        name={EWidgetSettingsFormNames.FONT}
      >
        <Input placeholder="Введите" size="large" />
      </Form.Item>
      <Form.Item
        label="Z-index меню виджета"
        name={EWidgetSettingsFormNames.MENU_Z_INDEX}
      >
        <InputNumber
          style={{
            width: '100%',
          }}
          min={0}
          max={2147483647}
          placeholder="Введите"
          size="large"
        />
      </Form.Item>
      <Form.Item
        name={EWidgetSettingsFormNames.USES_REM}
        valuePropName="checked"
        label="Относительные единицы измерения"
        tooltip={'Сайт использует rem или em для размеров шрифты'}
      >
        <Checkbox>Сайт использует относительные единицы измерения</Checkbox>
      </Form.Item>
      <Form.Item noStyle dependencies={[EWidgetSettingsFormNames.USES_REM]}>
        {({ getFieldValue }) => {
          const isCiteUseRem = getFieldValue(EWidgetSettingsFormNames.USES_REM);

          if (isCiteUseRem) {
            return (
              <Form.Item
                name={EWidgetSettingsFormNames.REM_TARGET_SELECTOR}
                label="Элемент которому задан базовый размер шрифта"
                tooltip="Вы можете ввести селектор в формате тег.класс#id"
                rules={[
                  {
                    required: true,
                    message: 'Поле обязательно для заполнения',
                  },
                ]}
              >
                <Input placeholder="Введите" size="large" />
              </Form.Item>
            );
          }

          return null;
        }}
      </Form.Item>
      <SectionTitle title="Кнопка активации виджета" theme={theme} />
      <Form.Item name={EWidgetSettingsFormNames.BUTTON_TYPE} label="Тип кнопки">
        <Radio.Group>
          <Radio value={EAccessButtonType.HUMAN}>Человек</Radio>
          <Radio value={EAccessButtonType.EYE}>Глаз</Radio>
        </Radio.Group>
      </Form.Item>
      <SectionParagraph>
        Виджет предоставляет событие{NBSP}
        <CommandText>window.openAccessibilityWidget()</CommandText>. Вы можете
        использовать его для создания собственной кнопки вызова виджета
      </SectionParagraph>
      <Form.Item
        name={EWidgetSettingsFormNames.HIDE_BUTTON}
        valuePropName="checked"
        label="Скрыть кнопку"
        tooltip={
          'Вы можете скрыть кнопку если используете событие вызова меню виджета со своей кнопкой'
        }
      >
        <Checkbox>Скрыть</Checkbox>
      </Form.Item>
      <Form.Item noStyle dependencies={[EWidgetSettingsFormNames.HIDE_BUTTON]}>
        {({ getFieldValue }) => {
          const isHidden = getFieldValue(EWidgetSettingsFormNames.HIDE_BUTTON);

          if (!isHidden) {
            return (
              <Fragment>
                <Form.Item
                  name={EWidgetSettingsFormNames.ACCESS_BUTTON_Z_INDEX}
                  label="Z-index кнопки виджета"
                >
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                    min={0}
                    max={2147483647}
                    placeholder="Введите"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name={EWidgetSettingsFormNames.ACCESS_BUTTON_SIZE}
                  label="Размер кнопки"
                  tooltip="Размер указывается в пикселях"
                >
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                    min={16}
                    placeholder="Введите"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name={EWidgetSettingsFormNames.ACCESS_BUTTON_BORDER_RADIUS}
                  label="Радиус скругления углов кнопки"
                  tooltip="Размер указывается в пикселях"
                >
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                    min={0}
                    placeholder="Введите"
                    size="large"
                  />
                </Form.Item>
                <SubTitle>Позиционирование</SubTitle>
                <PositionListForm
                  setFormFocused={setMainPlacementFormFocused}
                  formListName={EWidgetSettingsFormNames.ADAPTIVE}
                />
                <Form.Item
                  name={
                    EWidgetSettingsFormNames.ACCESS_BUTTON_ENABLE_BREAKPOINTS
                  }
                  valuePropName="checked"
                  label="Расширенные настройки"
                  tooltip={
                    'Настроить адаптивное положение виджета в зависимости от ширины экрана'
                  }
                >
                  <Checkbox>Настраивать положение виджета адаптивно</Checkbox>
                </Form.Item>
                <Form.Item
                  noStyle
                  dependencies={[
                    EWidgetSettingsFormNames.ACCESS_BUTTON_ENABLE_BREAKPOINTS,
                  ]}
                >
                  {({ getFieldValue: getFieldValueLocal }) => {
                    const isEndpointsEnabled = getFieldValueLocal(
                      EWidgetSettingsFormNames.ACCESS_BUTTON_ENABLE_BREAKPOINTS
                    );

                    if (isEndpointsEnabled) {
                      return (
                        <Fragment>
                          <SectionParagraph>
                            Выберите максимальное разрешение для которого будут
                            работать данные правила и введите размер отступа в
                            {NBSP}
                            <CommandText>px</CommandText>. По умолчанию будут
                            активны базовые правила позиционирования.
                          </SectionParagraph>
                          <Slider
                            marks={ADAPTIVE_SLIDER_BASE_MARKS}
                            value={selectedAdaptiveStep}
                            onChange={setSelectedAdaptiveStep}
                            step={null}
                            min={480}
                            max={1920}
                          />
                          <PositionListForm
                            formListName={[
                              EWidgetSettingsFormNames.ACCESS_BUTTON_ADAPTIVE_BREAKPOINTS,
                              selectedAdaptiveStep,
                            ]}
                          />
                        </Fragment>
                      );
                    }

                    return null;
                  }}
                </Form.Item>
              </Fragment>
            );
          }

          return null;
        }}
      </Form.Item>
      <SectionTitle title="Доступные эффекты" theme={theme} />
      <EffectsForm formName={EWidgetSettingsFormNames.EFFECTS_SETTINGS} />
      <SectionTitle title="Активные профили" theme={theme} />
      <PresetsForm formName={EWidgetSettingsFormNames.PRESETS_SETTINGS} />
      <ButtonsWrapper>
        <Tooltip title="Применить настройки виджета">
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={form.submit}
            loading={finisLoading}
            htmlType="button"
          >
            Применить
          </Button>
        </Tooltip>
        <Tooltip title="Вернуть настройки виджета к актуальному состоянию">
          <Button
            icon={<RollbackOutlined />}
            onClick={handleCancel}
            loading={resetLoading}
            htmlType="button"
          >
            Отмена
          </Button>
        </Tooltip>
        {currentSettingItem.id && (
          <Tooltip
            title={`Деактивируйте виджет если не планируете пользоваться им длительное время. Для повторной активации достаточно нажать кнопку "Применить"`}
          >
            <Button danger onClick={handleDeactivateWidget}>
              Деактивировать виджет
            </Button>
          </Tooltip>
        )}
        <Popconfirm
          title="Вы уверены?"
          description="Данное действие необратимо удалит виджет"
          okButtonProps={{
            danger: true,
            type: 'primary',
          }}
          cancelButtonProps={{
            type: 'primary',
          }}
          okText="Да, удалить"
          cancelText="Отмена"
          onConfirm={handleDeleteWidget}
        >
          <Button danger type="primary">
            Удалить виджет
          </Button>
        </Popconfirm>
      </ButtonsWrapper>
    </Form>
  );
});

export default withTheme(WidgetSettings);
