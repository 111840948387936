import styled from '@emotion/styled';
import { FC, Fragment, useEffect, useRef, useState } from 'react';

import BlackCursorIcon from '../../icons/blackCursorIcon';
import DefaultCursorIcon from '../../icons/defaultCursorIcon';
import PointerCursor from '../../icons/pointerCursor';

const Wrapper = styled('div')`
  position: fixed;
  width: 40px;
  height: 60px;
  pointer-events: none;
  overflow: hidden;
  z-index: ${({ theme }) => theme.SETTINGS.MENU.Z_INDEX + 3};

  svg {
    width: 40px;
    height: 60px;
  }
`;

type TProps = {
  isBlack?: boolean;
};

export const CursorComponent: FC<TProps> = ({ isBlack }) => {
  const cursorRef = useRef<HTMLDivElement>(null);

  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (cursorRef.current) {
        cursorRef.current.style.top = `${e.y}px`;
        cursorRef.current.style.left = `${e.x}px`;
      }

      if ((e.target as HTMLElement).style?.cursor === 'pointer') {
        setIsHover(true);
      } else {
        setIsHover(false);
      }
    };

    window.addEventListener('mousemove', listener);
    window.dispatchEvent(new Event('mousemove'));

    return () => {
      window.removeEventListener('mouseover', listener);
    };
  }, []);

  return (
    <Wrapper ref={cursorRef}>
      {isBlack ? (
        <Fragment>{isHover ? <PointerCursor /> : <BlackCursorIcon />}</Fragment>
      ) : (
        <Fragment>
          {isHover ? <PointerCursor /> : <DefaultCursorIcon />}
        </Fragment>
      )}
    </Wrapper>
  );
};
