import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { ETextEffectKeys } from '../effects/names';
import { settingsList } from '../effects/root';
import { TSettingsList, TWidgetEffectsSettings } from '../types/widget';
import { isMobile, isSpeechAvailable } from '../utils/web';
import { WIDGET_SETTINGS_INDEXES } from '../utils/widget-settingsd-define-index';

function checkTargetBrowser(
  target: 'mobile' | 'desktop',
  cond: TWidgetEffectsSettings[string]
): boolean {
  if (target === 'mobile' && cond.mobile) {
    return true;
  } else if (target === 'desktop' && cond.desktop) {
    return true;
  }

  return false;
}

export const useSettingsList = (conditions: TWidgetEffectsSettings) => {
  const resolutionRef = useRef<'mobile' | 'desktop'>('mobile');
  const [targetResolution, setTargetResolution] = useState<
    'mobile' | 'desktop'
  >('mobile');

  useEffect(() => {
    resolutionRef.current = targetResolution;
  }, [targetResolution]);

  useLayoutEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    let listener = () => {
      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        if (resolutionRef.current !== 'mobile') {
          setTargetResolution('mobile');
        }
      } else {
        if (window.innerWidth < 768) {
          if (resolutionRef.current !== 'mobile') {
            setTargetResolution('mobile');
          }
        } else {
          if (resolutionRef.current !== 'desktop') {
            setTargetResolution('desktop');
          }
        }
      }
    };

    listener();

    if (isMobile) {
      if (resolutionRef.current !== 'mobile') {
        setTargetResolution('mobile');
      }
    } else {
      window.addEventListener('resize', listener);
    }

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  const list = useMemo(() => {
    const result: TSettingsList = [];

    Object.keys(conditions).forEach((key) => {
      const configEffectData = conditions[key];
      const effect = settingsList
        .map((el) => ({
          ...el,
          index: WIDGET_SETTINGS_INDEXES.indexOf(el.key),
        }))
        .find((el) => el.key === key);
      const mobile = isMobile();

      if (effect && (configEffectData.desktop || configEffectData.mobile)) {
        if (
          effect.key === ETextEffectKeys.TEXT_TO_SPEECH &&
          !isSpeechAvailable()
        ) {
          return;
        }

        if (checkTargetBrowser(targetResolution, configEffectData)) {
          if (configEffectData.desktop && configEffectData.mobile) {
            result.push(effect);
          } else if (configEffectData.desktop && !mobile) {
            result.push(effect);
          } else if (configEffectData.mobile && mobile) {
            result.push(effect);
          }
        }
      }
    });

    return result;
  }, [conditions, targetResolution, settingsList]);

  return useMemo(() => {
    return [list];
  }, [list]);
};
