import { LoadingOutlined } from '@ant-design/icons';
import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';

import { EWidgetStatus } from '../../../api/features/widget/types';
import { ColorByStatus, WidgetStatusTexts } from '../../../utils/widget/status';

const StatusWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: ${({ theme }) => theme.MAIN.BLACK.C200};
  border-radius: 4px;
  padding-inline: 6px;
  width: fit-content;
`;

const StatusMarker = styled.div<{
  color: string;
}>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const StatusLoadingWrapper = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.MAIN.WHITE.C100};
`;

const StatusText = styled.span`
  font-weight: bold;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.MAIN.WHITE.C200};
`;

type TProps = {
  status: EWidgetStatus;
  theme: Theme;
};

const WidgetStatus: FC<TProps> = ({ status, theme }) => {
  return (
    <StatusWrapper theme={theme}>
      {status === EWidgetStatus.BUILD_IN_PROGRESS ? (
        <StatusLoadingWrapper>
          <LoadingOutlined />
        </StatusLoadingWrapper>
      ) : (
        <StatusMarker color={ColorByStatus[status]} theme={theme} />
      )}
      <StatusText theme={theme}>{WidgetStatusTexts[status]}</StatusText>
    </StatusWrapper>
  );
};

export default withTheme(WidgetStatus);
