import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useStatisticWriter } from '../../hooks/useStatisticWriter';
import ReturnIcons from '../../icons/returnIcons';
import { LocaleParts } from '../../locale';
import { TStatEvent } from '../../types/statistic';
import { textToBoolean, toTextBoolean } from '../../utils/common';

type TProps = {
  handleReset?: () => void;
  theme: Theme;
  tabIndex: number;
  widgetIncreased?: boolean;
};

const Wrapper = styled('div')`
  display: block;
  padding: 16px;
  margin-top: auto;
  z-index: 2;
  background-color: ${({ theme }) => theme.COLORS.MENU.BACKGROUND};

  @media only screen and (max-width: 520px) {
    padding: 10px;
  }
`;

const MetaWrapper = styled('div')<{
  widgetIncreased?: 'true' | 'false';
}>`
  text-align: center;
  color: ${({ theme }) => theme.COLORS.MENU.TEXT_ON_MENU} !important;
  font-size: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 28 : 14}px;

  @media only screen and (max-width: 520px) {
    font-size: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 22 : 14}px;
  }

  a {
    color: ${({ theme }) => theme.COLORS.COMMON.ACCENT} !important;
    text-decoration: none;
  }
`;

const Button = styled('button')<{
  widgetIncreased?: 'true' | 'false';
}>`
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 24 : 12}px;
  gap: ${({ widgetIncreased }) => (textToBoolean(widgetIncreased) ? 24 : 12)}px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.COLORS.MENU.RESET_BUTTON.BACKGROUND};
  color: ${({ theme }) => theme.COLORS.MENU.RESET_BUTTON.TEXT};
  border-radius: ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.BORDER_RADIUS}px;
  font-size: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 32 : 16}px;
  margin-bottom: 16px;
  transition: background-color 0.3s ease-out;

  @media only screen and (max-width: 520px) {
    font-size: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 24 : 16}px;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.COLORS.MENU.RESET_BUTTON.BACKGROUND_HOVERED};
  }

  svg {
    width: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 46 : 23}px;
    height: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 44 : 22}px;

    @media only screen and (max-width: 520px) {
      width: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 34.5 : 23}px;
      height: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 33 : 22}px;
    }
  }
`;

const Footer: FC<TProps> = ({
  handleReset,
  theme,
  tabIndex,
  widgetIncreased,
}) => {
  const { t } = useTranslation([LocaleParts.WIDGET]);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [handleWriteStatistic] = useStatisticWriter();

  const isLinkHidden = useMemo(
    () => !!theme.SETTINGS.COMMON.HIDE_LINK,
    [theme]
  );

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (
        buttonRef.current &&
        e.code === 'Enter' &&
        document.activeElement === buttonRef.current
      ) {
        if (handleReset) {
          handleReset();
        }
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, []);

  const handleLinkClick = useCallback(() => {
    void handleWriteStatistic({
      event: TStatEvent.LANDING_LINK_CLICK,
      text: 'Переход на лендинг Assistapp',
      time: dayjs().toISOString(),
    });
  }, []);

  return (
    <Wrapper theme={theme}>
      <Button
        onClick={handleReset}
        tabIndex={tabIndex}
        ref={buttonRef}
        theme={theme}
        widgetIncreased={toTextBoolean(widgetIncreased)}
      >
        <ReturnIcons theme={theme} />
        {t('resetButton')}
      </Button>
      <MetaWrapper
        theme={theme}
        widgetIncreased={toTextBoolean(widgetIncreased)}
      >
        {t('metaText')}{' '}
        {isLinkHidden ? (
          <>Assistapp. {t('metaCompany')}</>
        ) : (
          <a
            href={process.env.LANDING_URL}
            target="_blank"
            onClick={handleLinkClick}
          >
            Assistapp
          </a>
        )}
      </MetaWrapper>
    </Wrapper>
  );
};

export default withTheme(Footer);
