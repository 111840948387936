import { combineReducers } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { getPersistConfig } from '../../utils/get-persist-config';
import { loadingReducer } from './services/loadingSlice';
import {
  AppLocaleInitialStateType,
  localeReducer,
} from './services/localeSlice';
import {
  AppThemeInitialStateType,
  appThemeReducer,
} from './services/themeSlice';

const themeConfig: PersistConfig<AppThemeInitialStateType> = getPersistConfig({
  key: 'app/theme',
});

const localeConfig: PersistConfig<AppLocaleInitialStateType> = getPersistConfig(
  {
    key: 'widget/locale/persist',
  }
);

export const applicationReducers = combineReducers({
  theme: persistReducer(themeConfig, appThemeReducer),
  locale: persistReducer(localeConfig, localeReducer),
  loading: loadingReducer,
});
