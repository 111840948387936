import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

const Text = styled.code`
  display: inline;
  background-color: ${({ theme }) => theme.MAIN.WHITE.C300};
  padding-inline: 4px;
  border-radius: 4px;
  user-select: all;
  word-break: break-word;
`;

const CommandText: FC<
  PropsWithChildren & {
    theme: Theme;
  }
> = ({ children, theme }) => {
  return <Text theme={theme}>{children}</Text>;
};

export default withTheme(CommandText);
