import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const ReadableIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.78344 5.08997C6.69886 4.94789 6.57882 4.83023 6.43508 4.74852C6.29133 4.6668 6.12883 4.62384 5.96348 4.62384C5.79814 4.62384 5.63563 4.6668 5.49189 4.74852C5.34814 4.83023 5.2281 4.94789 5.14352 5.08997L0.101855 15.1733C0.0469339 15.2811 0.0138935 15.3987 0.00463911 15.5193C-0.00461532 15.64 0.0100993 15.7612 0.0479345 15.8761C0.0857697 15.991 0.145977 16.0973 0.225085 16.1889C0.304194 16.2804 0.400638 16.3553 0.508857 16.4094C0.617077 16.4635 0.734931 16.4956 0.855625 16.5039C0.976318 16.5123 1.09746 16.4966 1.21208 16.4579C1.32669 16.4191 1.4325 16.3581 1.52341 16.2783C1.61433 16.1985 1.68854 16.1014 1.74177 15.9928L3.31752 12.8331H8.60119L10.1815 15.9928C10.2348 16.1014 10.309 16.1985 10.3999 16.2783C10.4908 16.3581 10.5966 16.4191 10.7112 16.4579C10.8258 16.4966 10.947 16.5123 11.0677 16.5039C11.1884 16.4956 11.3062 16.4635 11.4144 16.4094C11.5227 16.3553 11.6191 16.2804 11.6982 16.1889C11.7773 16.0973 11.8375 15.991 11.8754 15.8761C11.9132 15.7612 11.9279 15.64 11.9187 15.5193C11.9094 15.3987 11.8764 15.2811 11.8214 15.1733L6.78344 5.08997ZM4.23419 10.9997L5.95844 7.54939L7.68452 10.9997H4.23419Z"
        fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
      />
      <path
        d="M21.0832 7.33304C20.8401 7.33304 20.607 7.42962 20.435 7.60152C20.2631 7.77343 20.1666 8.00659 20.1666 8.2497V8.27354C19.3779 7.66688 18.4116 7.33641 17.4166 7.33304C16.201 7.33304 15.0352 7.81592 14.1757 8.67547C13.3161 9.53501 12.8333 10.7008 12.8333 11.9164C12.8333 13.1319 13.3161 14.2977 14.1757 15.1573C15.0352 16.0168 16.201 16.4997 17.4166 16.4997C18.4116 16.4963 19.3779 16.1659 20.1666 15.5592V15.583C20.1666 15.8262 20.2631 16.0593 20.435 16.2312C20.607 16.4031 20.8401 16.4997 21.0832 16.4997C21.3263 16.4997 21.5595 16.4031 21.7314 16.2312C21.9033 16.0593 21.9999 15.8262 21.9999 15.583V8.2497C21.9999 8.00659 21.9033 7.77343 21.7314 7.60152C21.5595 7.42962 21.3263 7.33304 21.0832 7.33304ZM17.4166 14.6664C16.8727 14.6664 16.341 14.5051 15.8888 14.2029C15.4365 13.9007 15.0841 13.4712 14.8759 12.9688C14.6678 12.4663 14.6133 11.9133 14.7194 11.3799C14.8255 10.8464 15.0874 10.3564 15.472 9.97183C15.8566 9.58723 16.3466 9.32532 16.8801 9.21921C17.4135 9.1131 17.9665 9.16756 18.4689 9.3757C18.9714 9.58384 19.4009 9.93632 19.7031 10.3886C20.0053 10.8408 20.1666 11.3725 20.1666 11.9164C20.1666 12.6457 19.8768 13.3452 19.3611 13.8609C18.8454 14.3766 18.1459 14.6664 17.4166 14.6664Z"
        fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
      />
    </svg>
  );
};

export default withTheme(ReadableIcon);
