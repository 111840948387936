import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const InvertColorsIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_52_26)">
        <path
          d="M10.2068 0.0683925H10.1998C9.97578 0.017782 9.74117 0.0458224 9.53539 0.147802C9.32961 0.249781 9.1652 0.419484 9.0698 0.628393L0.374795 19.7594C0.167174 20.2163 0.0775149 20.7181 0.11403 21.2187C0.150545 21.7192 0.312066 22.2026 0.583801 22.6246C0.855537 23.0466 1.2288 23.3937 1.66941 23.634C2.11002 23.8744 2.60389 24.0003 3.10579 24.0004H9.9998C10.265 24.0004 10.5194 23.895 10.7069 23.7075C10.8944 23.52 10.9998 23.2656 10.9998 23.0004V1.04639C10.9979 0.817669 10.9184 0.596366 10.7743 0.4187C10.6303 0.241034 10.4302 0.117528 10.2068 0.0683925V0.0683925ZM8.9998 22.0004H3.10579C2.93839 22.0005 2.77364 21.9586 2.62664 21.8785C2.47964 21.7984 2.3551 21.6827 2.26443 21.542C2.17377 21.4013 2.11988 21.24 2.10772 21.0731C2.09555 20.9061 2.12549 20.7388 2.19479 20.5864L8.9998 5.61639V22.0004Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M23.6249 19.7589L14.9299 0.627884C14.8299 0.417778 14.6613 0.248061 14.4518 0.146783C14.2424 0.0455057 14.0046 0.0187307 13.7779 0.0708836C13.5569 0.121195 13.3596 0.245071 13.2182 0.422213C13.0769 0.599356 12.9999 0.819257 12.9999 1.04588V22.9999C12.9999 23.2651 13.1052 23.5195 13.2928 23.707C13.4803 23.8945 13.7347 23.9999 13.9999 23.9999H20.8939C21.3958 23.9998 21.8896 23.8739 22.3303 23.6335C22.7709 23.3932 23.1441 23.0461 23.4159 22.6241C23.6876 22.2021 23.8491 21.7187 23.8856 21.2181C23.9221 20.7176 23.8325 20.2158 23.6249 19.7589Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
      </g>
      <defs>
        <clipPath id="clip0_52_26">
          <rect width="24" height="24" fill={theme.COLORS.COMMON.ON_ACCENT} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(InvertColorsIcon);
