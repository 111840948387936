import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { TWidget } from '../../../../api/features/widget/types';
import { WidgetExportScript } from '../../../../constants/widget-export-script';
import { QUERY_ACTIVE_WIDGET_NAME } from '../../../../containers/pages/documentation-page';
import { TMessageAPI } from '../../../../providers/notification-provider';
import { EPaths } from '../../../../routes/route-paths';
import { ECSSVars } from '../../../../styles';
import { withServerUtcOffset } from '../../../../utils/dates';
import SectionTitle from '../../section-title';
import SourceCopyInput from '../../source-copy-input';
import StatItem, { StatsWrapper } from '../../stats/stat-item';
import WidgetStatus from '../widget-status';

type TProps = {
  theme: Theme;
  widget: TWidget;
  messageApi: TMessageAPI;
};

const WidgetCopySource = styled(SourceCopyInput)`
  ${ECSSVars.THEME_ICON_COLOR}: ${({ theme }) => theme.MAIN.WHITE.C100};
`;

const StatusWrapper = styled.div`
  margin-block: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

const WidgetMainInfo: FC<TProps> = ({ theme, widget, messageApi }) => {
  return (
    <div>
      <SectionTitle title="Актуальный статус" theme={theme} />
      <StatsWrapper theme={theme}>
        <StatItem
          value={withServerUtcOffset
            .call(dayjs(widget.updatedAt))
            .format('DD.MM.YY HH:mm')}
          label="Дата обновления"
        />
        <StatItem
          value={withServerUtcOffset
            .call(dayjs(widget.createdAt))
            .format('DD.MM.YY HH:mm')}
          label="Дата создания"
        />
      </StatsWrapper>
      {!!(widget.widgetVendorsUrl && widget.widgetUrl) && (
        <WidgetCopySource
          value={WidgetExportScript(widget.widgetVendorsUrl, widget.widgetUrl)}
          messageApi={messageApi}
        />
      )}
      <StatusWrapper>
        <WidgetStatus status={widget.status} theme={theme} />
        <NavLink
          to={{
            pathname: EPaths.DOCUMENTATION,
            search: `?${QUERY_ACTIVE_WIDGET_NAME}=${widget.widgetFolder}`,
          }}
        >
          Как установить?
        </NavLink>
      </StatusWrapper>
    </div>
  );
};

export default withTheme(WidgetMainInfo);
