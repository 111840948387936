import { useCallback, useEffect, useMemo } from 'react';

import { IS_MODULE } from '../../constants';
import { useWidgetEffect } from '../../hooks/useWidgetEffect';
import TextIcon from '../../icons/textIcon';
import { prepareFontSize } from '../../init-functions/prepare-font-size';
import { useCurrentTheme } from '../../services/theme-service';
import { useAppDispatch } from '../../state';
import { setEffectLoadingAction } from '../../state/reducers/widget/slices/effectsSlice';
import { ENV_CONFIG } from '../../styles';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const FS_CLASS_NAME_20 = 'ac-wd-fs-20-effect-style';
export const FS_CLASS_NAME_40 = 'ac-wd-fs-40-effect-style';
export const BASE_ELEMENT_BASE_SELECTOR = 'html';

export const textEffect: TSettingItem<typeof prepareFontSize> = {
  Icon: TextIcon,
  key: ETextEffectKeys.TEXT_INCREASE,
  initializeFunction: prepareFontSize,
  needToInitialize: true,
};

let effectInited = false;

export const useTextEffect = () => {
  const theme = useCurrentTheme();
  const { effect, isSettingOn, settingItem } = useWidgetEffect(
    ETextEffectKeys.TEXT_INCREASE
  );

  const dispatch = useAppDispatch();

  const rootSelector = useMemo(() => {
    return (
      theme.SETTINGS.EFFECTS?.REM_TARGET_SELECTOR ?? BASE_ELEMENT_BASE_SELECTOR
    );
  }, [theme]);

  const initEffect = useCallback(async () => {
    if (
      settingItem?.needToInitialize &&
      !ENV_CONFIG.theme.SETTINGS.EFFECTS?.CITE_USE_REM &&
      (ENV_CONFIG.effects.textIncrease.desktop ||
        ENV_CONFIG.effects.textIncrease.mobile)
    ) {
      try {
        dispatch(
          setEffectLoadingAction({
            key: ETextEffectKeys.TEXT_INCREASE,
            loading: true,
          })
        );
        if (!IS_MODULE) {
          await settingItem.initializeFunction(rootSelector);
        }
        dispatch(
          setEffectLoadingAction({
            key: ETextEffectKeys.TEXT_INCREASE,
            loading: false,
          })
        );
      } catch (e) {
      } finally {
        dispatch(
          setEffectLoadingAction({
            key: ETextEffectKeys.TEXT_INCREASE,
            loading: false,
          })
        );
      }
    }
  }, [settingItem, effect, rootSelector]);

  useEffect(() => {
    if (
      settingItem?.needToInitialize &&
      !effectInited &&
      effect.value !== false
    ) {
      effectInited = true;
      void initEffect();
    }
  }, [effect, settingItem, initEffect]);

  useEffect(() => {
    const documentElement = document.querySelector(rootSelector) as HTMLElement;

    if (!documentElement || effect.loading) {
      return;
    }

    if (isSettingOn) {
      documentElement.classList.remove(FS_CLASS_NAME_20, FS_CLASS_NAME_40);

      if (effect.value) {
        documentElement.classList.add(`ac-wd-fs-${effect.value}-effect-style`);
      }
    } else {
      documentElement.classList.remove(FS_CLASS_NAME_20, FS_CLASS_NAME_40);
    }
  }, [effect, isSettingOn]);
};
