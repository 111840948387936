import { ECiteThemeEffect } from '../types/widget';

type ThemeKey = 100 | 200 | 300 | 400;

type TThemeItem = Record<ThemeKey, string>;

type TThemeDictionary = Record<ECiteThemeEffect, TThemeItem>;

export const CITE_ADDITIONAL_THEME_DICTIONARY: TThemeDictionary = {
  [ECiteThemeEffect.WHITE]: {
    100: '#FFFFFF',
    200: '#D7D7D7',
    300: '#8E8E8E',
    400: '#000000',
  },
  [ECiteThemeEffect.BLACK]: {
    100: '#000000',
    200: '#3B3B3B',
    300: '#929292',
    400: '#FFFFFF',
  },
  [ECiteThemeEffect.BLUE]: {
    100: '#B0E0E6',
    200: '#5F9EA0',
    300: '#1C3D5A',
    400: '#00008B',
  },
  [ECiteThemeEffect.BEIGE]: {
    100: '#F5F5DC',
    200: '#C8AD7F',
    300: '#6F4F28',
    400: '#8B4513',
  },
  [ECiteThemeEffect.BROWN]: {
    100: '#654321',
    200: '#3B4829',
    300: '#6B8E23',
    400: '#228B22',
  },
};

export const getThemeVariables: (
  themeDictionary: TThemeDictionary
) => string = (dict) => {
  let result = ':root {';

  (Object.keys(dict) as ECiteThemeEffect[]).forEach((themeKey) => {
    (Object.keys(dict[themeKey]) as unknown as ThemeKey[]).forEach(
      (themeWeight) => {
        result += `--accessibility-theme-${themeKey}-${themeWeight}: var(--accessibility-theme-active, ${dict[themeKey][themeWeight]});`;
      }
    );
  });

  result += '}';

  return result;
};
