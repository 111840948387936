import { WIDGET_SETTINGS_DICTIONARY } from '@kylfyk/accessability-react';
import { WIDGET_SETTINGS_INDEXES } from '@kylfyk/accessability-react';
import { Form, Table } from 'antd';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormItemProps } from 'antd/es/form/FormItem';

import { LocaleParts } from '../../../locale';
import { getWidgetEffectColumns } from '../../tables';
import { TWidgetEffectTableData } from '../../tables';

type TProps = {
  formName: FormItemProps['name'];
};

const EffectsForm: FC<TProps> = memo(({ formName }) => {
  const { t } = useTranslation([LocaleParts.CORE_MODULE]);

  const tableData: TWidgetEffectTableData[] = useMemo(() => {
    return WIDGET_SETTINGS_DICTIONARY.sort((a, b) => {
      return (
        WIDGET_SETTINGS_INDEXES.indexOf(a.key) -
        WIDGET_SETTINGS_INDEXES.indexOf(b.key)
      );
    }).map((el) => ({
      key: el.key,
      name: t(`${el.key}.base`),
      Icon: el.Icon as any,
    }));
  }, [t]);

  const columns = useMemo(() => getWidgetEffectColumns(formName), [formName]);

  return (
    <Form.Item name={formName}>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        size={'small'}
      />
    </Form.Item>
  );
});

export default EffectsForm;
