import styled from '@emotion/styled';
import { FC, Fragment, PropsWithChildren } from 'react';

import { ETextEffectKeys } from '../../effects/names';
import { useEffectSelector } from '../../state/reducers/widget/selectors';

const BrightDegree = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  z-index: 9999999999;
  background-color: black;
  opacity: 0.3;
`;

export const Brightness: FC<PropsWithChildren> = ({ children }) => {
  const effect = useEffectSelector(ETextEffectKeys.BRIGHTNESS);

  return (
    <Fragment>
      {effect.value === -1 && <BrightDegree />}
      {children}
    </Fragment>
  );
};
