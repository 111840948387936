import { createRoot } from 'react-dom/client';

import { AccessWidget } from './access-widget';
import { IS_MODULE } from './constants';
import { checkDateTo } from './utils/check-date-to';

export { WIDGET_SETTINGS_INDEXES } from './utils/widget-settingsd-define-index';
export { EAccessButtonType } from './styles';
export { TPresetItem, TPresetList, WIDGET_PRESETS_LIST } from './presets';
export { ModuleWidget } from './module-widget';
export { default as AccessIcon } from './icons/accessIcon';
export { TWidgetSetting, TWidgetSettings } from './types/widget';
export { WIDGET_SETTINGS_DICTIONARY } from './effects/root';
export { ETextEffectKeys } from './effects/names';
export { TWidgetEffectsSettings } from './types/widget';
export { TBreakPoints, TPlacement, TSettingsPosition } from './styles';

export const WIDGET_NAME = 'access-widget';

export const initWidget = (container?: HTMLElement) => {
  if (!checkDateTo()) {
    return;
  }

  const wrapper = container ?? document.createElement(WIDGET_NAME);

  document.body.prepend(wrapper);

  const widgetRoot = createRoot(wrapper);
  widgetRoot.render(<AccessWidget />);
};

if (
  window !== undefined &&
  !IS_MODULE &&
  !document.querySelector('access-widget') &&
  !window.openAccessibilityWidget
) {
  initWidget();
}
