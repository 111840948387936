import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import React, { FC, memo } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { TWidget } from '../../../api/features/widget/types';
import { WidgetExportScript } from '../../../constants/widget-export-script';
import { QUERY_ACTIVE_WIDGET_NAME } from '../../../containers/pages/documentation-page';
import { TMessageAPI } from '../../../providers/notification-provider';
import { EPaths } from '../../../routes/route-paths';
import { ECSSVars } from '../../../styles';
import { withServerUtcOffset } from '../../../utils/dates';
import { extractDomain } from '../../../utils/regex';
import SettingsIcon from '../../icons/settings-icon';
import SourceCopyInput from '../source-copy-input';
import StatItem, { StatsWrapper } from '../stats/stat-item';
import WidgetStatus from '../widget/widget-status';

export const WidgetPaneWrapper = styled.section<{
  primarycolor: string;
  textcolor: string;
}>`
  border-radius: 12px;
  background-color: ${({ primarycolor }) => primarycolor};
  color: ${({ textcolor }) => textcolor};
  padding: 20px;
  max-width: 500px;

  ${ECSSVars.THEME_ICON_COLOR}:${({ textcolor }) => textcolor};
`;

const WidgetPaneHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
`;

const DomainLink = styled(Link)`
  color: inherit;
  font-size: 1.125rem;
  font-weight: bold;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: inherit;
  }
`;

const SettingsLink = styled(NavLink)`
  color: inherit;
  transition: transform 0.3s ease-out;
  transform-origin: 50% 50%;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover {
    color: inherit;
    transform: rotate(60deg);
  }
`;

const WidgetStatItem = styled(StatItem)`
  color: var(${ECSSVars.THEME_ICON_COLOR});
`;

const WidgetCopySource = styled(SourceCopyInput)`
  color: var(${ECSSVars.THEME_ICON_COLOR});
  background-color: rgba(0, 0, 0, 0.2);
  word-break: break-all;
`;

const HowToInstallLink = styled(NavLink)`
  color: var(${ECSSVars.THEME_ICON_COLOR});
  text-decoration: underline;

  &:hover {
    color: var(${ECSSVars.THEME_ICON_COLOR});
  }
`;

const WidgetFooter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-top: 12px;
`;

type TProps = {
  theme: Theme;
  widget: TWidget;
  messageApi: TMessageAPI;
};

const WidgetPane: FC<TProps> = memo(({ theme, widget, messageApi }) => {
  return (
    <WidgetPaneWrapper
      primarycolor={widget.setting.theme.COLORS.COMMON.ACCENT}
      textcolor={widget.setting.theme.COLORS.COMMON.ON_ACCENT}
      theme={theme}
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary:
                widget.setting.theme.COLORS.MENU.RESET_BUTTON.BACKGROUND,
              colorPrimaryHover:
                widget.setting.theme.COLORS.MENU.RESET_BUTTON
                  .BACKGROUND_HOVERED,
              colorPrimaryActive:
                widget.setting.theme.COLORS.MENU.RESET_BUTTON
                  .BACKGROUND_HOVERED,
              colorText: widget.setting.theme.COLORS.MENU.RESET_BUTTON.TEXT,
            },
          },
        }}
      >
        <WidgetPaneHeader theme={theme}>
          <DomainLink to={widget.domain} target="_blank" theme={theme}>
            {extractDomain(widget.domain)}
          </DomainLink>
          <SettingsLink
            to={`${EPaths.SETTINGS}/${widget.widgetFolder}`}
            title="Перейти в настройки"
            theme={theme}
          >
            <SettingsIcon theme={theme} />
          </SettingsLink>
        </WidgetPaneHeader>
        <StatsWrapper theme={theme}>
          <WidgetStatItem
            value={withServerUtcOffset
              .call(dayjs(widget.updatedAt))
              .format('DD.MM.YY HH:mm')}
            label="Дата обновления"
            theme={theme}
          />
          <WidgetStatItem
            value={withServerUtcOffset
              .call(dayjs(widget.createdAt))
              .format('DD.MM.YY HH:mm')}
            label="Дата создания"
            theme={theme}
          />
        </StatsWrapper>
        {!!(widget.widgetVendorsUrl && widget.widgetUrl) && (
          <WidgetCopySource
            value={WidgetExportScript(
              widget.widgetVendorsUrl,
              widget.widgetUrl
            )}
            messageApi={messageApi}
            textAreaBackgroundColor="transparent"
          />
        )}
        <WidgetFooter>
          <WidgetStatus status={widget.status} theme={theme} />
          <HowToInstallLink
            to={{
              pathname: EPaths.DOCUMENTATION,
              search: `?${QUERY_ACTIVE_WIDGET_NAME}=${widget.widgetFolder}`,
            }}
          >
            Как установить?
          </HowToInstallLink>
        </WidgetFooter>
      </ConfigProvider>
    </WidgetPaneWrapper>
  );
});

export default withTheme(WidgetPane);
