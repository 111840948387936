import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';
import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';

import { AppProvider } from './providers/app-provider';
import { RootRoutes } from './routes/root-routes';

const container = document.getElementById('root');

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.locale('ru');

function App() {
  return (
    <Fragment>
      <AppProvider>
        <RootRoutes />
      </AppProvider>
    </Fragment>
  );
}

if (container) {
  const root = createRoot(container);

  root.render(<App />);
} else {
  throw new Error("Корневой элемент c id='root' не найден");
}
