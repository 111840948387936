import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { addClearStateOnLogoutMatcher } from '../../utils/state-utils';

export type ThemeType = 'light';

interface InitialType {
  currentTheme: ThemeType;
  variants: ThemeType[];
}

const initialState: InitialType = {
  currentTheme: 'light',
  variants: ['light'],
};

const appThemeSlice = createSlice({
  name: 'app/theme',
  initialState,
  reducers: {
    setTheme(state, action: PayloadAction<ThemeType>) {
      state.currentTheme = action.payload;
    },
  },
  extraReducers: (builder) => {
    addClearStateOnLogoutMatcher(builder, initialState);
  },
});

export const appThemeReducer = appThemeSlice.reducer;
