import { Theme, withTheme } from '@emotion/react';
import { Typography } from 'antd';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { EWidgetStatus, TWidget } from '../../api/features/widget/types';
import { DocumentationStepTitle } from '../../components/ui/documentation/text-components';
import WidgetStatus from '../../components/ui/widget/widget-status';
import { NBSP } from '../../constants/formatters';
import { WidgetExportScript } from '../../constants/widget-export-script';
import { EPaths } from '../../routes/route-paths';

type TProps = {
  currentWidget?: TWidget;
  theme: Theme;
};

const CodeCopyDocumentationFragment: FC<TProps> = ({
  currentWidget,
  theme,
}) => {
  return (
    <>
      <DocumentationStepTitle level={4}>
        1. Копирование кода виджета
      </DocumentationStepTitle>
      <Typography.Paragraph>
        Дождитесь когда статус виджета изменится на{NBSP}
        <WidgetStatus status={EWidgetStatus.BUILD_COMPLETE} theme={theme} />
      </Typography.Paragraph>
      <Typography.Paragraph>
        Скопируйте код{NBSP}виджета на{NBSP}
        <NavLink to={EPaths.HOME}>главной</NavLink> странице
        {currentWidget ? `, на${NBSP}этой странице или${NBSP}` : ` или${NBSP}`}
        на странице{' '}
        <NavLink
          to={
            currentWidget
              ? `${EPaths.SETTINGS}/${currentWidget.widgetFolder}`
              : EPaths.SETTINGS
          }
        >
          настроек
        </NavLink>{' '}
        необходимого домена.
      </Typography.Paragraph>
      {currentWidget &&
        !!currentWidget.widgetVendorsUrl &&
        !!currentWidget.widgetUrl && (
          <SyntaxHighlighter language="htmlbars" style={coy} showLineNumbers>
            {WidgetExportScript(
              currentWidget.widgetVendorsUrl,
              currentWidget.widgetUrl
            )}
          </SyntaxHighlighter>
        )}
    </>
  );
};

export default withTheme(CodeCopyDocumentationFragment);
