import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';

import { ECSSVars } from '../../../styles';

type TProps = {
  theme: Theme;
  color: string;
} & HTMLAttributes<HTMLDivElement>;

const Wrapper = styled.div<{
  color: string;
}>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid var(${ECSSVars.THEME_RADIO_BORDER_COLOR});
  box-shadow: 0 0 6px var(${ECSSVars.THEME_RADIO_BOX_SHADOW});
  transition:
    border-color 0.3s ease-out,
    box-shadow 0.3s ease-out;
  background-color: ${({ color }) => color};
`;

const ThemePickerButton: FC<TProps> = ({ theme, color, ...props }) => {
  return <Wrapper theme={theme} color={color} {...props} />;
};

export default withTheme(ThemePickerButton);
