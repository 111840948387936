import { useEffect } from 'react';

import ContrastIcon from '../../icons/contrastIcon';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const contrastEffect: TSettingItem = {
  Icon: ContrastIcon,
  key: ETextEffectKeys.CONTRAST,
};

export const useContrastEffect = () => {
  const effect = useEffectSelector(ETextEffectKeys.CONTRAST);
  const container = document.querySelector('html') as HTMLElement;
  const currentFilters = ['contrast(1.3)', 'contrast(0.7)'];

  useEffect(() => {
    let oldFilters = window.getComputedStyle(container)?.filter ?? '';
    oldFilters = oldFilters
      .split(' ')
      .filter((el) => el !== 'none' && currentFilters.indexOf(el) === -1)
      .join(' ');

    if (effect.value === 1) {
      container.style.filter = `${oldFilters} ${currentFilters[0]}`;
    } else if (effect.value === -1) {
      container.style.filter = `${oldFilters} ${currentFilters[1]}`;
    } else {
      container.style.filter = oldFilters;
    }
  }, [effect]);
};
