import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import App from 'antd/lib/app';
import dayjs from 'dayjs';
import React, {
  FC,
  Fragment,
  StrictMode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { WidgetButtonPowered } from './components-powered/widget-button-powered';
import { WidgetMenuPowered } from './components-powered/widget-menu-powered';
import { useStatisticWriter } from './hooks/useStatisticWriter';
import './index.scss';
import { EffectsService } from './services/effects-service';
import { FocusService, useFocusContext } from './services/focus-service';
import { ThemeService } from './services/theme-service';
import { persistedStore, store, useAppDispatch } from './state';
import { useWidgetSelector } from './state/reducers/widget/selectors';
import { setWidgetOpenAction } from './state/reducers/widget/slices/mainStateSlice';
import { TStatEvent } from './types/statistic';
import { IS_SESSION_ACTIVE_KEY } from './utils/web';

declare global {
  interface Window {
    openAccessibilityWidget: () => void;
    closeAccessibilityWidget: () => void;
  }
}

const appCache = createCache({
  key: 'accessibility-widget',
  prepend: true,
  speedy: false,
});

const exceptTags = ['INPUT', 'TEXTAREA'];

const Widget: FC = () => {
  const dispatch = useAppDispatch();
  const deleteTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { opened } = useWidgetSelector();
  const menuMountedRef = useRef(false);
  const [mountMenu, setMountMenu] = useState(false);
  const [handleWriteStatistic] = useStatisticWriter();

  const { setFocusElement, focusAtLastElement } = useFocusContext();

  useEffect(() => {
    const value = sessionStorage.getItem(IS_SESSION_ACTIVE_KEY);

    if (!value) {
      sessionStorage.setItem(IS_SESSION_ACTIVE_KEY, 'true');
      void handleWriteStatistic({
        event: TStatEvent.WIDGET_LOADED,
        text: 'Виджет загружен впервые за сессию',
        time: dayjs().toISOString(),
      });
    }
  }, []);

  const handleClick = useCallback(
    (isKbd?: boolean) => {
      if (opened) {
        return;
      }

      if (deleteTimeoutRef.current) {
        clearTimeout(deleteTimeoutRef.current);
      }

      setMountMenu(true);
      menuMountedRef.current = true;

      setTimeout(() => {
        dispatch(setWidgetOpenAction(true));
        void handleWriteStatistic({
          event: isKbd ? TStatEvent.OPEN_WIDGET_KBD : TStatEvent.OPEN_WIDGET,
          text: isKbd ? 'Виджет открыт (клавиатура)' : 'Виджет открыт',
          time: dayjs().toISOString(),
        });
      }, 1);
    },
    [opened]
  );

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (!opened) {
        const target = e.target as HTMLElement;

        if (e.code === 'KeyA' && !exceptTags.includes(target.tagName)) {
          handleClick(true);
          if (document.activeElement) {
            setFocusElement(document.activeElement as HTMLElement);
          }
        }
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [opened]);

  useLayoutEffect(() => {
    if (!opened && menuMountedRef.current) {
      if (deleteTimeoutRef.current) {
        clearTimeout(deleteTimeoutRef.current);
      }

      deleteTimeoutRef.current = setTimeout(() => {
        menuMountedRef.current = false;
        setMountMenu(false);
      }, 700);
    }
  }, [opened]);

  const handleOpen = useCallback(() => {
    if (document.activeElement) {
      setFocusElement(document.activeElement as HTMLElement);
    }
    handleClick();
  }, [handleClick]);

  const handleCloseWidget = useCallback(() => {
    if (opened) {
      dispatch(setWidgetOpenAction(false));
      void handleWriteStatistic({
        event: TStatEvent.CLOSE_WIDGET,
        text: 'Виджет закрыт',
        time: dayjs().toISOString(),
      });

      focusAtLastElement();
    }
  }, [opened]);

  useEffect(() => {
    window.openAccessibilityWidget = () => {
      handleOpen();
    };

    window.closeAccessibilityWidget = () => {
      handleCloseWidget();
    };
  }, [handleOpen, handleCloseWidget]);

  return (
    <Fragment>
      <WidgetButtonPowered opened={opened} handleOpen={handleOpen} />
      {mountMenu && <WidgetMenuPowered />}
    </Fragment>
  );
};

export const AccessWidget: FC = () => {
  return (
    <StrictMode>
      <CacheProvider value={appCache}>
        <Provider store={store}>
          <PersistGate persistor={persistedStore} loading={null}>
            <App>
              <ThemeService>
                <EffectsService>
                  <FocusService>
                    <Widget />
                  </FocusService>
                </EffectsService>
              </ThemeService>
            </App>
          </PersistGate>
        </Provider>
      </CacheProvider>
    </StrictMode>
  );
};
