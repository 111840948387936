import { EWidgetStatus } from '../../api/features/widget/types';

export const ColorByStatus: Record<EWidgetStatus, string> = {
  [EWidgetStatus.DRAFT]: '#EBB834',
  [EWidgetStatus.INACTIVE]: '#c5c5c9',
  [EWidgetStatus.NEED_BUILD]: '#ea7f15',
  [EWidgetStatus.ACTIVE]: '#27ec0a',
  [EWidgetStatus.BUILD_IN_PROGRESS]: '#0a73ec',
  [EWidgetStatus.BUILD_COMPLETE]: '#27ec0a',
};

export const WidgetStatusTexts: Record<EWidgetStatus, string> = {
  [EWidgetStatus.DRAFT]: 'Черновик',
  [EWidgetStatus.INACTIVE]: 'Не активен',
  [EWidgetStatus.NEED_BUILD]: 'Ожидается обновление',
  [EWidgetStatus.ACTIVE]: 'Активен',
  [EWidgetStatus.BUILD_IN_PROGRESS]: 'Виджет обновляется',
  [EWidgetStatus.BUILD_COMPLETE]: 'Обновление завершено',
};
