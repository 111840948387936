import { Theme, withTheme } from '@emotion/react';
import { FC, useCallback, useEffect, useState } from 'react';

import { TSettingValue } from '../../types/widget';
import { toTextBoolean } from '../../utils/common';
import { isMobile } from '../../utils/web';
import CommonSwitch from './common';
import MarkArea from './mark-area';
import { SwitchMarker } from './switch-marker';

type TProps = {
  theme: Theme;
  value: TSettingValue;
  onChange?: (value: TSettingValue) => void;
  widgetIncreased?: boolean;
};

const SimpleSwitch: FC<TProps> = ({
  theme,
  value,
  onChange,
  widgetIncreased,
}) => {
  const [leftPosition, setLeftPosition] = useState(3);

  const handleChange = useCallback(() => {
    if (onChange) {
      onChange(!value);
    }
  }, [value]);

  useEffect(() => {
    const mobile = isMobile();

    if (mobile || document.documentElement.clientWidth <= 520) {
      if (value) {
        setLeftPosition(widgetIncreased ? 55 : 31);
      } else {
        setLeftPosition(widgetIncreased ? 5 : 3);
      }
    } else {
      if (value) {
        setLeftPosition(widgetIncreased ? 66 : 31);
      } else {
        setLeftPosition(widgetIncreased ? 6 : 3);
      }
    }
  }, [value, widgetIncreased]);

  return (
    <CommonSwitch
      theme={theme}
      onClick={handleChange}
      active={value as boolean}
      widgetIncreased={toTextBoolean(widgetIncreased)}
    >
      <SwitchMarker
        left={leftPosition}
        theme={theme}
        widgetIncreased={toTextBoolean(widgetIncreased)}
      />
      <MarkArea
        theme={theme}
        widgetIncreased={toTextBoolean(widgetIncreased)}
      />
      <MarkArea
        theme={theme}
        widgetIncreased={toTextBoolean(widgetIncreased)}
      />
    </CommonSwitch>
  );
};

export default withTheme(SimpleSwitch);
