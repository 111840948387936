import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const TextToSpeechIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_106_26)">
        <path
          d="M19.0728 3.933C18.9876 3.84783 18.8865 3.78028 18.7752 3.73418C18.664 3.68809 18.5447 3.66437 18.4243 3.66437C18.3038 3.66437 18.1845 3.68809 18.0733 3.73418C17.962 3.78028 17.8609 3.84783 17.7757 3.933C17.6905 4.01817 17.623 4.11928 17.5769 4.23056C17.5308 4.34183 17.5071 4.4611 17.5071 4.58154C17.5071 4.70199 17.5308 4.82126 17.5769 4.93253C17.623 5.04381 17.6905 5.14492 17.7757 5.23009C19.3038 6.76175 20.1619 8.83696 20.1619 11.0005C20.1619 13.164 19.3038 15.2393 17.7757 16.7709C17.6037 16.9429 17.5071 17.1762 17.5071 17.4195C17.5071 17.6627 17.6037 17.896 17.7757 18.068C17.9477 18.24 18.181 18.3366 18.4243 18.3366C18.6675 18.3366 18.9008 18.24 19.0728 18.068C20.9444 16.1921 21.9955 13.6504 21.9955 11.0005C21.9955 8.3506 20.9444 5.8089 19.0728 3.933V3.933Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M16.5919 6.68322C16.5067 6.59775 16.4055 6.5299 16.2941 6.48352C16.1827 6.43715 16.0633 6.41318 15.9426 6.41297C15.822 6.41275 15.7025 6.43631 15.5909 6.48228C15.4793 6.52826 15.3779 6.59576 15.2925 6.68093C15.207 6.7661 15.1391 6.86726 15.0928 6.97866C15.0464 7.09005 15.0224 7.20948 15.0222 7.33014C15.022 7.4508 15.0456 7.57032 15.0915 7.68187C15.1375 7.79343 15.205 7.89483 15.2902 7.9803C16.0902 8.78155 16.5395 9.86753 16.5395 10.9998C16.5395 12.1321 16.0902 13.218 15.2902 14.0193C15.205 14.1048 15.1375 14.2062 15.0915 14.3177C15.0456 14.4293 15.022 14.5488 15.0222 14.6695C15.0224 14.7901 15.0464 14.9095 15.0928 15.0209C15.1391 15.1323 15.207 15.2335 15.2925 15.3187C15.4651 15.4907 15.699 15.5871 15.9426 15.5866C16.0633 15.5864 16.1827 15.5624 16.2941 15.5161C16.4055 15.4697 16.5067 15.4018 16.5919 15.3164C17.7352 14.1708 18.3774 12.6183 18.3774 10.9998C18.3774 9.38125 17.7352 7.82882 16.5919 6.68322V6.68322Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M12.6683 0.183537C9.88103 0.707199 7.40179 2.28257 5.74383 4.58354H4.58333C3.36835 4.58548 2.20369 5.06898 1.34457 5.92811C0.485448 6.78723 0.00193932 7.95189 0 9.16687L0 12.8335C0.00193932 14.0485 0.485448 15.2132 1.34457 16.0723C2.20369 16.9314 3.36835 17.4149 4.58333 17.4169H5.74383C7.40212 19.7175 9.8812 21.2928 12.6683 21.8169C12.723 21.8275 12.7786 21.8328 12.8343 21.8325C13.0774 21.8325 13.3105 21.7359 13.4824 21.564C13.6543 21.3921 13.7509 21.1589 13.7509 20.9158V1.08737C13.7513 0.952743 13.7219 0.819694 13.665 0.697679C13.6081 0.575664 13.5251 0.467678 13.4217 0.381397C13.3184 0.295116 13.1973 0.232658 13.0671 0.198461C12.9369 0.164264 12.8007 0.159169 12.6683 0.183537V0.183537ZM11.9167 19.7406C9.89485 19.0961 8.15639 17.7755 6.99325 16.0006C6.91014 15.8728 6.79646 15.7677 6.66248 15.6948C6.52851 15.622 6.37849 15.5838 6.226 15.5835H4.58333C3.85399 15.5835 3.15451 15.2938 2.63879 14.7781C2.12306 14.2624 1.83333 13.5629 1.83333 12.8335V9.16687C1.83333 8.43752 2.12306 7.73805 2.63879 7.22233C3.15451 6.7066 3.85399 6.41687 4.58333 6.41687H6.23333C6.38565 6.41685 6.53557 6.37887 6.66953 6.30637C6.80348 6.23387 6.91726 6.12913 7.00058 6.00162C8.16135 4.22717 9.89714 2.90599 11.9167 2.25979V19.7406Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
      </g>
      <defs>
        <clipPath id="clip0_106_26">
          <rect
            width="22"
            height="22"
            fill={theme.COLORS.MENU.MENU_ITEM.BACKGROUND}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(TextToSpeechIcon);
