import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const BlindProfile: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_339_10788)">
        <path
          d="M2.33333 12.8333H15.1667C15.7855 12.8333 16.379 12.5875 16.8166 12.1499C17.2542 11.7123 17.5 11.1188 17.5 10.5V2.33333C17.5 1.71449 17.2542 1.121 16.8166 0.683417C16.379 0.245833 15.7855 0 15.1667 0L2.33333 0C1.71449 0 1.121 0.245833 0.683417 0.683417C0.245833 1.121 0 1.71449 0 2.33333L0 10.5C0 11.1188 0.245833 11.7123 0.683417 12.1499C1.121 12.5875 1.71449 12.8333 2.33333 12.8333ZM2.33333 2.33333H15.1667V10.5H2.33333V2.33333Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M25.6668 0H22.1668C21.548 0 20.9545 0.245833 20.5169 0.683417C20.0793 1.121 19.8335 1.71449 19.8335 2.33333V10.5C19.8335 11.1188 20.0793 11.7123 20.5169 12.1499C20.9545 12.5875 21.548 12.8333 22.1668 12.8333H25.6668C26.2857 12.8333 26.8792 12.5875 27.3167 12.1499C27.7543 11.7123 28.0002 11.1188 28.0002 10.5V2.33333C28.0002 1.71449 27.7543 1.121 27.3167 0.683417C26.8792 0.245833 26.2857 0 25.6668 0V0ZM25.6668 10.5H22.1668V2.33333H25.6668V10.5Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M5.83333 15.1671H2.33333C1.71449 15.1671 1.121 15.4129 0.683418 15.8505C0.245833 16.2881 0 16.8815 0 17.5004L0 25.667C0 26.2859 0.245833 26.8794 0.683418 27.317C1.121 27.7545 1.71449 28.0004 2.33333 28.0004H5.83333C6.45217 28.0004 7.04566 27.7545 7.48325 27.317C7.92083 26.8794 8.16667 26.2859 8.16667 25.667V17.5004C8.16667 16.8815 7.92083 16.2881 7.48325 15.8505C7.04566 15.4129 6.45217 15.1671 5.83333 15.1671ZM5.83333 25.667H2.33333V17.5004H5.83333V25.667Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
        <path
          d="M25.6667 15.1671H12.8333C12.2145 15.1671 11.621 15.4129 11.1834 15.8505C10.7458 16.2881 10.5 16.8815 10.5 17.5004V25.667C10.5 26.2859 10.7458 26.8794 11.1834 27.317C11.621 27.7545 12.2145 28.0004 12.8333 28.0004H25.6667C26.2855 28.0004 26.879 27.7545 27.3166 27.317C27.7542 26.8794 28 26.2859 28 25.667V17.5004C28 16.8815 27.7542 16.2881 27.3166 15.8505C26.879 15.4129 26.2855 15.1671 25.6667 15.1671ZM25.6667 25.667H12.8333V17.5004H25.6667V25.667Z"
          fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
        />
      </g>
      <defs>
        <clipPath id="clip0_339_10788">
          <rect width="28" height="28" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(BlindProfile);
