import dayjs from 'dayjs';

import { ColumnsType } from 'antd/lib/table';

import { EUserRole, EUserStatus } from '../../../api/features/auth/types';
import { sortByCreateDateFn } from '../../../utils/common-js';
import { userRoleText } from '../../../utils/user/role';
import { UserTextByStatus } from '../../../utils/user/status';
import { TUserTableData } from '../types/users-table-types';

export const getUsersTableColumns: () => ColumnsType<TUserTableData> = () => [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Роль',
    dataIndex: 'role',
    key: 'role',
    render: (role: EUserRole) => {
      return userRoleText[role];
    },
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: (status: EUserStatus) => {
      return UserTextByStatus[status];
    },
  },
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: sortByCreateDateFn,
    defaultSortOrder: 'ascend',
    render: (date: string) => {
      return dayjs(date).format('DD.MM.YYYY HH:mm');
    },
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (date: string) => {
      return dayjs(date).format('DD.MM.YYYY HH:mm');
    },
  },
];
