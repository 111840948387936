import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, HTMLAttributes } from 'react';

import {
  TTextBoolean,
  textToBoolean,
  toTextBoolean,
} from '../../utils/ui/common';

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 30px 0 20px 0;
`;

const Title = styled.h2<{
  danger: TTextBoolean;
}>`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 1.5rem;
  color: ${({ theme, danger }) =>
    textToBoolean(danger) ? theme.MAIN.DANGER.C200 : theme.MAIN.TEXT.C100};
`;

const LineMark = styled.div`
  height: 1px;
  flex: 1;
  background-color: ${({ theme }) => theme.MAIN.WHITE.C300};
`;

type TProps = HTMLAttributes<HTMLDivElement> & {
  title: string;
  theme: Theme;
  danger?: boolean;
};

const SectionTitle: FC<TProps> = ({ title, theme, danger, ...props }) => {
  return (
    <Wrapper {...props} theme={theme}>
      <Title theme={theme} danger={toTextBoolean(danger)}>
        {title}
      </Title>
      <LineMark theme={theme} />
    </Wrapper>
  );
};

export default withTheme(SectionTitle);
