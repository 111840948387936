import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { getIconBaseColor } from '../../utils/ui/icons';
import { TBaseIconProps } from './index';

const ArrowDownSmIcon: FC<TBaseIconProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="10"
      height="4"
      viewBox="0 0 10 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 0.5L5 3.5L9 0.5"
        stroke={getIconBaseColor(theme.MAIN.TEXT.C200)}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default withTheme(ArrowDownSmIcon);
