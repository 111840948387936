import { OBSERVER_IGNORE_LIST } from '../init-functions/prepare-font-size';

function resolveCSSValue(
  cssProperty: string,
  cssValue: string,
  element: HTMLElement
): string | null {
  const tempElement = document.createElement(element.tagName);
  tempElement.style.cssText = `${cssProperty}: ${cssValue};`;
  element.appendChild(tempElement);

  // Получаем скомпилированное значение
  const computedValue =
    getComputedStyle(tempElement).getPropertyValue(cssProperty);

  // Удаляем временный элемент из DOM
  element.removeChild(tempElement);

  return computedValue.trim() || null;
}

function getComputedColor(
  element: HTMLElement,
  property: string
): string | undefined {
  let existThemeItem = false;
  if (
    element
      .getAttributeNames()
      .some((name) => name.includes(`accessibility-access-theme-${property}`))
  ) {
    existThemeItem = true;
  }

  if (existThemeItem) {
    let elementCss = extractCssValues(css(element), property);

    const lastValue = elementCss[0];

    if (lastValue) {
      const result = resolveCSSValue(property, lastValue, element);

      if (result) {
        const colorKey = getCiteColorRate(result);

        element.setAttribute(
          `accessibility-access-theme-${property}-${colorKey}`,
          ''
        );

        return result;
      } else {
        return window
          .getComputedStyle(element)
          .getPropertyValue(property)
          .trim();
      }
    } else {
      return window.getComputedStyle(element).getPropertyValue(property).trim();
    }
  } else {
    return window.getComputedStyle(element).getPropertyValue(property).trim();
  }
}

function parseColorToRgb(color: string): [number, number, number] | null {
  let match;
  if ((match = color.match(/^#([0-9a-f]{3}){1,2}$/i))) {
    const hex = match[0].slice(1);
    const normalizedHex =
      hex.length === 3
        ? hex
            .split('')
            .map((c) => c + c)
            .join('')
        : hex;
    const num = parseInt(normalizedHex, 16);
    return [(num >> 16) & 255, (num >> 8) & 255, num & 255];
  } else if (
    (match = color.match(/^rgb(a)?\((\d+),\s*(\d+),\s*(\d+)(,\s*[\d.]+)?\)$/i))
  ) {
    return [+match[2], +match[3], +match[4]];
  } else if (
    (match = color.match(
      /^hsl(a)?\((\d+),\s*([\d.]+)%,\s*([\d.]+)%(,\s*[\d.]+)?\)$/i
    ))
  ) {
    const h = +match[2] / 360,
      s = +match[3] / 100,
      l = +match[4] / 100;
    const k = (n: number) => (n + h * 12) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n: number) =>
      Math.round(
        255 * (l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1))))
      );
    return [f(0), f(8), f(4)];
  }
  return null;
}

function luminance([r, g, b]: [number, number, number]): number {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}

function calculateColorLevel(luminance: number): 100 | 200 | 300 | 400 {
  if (luminance >= 0.75) {
    return 100;
  } else if (luminance >= 0.5) {
    return 200;
  } else if (luminance >= 0.25) {
    return 300;
  } else {
    return 400;
  }
}

function getCiteColorRate(color: string): number | null {
  const rgb = parseColorToRgb(color);

  if (rgb) {
    return calculateColorLevel(luminance(rgb));
  }

  return null;
}

function updateElementAttributes(
  element: HTMLElement,
  property: string,
  color: string | 'transparent'
) {
  const colorRate = color === 'transparent' ? 0 : getCiteColorRate(color);
  if (colorRate !== null) {
    const attrName = `accessibility-access-theme-${property}-${colorRate}`;

    for (const keyToRemove of [0, 100, 200, 300, 400].filter(
      (el) => el !== colorRate
    )) {
      const attrToRemove = `accessibility-access-theme-${property}-${keyToRemove}`;
      if (element.hasAttribute?.(attrToRemove)) {
        element.removeAttribute(attrToRemove);
      }
    }

    element.setAttribute(attrName, '');
  }
}

export const citeThemeColorProperties = [
  'color',
  'background-color',
  'background-image',
  'border-color',
  // Добавьте другие цветовые свойства, если необходимо
];

export function traverseDOMAccessTheme(
  element: HTMLElement,
  parentColors?: { [key: string]: string }
) {
  if (OBSERVER_IGNORE_LIST.includes(element.nodeName)) {
    return;
  }

  let elementColors: { [key: string]: string } = {};

  for (const property of citeThemeColorProperties) {
    const computedColor = getComputedColor(element, property);

    if (computedColor && computedColor !== 'none') {
      if (computedColor === 'rgba(0, 0, 0, 0)') {
        elementColors[property] = 'transparent';
      } else {
        elementColors[property] = computedColor;
      }
    }
  }

  for (const child of Array.from(element.children) as HTMLElement[]) {
    traverseDOMAccessTheme(child, elementColors);
  }

  Object.keys(elementColors).forEach((key) => {
    updateElementAttributes(element, key, elementColors[key]);
    if ('matches' in element && element.matches(':hover')) {
      element.setAttribute('accessibility-access-theme-hoverable', '');
    } else if ('removeAttribute' in element) {
      element.removeAttribute('accessibility-access-theme-hoverable');
    }
  });
}

type TObserveColorThemeFnProps = {
  ignoreContainer: Element | null;
};

function extractCssValues(cssRules: string[], property: string) {
  // Создаем регулярное выражение для поиска значения указанного CSS свойства
  const regex = new RegExp(`(?:^|;|\\s)${property}\\s*:\\s*([^;]+)`, 'gi');
  const values: string[] = [];

  cssRules.forEach((rule) => {
    let match;
    while ((match = regex.exec(rule)) !== null) {
      // Добавляем найденное значение в массив
      if (
        !match[1].includes('accessibility-theme-') &&
        !match[1].includes('transparent') &&
        !match[1].includes('inherit')
      ) {
        values.push(match[1].trim());
      }
    }
  });

  return values;
}

function css(a: HTMLElement) {
  const sheets = document.styleSheets,
    o = [];
  a.matches =
    a.matches ||
    // @ts-ignore
    a.webkitMatchesSelector ||
    // @ts-ignore
    a.mozMatchesSelector ||
    // @ts-ignore
    a.msMatchesSelector ||
    // @ts-ignore
    a.oMatchesSelector;
  for (const i in sheets) {
    try {
      const rules = sheets[i].rules || sheets[i].cssRules;
      for (const r in rules) {
        // @ts-ignore
        if (a.matches(rules[r].selectorText)) {
          o.push(rules[r].cssText);
        }
      }
    } catch (e) {}
  }
  return o;
}

export const getObserveColorThemeFn: (
  props: TObserveColorThemeFnProps
) => MutationCallback =
  ({ ignoreContainer }) =>
  (mutationsList) => {
    for (const el of mutationsList) {
      if (ignoreContainer?.contains(el.target)) {
        return;
      }
      // Произошла смена класса -> возможное изменение цвета
      if (
        el.attributeName === 'class' ||
        el.attributeName === 'disabled' ||
        el.attributeName === 'style'
      ) {
        traverseDOMAccessTheme(el.target as HTMLElement);
      } else if (
        // Добавлены ноды -> обновление цвета
        !el.attributeName &&
        el.addedNodes.length > 0
      ) {
        for (const node in el.addedNodes) {
          if (typeof node === 'object' && 'attributeName' in node) {
            traverseDOMAccessTheme(node as HTMLElement);
          }
        }
      }
    }
  };

export const updateObserverRecords = (
  record: MutationRecord,
  widgetContainer?: Element | null
) => {
  if (widgetContainer?.contains?.(record.target)) {
    return;
  }
  if (
    record.attributeName === 'class' ||
    record.attributeName === 'disabled' ||
    record.attributeName === 'style'
  ) {
    traverseDOMAccessTheme(record.target as HTMLElement);
  } else if (
    !record.attributeName &&
    record.addedNodes.length > 0 &&
    !widgetContainer?.contains?.(record.target)
  ) {
    for (const node in record.addedNodes) {
      if (typeof node === 'object' && 'attributeName' in node) {
        traverseDOMAccessTheme(node as HTMLElement);
      }
    }
  }
};
