import { Theme, withTheme } from '@emotion/react';
import { Image, Typography } from 'antd';
import React, { FC } from 'react';

import { TWidget } from '../../api/features/widget/types';
import { DocumentationStepTitle } from '../../components/ui/documentation/text-components';
import { NBSP } from '../../constants/formatters';
import tildaHtmlGuideImage from '../../static/images/tilda-html-block-guide.png';
import CodeCopyDocumentationFragment from './code-copy-documentation-fragment';

type TProps = {
  currentWidget?: TWidget;
  theme: Theme;
};

const TildaDoc: FC<TProps> = ({ currentWidget, theme }) => {
  return (
    <div>
      <Typography.Title level={3}>Установка виджета на Tilda</Typography.Title>
      <CodeCopyDocumentationFragment
        currentWidget={currentWidget}
        theme={theme}
      />
      <DocumentationStepTitle level={4}>
        2. Создайте новый блок на необходимой странице
      </DocumentationStepTitle>
      <Typography.Paragraph>
        Убедитесь что ваш тариф соответствует уровню Personal или выше
      </Typography.Paragraph>
      <Typography.Paragraph>
        В{NBSP}библиотеке блоков выберите блок с{NBSP}названием "HTML-код" и
        {NBSP}добавьте его{NBSP}в{NBSP}конец страницы. (Другое &gt; HTML-код)
      </Typography.Paragraph>
      <Image
        preview={false}
        src={tildaHtmlGuideImage}
        alt={`Скриншот библиотеки блоков в Tilda с найденным блоком "HTML-код"`}
      />
      <DocumentationStepTitle level={4}>
        3. Редактирование контента HTML-кода
      </DocumentationStepTitle>
      <Typography.Paragraph>
        После добавления блока вам необходимо нажать кнопку "Контент" чтобы
        изменить содержимое блока. Вставьте в блок скопированный код виджета.
      </Typography.Paragraph>
      <DocumentationStepTitle level={4}>
        4. Сохранение и публикация
      </DocumentationStepTitle>
      <Typography.Paragraph>
        После добавления кода нажмите кнопку "Сохранить" и "Опубликовать"
      </Typography.Paragraph>
    </div>
  );
};

export default withTheme(TildaDoc);
