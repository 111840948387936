import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Dispatch, FC, SetStateAction, memo, useCallback } from 'react';

import { ECSSVars } from '../../styles';
import {
  TTextBoolean,
  textToBoolean,
  toTextBoolean,
} from '../../utils/ui/common';
import ArrowDownSmIcon from '../icons/arrow-down-sm-icon';

type TProps = {
  theme: Theme;
  label: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin: 12px 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.MAIN.TEXT.C200};
  border-radius: 4px;
  transition: background-color 0.3s ease-out;

  ${ECSSVars.THEME_ICON_COLOR}: ${({ theme }) => theme.MAIN.TEXT.C200};

  :hover {
    background-color: ${({ theme }) => theme.MAIN.WHITE.C200};
  }
`;

const Label = styled.span`
  font-size: 1rem;
  margin-right: 4px;
  white-space: nowrap;
`;

const IconWrapper = styled.div<{
  opened: TTextBoolean;
}>`
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scaleY(${({ opened }) => (textToBoolean(opened) ? '1' : '-1')});
  transition: transform 0.3s ease-out;
`;

const AccordionTrigger: FC<TProps> = memo(({ theme, label, setOpen, open }) => {
  const handleSwitch = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <Wrapper
      theme={theme}
      aria-label={label}
      aria-selected={open}
      onClick={handleSwitch}
      type="button"
    >
      <Label theme={theme}>{label}</Label>
      <IconWrapper opened={toTextBoolean(open)}>
        <ArrowDownSmIcon theme={theme} />
      </IconWrapper>
    </Wrapper>
  );
});

export default withTheme(AccordionTrigger);
