import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';

import { textToBoolean, toTextBoolean } from '../../utils/common';
import SimpleSwitch from '../switch/simple-switch';

export const SettingItemWrapper = styled('button')<{
  widgetIncreased?: 'true' | 'false';
}>`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.BORDER_RADIUS}px;

  background-color: ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.BACKGROUND};

  padding: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? '16px 20px' : '12px 16px'};
  gap: ${({ widgetIncreased }) => (textToBoolean(widgetIncreased) ? 22 : 16)}px;
  user-select: none;
  border: none;
  outline-color: ${({ theme }) => theme.COLORS.COMMON.ACCENT};

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;

export const SettingItemUnButtoned = SettingItemWrapper.withComponent('div');

export const SettingItemText = styled('div')<{
  widgetIncreased?: 'true' | 'false';
}>`
  flex: 1;
  color: ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.TEXT};
  font-size: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 36 : 18}px;
  font-family: ${({ theme }) => theme.SETTINGS.COMMON.FONT};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: center;

  @media only screen and (max-width: 520px) {
    font-size: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 26 : 18}px;
  }
`;

type TProps = {
  title: string;
  value: boolean;
  onClick?: () => void;
  widgetIncreased?: boolean;
  tabIndex?: number;
  theme?: Theme;
};

export const SettingItem: FC<TProps> = ({
  title,
  value,
  onClick,
  widgetIncreased,
  tabIndex,
  theme,
}) => {
  return (
    <SettingItemWrapper
      onClick={onClick}
      widgetIncreased={toTextBoolean(widgetIncreased)}
      tabIndex={tabIndex}
      theme={theme}
    >
      <SettingItemText
        widgetIncreased={toTextBoolean(widgetIncreased)}
        theme={theme}
      >
        {title}
      </SettingItemText>
      <SimpleSwitch
        value={value}
        widgetIncreased={widgetIncreased}
        theme={theme}
      />
    </SettingItemWrapper>
  );
};
