import { WIDGET_NAME } from '../index';

export const OBSERVER_IGNORE_LIST = [
  'ACCESS-WIDGET',
  'IFRAME',
  'SCRIPT',
  'STYLE',
  'IMG',
  'HEAD',
];

async function changeFontSize(
  element: HTMLElement,
  prevFontStyle?: string | null,
  baseFontSize: number = 16
) {
  if (element.tagName === 'svg') {
    if (prevFontStyle) {
      element.style.fontSize = prevFontStyle;
    }
    return Promise.resolve();
  }

  // В код могут попасть элементы не являющиеся DOM-элементами
  if (
    !element ||
    !('style' in element) ||
    OBSERVER_IGNORE_LIST.includes(element.nodeName)
  ) {
    return Promise.resolve();
  }

  const computedStyle = window.getComputedStyle(element, null);

  const originalFontSize = computedStyle.getPropertyValue('font-size');
  const originalLineHeight = computedStyle.getPropertyValue('line-height');

  if (originalFontSize) {
    for (let i = 0; i < element.children.length; i++) {
      await new Promise<void>((resolve) => {
        changeFontSize(
          element.children[i] as HTMLElement,
          originalFontSize,
          baseFontSize
        ).then(() => {
          resolve();
        });
      });
    }

    if (originalFontSize.includes('px')) {
      const size = parseFloat(originalFontSize.replace('px', ''));

      element.style.fontSize = size / baseFontSize + 'rem';
      element.dataset.accessibilityReady = 'true';

      if (originalLineHeight !== 'normal') {
        const lineHeight = Math.round(
          (parseFloat(originalLineHeight.replace('px', '')) / size) * 100
        );

        element.style.lineHeight = lineHeight + '%';
      }
    }
  }

  return Promise.resolve();
}

export const prepareFontSize = async (baseElementSelector: string) => {
  let fontSizeBase = 16;

  try {
    const baseHtmlElement = document.querySelector(baseElementSelector);

    if (baseHtmlElement) {
      const baseFontSize = getComputedStyle(baseHtmlElement).fontSize;

      fontSizeBase = Number(baseFontSize.match(/[+-]?\d+(\.\d+)?/g)?.[0] ?? 16);
    }

    await new Promise<void>((res) => {
      requestAnimationFrame(() => {
        changeFontSize(document.body, null, fontSizeBase).then(() => {
          res();
        });
      });
    });
  } catch (e) {
    console.error(e);
  }

  try {
    const widgetContainer = document.querySelector(WIDGET_NAME);

    const handleAnalyzeMutate: MutationCallback = async (mutationsList) => {
      if (widgetContainer) {
        for (const el of mutationsList) {
          if (
            el?.target?.nodeName &&
            !OBSERVER_IGNORE_LIST.includes(el.target.nodeName) &&
            el?.addedNodes?.length > 0 &&
            !widgetContainer.contains(el.target)
          ) {
            for (const node of el.addedNodes as unknown as HTMLElement[]) {
              if (node?.dataset?.accessibilityReady !== 'true') {
                await new Promise<void>((res) => {
                  changeFontSize(node, null, fontSizeBase).then(() => {
                    res();
                  });
                });
              }
            }
          }
        }
      }
    };

    const observer = new MutationObserver(handleAnalyzeMutate);
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
    };
    observer.observe(document.body, config);
  } catch (e) {
    console.error(e);
  }
};
