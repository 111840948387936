import { FileSearchOutlined } from '@ant-design/icons';
import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Popover, Space } from 'antd';
import { TFunction } from 'i18next';
import { ComponentType, FC, memo } from 'react';
import { Link } from 'react-router-dom';

import { TUserDataResponse } from '../../../api/features/auth/types';
import techDocFile from '../../../static/files/Assistapp_Technical_documentation_for_software.pdf';
import { EButtonTypes } from '../../../utils/ui/buttons';
import Logo from '../../icons/logo';
import UserIcon from '../../icons/user-icon';
import IconButton from '../../ui/buttons/icon-button';
import { TLangPickerProps } from '../../ui/language-picker';
import CompanyTradeMark, { TCompanyMarkProps } from './company-trade-mark';
import ProfilePopoverContent, {
  TUserPopoverMenuItem,
} from './profile-popover-content';

export type TAsideProps = {
  t: TFunction<string[]>;
  theme: Theme;
  userData?: TUserDataResponse;
  userMenu: TUserPopoverMenuItem[];
  languageOptions: TLangPickerProps['options'];
  currentLanguage: TLangPickerProps['currentLanguage'];
  setLanguage: TLangPickerProps['setLanguage'];
  rightsYear: TCompanyMarkProps['rightsYear'];
  AsideContentComponent: ComponentType;
};

const Wrapper = styled('aside')`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  max-height: 100vh;
  height: 100vh;
  padding: 30px 26px 20px;
  background-color: ${({ theme }) => theme.MAIN.WHITE.C200};
`;

const LogoWrapper = styled('div')`
  max-width: 180px;
  height: 60px;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const AsideContent = styled('div')`
  position: relative;
  flex: 1;
  margin-block: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Footer = styled('div')`
  font-size: 0.75rem;
`;

const FooterActions = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const UserActionsWrapper = styled('div')`
  display: flex;
  gap: 20px;

  svg {
    color: ${({ theme }) => theme.MAIN.TEXT.C200};
  }
`;

const Aside: FC<TAsideProps> = ({
  t,
  userData,
  userMenu,
  theme,
  // languageOptions,
  // currentLanguage,
  // setLanguage,
  rightsYear,
  AsideContentComponent,
}) => {
  return (
    <Wrapper>
      <LogoWrapper aria-label={t('aside.logoAltText')}>
        <Logo />
      </LogoWrapper>
      <AsideContent>
        <AsideContentComponent />
      </AsideContent>
      <Footer>
        <FooterActions>
          {userData && (
            <UserActionsWrapper>
              <Popover
                content={
                  <ProfilePopoverContent
                    userMenu={userMenu}
                    userData={userData}
                  />
                }
                placement="right"
              >
                <div>
                  <IconButton
                    type={EButtonTypes.PRIMARY}
                    aria-label={t('aside.userIconAltText')}
                    aria-haspopup="true"
                  >
                    <UserIcon />
                  </IconButton>
                </div>
              </Popover>
              {/*<IconButton title={t('aside.notificationIconAltText')}>*/}
              {/*  <NotificationIcon />*/}
              {/*</IconButton>*/}
            </UserActionsWrapper>
          )}
          {/*<LanguagePicker*/}
          {/*  t={t}*/}
          {/*  theme={theme}*/}
          {/*  setLanguage={setLanguage}*/}
          {/*  currentLanguage={currentLanguage}*/}
          {/*  options={languageOptions}*/}
          {/*/>*/}
        </FooterActions>
        <Link
          to={techDocFile}
          target="_blank"
          aria-label="Инструкция по установке и эксплуатации"
        >
          <Space align="center">
            <FileSearchOutlined />
            <span>Инструкция по установке и эксплуатации</span>
          </Space>
        </Link>
        <CompanyTradeMark t={t} theme={theme} rightsYear={rightsYear} />
      </Footer>
    </Wrapper>
  );
};

export default memo(withTheme(Aside));
