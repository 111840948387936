import { WIDGET_URL_BLACK_LIST } from '../constants/app';

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const URL_REGEX =
  /^(https?):\/\/((\[[0-9a-fA-F:]+\]|(\d{1,3}\.){3}\d{1,3}|[\w-]+(\.[\w-]+)*|xn--[a-z0-9-]+(\.xn--[a-z0-9-]+)*)(:\d{1,5})?)(\/[^\s?#]*)?(\?[^\s#]*)?(#[^\s]*)?$/;
export const WIDGET_FOLDER_REGEX = /^[a-zA-Z]+$/;

export const ALLOWED_URL_REGEX_LIST = createRegexForExcludedUrlsWithAnyPort(
  WIDGET_URL_BLACK_LIST
);

/**
 * Создаёт регулярное выражение для исключения URL-адресов из списка.
 * Это регулярное выражение используется для фильтрации URL-адресов,
 * исключая те, которые соответствуют любому из заданных шаблонов.
 * URL-адреса могут быть исключены с определенным портом или без порта.
 *
 * @param {string[]} excludedUrls - Массив URL-шаблонов для исключения.
 *                                  'XXXX' в URL означает любой порт.
 * @returns {RegExp} Регулярное выражение, которое может быть использовано для проверки URL-адресов.
 *
 * @example
 * const excludedUrls = ['https://example.com:XXXX', 'https://testsite.com'];
 * const regex = createRegexForExcludedUrlsWithAnyPort(excludedUrls);
 * regex.test('https://example.com:8080'); // true
 * regex.test('https://testsite.com'); // true
 *
 * @throws {TypeError} Если аргументы не соответствуют ожидаемым типам.
 *
 * @note Использование 'XXXX' в URL указывает на необходимость наличия порта в проверяемом URL.
 */
function createRegexForExcludedUrlsWithAnyPort(excludedUrls: string[]): RegExp {
  const patterns = excludedUrls.map((url) => {
    const escapedUrl = url.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    if (url.includes('XXXX')) {
      // Заменяем XXXX на регулярное выражение для порта и делаем порт опциональным
      return `https?:\/\/${escapedUrl.replace(/XXXX/g, '(?::\\d+)?')}`;
    } else {
      // Оставляем URL без порта или явно запрещаем указание порта
      return `https?:\/\/${escapedUrl}(?::\\d+)?`;
    }
  });

  const pattern = patterns.join('|');
  return new RegExp(`^(?:${pattern})(?:$|\/).*`, 'i');
}

/**
 * Проверяет, является ли URL поддоменом другого URL.
 *
 * @param subdomainUrl URL предполагаемого поддомена, который необходимо проверить.
 * @param domainUrl URL основного домена, с которым сравнивается поддомен.
 * @returns boolean Возвращает `true`, если `subdomainUrl` является поддоменом `domainUrl`.
 *                 Возвращает `false`, если `subdomainUrl` не является поддоменом `domainUrl`.
 *
 * @example
 * // Возвращает true
 * isSubdomainOf('https://sub.example.com', 'https://example.com');
 *
 * // Возвращает false
 * isSubdomainOf('https://example.com', 'https://sub.example.com');
 *
 * // Возвращает true (сам себе поддомен)
 * isSubdomainOf('https://example.com', 'https://example.com');
 */
export function isSubdomainOf(
  subdomainUrl: string,
  domainUrl: string
): boolean {
  try {
    const subdomain = new URL(subdomainUrl);
    const domain = new URL(domainUrl);

    // Проверяем, что hostname поддомена либо совпадает с доменом,
    // либо заканчивается на '.<domain-hostname>'

    // INFO: Раскомментировать когда поймем какая проверка на поддомен нам подходит
    return (
      (subdomain.hostname === domain.hostname ||
        subdomain.hostname.endsWith(`.${domain.hostname}`)) &&
      subdomainUrl !== domainUrl
    );
  } catch (error) {
    return false;
  }
}

/**
 * Форматирует URL адреса в формат без протокола
 *
 * @param url Полный URL адрес
 * @returns string URL без протокола
 *
 * @example
 * // Возвращает assistapp.test
 * extractDomain('https://assistapp.test/');
 *
 * // Возвращает assistapp.test/test
 * extractDomain('https://assistapp.test/test');
 */
export function extractDomain(url: string): string {
  const regex = /^(?:https?:\/\/)?([^\/]+)/;
  const match = url.match(regex);
  return match ? match[1] : '';
}
