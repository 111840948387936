import { useEffect } from 'react';

import CursorIcon from '../../icons/cursorIcon';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const cursorEffect: TSettingItem = {
  Icon: CursorIcon,
  key: ETextEffectKeys.CURSOR,
};

export const HIDDEN_CURSOR_CLASS_NAME = 'ac-wd-hc-effect-style';

export const useCursorEffect = () => {
  const effect = useEffectSelector(ETextEffectKeys.CURSOR);

  useEffect(() => {
    const container = document.querySelector('html') as HTMLElement;

    if (effect.value) {
      container.classList.add(HIDDEN_CURSOR_CLASS_NAME);
    } else {
      container.classList.remove(HIDDEN_CURSOR_CLASS_NAME);
    }
  }, [effect]);
};
