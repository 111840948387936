import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TBaseIconProps } from './index';

const Logo: FC<TBaseIconProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="181"
      height="60"
      viewBox="0 0 181 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_536_2084)">
        <path
          d="M51.348 30.3844C51.8487 30.958 52.0991 31.6 52.0991 32.3102C52.0991 33.0204 51.8624 33.6487 51.3884 34.195C50.928 34.7277 50.3529 34.994 49.6626 34.994C49.3648 34.994 48.8978 34.8506 48.2616 34.5638C47.6386 34.2633 46.955 33.9697 46.2106 33.6828C45.4795 33.3824 44.8366 33.2321 44.2815 33.2321C43.6997 33.2321 43.2123 33.3755 42.8197 33.6624C42.4405 33.9492 42.2513 34.3657 42.2513 34.9121C42.2513 35.2808 42.4405 35.6223 42.8197 35.9364C43.1986 36.2506 43.6926 36.5306 44.3018 36.7764C44.9248 37.0223 45.6014 37.2818 46.3324 37.555C47.0768 37.8281 47.8145 38.1559 48.5456 38.5383C49.2904 38.9071 49.967 39.3305 50.5762 39.8086C51.1987 40.2866 51.6998 40.9012 52.0787 41.6524C52.4579 42.4037 52.6476 43.2437 52.6476 44.1722C52.6476 46.221 51.8624 47.9216 50.2922 49.2739C48.7215 50.6124 46.6643 51.2816 44.1193 51.2816C42.9549 51.2816 41.7369 51.0903 40.4642 50.708C39.1918 50.3119 38.0885 49.7451 37.1545 49.0075C36.2204 48.2698 35.7534 47.471 35.7534 46.6104C35.7534 46.0503 35.9903 45.4633 36.4641 44.8486C36.9514 44.2339 37.5741 43.9263 38.3322 43.9263C38.8466 43.9263 39.3678 44.0359 39.8957 44.2545C40.4236 44.4726 40.8771 44.7118 41.2561 44.9715C41.635 45.2174 42.1157 45.4494 42.6979 45.668C43.2934 45.8866 43.9093 45.9957 44.5455 45.9957C45.4255 45.9957 46.0347 45.8594 46.3732 45.5858C46.725 45.2992 46.9009 44.862 46.9009 44.2746C46.9009 43.9062 46.7113 43.5714 46.3324 43.271C45.9536 42.9567 45.4525 42.6768 44.83 42.431C44.2207 42.1851 43.5437 41.9256 42.7994 41.6524C42.0683 41.3792 41.3306 41.0583 40.5861 40.6895C39.855 40.3071 39.1782 39.8768 38.5555 39.3988C37.9464 38.9071 37.4523 38.2857 37.0732 37.5345C36.6942 36.7833 36.5047 35.9364 36.5047 34.994C36.5047 32.9043 37.2628 31.2107 38.7789 29.9132C40.295 28.602 42.2442 27.9464 44.6266 27.9464C45.3984 27.9464 46.2039 28.042 47.0431 28.2332C47.8823 28.4108 48.6878 28.6703 49.4596 29.0117C50.231 29.3532 50.8606 29.8107 51.348 30.3844Z"
          fill={theme.MAIN.TEXT.C200}
        />
        <path
          d="M69.0542 30.3844C69.5548 30.958 69.8052 31.6 69.8052 32.3102C69.8052 33.0204 69.5686 33.6487 69.0945 34.195C68.6346 34.7277 68.059 34.994 67.3687 34.994C67.071 34.994 66.604 34.8506 65.9677 34.5638C65.3448 34.2633 64.6615 33.9697 63.9167 33.6828C63.1857 33.3824 62.5428 33.2321 61.9876 33.2321C61.4058 33.2321 60.9184 33.3755 60.5259 33.6624C60.1466 33.9492 59.9574 34.3657 59.9574 34.9121C59.9574 35.2808 60.1466 35.6223 60.5259 35.9364C60.9047 36.2506 61.3987 36.5306 62.008 36.7764C62.6309 37.0223 63.3075 37.2818 64.0386 37.555C64.7834 37.8281 65.5211 38.1559 66.2517 38.5383C66.9965 38.9071 67.6731 39.3305 68.2823 39.8086C68.9053 40.2866 69.406 40.9012 69.7848 41.6524C70.1641 42.4037 70.3537 43.2437 70.3537 44.1722C70.3537 46.221 69.5686 47.9216 67.9983 49.2739C66.4281 50.6124 64.3704 51.2816 61.8254 51.2816C60.661 51.2816 59.443 51.0903 58.1705 50.708C56.898 50.3119 55.7947 49.7451 54.8607 49.0075C53.9267 48.2698 53.4597 47.471 53.4597 46.6104C53.4597 46.0503 53.6963 45.4633 54.1704 44.8486C54.6578 44.2339 55.2803 43.9263 56.0384 43.9263C56.5528 43.9263 57.0739 44.0359 57.602 44.2545C58.1297 44.4726 58.5834 44.7118 58.9623 44.9715C59.3415 45.2174 59.8218 45.4494 60.404 45.668C60.9995 45.8866 61.6154 45.9957 62.2517 45.9957C63.1316 45.9957 63.7408 45.8594 64.0793 45.5858C64.4311 45.2992 64.607 44.862 64.607 44.2746C64.607 43.9062 64.4178 43.5714 64.0386 43.271C63.6597 42.9567 63.1586 42.6768 62.5361 42.431C61.9269 42.1851 61.2499 41.9256 60.5055 41.6524C59.7744 41.3792 59.0367 41.0583 58.2923 40.6895C57.5613 40.3071 56.8843 39.8768 56.2617 39.3988C55.6525 38.9071 55.1585 38.2857 54.7796 37.5345C54.4004 36.7833 54.2107 35.9364 54.2107 34.994C54.2107 32.9043 54.9688 31.2107 56.485 29.9132C58.0012 28.602 59.9503 27.9464 62.3332 27.9464C63.1046 27.9464 63.9101 28.042 64.7493 28.2332C65.5885 28.4108 66.394 28.6703 67.1658 29.0117C67.9372 29.3532 68.5668 29.8107 69.0542 30.3844Z"
          fill={theme.MAIN.TEXT.C200}
        />
        <path
          d="M72.384 47.6551V31.5521C72.384 30.5551 72.6618 29.7492 73.2166 29.1346C73.7717 28.5064 74.4891 28.1922 75.369 28.1922C76.249 28.1922 76.9663 28.5064 77.5215 29.1346C78.0899 29.7492 78.3744 30.5551 78.3744 31.5521V47.6551C78.3744 48.6521 78.0899 49.4648 77.5215 50.0933C76.9663 50.708 76.249 51.0151 75.369 51.0151C74.4891 51.0151 73.7717 50.708 73.2166 50.0933C72.6618 49.4648 72.384 48.6521 72.384 47.6551ZM73.0136 20.1612C73.6906 19.4783 74.4758 19.1368 75.369 19.1368C76.2627 19.1368 77.0478 19.4715 77.7244 20.1407C78.4014 20.81 78.7399 21.5817 78.7399 22.4558C78.7399 23.3982 78.4081 24.2177 77.7448 24.9143C77.0952 25.5972 76.303 25.9386 75.369 25.9386C74.4758 25.9386 73.6906 25.5903 73.0136 24.8938C72.337 24.1972 71.9985 23.3845 71.9985 22.4558C71.9985 21.5953 72.337 20.8304 73.0136 20.1612Z"
          fill={theme.MAIN.TEXT.C200}
        />
        <path
          d="M95.979 30.3844C96.4796 30.958 96.73 31.6 96.73 32.3102C96.73 33.0204 96.4934 33.6487 96.0193 34.195C95.5594 34.7277 94.9838 34.994 94.2935 34.994C93.9958 34.994 93.5288 34.8506 92.8925 34.5638C92.27 34.2633 91.5863 33.9697 90.8415 33.6828C90.1105 33.3824 89.4676 33.2321 88.9128 33.2321C88.3306 33.2321 87.8432 33.3755 87.4507 33.6624C87.0718 33.9492 86.8822 34.3657 86.8822 34.9121C86.8822 35.2808 87.0718 35.6223 87.4507 35.9364C87.8295 36.2506 88.324 36.5306 88.9328 36.7764C89.5557 37.0223 90.2323 37.2818 90.9634 37.555C91.7082 37.8281 92.4459 38.1559 93.1765 38.5383C93.9213 38.9071 94.5979 39.3305 95.2071 39.8086C95.8301 40.2866 96.3308 40.9012 96.71 41.6524C97.0889 42.4037 97.2785 43.2437 97.2785 44.1722C97.2785 46.221 96.4934 47.9216 94.9231 49.2739C93.3529 50.6124 91.2952 51.2816 88.7502 51.2816C87.5858 51.2816 86.3678 51.0903 85.0953 50.708C83.8228 50.3119 82.7195 49.7451 81.7855 49.0075C80.8515 48.2698 80.3845 47.471 80.3845 46.6104C80.3845 46.0503 80.6211 45.4633 81.0952 44.8486C81.5826 44.2339 82.2051 43.9263 82.9632 43.9263C83.4776 43.9263 83.9987 44.0359 84.5268 44.2545C85.0545 44.4726 85.5082 44.7118 85.8871 44.9715C86.2663 45.2174 86.7466 45.4494 87.3288 45.668C87.9243 45.8866 88.5402 45.9957 89.1765 45.9957C90.0564 45.9957 90.6656 45.8594 91.0041 45.5858C91.3559 45.2992 91.5318 44.862 91.5318 44.2746C91.5318 43.9062 91.3426 43.5714 90.9634 43.271C90.5845 42.9567 90.0834 42.6768 89.4609 42.431C88.8517 42.1851 88.1751 41.9256 87.4303 41.6524C86.6992 41.3792 85.9615 41.0583 85.2171 40.6895C84.4861 40.3071 83.8091 39.8768 83.1865 39.3988C82.5773 38.9071 82.0833 38.2857 81.7044 37.5345C81.3252 36.7833 81.1355 35.9364 81.1355 34.994C81.1355 32.9043 81.8937 31.2107 83.4098 29.9132C84.926 28.602 86.8756 27.9464 89.258 27.9464C90.0294 27.9464 90.8349 28.042 91.6741 28.2332C92.5133 28.4108 93.3188 28.6703 94.0906 29.0117C94.862 29.3532 95.4916 29.8107 95.979 30.3844Z"
          fill={theme.MAIN.TEXT.C200}
        />
        <path
          d="M100.852 47.6552V33.7443H99.512C98.6999 33.7443 98.0636 33.5053 97.6033 33.0272C97.1296 32.5492 96.8926 31.9005 96.8926 31.081C96.8926 30.3298 97.1429 29.7015 97.644 29.1961C98.1314 28.6908 98.7539 28.4381 99.512 28.4381H100.852V24.8323C100.852 23.8353 101.13 23.0294 101.684 22.4148C102.24 21.7866 102.957 21.4724 103.837 21.4724C104.717 21.4724 105.434 21.7866 105.989 22.4148C106.558 23.0294 106.842 23.8353 106.842 24.8323V28.4381H108.548C109.387 28.4381 110.071 28.6566 110.599 29.0937C111.14 29.5171 111.411 30.1795 111.411 31.081C111.411 31.9688 111.14 32.638 110.599 33.0887C110.071 33.5258 109.387 33.7443 108.548 33.7443H106.842V47.6552C106.842 48.6521 106.558 49.4648 105.989 50.0933C105.434 50.708 104.717 51.0152 103.837 51.0152C102.957 51.0152 102.24 50.708 101.684 50.0933C101.13 49.4648 100.852 48.6521 100.852 47.6552Z"
          fill={theme.MAIN.TEXT.C200}
        />
        <path
          d="M132.163 32.1463V47.0816C132.163 47.5049 132.156 47.8465 132.143 48.1058C132.129 48.3655 132.068 48.707 131.96 49.1304C131.865 49.5399 131.723 49.868 131.534 50.1139C131.344 50.3459 131.053 50.5574 130.66 50.7487C130.281 50.9262 129.821 51.0152 129.28 51.0152C128.454 51.0152 127.825 50.8033 127.391 50.3799C126.958 49.9566 126.633 49.3146 126.416 48.454C124.833 50.3392 122.931 51.2816 120.711 51.2816C117.882 51.2816 115.526 50.189 113.644 48.0034C111.763 45.8044 110.822 43.0184 110.822 39.6446C110.822 37.473 111.262 35.4926 112.142 33.7033C113.022 31.9005 114.22 30.4936 115.736 29.483C117.252 28.4586 118.91 27.9464 120.711 27.9464C121.861 27.9464 122.937 28.1786 123.939 28.643C124.941 29.1073 125.767 29.7971 126.416 30.7122C126.498 29.9747 126.809 29.3737 127.35 28.9093C127.906 28.4313 128.549 28.1922 129.28 28.1922C129.672 28.1922 130.024 28.24 130.335 28.3357C130.647 28.4313 130.904 28.5542 131.107 28.7044C131.31 28.841 131.479 29.0322 131.615 29.2781C131.75 29.5103 131.852 29.7288 131.919 29.9337C132.001 30.1249 132.055 30.3775 132.082 30.6917C132.122 31.0058 132.143 31.2517 132.143 31.4292C132.156 31.6068 132.163 31.8458 132.163 32.1463ZM116.812 39.6446C116.812 40.6554 116.975 41.6114 117.299 42.5128C117.625 43.4006 118.152 44.1588 118.883 44.7869C119.628 45.4154 120.514 45.7293 121.543 45.7293C122.532 45.7293 123.384 45.4221 124.102 44.8075C124.833 44.1793 125.361 43.4145 125.685 42.5128C126.011 41.6114 126.173 40.6554 126.173 39.6446C126.173 38.634 126.011 37.6779 125.685 36.7764C125.374 35.8613 124.853 35.0828 124.122 34.4409C123.404 33.7989 122.545 33.478 121.543 33.478C120.501 33.478 119.607 33.7989 118.863 34.4409C118.132 35.0828 117.604 35.8545 117.279 36.756C116.968 37.6574 116.812 38.6203 116.812 39.6446Z"
          fill={theme.MAIN.TEXT.C200}
        />
        <path
          d="M135.574 56.567V31.5522C135.574 30.5551 135.852 29.7493 136.407 29.1347C136.962 28.5064 137.679 28.1922 138.559 28.1922C140.184 28.1922 141.185 29.0322 141.564 30.7122C142.106 29.8244 142.891 29.1415 143.92 28.6635C144.962 28.1854 146.072 27.9464 147.25 27.9464C148.793 27.9464 150.194 28.2947 151.453 28.9913C152.712 29.6742 153.727 30.5893 154.498 31.7366C155.284 32.8838 155.879 34.1609 156.285 35.5677C156.705 36.9608 156.915 38.4086 156.915 39.911C156.915 41.3451 156.678 42.7452 156.204 44.111C155.731 45.4633 155.074 46.6717 154.235 47.737C153.409 48.8023 152.366 49.6628 151.108 50.3186C149.862 50.9606 148.508 51.2816 147.047 51.2816C144.989 51.2816 143.161 50.5779 141.564 49.1711V56.567C141.564 57.5644 141.28 58.37 140.711 58.9847C140.156 59.5993 139.439 59.9065 138.559 59.9065C137.679 59.9065 136.962 59.5993 136.407 58.9847C135.852 58.37 135.574 57.5644 135.574 56.567ZM141.564 39.4398C141.564 40.4778 141.72 41.4612 142.031 42.3898C142.343 43.3049 142.864 44.0904 143.595 44.7462C144.326 45.4016 145.212 45.7293 146.255 45.7293C147.243 45.7293 148.103 45.4154 148.833 44.7869C149.564 44.1449 150.092 43.3733 150.417 42.4721C150.756 41.5705 150.925 40.6281 150.925 39.6446C150.925 38.6476 150.756 37.6915 150.417 36.7764C150.092 35.8613 149.564 35.0828 148.833 34.4409C148.103 33.7989 147.243 33.478 146.255 33.478C145.483 33.478 144.786 33.6487 144.163 33.9902C143.554 34.3316 143.067 34.7892 142.701 35.3628C142.336 35.9364 142.052 36.5716 141.849 37.2681C141.659 37.9647 141.564 38.6886 141.564 39.4398Z"
          fill={theme.MAIN.TEXT.C200}
        />
        <path
          d="M159.453 56.567V31.5522C159.453 30.5551 159.731 29.7493 160.285 29.1347C160.841 28.5064 161.558 28.1922 162.438 28.1922C164.062 28.1922 165.064 29.0322 165.443 30.7122C165.985 29.8244 166.77 29.1415 167.799 28.6635C168.841 28.1854 169.951 27.9464 171.129 27.9464C172.672 27.9464 174.073 28.2947 175.332 28.9913C176.591 29.6742 177.606 30.5893 178.378 31.7366C179.163 32.8838 179.759 34.1609 180.164 35.5677C180.584 36.9608 180.794 38.4086 180.794 39.911C180.794 41.3451 180.557 42.7452 180.083 44.111C179.61 45.4633 178.953 46.6717 178.114 47.737C177.288 48.8023 176.245 49.6628 174.987 50.3186C173.741 50.9606 172.388 51.2816 170.925 51.2816C168.868 51.2816 167.041 50.5779 165.443 49.1711V56.567C165.443 57.5644 165.159 58.37 164.59 58.9847C164.035 59.5993 163.318 59.9065 162.438 59.9065C161.558 59.9065 160.841 59.5993 160.285 58.9847C159.731 58.37 159.453 57.5644 159.453 56.567ZM165.443 39.4398C165.443 40.4778 165.599 41.4612 165.91 42.3898C166.222 43.3049 166.743 44.0904 167.474 44.7462C168.205 45.4016 169.092 45.7293 170.134 45.7293C171.122 45.7293 171.981 45.4154 172.712 44.7869C173.443 44.1449 173.972 43.3733 174.296 42.4721C174.635 41.5705 174.804 40.6281 174.804 39.6446C174.804 38.6476 174.635 37.6915 174.296 36.7764C173.972 35.8613 173.443 35.0828 172.712 34.4409C171.981 33.7989 171.122 33.478 170.134 33.478C169.362 33.478 168.665 33.6487 168.042 33.9902C167.433 34.3316 166.946 34.7892 166.58 35.3628C166.215 35.9364 165.931 36.5716 165.727 37.2681C165.538 37.9647 165.443 38.6886 165.443 39.4398Z"
          fill={theme.MAIN.TEXT.C200}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4295 5.13134C25.4295 7.96527 23.1525 10.2626 20.3438 10.2626C17.5351 10.2626 15.2581 7.96527 15.2581 5.13134C15.2581 2.2974 17.5351 0 20.3438 0C23.1525 0 25.4295 2.2974 25.4295 5.13134ZM26.379 26.7878H26.3904C26.0381 25.3339 25.6591 23.1429 25.5031 20.089C27.8676 19.7332 30.2132 19.2102 32.1851 18.7128C33.9427 18.2695 35.4779 17.8268 36.5751 17.4946C37.1708 17.3144 37.7655 17.13 38.3558 16.9331C40.0971 16.3501 41.0939 14.3609 40.5128 12.6018C39.9208 10.8096 38.0008 9.84101 36.2245 10.4381C35.6949 10.6148 35.1613 10.7797 34.6269 10.9415C33.605 11.2508 32.1736 11.6634 30.5407 12.0753C27.198 12.9184 23.2966 13.6836 20.3438 13.6836C17.391 13.6836 13.4897 12.9184 10.1471 12.0753C8.51407 11.6634 7.08262 11.2508 6.06092 10.9415C5.53307 10.7817 5.00636 10.6178 4.48267 10.4446C2.72127 9.85687 0.765263 10.8148 0.174912 12.6018C-0.417237 14.3942 0.542816 16.3315 2.31923 16.9289C2.91355 17.1279 3.51264 17.3133 4.11238 17.4946C5.20971 17.8268 6.74495 18.2695 8.50242 18.7128C10.4744 19.2102 12.8201 19.7332 15.1845 20.089C15.0303 23.1082 14.6581 25.284 14.3093 26.738L8.1922 43.5524C7.77635 44.6992 7.4991 45.5664 7.36049 46.1538C7.24959 46.7131 7.19415 47.2586 7.19415 47.7902C7.19415 48.6294 7.48525 49.3145 8.06744 49.8462C8.67737 50.3497 9.46749 50.6014 10.4378 50.6014C11.4636 50.6014 12.2537 50.2796 12.8082 49.6364C13.224 49.161 13.6121 48.3638 13.9725 47.2448L14.8042 44.5594H25.8659L26.6976 47.2448C27.0303 48.3915 27.4184 49.2029 27.862 49.6783C28.4164 50.2938 29.2066 50.6014 30.2323 50.6014C31.2304 50.6014 32.0205 50.3497 32.6027 49.8462C33.1849 49.3427 33.476 48.6575 33.476 47.7902C33.476 47.2309 33.4206 46.6855 33.3096 46.1538C33.1987 45.6222 32.9215 44.7554 32.4779 43.5524L26.379 26.7878ZM20.5014 27.1469L16.8003 38.1818H23.8698L20.5014 27.1469Z"
          fill={theme.MAIN.ACCENT.C100}
        />
      </g>
      <defs>
        <clipPath id="clip0_536_2084">
          <rect width="180.896" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(Logo);
