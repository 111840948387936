import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type IMainState = {
  opened: boolean;
};

const initialState: IMainState = {
  opened: false,
};

const mainStateSlice = createSlice({
  name: 'widget/main',
  initialState,
  reducers: {
    setWidgetOpenAction(state, action: PayloadAction<boolean>) {
      state.opened = action.payload;
    },
  },
});

export const { setWidgetOpenAction } = mainStateSlice.actions;
export const mainStateReducer = mainStateSlice.reducer;
