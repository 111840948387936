import { Alert, Form } from 'antd';
import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import {
  useCreateWidgetAlternativeContentMutation,
  useGetUserAlternativeContentsQuery,
  useUpdateWidgetAlternateContentMutation,
} from '../../api/features/widget';
import {
  TAlternativeContentCreatePayload,
  TAlternativeContentForm,
  TAlternativeContentPatchPayload,
  TWidget,
} from '../../api/features/widget/types';
import SectionParagraph from '../../components/ui/section-paragraph';
import { NBSP } from '../../constants/formatters';
import { useAppMessage } from '../../providers/notification-provider';
import WidgetAlternativeContentForm from '../forms/widget-alternative-content-form';

type TProps = {
  widget?: TWidget;
};

const SimpleLanguage: FC<TProps> = memo(({ widget }) => {
  const [form] = Form.useForm<TAlternativeContentForm>();

  const { messageApi } = useAppMessage();

  const [createAlternateContent, { isLoading: isCreateLoading }] =
    useCreateWidgetAlternativeContentMutation();

  const [updateAlternateContent, { isLoading: isUpdateLoading }] =
    useUpdateWidgetAlternateContentMutation();

  const { data, isLoading, isError } = useGetUserAlternativeContentsQuery(
    widget?.id as string,
    {
      skip: !widget?.id,
    }
  );

  const currentAlternateContent: TAlternativeContentPatchPayload | null =
    useMemo(() => {
      if (!widget) {
        return null;
      }

      const filteredData = data?.filter((el) =>
        el.settings.items.some(
          (settingItem) =>
            settingItem.fullDomainPath.startsWith(widget.domain) ||
            widget.subdomain?.split(', ').includes(settingItem.fullDomainPath)
        )
      );

      return filteredData?.length === 1 ? filteredData[0] : null;
    }, [data]);

  useEffect(() => {
    if (currentAlternateContent) {
      form.setFieldsValue({
        items: currentAlternateContent.settings.items,
      });
    } else {
      form.resetFields();
    }
  }, [currentAlternateContent]);

  const handleFormFinish = useCallback(
    async (values: TAlternativeContentForm) => {
      if (!values.items || !widget) {
        messageApi.error('Отсутствуют элементы для сохранения.');
        return;
      }

      const dataToLoad: TAlternativeContentCreatePayload = {
        settings: {
          items: values.items.map((el) => ({
            ...el,
            originalValue: '',
          })),
        },
        widgetId: widget.id,
      };

      try {
        if ((data === null || data?.length === 0) && !isError) {
          await createAlternateContent(dataToLoad).unwrap();
        } else if (currentAlternateContent) {
          const updateDataToLoad: TAlternativeContentPatchPayload = {
            ...dataToLoad,
            id: currentAlternateContent.id,
          };

          await updateAlternateContent(updateDataToLoad);
          messageApi.success('Настройки ясного языка обновлены');
        } else {
          messageApi.error(
            'Не удалось получить информацию о текущих настройках ясного языка.'
          );
        }
      } catch (e) {
        messageApi.error('Не удалось сохранить настройки ясного языка');
      }
    },
    [widget, data, isError]
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleFormFinish}>
      {!widget && (
        <Alert
          message="Завершите первоначальную настройку виджета"
          description="Для настройки функций ясного языка необходимо завершить найстройку виджета"
          type="info"
          showIcon
        />
      )}
      {/*<Title title="Ясный язык" theme={theme} />*/}
      <SectionParagraph>
        <b>Ясный язык</b> — адаптированный вариант стандартного языка. Термин
        «ясный язык» может подразумевать два уровня адаптации: упрощение текста
        только для чтения и упрощение для чтения и понимания.
      </SectionParagraph>
      <SectionParagraph>
        Тексты на ясном языке предназначены для всех, у кого по разным причинам
        недостаточно хорошо сформирован навык чтения и развита способность
        понимать тексты на стандартном языке, в том числе для людей с ментальной
        инвалидностью и особенностями интеллектуального развития, людей с
        возрастными когнитивными изменениями, мигрантов и т. д.
      </SectionParagraph>
      <SectionParagraph>
        Добавьте поддержку ясного языка на свой сайт с помощью виджета. Введите
        {NBSP}
        <Link to="https://ru.wikipedia.org/wiki/XPath" target="_blank">
          XPath
        </Link>
        {NBSP}
        элемента, необходимый текст и адрес страницы для которой будет работать
        данный перевод. При включении функции ясного языка виджет заменит
        контент найденного элемента на введенный текст.
      </SectionParagraph>
      {widget && (
        <WidgetAlternativeContentForm
          widget={widget}
          isLoading={isLoading || isCreateLoading || isUpdateLoading}
        />
      )}
    </Form>
  );
});

export default SimpleLanguage;
