// ВНИМАНИЕ: порядок эффектов будет применен внутри виджета
export const ETextEffectKeys = {
  TEXT_INCREASE: 'textIncrease',
  CONTENT_SCALE: 'contentScale',
  READABLE_FONT: 'readableFont',
  DYSLEXIC_FONT: 'dyslexicFont',
  SIMPLE_LANGUAGE: 'simpleLanguage',
  CURSOR: 'cursorEffect',
  CITE_THEME: 'citeTheme',
  INVERT_COLORS: 'invertColors',
  BRIGHTNESS: 'brightness',
  CONTRAST: 'contrast',
  GRAYSCALE: 'grayscale',
  READ_LINE: 'readLine',
  READ_AREA: 'readArea',
  HIDE_IMAGES: 'hideImages',
  STOP_ANIMATIONS: 'stopAnimations',
  LINKS_MARK: 'linksMark',
  TEXT_TO_SPEECH: 'textToSpeech',
} as const;

export type TEffectTextKey =
  (typeof ETextEffectKeys)[keyof typeof ETextEffectKeys];
