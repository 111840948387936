import styled from '@emotion/styled';
import { ModuleWidget } from '@kylfyk/accessability-react';
import { FC, memo } from 'react';

import { useWidgetFormContext } from '../../../../providers/widget-settings-form-provider';

const Wrapper = styled.div`
  max-height: calc(100vh - 40px);
  position: sticky;
  top: 20px;
  padding: 20px;

  aside {
    // TODO Убрать селектор по тегу
    max-height: 100%;
    overflow: hidden;
    border-radius: 8px;
  }
`;

const WidgetPreviewComponent: FC = memo(() => {
  const { currentSettingItem } = useWidgetFormContext();

  return (
    <Wrapper>
      <ModuleWidget
        theme={currentSettingItem.setting.theme}
        effectsConfig={currentSettingItem.setting.effects}
        presetsConfig={currentSettingItem.setting.presets}
      />
    </Wrapper>
  );
});

export default WidgetPreviewComponent;
