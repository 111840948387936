import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const GrayScaleIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        width="8"
        height="22"
        rx="3"
        fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
      />
      <rect
        x="13"
        width="8"
        height="22"
        rx="3"
        fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
      />
    </svg>
  );
};

export default withTheme(GrayScaleIcon);
