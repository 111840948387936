import { createApi } from '@reduxjs/toolkit/query/react';

import { TSimpleLanguageResponse } from '../../types/api/effects';
import baseQuery from '../base-query';

enum EEffectsTagTypes {
  SIMPLE_LANGUAGE = 'simpleLanguage',
}

const tagTypes: EEffectsTagTypes[] = [EEffectsTagTypes.SIMPLE_LANGUAGE];

export const effectsApi = createApi({
  reducerPath: 'effects/api',
  baseQuery,
  tagTypes,
  endpoints: (builder) => ({
    getCiteSimpleLanguage: builder.query<TSimpleLanguageResponse, string>({
      query: () => ({
        url: `alternative-content/by-url/${encodeURIComponent(
          window.location.origin
        )}`,
        method: 'GET',
      }),
      providesTags: (_, __, arg) => [
        {
          id: arg,
          type: EEffectsTagTypes.SIMPLE_LANGUAGE,
        },
      ],
    }),
  }),
});

export const { useGetCiteSimpleLanguageQuery } = effectsApi;
