import { Global, css } from '@emotion/react';
import { FC, Fragment, PropsWithChildren } from 'react';

import { IS_MODULE } from '../../constants';
import { useCiteThemeEffect } from '../../effects/core/cite-theme-effect';
import { ETextEffectKeys } from '../../effects/names';
import { useWidgetEffect } from '../../hooks/useWidgetEffect';
import {
  CITE_ADDITIONAL_THEME_DICTIONARY,
  getThemeVariables,
} from '../../utils/color-theme-dictionary';
import { citeThemeColorProperties } from '../../utils/color-theme-transform';

const themeVariables = getThemeVariables(CITE_ADDITIONAL_THEME_DICTIONARY);

const colorsKeys: number[] = [100, 200, 300, 400];

export const CiteTheme: FC<PropsWithChildren> = ({ children }) => {
  const { effect } = useWidgetEffect(ETextEffectKeys.CITE_THEME);

  const [inited] = useCiteThemeEffect();

  return (
    <Fragment>
      <Global
        styles={css`
          ${effect.value !== false && !IS_MODULE && inited && themeVariables}

          ${!!effect.value &&
          inited &&
          !IS_MODULE &&
          `html {
                  background-color: var(--accessibility-theme-${effect.value}-100) !important;
                  color: var(--accessibility-theme-${effect.value}-400) !important;
                  }`}

          ${!!effect.value &&
          inited &&
          !IS_MODULE &&
          citeThemeColorProperties.map(
            (property) =>
              `
                    [accessibility-access-theme-${property}-0] {
                      ${property}: transparent !important;
                      background-image: none !important;
                    }
                ` +
              colorsKeys.map(
                (key) => `
              [accessibility-access-theme-${property}-${key}] {
                ${property}: var(--accessibility-theme-${
                  effect.value
                }-${key}) !important;
                ${
                  property === 'background-color' &&
                  `background-image: none !important;`
                } 
                ${
                  property === 'background-image' &&
                  `background-image: none !important;
                          background-color: var(--accessibility-theme-${effect.value}-${key}) !important;
                          `
                } 
              }                   
            `
              )
          )}
        `}
      />
      {children}
    </Fragment>
  );
};
