import widget_module_LocaleParts_en from '@kylfyk/accessability-react/dist/locale/effects/en.json';
import widget_module_LocaleParts_es from '@kylfyk/accessability-react/dist/locale/effects/es.json';
import widget_module_LocaleParts_ru from '@kylfyk/accessability-react/dist/locale/effects/ru.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import authFormsEn from './translations/auth-forms/en.json';
import authFormsEs from './translations/auth-forms/es.json';
import authFormsRu from './translations/auth-forms/ru.json';
import layoutEn from './translations/layout/en.json';
import layoutEs from './translations/layout/es.json';
import layoutRu from './translations/layout/ru.json';
import createWidgetFormEn from './translations/widget-create/en.json';
import createWidgetFormEs from './translations/widget-create/es.json';
import createWidgetFormRu from './translations/widget-create/ru.json';
import widgetModulePresetsEn from './translations/widget-module/presets/en.json';
import widgetModulePresetsEs from './translations/widget-module/presets/es.json';
import widgetModulePresetsRu from './translations/widget-module/presets/ru.json';

export enum Localization {
  EN = 'en',
  RU = 'ru',
  ES = 'es',
}

export enum LocaleParts {
  LAYOUT = 'layout',
  AUTH_FORMS = 'auth_forms',
  CREATE_WIDGET_FORM = 'create_widget_form',
  WIDGET_MODULE_PRESETS = 'widget_module_presets',
  CORE_MODULE = 'widget_module_core',
}

export const localeToText: (loc: Localization) => string = (loc) => {
  switch (loc) {
    case Localization.RU:
      return 'Русский';
    case Localization.EN:
      return 'English';
    case Localization.ES:
      return 'Español';
  }
};

i18n.use(initReactI18next).init({
  fallbackLng: Localization.RU,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    [Localization.RU]: {
      [LocaleParts.LAYOUT]: layoutRu,
      [LocaleParts.AUTH_FORMS]: authFormsRu,
      [LocaleParts.CREATE_WIDGET_FORM]: createWidgetFormRu,
      [LocaleParts.WIDGET_MODULE_PRESETS]: widgetModulePresetsRu,
      [LocaleParts.CORE_MODULE]: widget_module_LocaleParts_ru,
    },
    [Localization.EN]: {
      [LocaleParts.LAYOUT]: layoutEn,
      [LocaleParts.AUTH_FORMS]: authFormsEn,
      [LocaleParts.CREATE_WIDGET_FORM]: createWidgetFormEn,
      [LocaleParts.WIDGET_MODULE_PRESETS]: widgetModulePresetsEn,
      [LocaleParts.CORE_MODULE]: widget_module_LocaleParts_en,
    },
    [Localization.ES]: {
      [LocaleParts.LAYOUT]: layoutEs,
      [LocaleParts.AUTH_FORMS]: authFormsEs,
      [LocaleParts.CREATE_WIDGET_FORM]: createWidgetFormEs,
      [LocaleParts.WIDGET_MODULE_PRESETS]: widgetModulePresetsEs,
      [LocaleParts.CORE_MODULE]: widget_module_LocaleParts_es,
    },
  },
});

export default i18n;
