import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { loadingReducer } from './slices/loading-slice';
import { localeReducer } from './slices/locale-slice';
import { appThemeReducer } from './slices/theme-slice';

const themeConfig = {
  key: 'app/theme',
  storage,
};

const localeConfig = {
  key: 'app/locale',
  storage,
};

export const applicationReducers = combineReducers({
  theme: persistReducer(themeConfig, appThemeReducer),
  locale: persistReducer(localeConfig, localeReducer),
  loading: loadingReducer,
});
