import { Empty } from 'antd';
import { FC, useLayoutEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BasePageWrapper } from '../../components/pages/base-page-wrapper';
import HorizontalPageMenu, {
  TPageHorizontalMenuItem,
} from '../../components/ui/horizontal-page-menu';
import { WidgetEmptyWrapper } from '../../components/ui/widget/widget-settings';
import { EPaths } from '../../routes/route-paths';
import { useAppSelector } from '../../state';
import { widgetSettingsSelector } from '../../state/widgets/selectors';
import { extractDomain } from '../../utils/regex';
import WidgetStatistic from '../statistic/widget-statistic';

export const StatisticPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const settingsList = useAppSelector(widgetSettingsSelector);

  useLayoutEffect(() => {
    if (settingsList.list.length > 0) {
      navigate(settingsList.list[0].widgetFolder);
    }
  }, []);

  const menu: TPageHorizontalMenuItem[] = useMemo(
    () => [
      ...settingsList.list.map((el) => ({
        to: `${EPaths.STATISTIC}/${el.widgetFolder}`,
        text: extractDomain(el.domain),
      })),
    ],
    [settingsList]
  );

  const selectedWidget = useMemo(() => {
    const locationPaths = location.pathname.split('/');
    const widgetName = locationPaths[2];

    if (widgetName) {
      return settingsList.list.find((el) => el.widgetFolder === widgetName);
    }

    return undefined;
  }, [location, settingsList]);

  return (
    <BasePageWrapper>
      <HorizontalPageMenu menu={menu} label="Статистика сайта" />
      {selectedWidget && <WidgetStatistic widget={selectedWidget} />}
      {!selectedWidget && (
        <WidgetEmptyWrapper>
          <Empty description="Домен не выбран, или отсутствует" />
        </WidgetEmptyWrapper>
      )}
    </BasePageWrapper>
  );
};
