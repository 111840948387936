import { TWidgetSettings } from '../../../api/features/widget/types';

const BASE_THEME: TWidgetSettings['theme']['COLORS'] = {
  COMMON: {
    ACCENT: '#4C2FCF',
    ON_ACCENT: '#FFFFFF',
    BASE_RADIUS: 8,
    TEXT_COMMON: '#333333',
  },
  ACCESS_BUTTON: {
    MAIN_COLOR: '#4C2FCF',
    SECONDARY_COLOR: '#4C2FCF',
    INSIDE_ELEMENTS_COLOR: '#FFFFFF',
  },
  MENU: {
    SHADOW: '0 0 20px rgba(0, 0, 0, 0.2)',
    BACKGROUND: '#F1F3F6',
    SCROLLBAR_BG: '#878E9A',
    SCROLLBAR_BG_HOVERED: '#4A5262',
    HEADER_BG: '#F1F3F6',
    HEADER_TEXT_COLOR: '#333333',
    TEXT_ON_MENU: '#333333',
    RESET_BUTTON: {
      BACKGROUND: '#4C2FCF',
      BACKGROUND_HOVERED: '#4C2FCF',
      TEXT: '#FFFFFF',
    },
    MENU_ITEM: {
      BORDER_RADIUS: 8,
      BACKGROUND: '#F1F3F6',
      BORDER: '#EAEAEA',
      TITLE: '#4A5262',
      TEXT: '#4A5262',
      SWITCH_BG: '#4A5262',
    },
    SUB_GROUP: {
      BACKGROUND: '#FFFFFF',
      BORDER_RADIUS: 16,
      TITLE_COLOR: '#4A5262',
    },
  },
};

export default BASE_THEME;
