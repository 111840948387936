import { useEffect, useState } from 'react';

import { IS_MODULE } from '../../constants';
import CiteThemeIcon from '../../icons/citeThemeIcon';
import { WIDGET_NAME } from '../../index';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { TSettingItem } from '../../types/widget';
import {
  getObserveColorThemeFn,
  traverseDOMAccessTheme,
  updateObserverRecords,
} from '../../utils/color-theme-transform';
import { ETextEffectKeys } from '../names';

const initFunction: () => Promise<MutationObserver> = async () => {
  const widgetContainer = document.querySelector(WIDGET_NAME);

  traverseDOMAccessTheme(document.documentElement);

  const citeThemeObserverMutation: MutationCallback = getObserveColorThemeFn({
    ignoreContainer: widgetContainer,
  });

  const observer = new MutationObserver(citeThemeObserverMutation);

  const config: MutationObserverInit = {
    attributes: true,
    childList: true,
    subtree: true,
  };

  observer.observe(document.documentElement, config);

  return observer;
};

export const citeThemeEffect: TSettingItem<typeof initFunction> = {
  Icon: CiteThemeIcon,
  key: ETextEffectKeys.CITE_THEME,
  needToInitialize: true,
  initializeFunction: initFunction,
};

let effectInited = false;

export const useCiteThemeEffect = () => {
  const effect = useEffectSelector(ETextEffectKeys.CITE_THEME);

  const [inited, setInited] = useState(effectInited);

  useEffect(() => {
    let observer: MutationObserver;

    if (!effectInited && effect.value !== false && !IS_MODULE) {
      setInited(false);
      effectInited = true;
      initFunction().then((res) => {
        setInited(true);
        observer = res;
      });
    }

    return () => {
      if (observer) {
        const widgetContainer = document.querySelector(WIDGET_NAME);

        observer.disconnect();
        observer
          .takeRecords()
          .forEach((record) => updateObserverRecords(record, widgetContainer));
      }
    };
  }, [effect]);

  return [inited];
};
