import { OBSERVER_IGNORE_LIST } from '../init-functions/prepare-font-size';
import { TAlternativeContentItem } from '../types/api/effects';
import { getElementByXPath, getXPath } from '../types/x-path';

enum EAlternativeAttributes {
  ORIGINAL_VALUE = 'accessibilitySimpleLanguageOriginal',
  IS_ACTIVE = 'accessibilitySimpleLanguageActive',
}

export function setAlternativeContent(setting: TAlternativeContentItem) {
  const element = getElementByXPath(setting.pathJSElem);

  if (element && element.dataset[EAlternativeAttributes.IS_ACTIVE] !== 'true') {
    element.dataset[EAlternativeAttributes.ORIGINAL_VALUE] =
      element.textContent ?? '';
    element.dataset[EAlternativeAttributes.IS_ACTIVE] = 'true';
    element.textContent = setting.alternativeValue;
  }
}

export function clearAlternativeContent(setting: TAlternativeContentItem) {
  const element = getElementByXPath(setting.pathJSElem);

  if (element && element.dataset[EAlternativeAttributes.IS_ACTIVE] === 'true') {
    element.dataset[EAlternativeAttributes.IS_ACTIVE] = 'false';
    element.textContent =
      element.dataset[EAlternativeAttributes.ORIGINAL_VALUE] ??
      setting.originalValue;
  }
}

type TAlternativeContentObserverProps = {
  isSuccess: boolean;
  isSettingOn: boolean;
  isActive: boolean;
  ignoreContainer: Element | null;
  settings?: TAlternativeContentItem[];
};

export function updateNodeBySetting({
  settings,
  isActive,
  node,
}: Pick<TAlternativeContentObserverProps, 'settings' | 'isActive'> & {
  node: HTMLElement;
}) {
  const xPath = getXPath(node);
  const settingForItem =
    xPath &&
    settings?.find?.((el) => {
      return el.pathJSElem === xPath;
    });

  if (
    isActive &&
    settingForItem &&
    node?.dataset?.[EAlternativeAttributes.IS_ACTIVE] !== 'true'
  ) {
    setAlternativeContent(settingForItem);
  } else if (
    !isActive &&
    settingForItem &&
    node?.dataset?.[EAlternativeAttributes.IS_ACTIVE] === 'true'
  ) {
    clearAlternativeContent(settingForItem);
  }

  if (node.children) {
    for (const child of node.children as unknown as HTMLElement[]) {
      updateNodeBySetting({
        settings,
        isActive,
        node: child,
      });
    }
  }
}

export const getObserveAlternateContentFn: (
  props: TAlternativeContentObserverProps
) => MutationCallback =
  ({ isSuccess, isActive, isSettingOn, ignoreContainer, settings }) =>
  (mutationsList) => {
    if (isSuccess && isSettingOn && settings) {
      for (const el of mutationsList) {
        if (
          el?.target?.nodeName &&
          !OBSERVER_IGNORE_LIST.includes(el.target.nodeName) &&
          el?.addedNodes?.length > 0 &&
          !ignoreContainer?.contains?.(el.target)
        ) {
          for (const node of el.addedNodes as unknown as HTMLElement[]) {
            updateNodeBySetting({
              settings,
              isActive,
              node,
            });
          }
        }
      }
    }
  };
