import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ConfigProvider, Form, FormInstance } from 'antd';
import { CSSProperties, ReactElement } from 'react';

import { FormLabel } from '../../components/forms/base-form-components';
import { AntBaseButton } from '../../components/ui/buttons/ant-base-button';

type TProps<FormType> = {
  label: string;
  formInstance: FormInstance<FormType>;
  FormItems: ReactElement;
  AdditionalLinks?: ReactElement;
  buttonText: string;
  theme: Theme;
  loading?: boolean;
  onFormFinish: (values: FormType) => void;
};

const buttonStyles: CSSProperties = {
  width: '100%',
};

const Wrapper = styled('section')`
  label {
    padding-left: 0.75rem;
  }

  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }
`;

const AdditionalLinksWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-top: 24px;

  a {
    font-size: 1rem;
    color: ${({ theme }) => theme.MAIN.TEXT.C200};
    padding-left: 12px;
    transition: color 0.2s ease-out;

    &:hover {
      color: ${({ theme }) => theme.MAIN.ACCENT.C100};
    }
  }
`;

export const AppBaseMenuForm: <FormType extends object>(
  props: TProps<FormType>
) => JSX.Element = ({
  label,
  formInstance,
  FormItems,
  theme,
  buttonText,
  loading,
  AdditionalLinks,
  onFormFinish,
}) => {
  return (
    <Wrapper>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: theme.MAIN.WHITE.C100,
            colorBorder: theme.MAIN.WHITE.C100,
          },
        }}
      >
        <FormLabel>{label}</FormLabel>
        <Form
          layout="vertical"
          form={formInstance}
          requiredMark={false}
          onFinish={onFormFinish}
        >
          {FormItems}
          <AntBaseButton
            type="primary"
            loading={loading}
            style={buttonStyles}
            htmlType="submit"
          >
            {buttonText}
          </AntBaseButton>
        </Form>
        {AdditionalLinks && (
          <AdditionalLinksWrapper>{AdditionalLinks}</AdditionalLinksWrapper>
        )}
      </ConfigProvider>
    </Wrapper>
  );
};
