import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AppBaseMenu } from '../containers/menu/app-base-menu';
import { LoginPage } from '../containers/pages/login-page';
// import { RegistrationPage } from '../containers/pages/registration-page'; ALSO UNCOMMENT/REMOVE USAGE
import { EPaths } from './route-paths';

export const AppAsideLayoutRoutes: FC = () => {
  return (
    <Routes>
      <Route path={EPaths.LOGIN} element={<LoginPage />} />
      {/*<Route path={EPaths.REGISTRATION} element={<RegistrationPage />} /> ALSO UNCOMMENT/REMOVE IMPORT */}
      <Route path={'/*'} element={<AppBaseMenu />} />
    </Routes>
  );
};
