import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

const Text = styled.h3``;

const SubTitle: FC<
  PropsWithChildren & {
    theme: Theme;
  }
> = ({ children, theme }) => {
  return <Text theme={theme}>{children}</Text>;
};

export default withTheme(SubTitle);
