import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { FC } from 'react';

import CloseIcon from '../../icons/closeIcon';
import { useWidgetSettingsSelector } from '../../state/reducers/widget/selectors';
import { textToBoolean, toTextBoolean } from '../../utils/common';
import { TabIndex } from '../../utils/tab-index';

const Wrapper = styled('div')<{
  widgetIncreased: 'true' | 'false';
}>`
  width: 100%;
  background-color: ${({ theme }) => theme.COLORS.MENU.HEADER_BG};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 32px;
  font-family: ${({ theme }) => theme.SETTINGS.COMMON.FONT};
  color: ${({ theme }) => theme.COLORS.MENU.HEADER_TEXT_COLOR};

  @media only screen and (max-width: 520px) {
    padding-left: 24px;
  }

  h3 {
    font-weight: bold;
    flex: 1;
    font-size: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 48 : 24}px;
    line-height: 140%;
    margin: 0;
    padding: 0;
    word-break: break-word;

    @media only screen and (max-width: 520px) {
      font-size: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 34 : 20}px;
    }
  }
`;

const IconWrapper = styled('button')<{
  widgetIncreased: 'true' | 'false';
}>`
  background-color: transparent !important;
  border: none;
  margin: 0;
  padding: 0;
  width: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 66 : 56}px;
  height: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 66 : 56}px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 520px) {
    width: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 56 : 44}px;
    height: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 56 : 44}px;
  }

  svg {
    width: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 60 : 30}px;
    height: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 60 : 30}px;

    @media only screen and (max-width: 520px) {
      width: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 50 : 24}px;
      height: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 50 : 24}px;
    }
  }
`;

type TProps = {
  theme: Theme;
  handleClose?: () => void;
  t: TFunction<string>;
};

const Header: FC<TProps> = ({ theme, handleClose, t }) => {
  const { widgetIncreased } = useWidgetSettingsSelector();

  return (
    <Wrapper theme={theme} widgetIncreased={toTextBoolean(widgetIncreased)}>
      <h3>{t('header')}</h3>
      <IconWrapper
        onClick={handleClose}
        theme={theme}
        aria-label={t('closeButtonAriaLabel')}
        title={t('closeButtonAriaLabel')}
        tabIndex={TabIndex.CLOSE_BUTTON}
        widgetIncreased={toTextBoolean(widgetIncreased)}
      >
        <CloseIcon theme={theme} />
      </IconWrapper>
    </Wrapper>
  );
};

export default withTheme(Header);
