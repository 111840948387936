import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TPresetKey } from '../../../../presets';

export type TPresetsState = {
  [TPresetKey.Epilepsy]: boolean;
  [TPresetKey.Blind]: boolean;
  [TPresetKey.ADHD]: boolean;
  [TPresetKey.Cognitive]: boolean;
};

const initialState: TPresetsState = {
  [TPresetKey.Epilepsy]: false,
  [TPresetKey.Blind]: false,
  [TPresetKey.ADHD]: false,
  [TPresetKey.Cognitive]: false,
};

const presetSlice = createSlice({
  name: 'widget/preset',
  initialState: initialState,
  reducers: {
    setPresetAction: (
      state,
      action: PayloadAction<{
        key: TPresetKey;
        value: boolean;
      }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { setPresetAction } = presetSlice.actions;
export const presetReducer = presetSlice.reducer;
