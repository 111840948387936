import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { getIconBaseColor } from '../../utils/ui/icons';
import { TBaseIconProps } from './index';

const SettingsIcon: FC<TBaseIconProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_598_914)">
        <path
          d="M1.2051 13.5C1.82546 14.5767 3.20123 14.9466 4.27793 14.3262C4.27867 14.3258 4.27937 14.3254 4.28011 14.325L4.61385 14.1322C5.24385 14.6712 5.96799 15.0892 6.74983 15.3652V15.75C6.74983 16.9926 7.7572 18 8.99983 18C10.2425 18 11.2498 16.9926 11.2498 15.75V15.3652C12.0318 15.0888 12.756 14.6703 13.3858 14.1307L13.7211 14.3242C14.798 14.9456 16.1748 14.5762 16.7961 13.4992C17.4174 12.4223 17.048 11.0455 15.9711 10.4242L15.6381 10.2322C15.788 9.41657 15.788 8.58041 15.6381 7.76472L15.9711 7.57273C17.048 6.95141 17.4174 5.57469 16.7961 4.49771C16.1748 3.42077 14.798 3.05139 13.7211 3.6727L13.3873 3.86546C12.7567 3.32712 12.0321 2.90988 11.2498 2.63475V2.25C11.2498 1.00737 10.2425 0 8.99983 0C7.7572 0 6.74983 1.00737 6.74983 2.25V2.63475C5.96789 2.91118 5.24371 3.32968 4.61385 3.86926L4.2786 3.67502C3.20162 3.05367 1.8249 3.42306 1.20358 4.5C0.582268 5.57694 0.95162 6.9537 2.0286 7.57501L2.3616 7.767C2.21169 8.58266 2.21169 9.41882 2.3616 10.2345L2.0286 10.4265C0.954608 11.0495 0.586452 12.4235 1.2051 13.5ZM8.99983 6.00001C10.6567 6.00001 11.9998 7.34316 11.9998 9C11.9998 10.6568 10.6567 12 8.99983 12C7.34299 12 5.99985 10.6568 5.99985 9C5.99985 7.34316 7.34299 6.00001 8.99983 6.00001Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
      </g>
      <defs>
        <clipPath id="clip0_598_914">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(SettingsIcon);
