import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

import { textToBoolean, toTextBoolean } from '../../utils/common';

const Wrapper = styled('div')<{
  widgetIncreased?: 'true' | 'false';
  active: 'true' | 'false';
}>`
  width: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 60 : 30}px;
  height: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 60 : 30}px;
  border-radius: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 24 : 12}px;
  cursor: pointer;
  z-index: 2;
  color: ${({ active, theme }) =>
    textToBoolean(active)
      ? theme.COLORS.COMMON.ACCENT
      : theme.COLORS.COMMON.ON_ACCENT};
  text-align: center;
  font-size: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 32 : 16}px;
  line-height: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 60 : 30}px;
  font-family: ${({ theme }) => theme.SETTINGS.COMMON.FONT};
  font-style: normal;
  font-weight: 400;
  transition: color 0.3s ease-out;

  @media only screen and (max-width: 520px) {
    width: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 50 : 30}px;
    height: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 50 : 30}px;
    border-radius: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 19 : 12}px;
  }
`;

type TProps = {
  theme: Theme;
  onClick?: () => void;
  active?: boolean;
  widgetIncreased?: 'true' | 'false';
};

const MarkArea: FC<PropsWithChildren<TProps>> = ({
  children,
  onClick,
  theme,
  active = false,
  widgetIncreased,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      active={toTextBoolean(active)}
      theme={theme}
      widgetIncreased={widgetIncreased}
    >
      {children}
    </Wrapper>
  );
};

export default withTheme(MarkArea);
