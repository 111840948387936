import styled from '@emotion/styled';
import { TStatEvent } from '@kylfyk/accessability-react/dist/types/statistic';
import { Table } from 'antd';
import { FC, useMemo } from 'react';

import { ColumnsType } from 'antd/lib/table';

import { useGetStatisticByDomainQuery } from '../../api/features/statistic';
import { TWidgetSettingsItem } from '../../api/features/widget/types';
import { getAccessWidgetStatsColumns } from '../tables/columns/access-widget-stats-columns';
import { TAccessWidgetStatData } from '../tables/types/access-widget-stats-types';

type TProps = {
  widget: TWidgetSettingsItem;
};

const TableWrapper = styled.div`
  margin-block: 24px;
`;

const WidgetStatistic: FC<TProps> = ({ widget }) => {
  const { data, isFetching } = useGetStatisticByDomainQuery(
    widget.domain as string,
    {
      skip: !widget.id,
    }
  );

  const tableData: TAccessWidgetStatData[] = useMemo(() => {
    // @ts-ignore
    const eventsTable: Record<
      TStatEvent,
      Omit<TAccessWidgetStatData, 'count'>[]
    > = {};

    if (data) {
      data.forEach((statItem) => {
        if (eventsTable.hasOwnProperty(statItem.json.event)) {
          eventsTable[statItem.json.event].push({
            key: statItem.id,
            event: statItem.json.event,
            text: statItem.json.text,
            time: statItem.json.time,
          });
        } else {
          eventsTable[statItem.json.event] = [
            {
              key: statItem.id,
              event: statItem.json.event,
              text: statItem.json.text,
              time: statItem.json.time,
            },
          ];
        }
      });
    } else {
      return [];
    }

    const result: TAccessWidgetStatData[] = [];

    (Object.keys(eventsTable) as (keyof typeof eventsTable)[]).forEach(
      (key) => {
        if (eventsTable[key]) {
          result.push({
            ...(eventsTable[key] as Omit<TAccessWidgetStatData, 'count'>[])[0],
            count: eventsTable[key]?.length ?? 0,
          });
        }
      }
    );

    return result;
  }, [data]);

  const columns: ColumnsType<TAccessWidgetStatData> = useMemo(() => {
    return getAccessWidgetStatsColumns();
  }, []);

  return (
    <TableWrapper>
      <Table
        columns={columns}
        dataSource={tableData}
        loading={isFetching}
        pagination={false}
      />
    </TableWrapper>
  );
};

export default WidgetStatistic;
