import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import widgetsSettingsReducer from './widget-settings.slice';
import widgetsAllReducer from './widget.slice';

const settingsConfig = {
  key: 'widget/settings/persist',
  storage,
};

export const widgetsReducer = combineReducers({
  all: widgetsAllReducer,
  settings: persistReducer(settingsConfig, widgetsSettingsReducer),
});
