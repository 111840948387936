import { MinusOutlined } from '@ant-design/icons';
import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, ConfigProvider, Form, Input, Space } from 'antd';
import React, { FC, memo } from 'react';

import { TWidget } from '../../api/features/widget/types';
import { usePageContext } from '../../components/pages/base-page-wrapper';
import { URL_REGEX } from '../../utils/regex';

const { TextArea } = Input;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  background-color: ${({ theme }) => theme.MAIN.WHITE.C200};
  padding: 16px;
  grid-gap: 16px;
`;

const AlternateCard = styled.div`
  padding: 14px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.MAIN.WHITE.C100};
`;

const StyledSpace = styled(Space)`
  align-items: flex-end;
  margin-bottom: 12px;
  width: 100%;

  & > .ant-space-item:first-of-type {
    flex: 1;
  }

  & > .ant-space-item:nth-of-type(2) {
    flex: 1;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled(Space.Compact)`
  padding-block: 40px;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.MAIN.WHITE.C100};
`;

type TProps = {
  widget: TWidget;
  isLoading: boolean;
  theme: Theme;
};

const WidgetAlternativeContentForm: FC<TProps> = memo(
  ({ widget, isLoading, theme }) => {
    const { scrollToBottom } = usePageContext();

    return (
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBgContainer: theme.MAIN.WHITE.C200,
              colorBorder: theme.MAIN.WHITE.C100,
              borderRadius: 4,
            },
          },
        }}
      >
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              <Wrapper>
                {fields.map(({ key, name: inputName, ...restField }) => (
                  <AlternateCard key={key}>
                    <StyledSpace>
                      <Form.Item
                        {...restField}
                        name={[inputName, 'pathJSElem']}
                        label="XPath"
                        rules={[
                          {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                          },
                        ]}
                      >
                        <Input placeholder="XPath" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[inputName, 'fullDomainPath']}
                        label="URL адрес страницы"
                        rules={[
                          {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                          },
                          {
                            pattern: URL_REGEX,
                            // message: t('form.rules.urlInvalid'), Сделать общий перевод для ошибок форм
                            message:
                              'URL не валиден. Пример: https://example.com',
                          },
                          {
                            validator: (_: any, value: string) =>
                              value?.endsWith('/')
                                ? Promise.reject(
                                    new Error('Вводите адрес без "/" в конце')
                                  )
                                : Promise.resolve(),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Button
                        icon={<MinusOutlined />}
                        shape="circle"
                        onClick={() => {
                          remove(inputName);
                        }}
                      />
                    </StyledSpace>
                    <Form.Item
                      {...restField}
                      name={[inputName, 'alternativeValue']}
                      aria-label="Упрощенный текст"
                      rules={[
                        {
                          required: true,
                          message: 'Поле обязательно для заполнения',
                        },
                      ]}
                    >
                      <TextArea
                        autoSize={{
                          minRows: 2,
                          maxRows: 5,
                        }}
                        autoCorrect="on"
                        spellCheck="true"
                        placeholder="Введите упрощенный текст"
                      />
                    </Form.Item>
                  </AlternateCard>
                ))}
              </Wrapper>
              <ButtonWrapper>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Сохранить
                </Button>
                <Button
                  onClick={() => {
                    add({
                      fullDomainPath: widget.domain,
                    });
                    setTimeout(() => {
                      scrollToBottom({
                        block: 'end',
                        behavior: 'smooth',
                      });
                    }, 200);
                  }}
                >
                  Добавить элемент
                </Button>
              </ButtonWrapper>
            </>
          )}
        </Form.List>
      </ConfigProvider>
    );
  }
);

export default withTheme(WidgetAlternativeContentForm);
