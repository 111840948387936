import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { TIconProps } from './types';

const CursorIcon: FC<TIconProps> = ({ theme }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21_21)">
        <mask
          id="mask0_21_21"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="-1"
          width="16"
          height="25"
        >
          <path
            d="M15.008 23.9999C14.4538 24.0008 13.9101 23.8485 13.4369 23.5598C12.9638 23.2711 12.5797 22.8572 12.327 22.3639L9.2 15.6599L5.658 18.7529C5.51338 18.8793 5.33542 18.9614 5.14541 18.9893C4.95539 19.0172 4.76135 18.9898 4.58649 18.9104C4.41162 18.831 4.26332 18.7029 4.15932 18.5415C4.05531 18.38 4 18.192 4 17.9999V2.97994C3.99997 2.40205 4.16685 1.83646 4.48058 1.35114C4.7943 0.865831 5.24152 0.481457 5.76847 0.24423C6.29542 0.00700189 6.87968 -0.0729823 7.45099 0.0138921C8.02231 0.100767 8.55638 0.350802 8.989 0.733941L19.689 10.9719C19.8278 11.1046 19.9256 11.2743 19.9705 11.461C20.0154 11.6477 20.0057 11.8433 19.9424 12.0246C19.8792 12.2059 19.765 12.3651 19.6137 12.4833C19.4624 12.6014 19.2802 12.6735 19.089 12.6909L14.621 13.0979L17.686 19.6669C17.9125 20.1236 18.0189 20.6304 17.9952 21.1396C17.9714 21.6488 17.8184 22.1436 17.5504 22.5772C17.2824 23.0108 16.9083 23.369 16.4635 23.6179C16.0187 23.8668 15.5177 23.9983 15.008 23.9999Z"
            fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
          />
        </mask>
        <g mask="url(#mask0_21_21)">
          <rect x="4" width="16" height="24" fill="transparent" />
          <path
            d="M15.008 23.9999C14.4538 24.0008 13.9101 23.8485 13.4369 23.5598C12.9638 23.2711 12.5797 22.8572 12.327 22.3639L9.2 15.6599L5.658 18.7529C5.51338 18.8793 5.33542 18.9614 5.14541 18.9893C4.95539 19.0172 4.76135 18.9898 4.58649 18.9104C4.41162 18.831 4.26332 18.7029 4.15932 18.5415C4.05531 18.38 4 18.192 4 17.9999V2.97994C3.99997 2.40205 4.16685 1.83646 4.48058 1.35114C4.7943 0.865831 5.24152 0.481457 5.76847 0.24423C6.29542 0.00700189 6.87968 -0.0729823 7.45099 0.0138921C8.02231 0.100767 8.55638 0.350802 8.989 0.733941L19.689 10.9719C19.8278 11.1046 19.9256 11.2743 19.9705 11.461C20.0154 11.6477 20.0057 11.8433 19.9424 12.0246C19.8792 12.2059 19.765 12.3651 19.6137 12.4833C19.4624 12.6014 19.2802 12.6735 19.089 12.6909L14.621 13.0979L17.686 19.6669C17.9125 20.1236 18.0189 20.6304 17.9952 21.1396C17.9714 21.6488 17.8184 22.1436 17.5504 22.5772C17.2824 23.0108 16.9083 23.369 16.4635 23.6179C16.0187 23.8668 15.5177 23.9983 15.008 23.9999ZM9.543 13.0319C9.61253 13.032 9.68188 13.039 9.75 13.0529C9.90181 13.085 10.0441 13.1519 10.1656 13.2484C10.2871 13.3449 10.3845 13.4683 10.45 13.6089L14.125 21.4849C14.1867 21.601 14.2707 21.7037 14.3721 21.7873C14.4735 21.8708 14.5904 21.9336 14.7161 21.972C14.8418 22.0104 14.9738 22.0237 15.1046 22.011C15.2354 21.9984 15.3625 21.9602 15.4785 21.8984C15.5945 21.8367 15.6973 21.7528 15.7808 21.6513C15.8644 21.5499 15.9272 21.433 15.9656 21.3073C16.004 21.1816 16.0172 21.0496 16.0046 20.9188C15.992 20.788 15.9537 20.661 15.892 20.5449L12.21 12.6539C12.1421 12.5085 12.1102 12.3489 12.1169 12.1886C12.1237 12.0283 12.169 11.8719 12.2489 11.7327C12.3288 11.5936 12.4411 11.4757 12.5762 11.3891C12.7112 11.3024 12.8652 11.2496 13.025 11.2349L16.725 10.8979L7.634 2.20594C7.49154 2.08488 7.31718 2.00746 7.13183 1.98299C6.94648 1.95851 6.75801 1.98801 6.589 2.06794C6.41235 2.14563 6.26238 2.27346 6.15768 2.43558C6.05298 2.59769 5.99815 2.78697 6 2.97994V15.7999L8.885 13.2799C9.06708 13.1203 9.30088 13.0322 9.543 13.0319V13.0319Z"
            fill={theme.COLORS.MENU.MENU_ITEM.TEXT}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_21_21">
          <rect width="24" height="24" fill="transparent" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(CursorIcon);
