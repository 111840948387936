import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { effectsApi } from '../../../../api/features';
import { ETextEffectKeys, TEffectTextKey } from '../../../../effects/names';
import { TSettingValue, TSettingValueInfo } from '../../../../types/widget';

export type TEffectsSliceState = Record<TEffectTextKey, TSettingValueInfo>;

export const effectsInitialState: TEffectsSliceState = {
  [ETextEffectKeys.TEXT_INCREASE]: {
    value: false,
  },
  [ETextEffectKeys.READABLE_FONT]: {
    value: false,
  },
  [ETextEffectKeys.DYSLEXIC_FONT]: {
    value: false,
  },
  [ETextEffectKeys.SIMPLE_LANGUAGE]: {
    value: false,
  },
  [ETextEffectKeys.CURSOR]: {
    value: false,
  },
  [ETextEffectKeys.CITE_THEME]: {
    value: false,
  },
  [ETextEffectKeys.INVERT_COLORS]: {
    value: false,
  },
  [ETextEffectKeys.BRIGHTNESS]: {
    value: false,
  },
  [ETextEffectKeys.CONTRAST]: {
    value: false,
  },
  [ETextEffectKeys.GRAYSCALE]: {
    value: false,
  },
  [ETextEffectKeys.READ_LINE]: {
    value: false,
  },
  [ETextEffectKeys.READ_AREA]: {
    value: false,
  },
  [ETextEffectKeys.HIDE_IMAGES]: {
    value: false,
  },
  [ETextEffectKeys.STOP_ANIMATIONS]: {
    value: false,
  },
  [ETextEffectKeys.LINKS_MARK]: {
    value: false,
  },
  [ETextEffectKeys.TEXT_TO_SPEECH]: {
    value: false,
  },
  [ETextEffectKeys.CONTENT_SCALE]: {
    value: false,
  },
};

const effectsSlice = createSlice({
  name: 'widget/effects',
  initialState: { ...effectsInitialState } as TEffectsSliceState,
  reducers: {
    resetEffects() {
      return { ...effectsInitialState };
    },
    setEffectValue(
      state,
      action: PayloadAction<{
        key: TEffectTextKey;
        value: TSettingValue;
      }>
    ) {
      try {
        if (state[action.payload.key]) {
          state[action.payload.key].value = action.payload.value;
        } else {
          state[action.payload.key] = {
            value: action.payload.value,
          };
        }
      } catch (e) {
        state[action.payload.key] = {
          ...effectsInitialState[action.payload.key],
          value: action.payload.value,
        };
      }
    },
    setEffectLoadingAction(
      state,
      action: PayloadAction<{
        key: TEffectTextKey;
        loading: boolean;
      }>
    ) {
      try {
        if (state[action.payload.key]) {
          state[action.payload.key].loading = action.payload.loading;
        } else {
          state[action.payload.key] = {
            ...effectsInitialState[action.payload.key],
            ...state[action.payload.key],
            loading: action.payload.loading,
          };
        }
      } catch (e) {
        state[action.payload.key] = {
          ...effectsInitialState[action.payload.key],
          loading: action.payload.loading,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      effectsApi.endpoints.getCiteSimpleLanguage.matchPending,
      (state) => {
        if (ETextEffectKeys.SIMPLE_LANGUAGE in state) {
          state[ETextEffectKeys.SIMPLE_LANGUAGE] = {
            ...effectsInitialState[ETextEffectKeys.SIMPLE_LANGUAGE],
            ...state[ETextEffectKeys.SIMPLE_LANGUAGE],
            loading: true,
          };
        }
      }
    );
    builder.addMatcher(
      effectsApi.endpoints.getCiteSimpleLanguage.matchRejected,
      (state) => {
        if (ETextEffectKeys.SIMPLE_LANGUAGE in state) {
          state[ETextEffectKeys.SIMPLE_LANGUAGE] = {
            ...effectsInitialState[ETextEffectKeys.SIMPLE_LANGUAGE],
            ...state[ETextEffectKeys.SIMPLE_LANGUAGE],
            loading: false,
          };
        }
      }
    );
    builder.addMatcher(
      effectsApi.endpoints.getCiteSimpleLanguage.matchFulfilled,
      (state) => {
        if (ETextEffectKeys.SIMPLE_LANGUAGE in state) {
          state[ETextEffectKeys.SIMPLE_LANGUAGE] = {
            ...effectsInitialState[ETextEffectKeys.SIMPLE_LANGUAGE],
            ...state[ETextEffectKeys.SIMPLE_LANGUAGE],
            loading: false,
          };
        }
      }
    );
  },
});

export const { setEffectValue, resetEffects, setEffectLoadingAction } =
  effectsSlice.actions;
export const effectsReducer = effectsSlice.reducer;
