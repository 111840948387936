import styled from '@emotion/styled';

export const FormLabel = styled('h2')`
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.125rem;
  margin-bottom: 26px;
  color: ${({ theme }) => theme.MAIN.TEXT.C200};
  padding-left: 0.75rem;
`;
