import { Theme } from '@emotion/react';

export type TButtonThemeProps = {
  backgroundColor: string;
  hoverBackgroundColor: string;
  color: string;
  hoverColor: string;
  padding: string;
  fontSize: string;
  borderRadius: string;
};

export enum EButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export enum EButtonSizes {
  SM = 'small',
  DEFAULT = 'default',
  L = 'large',
}

export const GET_BUTTON_PRIMARY_COLORS: (
  theme: Theme
) => Record<EButtonTypes, string> = (theme) => ({
  [EButtonTypes.PRIMARY]: theme.MAIN.ACCENT.C100,
  [EButtonTypes.SECONDARY]: theme.MAIN.WHITE.C100,
  [EButtonTypes.DANGER]: theme.MAIN.DANGER.C100,
  [EButtonTypes.WARNING]: theme.MAIN.WARNING.C100,
  [EButtonTypes.SUCCESS]: theme.MAIN.SUCCESS.C100,
});

export const GET_BUTTON_PRIMARY_TEXT_COLORS: (
  theme: Theme
) => Record<EButtonTypes, string> = (theme) => ({
  [EButtonTypes.PRIMARY]: theme.MAIN.WHITE.C100,
  [EButtonTypes.SECONDARY]: theme.MAIN.TEXT.C200,
  [EButtonTypes.DANGER]: theme.MAIN.WHITE.C100,
  [EButtonTypes.WARNING]: theme.MAIN.WHITE.C100,
  [EButtonTypes.SUCCESS]: theme.MAIN.WHITE.C100,
});

export const GET_BUTTON_HOVER_PRIMARY_TEXT_COLORS: (
  theme: Theme
) => Record<EButtonTypes, string> = (theme) => ({
  [EButtonTypes.PRIMARY]: theme.MAIN.WHITE.C100,
  [EButtonTypes.SECONDARY]: theme.MAIN.TEXT.C100,
  [EButtonTypes.DANGER]: theme.MAIN.WHITE.C100,
  [EButtonTypes.WARNING]: theme.MAIN.WHITE.C100,
  [EButtonTypes.SUCCESS]: theme.MAIN.WHITE.C100,
});

export const mapButtonTypeToButtonTheme = (
  type: EButtonTypes,
  size: EButtonSizes,
  theme: Theme
) => {
  let result: TButtonThemeProps = {
    backgroundColor: GET_BUTTON_PRIMARY_COLORS(theme)[type],
    hoverBackgroundColor: theme.MAIN.WHITE.C300,
    color: GET_BUTTON_PRIMARY_TEXT_COLORS(theme)[type],
    hoverColor: GET_BUTTON_HOVER_PRIMARY_TEXT_COLORS(theme)[type],
    padding: '0.875rem 0.75rem',
    fontSize: '1rem',
    borderRadius: '4px',
  };

  switch (type) {
    case EButtonTypes.SECONDARY:
      break; // Initial значение
    case EButtonTypes.DANGER:
      result = {
        ...result,
        hoverBackgroundColor: theme.MAIN.DANGER.C200,
      };
      break;
    case EButtonTypes.PRIMARY:
      result = {
        ...result,
        hoverBackgroundColor: theme.MAIN.ACCENT.C200,
      };
      break;
    case EButtonTypes.WARNING:
      result = {
        ...result,
        hoverBackgroundColor: theme.MAIN.WARNING.C200,
      };
      break;
    case EButtonTypes.SUCCESS:
      result = {
        ...result,
        hoverBackgroundColor: theme.MAIN.SUCCESS.C200,
      };
      break;
  }

  switch (size) {
    case EButtonSizes.DEFAULT:
      break; // Initial значение
    case EButtonSizes.L:
      result = {
        ...result,
        padding: '1rem 1.125rem',
        fontSize: '1rem',
        borderRadius: '6px',
      };
      break;
    case EButtonSizes.SM:
      result = {
        ...result,
        padding: '0.625rem',
        fontSize: '0.75rem',
        borderRadius: '2px',
      };
      break;
  }

  return result;
};
