import styled from '@emotion/styled';

import { textToBoolean } from '../../utils/common';

export const SwitchMarker = styled('div')<{
  widgetIncreased?: 'true' | 'false';
  left: number;
}>`
  width: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 48 : 24}px;
  height: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 48 : 24}px;
  border-radius: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 24 : 12}px;
  background-color: ${({ theme }) => theme.COLORS.COMMON.ON_ACCENT};
  position: absolute;
  top: ${({ widgetIncreased }) => (textToBoolean(widgetIncreased) ? 6 : 3)}px;
  bottom: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 6 : 3}px;
  left: ${({ left }) => left}px;
  pointer-events: none;
  transition: left 0.3s ease-out;
  z-index: 0;

  @media only screen and (max-width: 520px) {
    top: ${({ widgetIncreased }) => (textToBoolean(widgetIncreased) ? 5 : 3)}px;
    width: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 40 : 24}px;
    height: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 40 : 24}px;
    border-radius: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 20 : 12}px;
  }
`;
