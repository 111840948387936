import { combineReducers } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { getPersistConfig } from '../../utils/get-persist-config';
import { effectsMigrations } from './migrations/effects.migrations';
import { TEffectsSliceState, effectsReducer } from './slices/effectsSlice';
import { mainStateReducer } from './slices/mainStateSlice';
import { TPresetsState, presetReducer } from './slices/presetSlice';
import {
  TWidgetSettingsState,
  widgetSettingsReducer,
} from './slices/settingsSlice';

const effectsConfig: PersistConfig<TEffectsSliceState> = getPersistConfig({
  key: 'effects/storage',
  stateMigrations: effectsMigrations,
});

const presetsConfig: PersistConfig<TPresetsState> = getPersistConfig({
  key: 'presets/storage',
});

const settingsConfig: PersistConfig<TWidgetSettingsState> = getPersistConfig({
  key: 'settings/storage',
});

export const widgetReducers = combineReducers({
  main: mainStateReducer,
  effects: persistReducer(effectsConfig, effectsReducer),
  presets: persistReducer(presetsConfig, presetReducer),
  settings: persistReducer(settingsConfig, widgetSettingsReducer),
});
