import { Theme, withTheme } from '@emotion/react';
import { Typography } from 'antd';
import React, { FC } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { TWidget } from '../../api/features/widget/types';
import { DocumentationStepTitle } from '../../components/ui/documentation/text-components';
import { WidgetExportScript } from '../../constants/widget-export-script';
import CodeCopyDocumentationFragment from './code-copy-documentation-fragment';

type TProps = {
  currentWidget?: TWidget;
  theme: Theme;
};

const HtmlDoc: FC<TProps> = ({ currentWidget, theme }) => {
  return (
    <div>
      <Typography.Title level={3}>Установка виджета в HTML</Typography.Title>
      <CodeCopyDocumentationFragment
        currentWidget={currentWidget}
        theme={theme}
      />
      <DocumentationStepTitle level={4}>
        2. Установка кода перед закрывающим тегом &lt;body/&gt;
      </DocumentationStepTitle>
      <Typography.Paragraph>
        Разместите код виджета перед закрывающим тегом &lt;body/&gt;. Вы также
        можете разместить тег выше по дереву элементов, однако код обязательно
        должен находиться в пределах этого тега.
      </Typography.Paragraph>
      <SyntaxHighlighter language="htmlbars" style={coy} showLineNumbers>
        {currentWidget &&
        !!currentWidget.widgetVendorsUrl &&
        currentWidget.widgetUrl
          ? `<body>
...код вашего сайта
${WidgetExportScript(currentWidget.widgetVendorsUrl, currentWidget.widgetUrl)}
</body>`
          : `<body>
...код вашего сайта
<!-- код виджета (пример) -->
${WidgetExportScript(
  'https://storage.assistapp.tech/example/assistapp-vendors.js',
  'https://storage.assistapp.tech/example/assistapp-example.js'
)}
</body>`}
      </SyntaxHighlighter>
    </div>
  );
};

export default withTheme(HtmlDoc);
