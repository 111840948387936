import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

const Paragraph = styled.p`
  padding: 0;
  margin: 0 0 16px;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.MAIN.TEXT.C100};
`;

const SectionParagraph: FC<PropsWithChildren> = ({ children }) => {
  return <Paragraph>{children}</Paragraph>;
};

export default SectionParagraph;
