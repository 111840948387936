import { useEffect, useLayoutEffect, useState } from 'react';

import ReadableIcon from '../../icons/readableIcon';
import { useAppDispatch } from '../../state';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { setEffectValue } from '../../state/reducers/widget/slices/effectsSlice';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const READABLE_FONT_CLASS_NAME = 'ac-wd-reb-effect-style';

export const readableEffect: TSettingItem = {
  Icon: ReadableIcon,
  key: ETextEffectKeys.READABLE_FONT,
};

export const useReadableEffect = () => {
  const dispatch = useAppDispatch();
  const [added, setAdded] = useState(false);
  const effect = useEffectSelector(ETextEffectKeys.READABLE_FONT);
  const dyslexicValue = useEffectSelector(ETextEffectKeys.DYSLEXIC_FONT);

  useLayoutEffect(() => {
    const head = document.querySelector('head') as HTMLElement;

    const linkPreConnect = document.createElement('link');
    const linkPreConnect2 = document.createElement('link');
    const linkFont = document.createElement('link');

    linkPreConnect.rel = 'preconnect';
    linkPreConnect2.rel = 'preconnect';
    linkFont.rel = 'stylesheet';

    linkPreConnect.href = 'https://fonts.googleapis.com';
    linkPreConnect2.href = 'https://fonts.gstatic.com';
    linkFont.href =
      'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap';

    linkPreConnect2.crossOrigin = '';

    head.append(linkPreConnect, linkPreConnect2, linkFont);

    return () => {
      linkPreConnect.remove();
      linkPreConnect2.remove();
      linkFont.remove();
    };
  }, []);

  useEffect(() => {
    const container = document.querySelector('html') as HTMLElement;
    const body = container.querySelector('body') as HTMLElement;

    if (!added) {
      setAdded(true);
    }

    if (effect.value) {
      if (dyslexicValue) {
        dispatch(
          setEffectValue({
            key: ETextEffectKeys.DYSLEXIC_FONT,
            value: false,
          })
        );
      }

      container.classList.add(READABLE_FONT_CLASS_NAME);
      body.classList.add(READABLE_FONT_CLASS_NAME);
    } else {
      container.classList.remove(READABLE_FONT_CLASS_NAME);
      body.classList.remove(READABLE_FONT_CLASS_NAME);
    }
  }, [effect, added]);
};
