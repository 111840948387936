import { ETextEffectKeys, TEffectTextKey } from '../../../../effects/names';
import { TSettingValue } from '../../../../types/widget';
import { TStateMigration } from '../../../utils/get-persist-config';
import {
  TEffectsSliceState,
  effectsInitialState,
} from '../slices/effectsSlice';

export const effectsMigrations: TStateMigration<TEffectsSliceState> = {
  0: (state) => {
    let newEffects: TEffectsSliceState = { ...effectsInitialState };
    try {
      const oldEffects = { ...state };

      (Object.keys(oldEffects) as TEffectTextKey[]).forEach((key) => {
        if (
          key in oldEffects &&
          (typeof oldEffects[key] === 'number' ||
            typeof oldEffects[key] === 'string' ||
            typeof oldEffects[key] === 'boolean')
        ) {
          newEffects[key] = {
            value: oldEffects[key] as unknown as TSettingValue,
          };
        } else if (key in oldEffects && typeof oldEffects[key] === 'object') {
          newEffects[key] = oldEffects[key];
        } else {
          newEffects[key] = {
            value: false,
          };
        }
      });
    } catch (e) {
      console.log(e);
      newEffects = { ...effectsInitialState };
    }

    return Promise.resolve({
      ...state,
      ...newEffects,
    });
  },
  1: (state) => {
    let newEffects: TEffectsSliceState = { ...state };
    try {
      const oldEffects = { ...state };

      if (!oldEffects[ETextEffectKeys.SIMPLE_LANGUAGE]) {
        newEffects = {
          ...oldEffects,
          [ETextEffectKeys.SIMPLE_LANGUAGE]: {
            value: false,
          },
        };
      }
    } catch (e) {
      newEffects = { ...effectsInitialState };
    }

    return Promise.resolve({
      ...newEffects,
    });
  },
  2: (state) => {
    let newEffects: TEffectsSliceState = { ...state };
    const oldEffects = { ...state };

    if (!oldEffects[ETextEffectKeys.CITE_THEME]) {
      newEffects = {
        ...oldEffects,
        [ETextEffectKeys.CITE_THEME]: {
          value: false,
        },
      };
    }

    return Promise.resolve({
      ...newEffects,
    });
  },
};
