import {
  BorderBottomOutlined,
  BorderLeftOutlined,
  BorderRightOutlined,
  BorderTopOutlined,
} from '@ant-design/icons';
import styled from '@emotion/styled';
import { Form, InputNumber } from 'antd';
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  memo,
  useEffect,
  useState,
} from 'react';

import {
  EPlacement,
  EPositionKeyToString,
  WIDGET_POSITION_FORM_ITEMS,
} from '../../utils/widget/options';

type TProps = {
  formListName: string | number | (string | number)[];
  setFormFocused?: Dispatch<SetStateAction<boolean>>;
};

const PositionIcons: Record<EPlacement, ReactNode> = {
  [EPlacement.TOP]: <BorderTopOutlined />,
  [EPlacement.LEFT]: <BorderLeftOutlined />,
  [EPlacement.RIGHT]: <BorderRightOutlined />,
  [EPlacement.BOTTOM]: <BorderBottomOutlined />,
};

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-column-gap: 14px;
`;

const PositionListForm: FC<TProps> = memo(
  ({ setFormFocused, formListName }) => {
    const [focused, setFocused] = useState(false);

    useEffect(() => {
      setFormFocused?.(focused);
    }, [focused]);

    const handleFocus = () => {
      setFocused(true);
    };

    const handleBlur = () => {
      setFocused(false);
    };

    return (
      <Wrapper>
        {WIDGET_POSITION_FORM_ITEMS.map(({ key, label }) => {
          let formName: string | number | (string | number)[] =
            EPositionKeyToString[key];

          if (formListName) {
            if (Array.isArray(formListName)) {
              formName = [...formListName, formName];
            } else {
              formName = [formListName, formName];
            }
          }

          return (
            <Form.Item label={label} name={formName} key={key}>
              <InputNumber
                prefix={PositionIcons[key]}
                style={{
                  width: '100%',
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                addonAfter="px"
              />
            </Form.Item>
          );
        })}
      </Wrapper>
    );
  }
);

export default PositionListForm;
