import { useEffect, useState } from 'react';

import { useWidgetEffect } from '../../hooks/useWidgetEffect';
import ScaleIcon from '../../icons/scaleIcon';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const SCALE_10_CLASS_NAME = 'ac-wd-sc-10-effect';
export const SCALE_25_CLASS_NAME = 'ac-wd-sc-25-effect';
export const SCALE_50_CLASS_NAME = 'ac-wd-sc-50-effect';
export const SCALE_75_CLASS_NAME = 'ac-wd-sc-75-effect';
export const SCALE_100_CLASS_NAME = 'ac-wd-sc-100-effect';

export const scaleEffect: TSettingItem = {
  Icon: ScaleIcon,
  key: ETextEffectKeys.CONTENT_SCALE,
};

export const useScaleEffect = () => {
  const { effect, isSettingOn } = useWidgetEffect(
    ETextEffectKeys.CONTENT_SCALE
  );

  const [windowWidth, setWindowWidth] = useState(window?.innerWidth ?? 1400);

  useEffect(() => {
    const listener = () => {
      if (window && window.innerWidth) {
        setWindowWidth(window.innerWidth);
      }
    };

    listener();

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  useEffect(() => {
    if (isSettingOn) {
      if ('zoom' in document.body.style) {
        const documentElement = document.documentElement;
        const widgetElement = documentElement.querySelector(
          'access-widget'
        ) as HTMLElement;

        if (isSettingOn) {
          if (documentElement && widgetElement) {
            if (effect.value === false) {
              documentElement.style.maxWidth = `${windowWidth}px`;
              // @ts-ignore
              documentElement.style.zoom = '100%';
              // @ts-ignore
              widgetElement.style.zoom = '100%';
            } else {
              const scaleRate = 1 - (effect.value as number) / 200;

              documentElement.style.maxWidth = `${Math.round(
                windowWidth * scaleRate
              )}px`;
              // @ts-ignore
              documentElement.style.zoom = `${
                (windowWidth / Math.round(windowWidth * scaleRate)) * 100
              }%`;

              // @ts-ignore
              widgetElement.style.zoom = `calc(
                100% / ${
                  (windowWidth / Math.round(windowWidth * scaleRate)) * 100
                } *
                  100
              )`;
            }
          }
        }
      }
    }
  }, [effect, isSettingOn, windowWidth]);
};
