import { useEffect } from 'react';

import LinksMarkIcon from '../../icons/linksMarkIcon';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const linksMarkEffect: TSettingItem = {
  Icon: LinksMarkIcon,
  key: ETextEffectKeys.LINKS_MARK,
};

export const LINKS_MARK_CLASS_NAME = 'ac-wd-lma-effect-style';

export const useLinksMarkEffect = () => {
  const effect = useEffectSelector(ETextEffectKeys.LINKS_MARK);
  const container = document.querySelector('body') as HTMLElement;

  useEffect(() => {
    if (effect.value) {
      container.classList.add(LINKS_MARK_CLASS_NAME);
    } else {
      container.classList.remove(LINKS_MARK_CLASS_NAME);
    }
  }, [effect]);
};
