import { Global, css } from '@emotion/react';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import {
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { HIDDEN_CURSOR_CLASS_NAME } from '../../effects/core/cursor-effect';
import { DYSLEXIC_CLASS_NAME } from '../../effects/core/dyslexic-effect';
import { HIDE_IMAGE_CLASS_NAME } from '../../effects/core/hide-images-effect';
import { LINKS_MARK_CLASS_NAME } from '../../effects/core/links-mark-effect';
import { READABLE_FONT_CLASS_NAME } from '../../effects/core/readable-effect';
import {
  SCALE_10_CLASS_NAME,
  SCALE_25_CLASS_NAME,
  SCALE_50_CLASS_NAME,
  SCALE_75_CLASS_NAME,
  SCALE_100_CLASS_NAME,
} from '../../effects/core/scale-effect';
import { STOP_ANIMATIONS_CLASS_NAME } from '../../effects/core/stop-animations-effect';
import {
  BASE_ELEMENT_BASE_SELECTOR,
  FS_CLASS_NAME_20,
  FS_CLASS_NAME_40,
} from '../../effects/core/text-effect';
import { TEXT_TO_SP_CLASS_NAME } from '../../effects/core/text-to-speech-effect';
import { ETextEffectKeys } from '../../effects/names';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { useCurrentTheme } from '../theme-service';
import { Brightness } from './brightness';
import { CiteTheme } from './cite-theme';
import { CursorEffectExp } from './cursor';
import { ReadArea } from './read-area';
import { ReadLine } from './read-line';

export const ExpandedEffects: FC<PropsWithChildren> = ({ children }) => {
  const theme = useCurrentTheme();
  const textIncreaseEffect = useEffectSelector(ETextEffectKeys.TEXT_INCREASE);

  const [windowWidth, setWindowWidth] = useState(window?.innerWidth ?? 1400);

  const [defaultFontSize, setDefaultFontSize] = useState(16);

  const calculateFontSize = useCallback(() => {
    try {
      let result = 16;

      if (!theme.SETTINGS.EFFECTS?.CITE_USE_REM) {
        return result;
      }

      const baseHtmlElement = document.querySelector(
        theme.SETTINGS.EFFECTS?.REM_TARGET_SELECTOR ??
          BASE_ELEMENT_BASE_SELECTOR
      );

      if (!baseHtmlElement) {
        return result;
      }

      if (textIncreaseEffect.value) {
        baseHtmlElement.classList.remove(
          `ac-wd-fs-${textIncreaseEffect.value}-effect-style`
        );
      }

      const baseFontSize = getComputedStyle(baseHtmlElement).fontSize;

      result = Number(baseFontSize.match(/[+-]?\d+(\.\d+)?/g)?.[0] ?? 16);

      if (textIncreaseEffect.value) {
        baseHtmlElement.classList.add(
          `ac-wd-fs-${textIncreaseEffect.value}-effect-style`
        );
      }

      return result;
    } catch (e) {
      return 16;
    }
  }, [theme, textIncreaseEffect]);

  useLayoutEffect(() => {
    setDefaultFontSize(calculateFontSize());
  }, []);

  useEffect(() => {
    let timeout: TimeoutId;

    const listener = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        if (window && window.innerWidth) {
          setWindowWidth(window.innerWidth);
        }

        setDefaultFontSize(calculateFontSize());
      }, 50);
    };

    window.addEventListener('resize', listener);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      window.removeEventListener('resize', listener);
    };
  }, [calculateFontSize]);

  return (
    <Fragment>
      <Global
        styles={css`
          @font-face {
            font-family: 'opendyslexic';
            src: url('http${process.env.HTTPS
              ? 's'
              : ''}://artics-portal-uploads.storage.yandexcloud.net/fonts/OpenDyslexic-Regular.woff');
            font-style: normal;
            font-weight: normal;
          }

          @font-face {
            font-family: 'opendyslexic';
            src: url('http${process.env.HTTPS
              ? 's'
              : ''}://artics-portal-uploads.storage.yandexcloud.net/fonts/OpenDyslexic-Italic.woff');
            font-style: italic;
            font-weight: normal;
          }

          @font-face {
            font-family: 'opendyslexic';
            src: url('http${process.env.HTTPS
              ? 's'
              : ''}://artics-portal-uploads.storage.yandexcloud.net/fonts/OpenDyslexic-Bold.woff');
            font-weight: bold;
            font-style: normal;
          }

          @font-face {
            font-family: 'opendyslexic';
            src: url('http${process.env.HTTPS
              ? 's'
              : ''}://artics-portal-uploads.storage.yandexcloud.net/fonts/OpenDyslexic-Bold-Italic.woff');
            font-weight: bold;
            font-style: italic;
          }
          .${HIDDEN_CURSOR_CLASS_NAME} {
            &,
            * {
              cursor: none !important;
            }
          }

          .${DYSLEXIC_CLASS_NAME} {
            font-family: 'opendyslexic', sans-serif !important;

            *:not(access-widget *):not([class*='fa']) {
              font-family: 'opendyslexic', sans-serif !important;
            }
          }

          .${HIDE_IMAGE_CLASS_NAME} {
            img {
              visibility: hidden !important;
              opacity: 0 !important;
            }

            *:not(access-widget *) {
              background-image: none !important;
            }
          }

          .${LINKS_MARK_CLASS_NAME} {
            a,
            a * {
              background-color: rgb(228, 255, 226) !important;
              color: #ff09de !important;
              font-weight: bold !important;
              backdrop-filter: invert(1) !important;
            }
          }

          .${READABLE_FONT_CLASS_NAME} {
            font-family: 'Open Sans', sans-serif !important;

            *:not(access-widget *):not([class*='fa']) {
              font-family: 'Open Sans', sans-serif !important;
            }
          }

          .${STOP_ANIMATIONS_CLASS_NAME} {
            &,
            * {
              transition: none !important;
              animation-iteration-count: 0 !important;
            }
          }

          .${FS_CLASS_NAME_20} {
            font-size: ${Math.round(defaultFontSize * 1.2)}px !important;
          }
          .${FS_CLASS_NAME_40} {
            font-size: ${Math.round(defaultFontSize * 1.4)}px !important;
          }

          .${TEXT_TO_SP_CLASS_NAME} {
            background-color: rgba(255, 241, 70, 0.7) !important;
            color: black !important;
            outline: 2px solid rgba(255, 241, 70, 0.7);
          }

          .${SCALE_10_CLASS_NAME} {
            max-width: ${Math.round(windowWidth * 0.95)}px;
            zoom: ${(windowWidth / Math.round(windowWidth * 0.95)) * 100}%;

            access-widget {
              zoom: calc(
                100% / ${(windowWidth / Math.round(windowWidth * 0.95)) * 100} *
                  100
              );
            }
          }

          .${SCALE_10_CLASS_NAME} {
            max-width: ${Math.round(windowWidth * 0.95)}px;
            zoom: ${(windowWidth / Math.round(windowWidth * 0.95)) * 100}%;

            access-widget {
              zoom: calc(
                100% / ${(windowWidth / Math.round(windowWidth * 0.95)) * 100} *
                  100
              );
            }
          }

          .${SCALE_25_CLASS_NAME} {
            max-width: ${Math.round(windowWidth * 0.875)}px;
            zoom: ${(windowWidth / Math.round(windowWidth * 0.875)) * 100}%;

            access-widget {
              zoom: calc(
                100% / ${(windowWidth / Math.round(windowWidth * 0.875)) * 100} *
                  100
              );
            }
          }

          .${SCALE_50_CLASS_NAME} {
            max-width: ${Math.round(windowWidth * 0.75)}px;
            zoom: ${(windowWidth / Math.round(windowWidth * 0.75)) * 100}%;

            access-widget {
              zoom: calc(
                100% / ${(windowWidth / Math.round(windowWidth * 0.75)) * 100} *
                  100
              );
            }
          }

          .${SCALE_75_CLASS_NAME} {
            max-width: ${Math.round(windowWidth * 0.625)}px;
            zoom: ${(windowWidth / Math.round(windowWidth * 0.625)) * 100}%;

            access-widget {
              zoom: calc(
                100% / ${(windowWidth / Math.round(windowWidth * 0.625)) * 100} *
                  100
              );
            }
          }

          .${SCALE_100_CLASS_NAME} {
            max-width: ${Math.round(windowWidth * 0.5)}px;
            zoom: ${(windowWidth / Math.round(windowWidth * 0.5)) * 100}%;

            access-widget {
              zoom: calc(
                100% / ${(windowWidth / Math.round(windowWidth * 0.5)) * 100} *
                  100
              );
            }
          }
        `}
      />
      <CiteTheme>
        <CursorEffectExp>
          <Brightness>
            <ReadArea>
              <ReadLine>{children}</ReadLine>
            </ReadArea>
          </Brightness>
        </CursorEffectExp>
      </CiteTheme>
    </Fragment>
  );
};
