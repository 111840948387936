import { createSelector } from '@reduxjs/toolkit';

import { authApi } from '../../api/features/auth';
import { EUserRole } from '../../api/features/auth/types';
import { useUserAuthSelector } from '../auth/selectors';
import { RootCombine } from '../root';

const widgetsSelector = (state: RootCombine) => state.widgets;

export const widgetSettingsSelector = (state: RootCombine) =>
  widgetsSelector(state).settings;
export const useAllWidgetsSelector = (state: RootCombine) =>
  widgetsSelector(state).all;
export const widgetListSelector = (state: RootCombine) =>
  useAllWidgetsSelector(state).list;

export const useCanCreateWidgetSelector = createSelector(
  [
    useUserAuthSelector,
    authApi.endpoints.getMyUserData.select(),
    widgetSettingsSelector,
  ],
  (userState, meData, widgetSettingsList) => {
    if (!meData.data || !userState.authed) {
      return false;
    }

    const widgetCountUnSaved = Math.max(
      widgetSettingsList.list.length - meData.data.countOfWidget,
      0
    );

    return (
      (meData.data.availableCountOfWidget > 0 &&
        meData.data.availableCountOfWidget > widgetCountUnSaved) ||
      meData.data.role === EUserRole.ADMIN
    );
  }
);
