import { Button } from 'antd';
import { FC } from 'react';

import { ButtonProps } from 'antd/es/button/button';

type TProps = {} & ButtonProps;

export const AntBaseButton: FC<TProps> = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>;
};
