import { createSelector } from '@reduxjs/toolkit';

import { EUserRole } from '../../api/features/auth/types';
import { RootCombine } from '../root';

export const useAuthSelector = (state: RootCombine) => state.auth;
export const useUserAuthSelector = (state: RootCombine) =>
  useAuthSelector(state).userAuth;
export const useUserIsAdminSelector = createSelector(
  [useUserAuthSelector],
  (data) => {
    return data?.decoded?.role === EUserRole.ADMIN && data.authed;
  }
);
