import { PoweroffOutlined } from '@ant-design/icons';
import { Checkbox, Form } from 'antd';
import { TFunction } from 'i18next';

import { FormItemProps } from 'antd/es/form/FormItem';
import { ColumnsType } from 'antd/lib/table';

import {
  EffectCellIconWrapper,
  EffectNameCell,
} from '../../../components/table/effect-table-components';
import { WIDGET_BASE_THEME } from '../../../utils/widget/theme';
import { TWidgetPresetTableData } from '../types/widget-preset-types';
import { getFormItemName } from './widget-effect-columns';

export const getWidgetPresetColumns: (
  baseFormName: FormItemProps['name'],
  t: TFunction<string[]>
) => ColumnsType<TWidgetPresetTableData> = (baseFormName, t) => [
  {
    title: 'Профиль',
    dataIndex: 'key',
    key: 'name',
    render: (value, record) => {
      return (
        <EffectNameCell>
          <EffectCellIconWrapper>
            {/*// @ts-ignore*/}
            <record.Icon theme={WIDGET_BASE_THEME} />
          </EffectCellIconWrapper>
          <span>{t(`${value}.title`)}</span>
        </EffectNameCell>
      );
    },
  },
  {
    title: <PoweroffOutlined />,
    dataIndex: 'key',
    key: 'turnStatus',
    width: 39,
    render: (key) => {
      return (
        <Form.Item
          name={getFormItemName(baseFormName, key)}
          valuePropName="checked"
          noStyle
        >
          <Checkbox />
        </Form.Item>
      );
    },
  },
];
