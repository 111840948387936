import { Form, FormInstance } from 'antd';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useDeleteWidgetMutation,
  useSaveWidgetSettingsMutation,
} from '../api/features/widget';
import {
  EWidgetStatus,
  TWidgetFullSettingsForm,
  TWidgetSettingsItem,
} from '../api/features/widget/types';
import { EPaths } from '../routes/route-paths';
import { useAppDispatch, useAppSelector } from '../state';
import {
  useUserAuthSelector,
  useUserIsAdminSelector,
} from '../state/auth/selectors';
import {
  removeWidgetSettingByDomainAction,
  setSubDomainAttentionViewedAction,
} from '../state/widgets/widget-settings.slice';
import { EWidgetSettingsFormNames } from '../types/forms/widget-settings';
import {
  mapWidgetSettingItemToFormValues,
  mapWidgetThemeFormToScheme,
} from '../utils/widget/mappers';
import { useAppMessage } from './notification-provider';

type TContext = {
  form: FormInstance<TWidgetFullSettingsForm>;
  onFormValuesChange: (data: TWidgetFullSettingsForm) => void;
  currentSettingItem: TWidgetSettingsItem;
  onFormFinish: (values: TWidgetFullSettingsForm) => void;
  updateCurrentItem: () => void;
  handleResetForm: () => Promise<void>;
  handleDeleteWidget: () => Promise<void>;
  handleDeactivateWidget: () => Promise<void>;
  handleCloseDomainAttention: () => void;
  subDomainAttentionViewed: boolean;
  finisLoading: boolean;
  isUserAdmin: boolean;
};

const context = createContext<TContext>({
  // @ts-ignore
  form: undefined,
  onFormValuesChange: () => {},
  onFormFinish: () => {},
  updateCurrentItem: () => {},
});

export const useWidgetFormContext = () => useContext(context);

export const WidgetSettingsFormProvider: FC<
  PropsWithChildren<{
    widgetSettingsItem: TWidgetSettingsItem;
    subDomainAttentionViewed: boolean;
  }>
> = ({ children, widgetSettingsItem, subDomainAttentionViewed }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm<TWidgetFullSettingsForm>();
  const [currentSettingItem, setCurrentSettingItem] =
    useState<TWidgetSettingsItem>(widgetSettingsItem);
  const user = useAppSelector(useUserAuthSelector);

  const isUserAdmin = useAppSelector(useUserIsAdminSelector);

  const [finisLoading, setFinishLoading] = useState(false);

  const { messageApi, notificationApi } = useAppMessage();

  const [saveWidget] = useSaveWidgetSettingsMutation();
  const [deleteWidget] = useDeleteWidgetMutation();

  const handleFormValuesChange = useCallback(
    (data: TWidgetFullSettingsForm) => {
      setCurrentSettingItem((prev) =>
        mapWidgetThemeFormToScheme(
          data,
          prev,
          form,
          EWidgetSettingsFormNames.READY_THEME in data,
          isUserAdmin
        )
      );
    },
    [widgetSettingsItem, isUserAdmin]
  );

  const handleCloseDomainAttention = useCallback(() => {
    dispatch(setSubDomainAttentionViewedAction(true));
  }, []);

  useEffect(() => {
    if (widgetSettingsItem) {
      setCurrentSettingItem(widgetSettingsItem);
      form.resetFields();
    }
  }, [widgetSettingsItem]);

  const handleResetForm = useCallback(async () => {
    await new Promise<void>((res) => {
      const widgetSettings =
        mapWidgetSettingItemToFormValues(widgetSettingsItem);
      form.setFieldsValue(widgetSettings);
      setCurrentSettingItem(widgetSettingsItem);
      res();
    });
  }, [widgetSettingsItem]);

  const onFormFinish = useCallback(
    async (data: TWidgetFullSettingsForm) => {
      try {
        setFinishLoading(true);
        if (!user.decoded?.id) {
          throw new Error('Unauthorized');
        }

        const widgetData = mapWidgetThemeFormToScheme(
          data,
          {
            ...currentSettingItem,
            status: EWidgetStatus.NEED_BUILD,
          },
          form,
          false,
          isUserAdmin
        );

        setCurrentSettingItem(widgetData);

        delete widgetData.widgetReadyTheme;

        if (!isUserAdmin) {
          delete widgetData.dateTo;
        }

        if ('widgetUrl' in widgetData) {
          delete widgetData.widgetUrl;
        }

        if ('widgetVendorsUrl' in widgetData) {
          delete widgetData.widgetVendorsUrl;
        }

        await saveWidget({
          data: {
            ...widgetData,
            ownerId: user.decoded.id,
          },
          id: widgetSettingsItem.id,
        }).unwrap();

        if (widgetSettingsItem.id) {
          messageApi.success('Настройки успешно применены, обновляем виджет');
        } else {
          notificationApi.success({
            message: 'Настройки успешно применены',
            description:
              'Обновляем виджет. Код для вставки виджета отобразится после обновления на странице настроек и на главной странице сайта',
          });
        }
      } catch (e) {
        console.error(e);
        void messageApi.error(`Произошла ошибка при обновлении виджета`);
      } finally {
        setFinishLoading(false);
      }
    },
    [currentSettingItem, user, widgetSettingsItem, isUserAdmin]
  );

  const updateCurrentItem = useCallback(() => {
    const formValues = form.getFieldsValue();
    setCurrentSettingItem((prev) =>
      mapWidgetThemeFormToScheme(formValues, prev, form, false, isUserAdmin)
    );
  }, [isUserAdmin]);

  const handleDeleteWidget = useCallback(async () => {
    if (currentSettingItem.id) {
      try {
        await deleteWidget(currentSettingItem.id).unwrap();
        messageApi.success('Виджет успешно удалён');
        navigate(EPaths.SETTINGS);
      } catch (e) {
        console.error(e);
        messageApi.error(
          'Произошла ошибка при удалении виджета, попробуйте позже'
        );
      }
    } else {
      dispatch(removeWidgetSettingByDomainAction(currentSettingItem.domain));
      messageApi.success('Виджет успешно удалён');
      navigate(EPaths.SETTINGS);
    }
  }, [currentSettingItem]);

  const handleDeactivateWidget = useCallback(async () => {
    try {
      if (!user.decoded?.id) {
        throw new Error('Unauthorized');
      }

      if (currentSettingItem.id) {
        await saveWidget({
          id: currentSettingItem.id,
          data: {
            ...currentSettingItem,
            ownerId: user.decoded.id,
            status: EWidgetStatus.INACTIVE,
          },
        });

        messageApi.success('Виджет деактивирован');
      }
    } catch (e) {
      console.error(e);
      messageApi.error('Не удалось деактивировать виджет');
    }
  }, [currentSettingItem, user]);

  return (
    <context.Provider
      value={{
        form,
        onFormValuesChange: handleFormValuesChange,
        currentSettingItem,
        onFormFinish,
        updateCurrentItem,
        handleResetForm,
        finisLoading,
        handleDeleteWidget,
        handleDeactivateWidget,
        subDomainAttentionViewed,
        handleCloseDomainAttention,
        isUserAdmin,
      }}
    >
      {children}
    </context.Provider>
  );
};
