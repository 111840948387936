import { ENV_CONFIG_TYPE } from '../types/env';

export enum EAccessButtonType {
  HUMAN = 'human',
  EYE = 'eye',
}

export type ICommonStyles<T extends string | number> = {
  COMMON: {
    ACCENT: string; // PRIMARY_COLOR
    ON_ACCENT: string; // ON_PRIMARY_COLOR
    BASE_RADIUS: T;
    TEXT_COMMON: string; // TEXT_COLOR
  };
  ACCESS_BUTTON: {
    MAIN_COLOR: string; // PRIMARY_COLOR
    SECONDARY_COLOR: string; // SECONDARY_COLOR
    INSIDE_ELEMENTS_COLOR: string; // new
  };
  MENU: {
    SHADOW: string;
    BACKGROUND: string; // LIGHT_BG_COLOR
    SCROLLBAR_BG: string; // TEXT_SECONDARY
    SCROLLBAR_BG_HOVERED: string;
    HEADER_BG: string; // PRIMARY_COLOR
    HEADER_TEXT_COLOR: string; // ELEMENTS_ON_BG
    TEXT_ON_MENU: string; // TEXT_SECONDARY
    RESET_BUTTON: {
      BACKGROUND: string; // COMMON_WHITE
      BACKGROUND_HOVERED: string; // COMMON_WHITE
      TEXT: string; // TEXT_SECONDARY
    };
    SUB_GROUP: {
      BACKGROUND: string;
      BORDER_RADIUS: T;
      TITLE_COLOR: string;
    };
    MENU_ITEM: {
      BORDER_RADIUS: T;
      BACKGROUND: string; // COMMON_WHITE
      BORDER: string; // COMMON_WHITE
      TITLE: string; // TEXT_SECONDARY
      TEXT: string; // TEXT_SECONDARY
      SWITCH_BG: string; // TEXT_SECONDARY
    };
  };
};

export type TSettingsPosition<T extends string | number> = Partial<{
  [key in TPlacement]: T;
}>;

// CSS свойство для position: absolute
export type TPlacement = 'top' | 'left' | 'right' | 'bottom';

// Ключ - макс-ширина экрана, значение - обЪект с полями TPlacement и значениями в px.
export type TBreakPoints<T extends string | number = string> = Record<
  T,
  TSettingsPosition<T>
>;

export type ISettings<T extends string | number> = {
  COMMON: {
    FONT: string;
    HIDE_LINK?: boolean;
  };
  MAIN_BUTTON: {
    TYPE?: EAccessButtonType;
    BUTTON_HIDDEN?: boolean;
    Z_INDEX: T;
    SIZE: T;
    BORDER_RADIUS: T;
    POSITION: TSettingsPosition<T> & {
      BREAK_POINTS?: TBreakPoints<T>;
    };
  };
  MENU: {
    Z_INDEX: T;
  };
  EFFECTS?: {
    CITE_USE_REM?: boolean;
    REM_TARGET_SELECTOR?: string;
  };
};

export type TWidgetPosition = ISettings<string>['MAIN_BUTTON']['POSITION'];

export const ENV_CONFIG: ENV_CONFIG_TYPE = process.env.WIDGET_CONFIG as any;

export const COMMON_STYLES: ICommonStyles<number> = ENV_CONFIG.theme.COLORS;

export const SETTINGS: ISettings<number> = ENV_CONFIG.theme.SETTINGS;
