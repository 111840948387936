import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Localization } from '../../../../locale';

export type AppLocaleInitialStateType = {
  current: Localization;
};

const initialState: AppLocaleInitialStateType = {
  current: Localization.RU,
};

const localeSlice = createSlice({
  name: 'widget/locale',
  initialState: initialState as AppLocaleInitialStateType,
  reducers: {
    setLocale(state, action: PayloadAction<Localization>) {
      state.current = action.payload;
    },
  },
});

export const { setLocale } = localeSlice.actions;
export const localeReducer = localeSlice.reducer;
