import { useCallback } from 'react';

import { useWriteStatisticMutation } from '../api/features/analytics';
import { TStatItem } from '../types/statistic';

const isError = {
  current: false,
};

export const useStatisticWriter = () => {
  const [writeApi] = useWriteStatisticMutation();

  const writeStat = useCallback(async (dataToWrite: TStatItem) => {
    if (
      process.env.TARGET !== 'web' ||
      isError.current ||
      process.env.STAT_WRITER_DISABLED
    ) {
      return;
    }

    try {
      await writeApi(dataToWrite).unwrap();
      if (process.env.NODE_ENV === 'development') {
        console.log('write-statistic', dataToWrite);
      }
    } catch (e) {
      isError.current = true;
      console.warn(e);
    }
  }, []);

  return [writeStat];
};
