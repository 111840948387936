import { Form, Input } from 'antd';
import { TFunction } from 'i18next';
import { FC, Fragment, memo } from 'react';

import { EMAIL_REGEX } from '../../utils/regex';

type TFormProps = {
  t: TFunction<string[]>;
};

export type TRegisterFormType = {
  email: string;
  password: string;
  passwordRepeat: string;
};

const RegistrationForm: FC<TFormProps> = memo(({ t }) => {
  return (
    <Fragment>
      <Form.Item
        label={t('baseInputs.emailLabel')}
        name="email"
        rules={[
          {
            required: true,
            message: t('base.emptyFieldText'),
          },
          {
            pattern: EMAIL_REGEX,
            message: t('baseInputs.emailValidError'),
          },
        ]}
      >
        <Input
          autoComplete="username"
          placeholder={t('baseInputs.emailPlaceholder')}
        />
      </Form.Item>
      <Form.Item
        label={t('baseInputs.passwordLabel')}
        name="password"
        rules={[
          {
            required: true,
            message: t('base.emptyFieldText'),
          },
          {
            min: 8,
            max: 16,
            message: t('baseInputs.passwordLengthError'),
          },
        ]}
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>
      <Form.Item
        label={t('baseInputs.passwordRepeatLabel')}
        name="passwordRepeat"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: t('base.emptyFieldText'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t('baseInputs.passwordsNotEqual'))
              );
            },
          }),
        ]}
      >
        <Input.Password autoComplete="new-password" />
      </Form.Item>
    </Fragment>
  );
});

export default RegistrationForm;
