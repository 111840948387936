import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FC, PropsWithChildren } from 'react';

import { textToBoolean, toTextBoolean } from '../../utils/common';

type TProps = {
  theme: Theme;
  onClick?: () => void;
  active: boolean;
  widgetIncreased?: 'true' | 'false';
};

const Wrapper = styled('div')<{
  widgetIncreased?: 'true' | 'false';
  active: 'true' | 'false';
}>`
  speak: never;
  height: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 60 : 30}px;
  border-radius: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 30 : 15}px;
  display: flex;
  align-items: center;
  background-color: ${({ theme, active }) =>
    textToBoolean(active)
      ? theme.COLORS.COMMON.ACCENT
      : theme.COLORS.MENU.MENU_ITEM.SWITCH_BG};
  position: relative;
  transition: background-color 0.3s ease-out;
  cursor: pointer;
  width: auto;

  @media only screen and (max-width: 520px) {
    height: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 50 : 30}px;
    border-radius: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 25 : 15}px;
  }

  &,
  & * {
    box-sizing: border-box;
  }
`;

const CommonSwitch: FC<PropsWithChildren<TProps>> = ({
  children,
  onClick,
  theme,
  active,
  widgetIncreased,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      active={toTextBoolean(active)}
      theme={theme}
      aria-hidden
      widgetIncreased={widgetIncreased}
    >
      {children}
    </Wrapper>
  );
};

export default withTheme(CommonSwitch);
