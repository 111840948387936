import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import {
  FC,
  HTMLAttributes,
  MouseEventHandler,
  memo,
  useCallback,
  useMemo,
} from 'react';

import { useAppSelector } from '../../state';
import { TStatEvent, TStatItem } from '../../types/statistic';
import { TSettingItem, TSettingValue } from '../../types/widget';
import {
  textToBoolean,
  toTextBoolean,
  widgetEffectTextKeys,
  widgetSettingAvailableValues,
} from '../../utils/common';

type TProps = Omit<HTMLAttributes<HTMLButtonElement>, 'onChange'> & {
  theme: Theme;
  settingItem: TSettingItem;
  onChange?: (value: TSettingValue) => void;
  index: number;
  order?: number;
  widgetIncreased?: boolean;
  t: TFunction<string>;
  handleWriteStatistic: (data: TStatItem) => Promise<void>;
};

const ItemStages = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StageItem = styled('div')<{
  widgetIncreased?: 'true' | 'false';
  isItemChecked?: 'true' | 'false';
  isCurrent?: 'true' | 'false';
}>`
  border-radius: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 4 : 2}px;
  height: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 20 : 10}px;
  flex: 1;
  background-color: ${({ theme, isItemChecked }) =>
    textToBoolean(isItemChecked)
      ? theme.COLORS.COMMON.ON_ACCENT
      : theme.COLORS.MENU.MENU_ITEM.BACKGROUND};
  opacity: ${({ isCurrent }) => (textToBoolean(isCurrent) ? 1 : 0.5)};
  transition:
    opacity 0.3s ease-out,
    background-color 0.3s ease-out;

  @media only screen and (max-width: 520px) {
    border-radius: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 3 : 2}px;
    height: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 12 : 10}px;
  }

  &:hover {
    opacity: ${({ isCurrent, isItemChecked }) =>
      textToBoolean(isItemChecked) ? (textToBoolean(isCurrent) ? 1 : 0.7) : 0};
  }
`;

const Wrapper = styled('button')<{
  widgetIncreased?: 'true' | 'false';
  isItemChecked?: 'true' | 'false';
  isLoading?: 'true' | 'false';
  order?: number;
}>`
  position: relative;
  padding-top: 100%;
  height: 0;
  outline-color: ${({ theme, isItemChecked }) =>
    textToBoolean(isItemChecked)
      ? theme.COLORS.MENU.MENU_ITEM.TITLE
      : theme.COLORS.COMMON.ACCENT} !important;
  background-color: ${({ theme, isItemChecked }) =>
    textToBoolean(isItemChecked)
      ? theme.COLORS.COMMON.ACCENT
      : theme.COLORS.MENU.MENU_ITEM.BACKGROUND} !important;
  color: ${({ theme, isItemChecked }) =>
    textToBoolean(isItemChecked)
      ? theme.COLORS.COMMON.ON_ACCENT
      : theme.COLORS.MENU.MENU_ITEM.TEXT} !important;
  border: 1px solid ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.BORDER};
  border-radius: ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.BORDER_RADIUS}px;
  transition:
    border-color 0.3s ease-out,
    background-color 0.3s ease-out;
  font-family: inherit;
  cursor: ${({ isLoading }) =>
    textToBoolean(isLoading) ? 'progress' : 'pointer'} !important;
  order: ${({ order }) => order};

  &:hover {
    border-color: ${({ theme }) => theme.COLORS.COMMON.ACCENT} !important;
  }
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-family: inherit;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 42px;
  border-radius: ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.BORDER_RADIUS}px;
  color: ${({ theme }) => theme.COLORS.MENU.MENU_ITEM.BACKGROUND};
`;

const IcoWrapper = styled.div<{
  widgetIncreased?: 'true' | 'false';
  isItemChecked?: 'true' | 'false';
}>`
  flex-basis: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  svg {
    width: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 100 : 40}px;
    height: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 100 : 40}px;

    @media only screen and (max-width: 520px) {
      width: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 54 : 40}px;
      height: ${({ widgetIncreased }) =>
        textToBoolean(widgetIncreased) ? 54 : 40}px;
    }

    path,
    rect {
      fill: ${({ theme, isItemChecked }) =>
        textToBoolean(isItemChecked)
          ? theme.COLORS.COMMON.ON_ACCENT
          : theme.COLORS.MENU.MENU_ITEM.TITLE} !important;
    }

    .assistapp_stroke_req {
      stroke: ${({ theme, isItemChecked }) =>
        textToBoolean(isItemChecked)
          ? theme.COLORS.COMMON.ON_ACCENT
          : theme.COLORS.MENU.MENU_ITEM.TITLE} !important;
    }
  }
`;

const TextWrapper = styled('div')<{
  widgetIncreased?: 'true' | 'false';
}>`
  flex: 1;
  padding-top: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 20 : 12}px;
  padding-inline: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 6 : 3}px;
  text-align: center;
  font-weight: bolder;
  font-family: inherit;
  font-size: ${({ widgetIncreased }) =>
    textToBoolean(widgetIncreased) ? 'min(3.4vw, 28px)' : '14px'};
  padding-bottom: 2px;
  line-height: 120%;
  user-select: none;
  white-space: normal !important;
  text-transform: none !important;

  @media only screen and (max-width: 520px) {
    padding-top: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 8 : 12}px;
    padding-inline: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? 6 : 3}px;
    font-size: ${({ widgetIncreased }) =>
      textToBoolean(widgetIncreased) ? '24px' : 'min(3.4vw, 14px)'};
  }
`;

const GridSettingItem: FC<TProps> = memo(
  ({
    theme,
    settingItem,
    t,
    index,
    widgetIncreased,
    onChange,
    handleWriteStatistic,
    ...props
  }) => {
    const effect = useAppSelector(
      (state) => state.widget.effects[settingItem.key]
    );

    const VALUES_ARRAY = useMemo(() => {
      return widgetSettingAvailableValues[settingItem.key];
    }, [settingItem]);

    const handleSwitchValue = useCallback(
      (e: Parameters<MouseEventHandler>[0]) => {
        if (effect?.loading) {
          return;
        }

        let nextValue;

        const currentValueIndex = VALUES_ARRAY.indexOf(effect.value);

        if (currentValueIndex + 1 < VALUES_ARRAY.length) {
          nextValue = VALUES_ARRAY[currentValueIndex + 1];
        } else {
          nextValue = VALUES_ARRAY[0];
        }

        // @ts-ignore
        onChange?.(nextValue);

        if (e.detail > 0) {
          void handleWriteStatistic({
            event: TStatEvent.EFFECT_CHANGE,
            text: t([`${settingItem.key}.base`]),
            time: dayjs().toISOString(),
            value: !effect.value,
          });
        } else {
          void handleWriteStatistic({
            event: TStatEvent.EFFECT_CHANGE_KBD,
            text: t([`${settingItem.key}.base`]) + ' - Клавиатура',
            time: dayjs().toISOString(),
            value: !effect.value,
          });
        }
      },
      [effect, VALUES_ARRAY, settingItem, onChange]
    );

    const text = useMemo(() => {
      const currentValueIndex = VALUES_ARRAY.indexOf(effect.value);
      const TEXT_KEYS = widgetEffectTextKeys[settingItem.key].map(
        (el) => `${settingItem.key}.${el}`
      );

      let textKeyIndex;

      if (TEXT_KEYS.length <= currentValueIndex) {
        textKeyIndex = TEXT_KEYS.length - 1;
      } else {
        textKeyIndex = currentValueIndex;
      }

      return t(TEXT_KEYS[textKeyIndex], {
        value: effect.value,
      });
    }, [effect, t, VALUES_ARRAY, settingItem]);

    const handleStageItemClick = useCallback(
      (
        newValue: string | number | boolean,
        e: Parameters<MouseEventHandler>[0]
      ) => {
        if (effect.value !== newValue && effect.value !== false) {
          e.stopPropagation();
          // @ts-ignore
          onChange?.(newValue);
          void handleWriteStatistic({
            event: TStatEvent.EFFECT_CHANGE_KBD,
            text: t([`${settingItem.key}.base`]),
            time: dayjs().toISOString(),
            value: !newValue,
          });
        }
      },
      [onChange, settingItem, effect]
    );

    return (
      <Wrapper
        {...props}
        theme={theme}
        title={t([`${settingItem.key}.base`])}
        aria-label={t([`${settingItem.key}.base`])}
        tabIndex={index + 1}
        widgetIncreased={toTextBoolean(widgetIncreased)}
        isLoading={toTextBoolean(effect.loading)}
        onClick={handleSwitchValue}
        isItemChecked={toTextBoolean(!(effect.value === false))}
      >
        <Inner theme={theme}>
          <IcoWrapper
            theme={theme}
            widgetIncreased={toTextBoolean(widgetIncreased)}
            isItemChecked={toTextBoolean(!(effect.value === false))}
          >
            <settingItem.Icon theme={theme} />
          </IcoWrapper>
          <TextWrapper
            theme={theme}
            widgetIncreased={toTextBoolean(widgetIncreased)}
          >
            {text}
          </TextWrapper>
          {VALUES_ARRAY.length > 2 && (
            <ItemStages theme={theme}>
              {VALUES_ARRAY.map((el) => (
                <StageItem
                  theme={theme}
                  key={el.toString()}
                  widgetIncreased={toTextBoolean(widgetIncreased)}
                  isItemChecked={toTextBoolean(!(effect.value === false))}
                  isCurrent={toTextBoolean(el === effect.value)}
                  onClick={(e) => {
                    handleStageItemClick(el, e);
                  }}
                />
              ))}
            </ItemStages>
          )}
        </Inner>
        {effect.loading && (
          <LoadingWrapper>
            <LoadingOutlined />
          </LoadingWrapper>
        )}
      </Wrapper>
    );
  }
);

export default withTheme(GridSettingItem);
