import styled from '@emotion/styled';
import {
  FC,
  HTMLAttributes,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useRef,
} from 'react';

type TContext = {
  scrollToBottom: Element['scrollIntoView'];
};

const context = createContext<TContext>({
  scrollToBottom: () => {},
});

export const usePageContext = () => useContext(context);

const Wrapper = styled('section')`
  padding-left: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 80px);
`;

type TProps = PropsWithChildren<HTMLAttributes<HTMLElement>>;

export const BasePageWrapper: FC<TProps> = ({ children, ...props }) => {
  const wrapperRef = useRef<HTMLElement | null>(null);

  const scrollToBottom: Element['scrollIntoView'] = useCallback((arg) => {
    if (wrapperRef.current) {
      wrapperRef.current?.scrollIntoView(arg);
    }
  }, []);

  return (
    <context.Provider
      value={{
        scrollToBottom,
      }}
    >
      <Wrapper {...props} ref={wrapperRef}>
        {children}
      </Wrapper>
    </context.Provider>
  );
};
