import dayjs from 'dayjs';

export function deepMerge(...objects: object[]) {
  const isObject = (obj: any) => obj && typeof obj === 'object';

  // tslint:disable-next-line:no-shadowed-variable
  function deepMergeInner(target: object, source: object) {
    Object.keys(source).forEach((key: string) => {
      // @ts-ignore
      const targetValue = target[key];
      // @ts-ignore
      const sourceValue = source[key];

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        // @ts-ignore
        target[key] = targetValue.concat(sourceValue);
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        // @ts-ignore
        target[key] = deepMergeInner(
          Object.assign({}, targetValue),
          sourceValue
        );
      } else {
        // @ts-ignore
        target[key] = sourceValue;
      }
    });

    return target;
  }

  if (objects.length < 2) {
    throw new Error(
      'deepMerge: this function expects at least 2 objects to be provided'
    );
  }

  if (objects.some((object) => !isObject(object))) {
    throw new Error('deepMerge: all values should be of type "object"');
  }

  const target = objects.shift();
  let source: object;

  // @ts-ignore
  // tslint:disable-next-line:no-conditional-assignment
  while ((source = objects.shift())) {
    // @ts-ignore
    deepMergeInner(target, source);
  }

  return target;
}

export function sortByCreateDateFn<
  T extends {
    createdAt: string;
  },
>(a: T, b: T) {
  const res = dayjs(b.createdAt).diff(dayjs(a.createdAt));
  return res < 0 ? -1 : res > 0 ? 1 : 0;
}
