import { SettingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Empty, Tabs, TabsProps } from 'antd';
import React, { FC, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TWidget, TWidgetSettingsItem } from '../../api/features/widget/types';
import SimpleLanguageIcon from '../../components/icons/simple-lang-icon';
import { BasePageWrapper } from '../../components/pages/base-page-wrapper';
import HorizontalPageMenu, {
  TPageHorizontalMenuItem,
} from '../../components/ui/horizontal-page-menu';
import WidgetSettings, {
  WidgetEmptyWrapper,
} from '../../components/ui/widget/widget-settings';
import { useAppMessage } from '../../providers/notification-provider';
import { EPaths } from '../../routes/route-paths';
import { useAppSelector } from '../../state';
import {
  useCanCreateWidgetSelector,
  widgetListSelector,
  widgetSettingsSelector,
} from '../../state/widgets/selectors';
import { ECSSVars } from '../../styles';
import { extractDomain } from '../../utils/regex';
import SimpleLanguage from '../simple-language';

const TabsWrapper = styled.div`
  margin-top: 8px;

  .ant-tabs-tab-active {
    ${ECSSVars.THEME_ICON_COLOR}: ${({ theme }) => theme.MAIN.ACCENT.C100};
  }

  .ant-tabs-tab:hover {
    ${ECSSVars.THEME_ICON_COLOR}: ${({ theme }) => theme.MAIN.ACCENT.C100};
  }
`;

enum ESettingTab {
  MAIN = 'main',
  SIMPLE_LANGUAGE = 'simple-lang',
}

const SETTINGS_TABS: TabsProps['items'] = [
  {
    key: ESettingTab.MAIN,
    label: 'Основное',
    icon: <SettingOutlined />,
  },
  {
    key: ESettingTab.SIMPLE_LANGUAGE,
    label: 'Ясный язык',
    icon: (
      <SimpleLanguageIcon
        style={{
          width: 14,
          height: 14,
        }}
      />
    ),
  },
];

export const SettingsPage: FC = () => {
  const settingsList = useAppSelector(widgetSettingsSelector);
  const createdWidgetsList = useAppSelector(widgetListSelector);
  const location = useLocation();

  const { messageApi } = useAppMessage();

  const isAddWidgetAvailable = useAppSelector(useCanCreateWidgetSelector);

  const menu: TPageHorizontalMenuItem[] = useMemo(
    () =>
      (
        [
          ...settingsList.list.map((el) => ({
            to: `${EPaths.SETTINGS}/${el.widgetFolder}`,
            text: extractDomain(el.domain),
          })),
          isAddWidgetAvailable && {
            to: EPaths.CREATE_WIDGET,
            text: 'Создать новый виджет',
          },
        ] as (TPageHorizontalMenuItem | false)[]
      ).filter(Boolean) as TPageHorizontalMenuItem[],
    [settingsList, isAddWidgetAvailable]
  );

  const selectedWidgetName: string | undefined = useMemo(() => {
    const locationPaths = location.pathname.split('/');
    return locationPaths[2];
  }, [location]);

  const selectedWidget: TWidgetSettingsItem | undefined = useMemo(() => {
    if (selectedWidgetName) {
      return settingsList.list.find(
        (el) => el.widgetFolder === selectedWidgetName
      );
    }

    return undefined;
  }, [selectedWidgetName, settingsList]);

  const selectedFullWidget: TWidget | undefined = useMemo(() => {
    if (
      !createdWidgetsList ||
      createdWidgetsList.length === 0 ||
      !selectedWidgetName
    ) {
      return undefined;
    }

    return createdWidgetsList.find(
      (el) => el.widgetFolder === selectedWidgetName
    );
  }, [createdWidgetsList, selectedWidgetName]);

  const [currentSettingTab, setCurrentSettingTab] = useState(
    SETTINGS_TABS[0].key
  );

  return (
    <BasePageWrapper>
      <HorizontalPageMenu menu={menu} label="Какой виджет настраиваем?" />
      <TabsWrapper>
        <Tabs
          items={SETTINGS_TABS}
          activeKey={currentSettingTab}
          onChange={setCurrentSettingTab}
        />
      </TabsWrapper>
      {selectedWidget && currentSettingTab === ESettingTab.MAIN && (
        <WidgetSettings
          widgetSettingsItem={selectedWidget}
          widgetFullInfo={selectedFullWidget}
          subDomainAttentionViewed={settingsList.subDomainAttentionViewed}
          messageApi={messageApi}
        />
      )}
      {selectedWidget && currentSettingTab === ESettingTab.SIMPLE_LANGUAGE && (
        <SimpleLanguage widget={selectedFullWidget} />
      )}
      {!selectedWidget && (
        <WidgetEmptyWrapper>
          <Empty description="Виджет не выбран, или отсутствует" />
        </WidgetEmptyWrapper>
      )}
    </BasePageWrapper>
  );
};
