import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { getIconBaseColor } from '../../utils/ui/icons';
import { TBaseIconProps } from './index';

const CopyIcon: FC<TBaseIconProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_463_2469)">
        <path
          d="M8.75 11.6667H2.91667C2.1434 11.6657 1.40208 11.3582 0.855295 10.8114C0.308514 10.2646 0.00092625 9.52326 0 8.75L0 2.91667C0.00092625 2.1434 0.308514 1.40208 0.855295 0.855295C1.40208 0.308514 2.1434 0.00092625 2.91667 0L8.75 0C9.52326 0.00092625 10.2646 0.308514 10.8114 0.855295C11.3582 1.40208 11.6657 2.1434 11.6667 2.91667V8.75C11.6657 9.52326 11.3582 10.2646 10.8114 10.8114C10.2646 11.3582 9.52326 11.6657 8.75 11.6667ZM14 11.0833V3.5C14 3.34529 13.9385 3.19692 13.8291 3.08752C13.7197 2.97812 13.5714 2.91667 13.4167 2.91667C13.262 2.91667 13.1136 2.97812 13.0042 3.08752C12.8948 3.19692 12.8333 3.34529 12.8333 3.5V11.0833C12.8333 11.5475 12.649 11.9926 12.3208 12.3208C11.9926 12.649 11.5475 12.8333 11.0833 12.8333H3.5C3.34529 12.8333 3.19692 12.8948 3.08752 13.0042C2.97812 13.1136 2.91667 13.262 2.91667 13.4167C2.91667 13.5714 2.97812 13.7197 3.08752 13.8291C3.19692 13.9385 3.34529 14 3.5 14H11.0833C11.8566 13.9991 12.5979 13.6915 13.1447 13.1447C13.6915 12.5979 13.9991 11.8566 14 11.0833Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
      </g>
      <defs>
        <clipPath id="clip0_463_2469">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(CopyIcon);
