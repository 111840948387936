import { FileSearchOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { NonUndefined } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { Button, Tabs, TabsProps, Typography } from 'antd';
import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { TWidget } from '../../api/features/widget/types';
import { BasePageWrapper } from '../../components/pages/base-page-wrapper';
import { useAppSelector } from '../../state';
import { useAllWidgetsSelector } from '../../state/widgets/selectors';
import techDocFile from '../../static/files/Assistapp_Technical_documentation_for_software.pdf';
import HtmlDoc from '../documentation-tabs/html-doc';
import TildaDoc from '../documentation-tabs/tilda-doc';

const Wrapper = styled(BasePageWrapper)`
  padding-bottom: 60px;
`;

const TabBarExtraContent: TabsProps['tabBarExtraContent'] = {
  left: (
    <Typography.Title
      style={{
        marginRight: 20,
      }}
      level={5}
    >
      Платформа
    </Typography.Title>
  ),
  right: (
    <Link to={techDocFile} target="_blank">
      <Button type="primary" icon={<FileSearchOutlined />}>
        Инструкция по установке и эксплуатации
      </Button>
    </Link>
  ),
};

const getDocumentationTabItems: (
  currentWidget?: TWidget
) => NonUndefined<TabsProps['items']> = (widget) => [
  {
    key: 'html',
    label: 'HTML',
    children: <HtmlDoc currentWidget={widget} />,
  },
  // {
  //   key: 'vue',
  //   label: 'Vue JS',
  //   children: 'Content of Tab Pane 3',
  // },
  // {
  //   key: 'react',
  //   label: 'React JS',
  //   children: 'Content of Tab Pane 3',
  // },
  {
    key: 'tilda',
    label: 'Tilda',
    children: <TildaDoc currentWidget={widget} />,
  },
  // {
  //   key: 'wordpress',
  //   label: 'WordPress',
  //   children: 'Content of Tab Pane 3',
  // },
  // {
  //   key: 'bitrix',
  //   label: '1С-Битрикс',
  //   children: 'Content of Tab Pane 3',
  // },
  // {
  //   key: 'shopify',
  //   label: 'Shopify',
  //   children: 'Content of Tab Pane 3',
  // },
  // {
  //   key: 'opencart',
  //   label: 'OpenCart',
  //   children: 'Content of Tab Pane 3',
  // },
  // {
  //   key: 'joomla',
  //   label: 'Joomla',
  //   children: 'Content of Tab Pane 3',
  // },
  // {
  //   key: 'modx',
  //   label: 'MODX',
  //   children: 'Content of Tab Pane 3',
  // },
  // {
  //   key: 'drupal',
  //   label: 'Drupal',
  //   children: 'Content of Tab Pane 3',
  // },
];

export const QUERY_ACTIVE_TAB_NAME = 'docTab';
export const QUERY_ACTIVE_WIDGET_NAME = 'widgetKey';

export const DocumentationPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabKey, setActiveTabKey] = useState<string>();

  const { list } = useAppSelector(useAllWidgetsSelector);

  const activeWidget: TWidget | undefined = useMemo(() => {
    const myWidgetKey = searchParams.get(QUERY_ACTIVE_WIDGET_NAME);

    if (myWidgetKey && list) {
      return list.find((el) => el.widgetFolder === myWidgetKey);
    }
    return undefined;
  }, [searchParams, list]);

  const handleTabKeyChange = useCallback((key: string) => {
    setSearchParams((params) => {
      params.set(QUERY_ACTIVE_TAB_NAME, key);
      setActiveTabKey(key);
      return params;
    });
  }, []);

  const documentationTabItems: NonUndefined<TabsProps['items']> =
    useMemo(() => {
      return getDocumentationTabItems(activeWidget);
    }, [activeWidget]);

  useLayoutEffect(() => {
    const activeTab = searchParams.get(QUERY_ACTIVE_TAB_NAME);

    if (activeTab) {
      setActiveTabKey(activeTab);
    } else {
      handleTabKeyChange(documentationTabItems[0].key);
    }
  }, []);

  return (
    <Wrapper>
      <Typography.Title level={2}>
        Документация по установке виджета
      </Typography.Title>
      <Tabs
        defaultActiveKey={documentationTabItems[0].key}
        items={documentationTabItems}
        onChange={handleTabKeyChange}
        activeKey={activeTabKey}
        tabBarExtraContent={TabBarExtraContent}
      />
    </Wrapper>
  );
};
