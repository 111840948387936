import styled from '@emotion/styled';

export const EffectNameCell = styled.div`
  display: flex;
  gap: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.MAIN.TEXT.C200};
`;

export const EffectCellIconWrapper = styled.div`
  width: 22px;
  max-width: 22px;
  min-width: 22px;
  height: 22px;
  max-height: 22px;
  min-height: 22px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
