import {
  TSettingItem,
  TSettingsList,
  TWidgetSetting,
  TWidgetSettings,
} from '../types/widget';
import * as effects from './core';

export const WIDGET_SETTINGS_DICTIONARY: TWidgetSettings = [
  ...(Object.keys(effects) as (keyof typeof effects)[]).map(
    (el) => effects[el]
  ),
].map((el) => {
  let newObj: Partial<TWidgetSetting> = {};

  (Object.keys(el) as (keyof TSettingItem)[]).map((key) => {
    newObj = {
      ...newObj,
      [key]: el[key] as any,
    };
  });

  return newObj as TWidgetSetting;
});

export const settingsList: TSettingsList = [
  ...(Object.keys(effects) as (keyof typeof effects)[]).map(
    (el) => effects[el]
  ),
];
