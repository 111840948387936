import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

import { authApi } from '../../api/features/auth';
import { TAuthJWTDecoded, TAuthResponse } from '../../api/features/auth/types';

type InitialStateType = {
  authed: boolean;
  data?: TAuthResponse;
  decoded?: TAuthJWTDecoded;
};

const initialState: InitialStateType = {
  authed: false,
  data: undefined,
  decoded: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState as InitialStateType,
  reducers: {
    authLogoutAction(state) {
      state.authed = false;
      state.data = undefined;
      state.decoded = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, action) => {
        state.decoded = jwtDecode(action.payload.accessToken);
        state.data = action.payload;
        state.authed = true;
      }
    );
    builder.addMatcher(
      authApi.endpoints.registration.matchFulfilled,
      (state, action) => {
        if (!action.meta.arg.originalArgs.skipAccountSetup) {
          state.decoded = jwtDecode(action.payload.accessToken);
          state.data = action.payload;
          state.authed = true;
        }
      }
    );
    builder.addMatcher(
      authApi.endpoints.getMyUserData.matchRejected,
      (state, action) => {
        if (action.payload?.status === 401) {
          state.authed = false;
          state.data = undefined;
          state.decoded = undefined;
        }
      }
    );
  },
});

export const { authLogoutAction } = authSlice.actions;
export const userAuthReducer = authSlice.reducer;
