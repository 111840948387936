import {
  EAccessButtonType,
  TWidgetEffectsSettings,
} from '@kylfyk/accessability-react';
import {
  TPresetKey,
  TWidgetPresetConfig,
} from '@kylfyk/accessability-react/dist/presets';
import {
  ICommonStyles,
  ISettings,
  TBreakPoints,
  TSettingsPosition,
} from '@kylfyk/accessability-react/dist/styles';
import { Dayjs } from 'dayjs';

import { EWidgetPresetThemeKey } from '../../../styles/widget-preset-style';
import {
  EWidgetSettingsFormNames,
  TFormNames,
} from '../../../types/forms/widget-settings';

export enum EWidgetStatus {
  DRAFT = 'DRAFT', // ТОЛЬКО НА КЛИЕНТЕ (при создании виджета), У СЕРВЕРА ТАКОГО СТАТУСА НЕТ
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  NEED_BUILD = 'NEED_BUILD',
  BUILD_IN_PROGRESS = 'BUILD_IN_PROGRESS',
  BUILD_COMPLETE = 'BUILD_COMPLETE',
}

export type TWidgetSettings = {
  theme: {
    COLORS: ICommonStyles<number>;
    SETTINGS: ISettings<number>;
  };
  presets: {
    [key in TPresetKey]: boolean;
  };
  effects: TWidgetEffectsSettings;
};

export type TWidgetBaseForm = {
  domain: string;
  widgetFolder: string;
  subdomain?: string[];
};

export type TWidgetPayload = {
  setting: TWidgetSettings;
  ownerId: string;
} & TWidgetBaseForm;

export type TWidgetCreateForm = (
  | {
      subdomain: undefined;
    }
  | {
      subdomain: string[];
    }
) &
  TWidgetBaseForm;

export type TCheckFolderPayload = {
  folder: string;
};

export type TWidget = {
  id: string;
  status: EWidgetStatus;

  widgetUrl?: string;
  widgetVendorsUrl?: string;

  domain: string;
  subdomain?: string;
  widgetFolder: string;
  setting: TWidgetSettings;

  dateTo?: string | null;
  createdAt: string;
  updatedAt: string;
};

// Представление виджета для раздела настроек (тут может быть как существующий виджет, так и новый)
export type TWidgetSettingsItem = Omit<
  TWidget,
  'id' | 'createdAt' | 'widgetUrl' | 'widgetVendorsUrl'
> & {
  id?: string;
  widgetReadyTheme?: EWidgetPresetThemeKey;
};

export type TSaveWidgetPayload = Pick<
  TWidgetSettingsItem,
  'domain' | 'subdomain' | 'setting' | 'widgetFolder' | 'id' | 'status'
> & {
  ownerId: string;
};

export type TWidgetFullSettingsForm = {
  [EWidgetSettingsFormNames.DATE_TO]?: Dayjs;
  [EWidgetSettingsFormNames.DOMAIN]: string;
  [EWidgetSettingsFormNames.SUB_DOMAIN]?: string[];
  [EWidgetSettingsFormNames.READY_THEME]?: EWidgetPresetThemeKey;
  [EWidgetSettingsFormNames.COLORS]: Record<
    TFormNames<ICommonStyles<string>>[number]['key'],
    string
  >;
  [EWidgetSettingsFormNames.FONT]?: string;
  [EWidgetSettingsFormNames.META_LINK_HIDDEN]?: boolean;
  [EWidgetSettingsFormNames.MENU_Z_INDEX]?: number;
  [EWidgetSettingsFormNames.USES_REM]?: boolean;
  [EWidgetSettingsFormNames.BUTTON_TYPE]?: EAccessButtonType;
  [EWidgetSettingsFormNames.HIDE_BUTTON]?: boolean;
  [EWidgetSettingsFormNames.ACCESS_BUTTON_Z_INDEX]?: number;
  [EWidgetSettingsFormNames.ACCESS_BUTTON_SIZE]?: number;
  [EWidgetSettingsFormNames.ACCESS_BUTTON_BORDER_RADIUS]?: number;
  [EWidgetSettingsFormNames.ACCESS_BUTTON_ENABLE_BREAKPOINTS]?: boolean;
  [EWidgetSettingsFormNames.ADAPTIVE]?: TSettingsPosition<number>;
  [EWidgetSettingsFormNames.ACCESS_BUTTON_ADAPTIVE_BREAKPOINTS]?: TBreakPoints<number>;
  [EWidgetSettingsFormNames.REM_TARGET_SELECTOR]?: string;
  [EWidgetSettingsFormNames.EFFECTS_SETTINGS]: TWidgetEffectsSettings;
  [EWidgetSettingsFormNames.PRESETS_SETTINGS]: TWidgetPresetConfig;
};

type TAlternativeContentItem = {
  alternativeValue: string;
  originalValue: string;
  fullDomainPath: string;
  pathJSElem: string;
};

export type TWidgetAlternativeContent = {
  settings: {
    items: TAlternativeContentItem[];
  };
};

export type TWidgetAlternativeContentResponse =
  | (TAlternativeContentCreatePayload & {
      id: string;
    })[]
  | null;

export type TAlternativeContentForm = {
  items: Omit<TAlternativeContentItem, 'originalValue'>[];
};

export type TAlternativeContentCreatePayload = TWidgetAlternativeContent & {
  widgetId: string;
};

export type TAlternativeContentPatchPayload =
  TAlternativeContentCreatePayload & {
    id: string;
  };
