import {
  ETextEffectKeys,
  WIDGET_PRESETS_LIST,
} from '@kylfyk/accessability-react';
import { TWidgetEffectsSettings } from '@kylfyk/accessability-react/dist/types/widget';

import { TWidgetSettings } from '../../api/features/widget/types';
import { WIDGET_BASE_THEME } from './theme';

const getPresetsKeys: () => TWidgetSettings['presets'] = () => {
  let result: Partial<TWidgetSettings['presets']> = {};

  WIDGET_PRESETS_LIST.map((el) => {
    result = {
      ...result,
      [el.key]: true,
    };
  });

  return result as TWidgetSettings['presets'];
};

const DEFAULT_EFFECTS_PRESETS: Partial<TWidgetEffectsSettings> = {
  [ETextEffectKeys.CONTENT_SCALE]: {
    mobile: false,
    desktop: true,
  },
  [ETextEffectKeys.CURSOR]: {
    mobile: false,
    desktop: true,
  },
};

const getEffectsSettings: () => TWidgetEffectsSettings = () => {
  let result: Partial<TWidgetEffectsSettings> = {};

  for (const key in ETextEffectKeys) {
    if (ETextEffectKeys[key as keyof typeof ETextEffectKeys]) {
      if (
        DEFAULT_EFFECTS_PRESETS[
          ETextEffectKeys[key as keyof typeof ETextEffectKeys]
        ]
      ) {
        result = {
          ...result,
          [ETextEffectKeys[key as keyof typeof ETextEffectKeys]]:
            DEFAULT_EFFECTS_PRESETS[
              ETextEffectKeys[key as keyof typeof ETextEffectKeys]
            ],
        };
      } else {
        result = {
          ...result,
          [ETextEffectKeys[key as keyof typeof ETextEffectKeys]]: {
            mobile: true,
            desktop: true,
          },
        };
      }
    }
  }

  return result as TWidgetEffectsSettings;
};

export function getDefaultWidgetSettings(): TWidgetSettings {
  return {
    theme: WIDGET_BASE_THEME,
    presets: getPresetsKeys(),
    effects: getEffectsSettings(),
  };
}
