import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { CONTEXT_ROOT } from '../constants/app';
import { persistedStore, store } from '../state';
import { AuthProvider } from './auth-provider';
import { LocaleProvider } from './locale-provider';
import { NotificationProvider } from './notification-provider';
import { ThemeProvider } from './theme-provider';

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore} loading={null}>
        <LocaleProvider>
          <BrowserRouter basename={CONTEXT_ROOT}>
            <NotificationProvider>
              <AuthProvider>
                <ThemeProvider>{children}</ThemeProvider>
              </AuthProvider>
            </NotificationProvider>
          </BrowserRouter>
        </LocaleProvider>
      </PersistGate>
    </Provider>
  );
};
