export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const isSpeechAvailable = () => {
  return !!window.speechSynthesis;
};

export const speak = (text: string, selectedVoice: SpeechSynthesisVoice) => {
  if (speechSynthesis.speaking) {
    console.error('speechSynthesis.speaking');
    return;
  }

  const stringToSpeech = text
    .split(' ')
    .map((el) => el.replace(new RegExp('\n', 'g'), ''));
  const resultTextArray: string[] = [];

  let prevWasSpace = false;

  for (let i = 0; i < stringToSpeech.length; i++) {
    if (stringToSpeech[i]) {
      resultTextArray.push(stringToSpeech[i]);

      if (prevWasSpace) {
        prevWasSpace = false;
      }
    } else if (i === 0 && stringToSpeech[i] !== '') {
      resultTextArray.push(stringToSpeech[i]);
      prevWasSpace = false;
    } else if (
      i > 0 &&
      !stringToSpeech[i] &&
      !stringToSpeech[i - 1] &&
      !prevWasSpace &&
      resultTextArray.length > 0
    ) {
      resultTextArray.push('. ');
      prevWasSpace = true;
    } else if (prevWasSpace && stringToSpeech[i]) {
      resultTextArray.push(stringToSpeech[i]);
      prevWasSpace = false;
    }
  }

  const finalText = resultTextArray
    .join(' ')
    .split('')
    .map((el, i, arr) => {
      if (
        i !== 0 &&
        arr[i - 1].toLowerCase() !== arr[i - 1].toUpperCase() &&
        arr[i - 1].toLowerCase() === arr[i - 1] &&
        arr[i].toUpperCase() === arr[i] &&
        arr[i].toUpperCase() !== arr[i].toLowerCase()
      ) {
        return `. ${arr[i]}`;
      } else return arr[i];
    })
    .join('');

  const ssUtterance = new SpeechSynthesisUtterance(finalText);

  if (selectedVoice.name === 'Милена') {
    ssUtterance.rate = 0.7;
  } else {
    ssUtterance.rate = 1.2;
  }

  ssUtterance.voice = selectedVoice;

  speechSynthesis.speak(ssUtterance);
};

export const IS_SESSION_ACTIVE_KEY = 'accessibility-widget-started';
