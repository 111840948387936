import { useEffect } from 'react';

import InvertColorsIcon from '../../icons/invertColorsIcon';
import { useEffectSelector } from '../../state/reducers/widget/selectors';
import { TSettingItem } from '../../types/widget';
import { ETextEffectKeys } from '../names';

export const invertColorEffect: TSettingItem = {
  Icon: InvertColorsIcon,
  key: ETextEffectKeys.INVERT_COLORS,
};

export const useInvertColorEffect = () => {
  const effect = useEffectSelector(ETextEffectKeys.INVERT_COLORS);
  const rule = 'invert(1)';

  useEffect(() => {
    const container = document.querySelector('html') as HTMLElement;

    let oldFilters = window.getComputedStyle(container)?.filter ?? '';
    oldFilters = oldFilters
      .split(' ')
      .filter((el) => el !== 'none' && rule !== el)
      .join(' ');

    if (effect.value) {
      container.style.filter = `${oldFilters} ${rule}`;
    } else {
      container.style.filter = oldFilters;
    }
  }, [effect]);
};
