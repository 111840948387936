export enum TStatEvent {
  WIDGET_LOADED = 'widget-loaded',
  OPEN_WIDGET = 'widget-open',
  CLOSE_WIDGET = 'widget-close',
  OPEN_WIDGET_KBD = 'widget-open-kbd',
  CLOSE_WIDGET_KBD = 'widget-close-kbd',
  RESET_SETTINGS = 'widget-reset-settings',
  CHANGE_LANGUAGE = 'language-change',
  EFFECT_CHANGE = 'effect-change',
  EFFECT_CHANGE_KBD = 'effect-change-kbd',
  PRESET_CHANGE = 'preset-change',
  PRESET_CHANGE_KBD = 'preset-change-kbd',
  LANDING_LINK_CLICK = 'landing-link-click',
}

export type TStatItem = {
  event: TStatEvent;
  time: string;
  text?: string;
  value?: boolean | number | string;
};
