import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { getIconBaseColor } from '../../utils/ui/icons';
import { TBaseIconProps } from './index';

const StatisticIcon: FC<TBaseIconProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_551_1702)">
        <path
          d="M4.125 15.75C3.62772 15.75 3.15081 15.5525 2.79917 15.2008C2.44754 14.8492 2.25 14.3723 2.25 13.875V1.125C2.25 0.826631 2.13147 0.540483 1.9205 0.329505C1.70952 0.118526 1.42337 0 1.125 0V0C0.826631 0 0.540483 0.118526 0.329505 0.329505C0.118526 0.540483 0 0.826631 0 1.125L0 13.875C0 14.969 0.434597 16.0182 1.20818 16.7918C1.98177 17.5654 3.03098 18 4.125 18H16.875C17.1734 18 17.4595 17.8815 17.6705 17.6705C17.8815 17.4595 18 17.1734 18 16.875C18 16.5766 17.8815 16.2905 17.6705 16.0795C17.4595 15.8685 17.1734 15.75 16.875 15.75H4.125Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
        <path
          d="M14.625 13.5C14.9234 13.5 15.2095 13.3815 15.4205 13.1705C15.6315 12.9595 15.75 12.6734 15.75 12.375V7.875C15.75 7.57663 15.6315 7.29048 15.4205 7.0795C15.2095 6.86853 14.9234 6.75 14.625 6.75C14.3266 6.75 14.0405 6.86853 13.8295 7.0795C13.6185 7.29048 13.5 7.57663 13.5 7.875V12.375C13.5 12.6734 13.6185 12.9595 13.8295 13.1705C14.0405 13.3815 14.3266 13.5 14.625 13.5Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
        <path
          d="M5.625 13.5C5.92337 13.5 6.20952 13.3815 6.4205 13.1705C6.63147 12.9595 6.75 12.6734 6.75 12.375V7.875C6.75 7.57663 6.63147 7.29048 6.4205 7.0795C6.20952 6.86853 5.92337 6.75 5.625 6.75C5.32663 6.75 5.04048 6.86853 4.8295 7.0795C4.61853 7.29048 4.5 7.57663 4.5 7.875V12.375C4.5 12.6734 4.61853 12.9595 4.8295 13.1705C5.04048 13.3815 5.32663 13.5 5.625 13.5Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
        <path
          d="M10.125 13.4995C10.4234 13.4995 10.7095 13.381 10.9205 13.17C11.1315 12.959 11.25 12.6729 11.25 12.3745V4.12451C11.25 3.82614 11.1315 3.53999 10.9205 3.32902C10.7095 3.11804 10.4234 2.99951 10.125 2.99951C9.82663 2.99951 9.54048 3.11804 9.3295 3.32902C9.11853 3.53999 9 3.82614 9 4.12451V12.3745C9 12.6729 9.11853 12.959 9.3295 13.17C9.54048 13.381 9.82663 13.4995 10.125 13.4995Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
      </g>
      <defs>
        <clipPath id="clip0_551_1702">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(StatisticIcon);
