import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { userAuthReducer } from './auth.slice';

const userAuthConfig = {
  key: 'user/auth',
  storage,
};

export const authReducer = combineReducers({
  userAuth: persistReducer(userAuthConfig, userAuthReducer),
});
