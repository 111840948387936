import { createApi } from '@reduxjs/toolkit/query/react';

import {
  removeWidgetSettingByIdAction,
  updateWidgetSettingAction,
} from '../../../state/widgets/widget-settings.slice';
import {
  addWidgetAction,
  removeWidgetAction,
  updateWidgetByIdAction,
} from '../../../state/widgets/widget.slice';
import baseQuery from '../../base-query';
import { authApi } from '../auth';
import {
  EWidgetStatus,
  TAlternativeContentCreatePayload,
  TAlternativeContentPatchPayload,
  TCheckFolderPayload,
  TSaveWidgetPayload,
  TWidget,
  TWidgetAlternativeContent,
  TWidgetAlternativeContentResponse,
  TWidgetPayload,
} from './types';

export const widgetApi = createApi({
  reducerPath: 'widget/api',
  baseQuery,
  endpoints: (builder) => ({
    createWidget: builder.mutation<TWidget, TWidgetPayload>({
      query: (body) => ({
        url: '/widget',
        method: 'POST',
        body,
      }),
    }),
    checkWidgetFolder: builder.mutation<boolean, TCheckFolderPayload>({
      query: (widgetFolder) => ({
        url: '/widget/check-folder',
        method: 'POST',
        body: widgetFolder,
      }),
    }),
    updateWidget: builder.query<TWidget, string>({
      query: (id) => ({
        url: `/widget/${id}`,
        method: 'GET',
      }),
      async onQueryStarted(updateWidgetId, api) {
        await new Promise<void>((res, rej) => {
          api.queryFulfilled
            .then((response) => {
              api.dispatch(
                updateWidgetByIdAction({
                  ...response.data,
                  status:
                    response.data.status === EWidgetStatus.ACTIVE
                      ? EWidgetStatus.BUILD_IN_PROGRESS
                      : response.data.status,
                })
              );

              if (
                response.data.status !== EWidgetStatus.BUILD_COMPLETE &&
                response.data.status !== EWidgetStatus.INACTIVE
              ) {
                setTimeout(() => {
                  api.dispatch(
                    widgetApi.endpoints.updateWidget.initiate(updateWidgetId, {
                      forceRefetch: true,
                    })
                  );
                }, 5000);
              }

              res();
            })
            .catch((e) => {
              if (e.error && e.error.status === 429) {
                console.warn('Too many requests');

                setTimeout(() => {
                  api.dispatch(
                    widgetApi.endpoints.updateWidget.initiate(updateWidgetId, {
                      forceRefetch: true,
                    })
                  );
                }, 20000);
                res();
              } else {
                rej(e);
              }
            });
        });
      },
    }),
    saveWidgetSettings: builder.mutation<
      TWidget,
      {
        id?: string;
        data: TSaveWidgetPayload;
      }
    >({
      query: ({ id, data }) => {
        let url = '/widget';
        let method = 'POST';
        const dataToLoad = { ...data };

        if (id) {
          url = url + `/${id}`;
          method = 'PATCH';

          if ('widgetFolder' in dataToLoad) {
            // @ts-ignore
            delete dataToLoad.widgetFolder;
          }
          if ('widgetReadyTheme' in dataToLoad) {
            // @ts-ignore
            delete dataToLoad.widgetReadyTheme;
          }
          if ('id' in dataToLoad) {
            // @ts-ignore
            delete dataToLoad.id;
          }
          if ('updatedAt' in dataToLoad) {
            // @ts-ignore
            delete dataToLoad.updatedAt;
          }
          if ('ownerId' in dataToLoad) {
            // @ts-ignore
            delete dataToLoad.ownerId;
          }
        }

        return {
          url,
          method,
          body: {
            ...dataToLoad,
          },
        };
      },
      transformErrorResponse: (response, _, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
      async onQueryStarted(arg, api) {
        await new Promise<void>((res) => {
          api.queryFulfilled
            .then((response) => {
              if (arg.id) {
                api.dispatch(updateWidgetByIdAction(response.data));
              } else {
                api.dispatch(addWidgetAction(response.data));
              }

              api.dispatch(
                updateWidgetSettingAction({
                  data: response.data,
                })
              );

              setTimeout(() => {
                api.dispatch(
                  widgetApi.endpoints.updateWidget.initiate(response.data.id, {
                    forceRefetch: true,
                  })
                );
              }, 5000);

              res();
            })
            .catch((e) => {
              console.error(e);
            });
        });
      },
    }),
    deleteWidget: builder.mutation<void, string>({
      query: (widgetId) => ({
        url: `widget/${widgetId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(widgetId, api) {
        await new Promise<void>((res) => {
          api.queryFulfilled
            .then(() => {
              api.dispatch(removeWidgetSettingByIdAction(widgetId));
              api.dispatch(removeWidgetAction(widgetId));
              api.dispatch(
                authApi.util.updateQueryData(
                  'getMyUserData',
                  undefined,
                  (data) => {
                    data?.widget?.filter?.((el) => el.id !== widgetId);
                  }
                )
              );
              res();
            })
            .catch((e) => {
              console.error(e);
            });
        });
      },
    }),
    getUserAlternativeContents: builder.query<
      TWidgetAlternativeContentResponse,
      string
    >({
      query: () => ({
        url: `alternative-content/by-owner`,
        method: 'GET',
      }),
    }),
    createWidgetAlternativeContent: builder.mutation<
      TWidgetAlternativeContent,
      TAlternativeContentCreatePayload
    >({
      query: (body) => ({
        url: 'alternative-content',
        method: 'POST',
        body,
      }),
    }),
    updateWidgetAlternateContent: builder.mutation<
      TAlternativeContentCreatePayload,
      TAlternativeContentPatchPayload
    >({
      query: ({ id, ...alternateContent }) => ({
        url: `alternative-content/${id}`,
        method: 'PATCH',
        body: alternateContent,
      }),
    }),
  }),
});

export const {
  useCheckWidgetFolderMutation,
  useSaveWidgetSettingsMutation,
  useDeleteWidgetMutation,
  useGetUserAlternativeContentsQuery,
  useCreateWidgetAlternativeContentMutation,
  useUpdateWidgetAlternateContentMutation,
} = widgetApi;
