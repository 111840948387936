import { Dispatch, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';

import * as apiList from '../api/features';
import { RootCombine, rootReducer } from './reducers';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      ...(Object.keys(apiList) as (keyof typeof apiList)[]).map(
        (apiKey) => apiList[apiKey].middleware
      )
    ),
});

export const persistedStore = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => Dispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootCombine> = useSelector;
