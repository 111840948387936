import { withTheme } from '@emotion/react';
import { FC } from 'react';

import { getIconBaseColor } from '../../utils/ui/icons';
import { TBaseIconProps } from './index';

const DocsIcon: FC<TBaseIconProps> = ({ theme, ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_551_1717)">
        <path
          d="M9 18C10.78 18 12.5201 17.4722 14.0001 16.4832C15.4802 15.4943 16.6337 14.0887 17.3149 12.4442C17.9961 10.7996 18.1743 8.99002 17.8271 7.24419C17.4798 5.49836 16.6226 3.89472 15.364 2.63604C14.1053 1.37737 12.5016 0.520204 10.7558 0.172937C9.00998 -0.17433 7.20038 0.00389957 5.55585 0.685088C3.91131 1.36628 2.50571 2.51983 1.51677 3.99987C0.527841 5.47991 0 7.21997 0 9C0.00258081 11.3862 0.951621 13.6738 2.63889 15.3611C4.32616 17.0484 6.61384 17.9974 9 18ZM9 3.75C9.2225 3.75 9.44001 3.81598 9.62502 3.9396C9.81002 4.06322 9.95422 4.23892 10.0394 4.44449C10.1245 4.65005 10.1468 4.87625 10.1034 5.09448C10.06 5.31271 9.95283 5.51316 9.7955 5.6705C9.63816 5.82783 9.43771 5.93498 9.21948 5.97839C9.00125 6.0218 8.77505 5.99952 8.56948 5.91437C8.36391 5.82922 8.18821 5.68503 8.0646 5.50002C7.94098 5.31502 7.875 5.09751 7.875 4.875C7.875 4.57664 7.99353 4.29049 8.2045 4.07951C8.41548 3.86853 8.70163 3.75 9 3.75ZM8.25 7.5H9C9.39783 7.5 9.77936 7.65804 10.0607 7.93934C10.342 8.22065 10.5 8.60218 10.5 9V13.5C10.5 13.6989 10.421 13.8897 10.2803 14.0303C10.1397 14.171 9.94891 14.25 9.75 14.25C9.55109 14.25 9.36032 14.171 9.21967 14.0303C9.07902 13.8897 9 13.6989 9 13.5V9H8.25C8.05109 9 7.86032 8.92099 7.71967 8.78033C7.57902 8.63968 7.5 8.44892 7.5 8.25C7.5 8.05109 7.57902 7.86033 7.71967 7.71967C7.86032 7.57902 8.05109 7.5 8.25 7.5Z"
          fill={getIconBaseColor(theme.MAIN.TEXT.C200)}
        />
      </g>
      <defs>
        <clipPath id="clip0_551_1717">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default withTheme(DocsIcon);
