import { FC, ReactElement, ReactNode, useMemo } from 'react';

import { EUserRole } from '../api/features/auth/types';
import { useAppSelector } from '../state';
import { useUserAuthSelector } from '../state/auth/selectors';

type TProps = {
  requiredRoles: EUserRole[];
  children: ReactNode;
};

export const PrivateRoute: FC<TProps> = ({ requiredRoles, children }) => {
  const { decoded, authed } = useAppSelector(useUserAuthSelector);

  const isRouteAvailable = useMemo(() => {
    return authed && decoded && requiredRoles.includes(decoded.role);
  }, [requiredRoles, authed, decoded]);

  return isRouteAvailable ? (children as ReactElement) : null;
};
