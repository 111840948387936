import dayjs from 'dayjs';

export function checkDateTo(): boolean {
  const dateTo: string | undefined = process.env.DATE_TO;

  if (dateTo) {
    return dayjs(dateTo).isAfter(dayjs());
  } else {
    return true;
  }
}
