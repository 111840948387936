import styled from '@emotion/styled';
import { Form } from 'antd';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCheckWidgetFolderMutation } from '../../api/features/widget';
import {
  TWidgetBaseForm,
  TWidgetCreateForm,
} from '../../api/features/widget/types';
import { BasePageWrapper } from '../../components/pages/base-page-wrapper';
import { LocaleParts } from '../../locale';
import { useAppMessage } from '../../providers/notification-provider';
import { EPaths } from '../../routes/route-paths';
import { useAppDispatch } from '../../state';
import { logErrorAction } from '../../state/actions';
import { createWidgetSettingAction } from '../../state/widgets/widget-settings.slice';
import { mapClientNewWidgetToSettingsItem } from '../../utils/widget/mappers';
import { CreateWidgetForm } from '../forms/create-widget-form';

const CreateWidgetFormWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const CreateWidgetPage: FC = () => {
  const dispatch = useAppDispatch();
  const [checkFolder] = useCheckWidgetFolderMutation();
  const { messageApi } = useAppMessage();
  const [form] = Form.useForm<TWidgetCreateForm>();
  const navigate = useNavigate();
  const { t } = useTranslation([LocaleParts.CREATE_WIDGET_FORM]);

  const handleCreateWidget = useCallback((formValues: TWidgetBaseForm) => {
    try {
      const newDraftWidget = mapClientNewWidgetToSettingsItem(formValues);

      dispatch(createWidgetSettingAction(newDraftWidget));
      void messageApi.success(
        'Виджет добавлен, давайте приступим к его настройке для размещения на сайте'
      );

      navigate(EPaths.SETTINGS + `/${formValues.widgetFolder}`);
    } catch (err) {
      dispatch(logErrorAction(err));
      void messageApi.error(
        'Произошла непредвиденная ошибка, попробуйте повторить попытку позже'
      );
    }
  }, []);

  const handleCheckWidgetFolder: (folder: string) => Promise<boolean> =
    useCallback((folder) => {
      return checkFolder({
        folder,
      }).unwrap();
    }, []);

  return (
    <BasePageWrapper>
      <CreateWidgetFormWrapper>
        <CreateWidgetForm
          onFormFinish={handleCreateWidget}
          checkWidgetFolder={handleCheckWidgetFolder}
          form={form}
          t={t}
        />
      </CreateWidgetFormWrapper>
    </BasePageWrapper>
  );
};
