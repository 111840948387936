import { Theme, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Alert, ConfigProvider } from 'antd';
import React, { FC, memo } from 'react';

import {
  TWidget,
  TWidgetSettingsItem,
} from '../../../../api/features/widget/types';
import WidgetSettingsForm from '../../../../containers/forms/widget-settings';
import { TMessageAPI } from '../../../../providers/notification-provider';
import { WidgetSettingsFormProvider } from '../../../../providers/widget-settings-form-provider';
import { ECSSVars } from '../../../../styles';
import { BASE_WIDGET_WIDTH } from '../../../../utils/widget/theme';
import WidgetMainInfo from '../widget-main-info';
import WidgetPreviewComponent from '../widget-preview-component';

export const WidgetEmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

const Wrapper = styled.div`
  ${ECSSVars.THEME_RADIO_BORDER_COLOR}: ${({ theme }) => theme.MAIN.BLACK.C100};
  ${ECSSVars.THEME_RADIO_BOX_SHADOW}: transparent;

  display: grid;
  position: relative;
  grid-template-columns: 1fr ${BASE_WIDGET_WIDTH + 40}px;

  .ant-form-item-label {
    padding-left: 12px;
    padding-bottom: 6px;
    line-height: 120%;
  }

  .ant-form-item {
    margin-bottom: 20px;
  }

  .hidden-radio {
    .ant-radio.ant-wave-target {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }
  }

  .ant-color-picker-trigger {
    justify-content: flex-start;
  }

  .ant-slider-mark-text {
    white-space: nowrap;
  }

  .ant-radio-wrapper-checked {
    ${ECSSVars.THEME_RADIO_BORDER_COLOR}: ${({ theme }) =>
      theme.MAIN.WHITE.C100};
    ${ECSSVars.THEME_RADIO_BOX_SHADOW}: ${({ theme }) => theme.MAIN.BLACK.C001};
  }
`;

export const WidgetSettingsGridForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
`;

export const WidgetGridSettingItem = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 1.2rem;
`;

const WidgetInfoInner = styled.div`
  background-color: transparent;
`;

type TProps = {
  theme: Theme;
  widgetSettingsItem: TWidgetSettingsItem;
  widgetFullInfo?: TWidget;
  subDomainAttentionViewed: boolean;
  messageApi: TMessageAPI;
};

const WidgetSettings: FC<TProps> = memo(
  ({
    widgetSettingsItem,
    theme,
    subDomainAttentionViewed,
    widgetFullInfo,
    messageApi,
  }) => {
    return (
      <Wrapper>
        <WidgetSettingsFormProvider
          widgetSettingsItem={widgetSettingsItem}
          subDomainAttentionViewed={subDomainAttentionViewed}
        >
          <WidgetInfoInner>
            {!widgetFullInfo && (
              <Alert
                showIcon
                type="info"
                message="Черновик"
                description={`Виджет не опубликован, для публикации и дальнейшей вставки на сайт необходимо нажать кнопку "Применить".`}
              />
            )}
            {widgetFullInfo && (
              <WidgetMainInfo
                theme={theme}
                widget={widgetFullInfo}
                messageApi={messageApi}
              />
            )}
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    colorBgContainer: theme.MAIN.WHITE.C200,
                    colorBorder: theme.MAIN.WHITE.C100,
                    borderRadius: 4,
                  },
                },
              }}
            >
              <WidgetSettingsForm widgetSettingsItem={widgetSettingsItem} />
            </ConfigProvider>
          </WidgetInfoInner>
          <WidgetPreviewComponent />
        </WidgetSettingsFormProvider>
      </Wrapper>
    );
  }
);

export default withTheme(WidgetSettings);
