import styled from '@emotion/styled';
import { Button, Space, Table, Typography } from 'antd';
import { FC, useCallback, useMemo, useState } from 'react';

import { useGetAllQuery } from '../../api/features/users';
import { BasePageWrapper } from '../../components/pages/base-page-wrapper';
import { UserCreateModal } from '../modals/user-create-modal';
import { getUsersTableColumns } from '../tables/columns/users-table-columns';
import { TUserTableData } from '../tables/types/users-table-types';

const TableWrapper = styled.div`
  margin-top: 20px;
`;

const PageHeader = styled(Space)`
  align-items: center;
  justify-content: space-between;
`;

export const UsersPage: FC = () => {
  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);

  const { data, isLoading } = useGetAllQuery();

  const tableData: TUserTableData[] = useMemo(() => {
    return data
      ? data.map((el) => ({
          key: el.id,
          email: el.email,
          role: el.role,
          status: el.status,
          createdAt: el.createdAt,
          updatedAt: el.updatedAt,
        }))
      : [];
  }, [data]);

  const tableColumns = useMemo(() => getUsersTableColumns(), []);

  const createNewUser = useCallback(() => {
    setCreateUserModalOpen(true);
  }, []);

  return (
    <BasePageWrapper>
      <PageHeader>
        <Typography.Title level={2}>Пользователи</Typography.Title>
        <Button type="primary" onClick={createNewUser}>
          Зарегистрировать нового клиента
        </Button>
      </PageHeader>
      <TableWrapper>
        <Table
          dataSource={tableData}
          columns={tableColumns}
          loading={isLoading}
        />
      </TableWrapper>
      <UserCreateModal
        open={createUserModalOpen}
        setOpen={setCreateUserModalOpen}
      />
    </BasePageWrapper>
  );
};
