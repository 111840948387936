import { createApi } from '@reduxjs/toolkit/query/react';

import { TStatItem } from '../../types/statistic';
import baseQuery from '../base-query';

export const analyticsApi = createApi({
  reducerPath: 'analytics/api',
  baseQuery,
  endpoints: (builder) => ({
    writeStatistic: builder.mutation<void, TStatItem>({
      query: (data) => ({
        url: 'statistic',
        method: 'POST',
        body: {
          domain: window.location.origin,
          json: data,
        },
      }),
    }),
  }),
});

export const { useWriteStatisticMutation } = analyticsApi;
