import { ETextEffectKeys, TEffectTextKey } from '../effects/names';

export const WIDGET_SETTINGS_INDEXES: TEffectTextKey[] = [
  ETextEffectKeys.TEXT_INCREASE,
  ETextEffectKeys.CONTENT_SCALE,
  ETextEffectKeys.READABLE_FONT,
  ETextEffectKeys.DYSLEXIC_FONT,
  ETextEffectKeys.SIMPLE_LANGUAGE,
  ETextEffectKeys.CURSOR,
  ETextEffectKeys.CITE_THEME,
  ETextEffectKeys.INVERT_COLORS,
  ETextEffectKeys.BRIGHTNESS,
  ETextEffectKeys.CONTRAST,
  ETextEffectKeys.GRAYSCALE,
  ETextEffectKeys.READ_LINE,
  ETextEffectKeys.READ_AREA,
  ETextEffectKeys.HIDE_IMAGES,
  ETextEffectKeys.STOP_ANIMATIONS,
  ETextEffectKeys.LINKS_MARK,
  ETextEffectKeys.TEXT_TO_SPEECH,
];
