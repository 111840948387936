import styled from '@emotion/styled';
import { FC, Fragment, PropsWithChildren, useEffect, useRef } from 'react';

import { ETextEffectKeys } from '../../effects/names';
import { useEffectSelector } from '../../state/reducers/widget/selectors';

const LinesElement = styled('div')`
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999999999;
  pointer-events: none;
`;

export const ReadLine: FC<PropsWithChildren> = ({ children }) => {
  const effect = useEffectSelector(ETextEffectKeys.READ_LINE);
  const lineRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (lineRef.current) {
        lineRef.current.style.top = `${e.y + 15}px`;
      }
    };

    if (effect.value) {
      window.addEventListener('mousemove', listener);
    }

    return () => {
      window.removeEventListener('mousemove', listener);
    };
  }, [effect]);

  return (
    <Fragment>
      {effect.value && <LinesElement ref={lineRef} />}
      {children}
    </Fragment>
  );
};
