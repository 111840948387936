import { TWidgetSettings } from '../../../api/features/widget/types';
import { EWidgetPresetThemeKey } from '../../../styles/widget-preset-style';
import BASE_THEME from './base';
import DARK_THEME from './dark';
import GREEN_THEME from './green';
import YELLOW_THEME from './yellow';

type TReadyWidgetTheme = TWidgetSettings['theme']['COLORS'];

export const READY_THEMES: Record<EWidgetPresetThemeKey, TReadyWidgetTheme> = {
  [EWidgetPresetThemeKey.DEFAULT]: BASE_THEME,
  [EWidgetPresetThemeKey.YELLOW]: YELLOW_THEME,
  [EWidgetPresetThemeKey.GREEN]: GREEN_THEME,
  [EWidgetPresetThemeKey.DARK]: DARK_THEME,
};
