import { EUserStatus } from '../../api/features/auth/types';

export const UserColorByStatus: Record<EUserStatus, string> = {
  [EUserStatus.ACTIVE]: '#27ec0a',
  [EUserStatus.DEACTIVE]: '#c5c5c9',
};

export const UserTextByStatus: Record<EUserStatus, string> = {
  [EUserStatus.ACTIVE]: 'Активен',
  [EUserStatus.DEACTIVE]: 'Не активен',
};
