import { LogoutOutlined } from '@ant-design/icons';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useGetMyUserDataQuery } from '../../api/features/auth';
import { TAsideProps } from '../../components/layouts/base/aside';
import { BaseLayout } from '../../components/layouts/base/base-layout';
import { TBaseSelectOptions } from '../../components/ui/inputs/base-select';
import { RIGHTS_RESERVED_YEAR } from '../../constants/app';
import { LocaleParts, Localization, localeToText } from '../../locale';
import { AppAsideLayoutRoutes } from '../../routes/app-aside-layout-routes';
import { useAppSelector } from '../../state';
import {
  useAppLoadingSelector,
  useLocaleSelector,
} from '../../state/app/selectors';
import { setLocale } from '../../state/app/slices/locale-slice';
import { authLogoutAction } from '../../state/auth/auth.slice';
import { useUserAuthSelector } from '../../state/auth/selectors';

const LANG_OPTIONS: TBaseSelectOptions<Localization>[] = [
  {
    value: Localization.RU,
    label: localeToText(Localization.RU),
  },
  {
    value: Localization.EN,
    label: localeToText(Localization.EN),
  },
  {
    value: Localization.ES,
    label: localeToText(Localization.ES),
  },
];

export const AppBaseLayout: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation([LocaleParts.LAYOUT]);
  const { authed } = useAppSelector(useUserAuthSelector);
  const { data } = useGetMyUserDataQuery(undefined, {
    skip: !authed,
  });
  const { baseContainerLoading } = useAppLoadingSelector();

  const { current } = useLocaleSelector();

  const handlePickLocale = useCallback((locale: Localization) => {
    dispatch(setLocale(locale));
  }, []);

  const userMenu: TAsideProps['userMenu'] = useMemo(
    () => [
      {
        key: 'logout',
        label: 'Выйти из аккаунта',
        icon: <LogoutOutlined />,
        onClick: () => {
          dispatch(authLogoutAction());
        },
      },
    ],
    []
  );

  return (
    <BaseLayout
      t={t}
      userData={authed ? data : undefined}
      userMenu={userMenu}
      currentLanguage={current}
      setLanguage={handlePickLocale}
      languageOptions={LANG_OPTIONS}
      rightsYear={RIGHTS_RESERVED_YEAR}
      AsideContentComponent={AppAsideLayoutRoutes}
      loading={baseContainerLoading}
    />
  );
};
