import styled from '@emotion/styled';
import { Empty } from 'antd';
import React, { FC } from 'react';

import { BasePageWrapper } from '../../components/pages/base-page-wrapper';
import WidgetPane from '../../components/ui/widget-pane';
import WidgetAddNewButton from '../../components/ui/widget-pane/widget-add-new-button';
import { useAppMessage } from '../../providers/notification-provider';
import { useAppSelector } from '../../state';
import {
  useAllWidgetsSelector,
  useCanCreateWidgetSelector,
  widgetSettingsSelector,
} from '../../state/widgets/selectors';
import { sortByCreateDateFn } from '../../utils/common-js';

const WidgetsWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  grid-auto-rows: min-content;
  height: auto;
  gap: 30px;
  padding-bottom: 30px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
`;

export const HomePage: FC = () => {
  const { list } = useAppSelector(useAllWidgetsSelector);
  const { list: settingsList } = useAppSelector(widgetSettingsSelector);
  const { messageApi } = useAppMessage();
  const isAddWidgetAvailable = useAppSelector(useCanCreateWidgetSelector);

  return (
    <BasePageWrapper>
      <WidgetsWrapper>
        {isAddWidgetAvailable && (
          <WidgetAddNewButton text="Добавить виджет на новый сайт" />
        )}
        {[...list].sort(sortByCreateDateFn).map((el) => (
          <WidgetPane key={el.id} widget={el} messageApi={messageApi} />
        ))}
        {list.length === 0 &&
          !isAddWidgetAvailable &&
          settingsList.length > 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_DEFAULT}
              description="После завершения настройки тут появится ваш виджет"
            />
          )}
      </WidgetsWrapper>
    </BasePageWrapper>
  );
};
