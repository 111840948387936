import { FC, useCallback, useMemo } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import WidgetMenu from './components/widget-menu';
import { useSettingsList } from './hooks/useSettingsList';
import { TWidgetPresetConfig, usePresetList } from './presets';
import { LocaleService } from './services/locale-service';
import { PresetService } from './services/preset-service';
import { persistedStore, store, useAppDispatch } from './state';
import {
  resetEffects,
  setEffectValue,
} from './state/reducers/widget/slices/effectsSlice';
import { ICommonStyles, ISettings } from './styles';
import { TOnWidgetChange, TWidgetEffectsSettings } from './types/widget';

const Content: FC<{
  theme: { COLORS: ICommonStyles<number>; SETTINGS: ISettings<number> };
  effectsConfig: TWidgetEffectsSettings;
  presetsConfig: TWidgetPresetConfig;
}> = ({ theme, effectsConfig, presetsConfig }) => {
  const dispatch = useAppDispatch();

  const handleReset = useCallback(() => {
    dispatch(resetEffects());
  }, []);

  const [settingsList] = useSettingsList(effectsConfig);
  const presetsList = usePresetList(presetsConfig);

  const onChangeList: TOnWidgetChange[] = useMemo(() => {
    return settingsList.map((el) => ({
      key: el.key,
      onChange: (value) => {
        dispatch(
          setEffectValue({
            value: value,
            key: el.key,
          })
        );
      },
    }));
  }, []);

  return (
    <WidgetMenu
      theme={theme}
      handleReset={handleReset}
      onChangeList={onChangeList}
      settingsList={settingsList}
      presetList={presetsList}
      defaultPosition
      visible
    />
  );
};

export const ModuleWidget: FC<{
  theme: { COLORS: ICommonStyles<number>; SETTINGS: ISettings<number> };
  effectsConfig: TWidgetEffectsSettings;
  presetsConfig: TWidgetPresetConfig;
}> = ({ theme, effectsConfig, presetsConfig }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore} loading={null}>
        <LocaleService theme={theme}>
          <PresetService>
            <Content
              theme={theme}
              effectsConfig={effectsConfig}
              presetsConfig={presetsConfig}
            />
          </PresetService>
        </LocaleService>
      </PersistGate>
    </Provider>
  );
};
