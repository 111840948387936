import AccessEye from '../icons/accessEye';
import AccessIcon from '../icons/accessIcon';
import { EAccessButtonType } from '../styles';

export function getAccessIconByType(type?: EAccessButtonType) {
  switch (type) {
    case EAccessButtonType.HUMAN:
      return AccessIcon;
    case EAccessButtonType.EYE:
      return AccessEye;
    default:
      return AccessIcon;
  }
}
