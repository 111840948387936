import TranslationOutlined from '@ant-design/icons/lib/icons/TranslationOutlined';
import { Theme, withTheme } from '@emotion/react';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { FC, useCallback, useMemo, useState } from 'react';

import { IS_MODULE } from '../../constants';
import { useStatisticWriter } from '../../hooks/useStatisticWriter';
import { Localization, localeToText } from '../../locale';
import { useAppDispatch } from '../../state';
import { useLocaleSelector } from '../../state/reducers/app/selectors';
import { setLocale } from '../../state/reducers/app/services/localeSlice';
import { useWidgetSettingsSelector } from '../../state/reducers/widget/selectors';
import { setWidgetIncreasedAction } from '../../state/reducers/widget/slices/settingsSlice';
import { TStatEvent } from '../../types/statistic';
import { toTextBoolean } from '../../utils/common';
import { TabIndex } from '../../utils/tab-index';
import { MenuListGroup, MenuSubGroupTitle } from '../widget-menu';
import {
  SettingItem,
  SettingItemText,
  SettingItemUnButtoned,
} from './setting-item';

type TProps = {
  theme: Theme;
  t: TFunction<string>;
};

const WidgetSettings: FC<TProps> = ({ theme, t }) => {
  const { widgetIncreased } = useWidgetSettingsSelector();
  const dispatch = useAppDispatch();
  const { current } = useLocaleSelector();
  const [handleWriteStatistic] = useStatisticWriter();

  const [isIncreased, setIncreased] = useState(widgetIncreased);

  const handleLocaleChange = useCallback(
    (locale: Localization) => {
      if (current !== locale) {
        dispatch(setLocale(locale));
        void handleWriteStatistic({
          event: TStatEvent.CHANGE_LANGUAGE,
          text: 'Изменение языка виджета',
          time: dayjs().toISOString(),
          value: locale,
        });
      }
    },
    [current]
  );

  const items = useMemo(() => {
    const res: {
      key: Localization;
      label: string;
      onClick: () => void;
    }[] = [];

    for (const localizationKey in Localization) {
      // @ts-ignore
      const key: Localization =
        // @ts-ignore
        Localization[localizationKey as typeof Localization];

      res.push({
        key: key,
        label: localeToText(key),
        onClick: () => handleLocaleChange(key),
      });
    }

    return res;
  }, [handleLocaleChange]);

  // const handleChangeVoicingActions = useCallback(() => {
  //   dispatch(setVoicingActionsAction(!voicingActions));
  // }, [voicingActions]);

  const handleWidgetIncreased = useCallback(() => {
    setIncreased((prev) => !prev);
    if (!IS_MODULE) {
      dispatch(setWidgetIncreasedAction(!widgetIncreased));
    }
  }, [widgetIncreased]);

  return (
    <MenuListGroup
      theme={theme}
      widgetIncreased={toTextBoolean(widgetIncreased)}
    >
      <MenuSubGroupTitle
        theme={theme}
        widgetIncreased={toTextBoolean(widgetIncreased)}
      >
        {t('subGroups.widgetSettings')}
      </MenuSubGroupTitle>
      {/*<SettingItem*/}
      {/*  title={t('subGroups.voicingActionsTitle')}*/}
      {/*  value={voicingActions}*/}
      {/*  onClick={handleChangeVoicingActions}*/}
      {/*  widgetIncreased={widgetIncreased}*/}
      {/*  tabIndex={TabIndex.CLOSE_BUTTON + 1}*/}
      {/*/>*/}
      <SettingItem
        title={t('subGroups.widgetIncreasedTitle')}
        value={isIncreased}
        onClick={handleWidgetIncreased}
        widgetIncreased={widgetIncreased}
        tabIndex={TabIndex.CLOSE_BUTTON + 2}
        theme={theme}
      />
      <SettingItemUnButtoned
        style={{
          marginTop: 12,
        }}
        theme={theme}
        widgetIncreased={toTextBoolean(widgetIncreased)}
      >
        <SettingItemText
          widgetIncreased={toTextBoolean(widgetIncreased)}
          theme={theme}
        >
          {t('langSelect')}
        </SettingItemText>
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement={'bottomRight'}
        >
          <Button
            icon={<TranslationOutlined />}
            tabIndex={TabIndex.TRANSLATE_BUTTON}
            title={t('langSelect')}
            size={widgetIncreased ? 'large' : 'middle'}
            style={{
              backgroundColor: 'transparent !important',
            }}
          >
            {localeToText(current)}
          </Button>
        </Dropdown>
      </SettingItemUnButtoned>
    </MenuListGroup>
  );
};

export default withTheme(WidgetSettings);
